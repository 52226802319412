import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../types';
import { parsedRequestError } from '../utils/errors';
import useAuthRequest from './useAuthRequest';
import {
  AccountNameQuery,
  AccountNameQueryVariables,
} from '../gql/gqlRequests';
import { accountNameRequest } from '../support/accounts';

export default function useAccountName(accountId: string) {
  const isEnabled = !!accountId;
  const accountNameQueryFn = useAuthRequest<
    AccountNameQueryVariables,
    AccountNameQuery
  >(accountNameRequest);
  const accountNameQuery = useQuery<AccountNameQuery, RequestError>({
    queryKey: ['account-name', accountId],
    queryFn: () => accountNameQueryFn({ id: accountId }),
    enabled: isEnabled,
  });

  if (accountNameQuery.isError)
    throw parsedRequestError(accountNameQuery.error);

  return {
    isLoading: accountNameQuery.isLoading && isEnabled,
    accountName: accountNameQuery.data?.account.name ?? '',
  };
}
