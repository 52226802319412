import React, { useState } from 'react';
import RegistrationLinkExpired from './RegistrationLinkExpired';
import RegistrationLinkRequest from './RegistrationLinkRequest';
import RegistrationLinkSent from './RegistrationLinkSent';
/**
 * A sub-component of `Register`, handling expiration flow.
 */
export default function RegistrationExpired() {
  const [screen, setScreen] = useState<'expired' | 'request' | 'sent'>(
    'expired',
  );

  switch (screen) {
    case 'expired':
      return (
        <RegistrationLinkExpired
          onNewLinkRequest={() => setScreen('request')}
        />
      );

    case 'request':
      return <RegistrationLinkRequest onLinkSent={() => setScreen('sent')} />;

    case 'sent':
      return <RegistrationLinkSent />;

    default:
      throw new Error(`Unhandled screen state ${screen}`);
  }
}
