import { Avatar, Box, HStack, IconButton, Image, Text } from '@chakra-ui/react';
import React, { useCallback, useContext, useState } from 'react';
import { UserContext } from '../../../contexts';
import { Role } from '../../../gql/gqlRequests';
import { displayEnum } from '../../../utils';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
} from '../../common/Details';
import LabelAndValue from '../../common/LabelAndValue';
import Page from '../../common/Page';

import editIcon from '../../../assets/edit.svg';
import TextButton from '../../common/buttons/TextButton';
import { ChangePasswordDialog } from './ChangePasswordDialog';
import { strings } from '../../../utils/strings';
import { TwoFactorAuthenticationDialog } from './TwoFactorAuthenticationDialog';

export default function Profile() {
  const { loggedInUser } = useContext(UserContext);
  const {
    firstName,
    lastName,
    email,
    role,
    accountName,
    appName,
    isTwoFactorEnabled,
    phoneNumber,
  } = loggedInUser;

  const [isChangePasswordDialogOpen, setIsChangePasswordDialogOpen] =
    useState(false);
  const [
    isTwoFactorAuthenticationDialogOpen,
    setIsTwoFactorAuthenticationDialogOpen,
  ] = useState(false);

  const isSysAdmin = role === Role.SystemAdmin;

  const renderUserName = useCallback(() => {
    return (
      <HStack spacing="30px">
        <Avatar name={firstName} size="lg" variant="profile" />
        <Text as="h2" textStyle="h2">{`${firstName} ${lastName}`}</Text>
      </HStack>
    );
  }, [firstName, lastName]);

  const renderUserInfo = useCallback(() => {
    return (
      <SectionBody>
        <SectionGrid>
          <LabelAndValue label={strings.common.email} value={email} />
          <LabelAndValue
            label={strings.profile.role}
            value={displayEnum(role)}
          />
          {!isSysAdmin && (
            <LabelAndValue
              label={strings.profile.account}
              value={accountName}
            />
          )}
          {!isSysAdmin && (
            <LabelAndValue
              label={strings.apps.apps}
              value={appName.join(', ')}
            />
          )}
          <LabelAndValue
            label={strings.common.phoneNumber}
            value={phoneNumber || strings.common.notAvailable}
          />
          <LabelAndValue
            label={strings.profile.twoFactorAuthentication}
            labelIcon={
              !isSysAdmin && (
                <IconButton
                  aria-label={strings.profile.ariaEdit2fa}
                  icon={
                    <Image
                      src={editIcon}
                      width="16px"
                      height="16px"
                      alt={strings.common.edit}
                    />
                  }
                  onClick={() => setIsTwoFactorAuthenticationDialogOpen(true)}
                  isRound
                  size="xs"
                  backgroundColor="transparent"
                  _hover={{
                    backgroundColor: 'neutrals.brandGrey.50',
                  }}
                  _active={{
                    backgroundColor: 'neutrals.brandGrey.100',
                  }}
                />
              )
            }
            value={
              isTwoFactorEnabled
                ? strings.common.enabled
                : strings.common.disabled
            }
          />
        </SectionGrid>
      </SectionBody>
    );
  }, [
    email,
    role,
    isSysAdmin,
    accountName,
    appName,
    phoneNumber,
    isTwoFactorEnabled,
  ]);

  const renderChangePassword = useCallback(() => {
    return (
      <Box>
        <TextButton
          label={strings.profile.changePassword}
          onClick={() => setIsChangePasswordDialogOpen(true)}
          leftIcon={<Image src={editIcon} width="16px" height="16px" />}
        />
      </Box>
    );
  }, []);

  return (
    <Page title={strings.profile.title}>
      <Details>
        <DetailsSection>
          {renderUserName()}
          {renderUserInfo()}
        </DetailsSection>
        <DetailsSection>{renderChangePassword()}</DetailsSection>
      </Details>

      <ChangePasswordDialog
        isOpen={isChangePasswordDialogOpen}
        onClose={() => setIsChangePasswordDialogOpen(false)}
        email={email}
      />
      <TwoFactorAuthenticationDialog
        isOpen={isTwoFactorAuthenticationDialogOpen}
        onClose={() => setIsTwoFactorAuthenticationDialogOpen(false)}
        isTwoFactorEnabled={isTwoFactorEnabled}
        phoneNumber={phoneNumber ?? ''}
      />
    </Page>
  );
}
