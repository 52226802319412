import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Box } from '@chakra-ui/react';

import Authentication from '../../common/authentication/Authentication';
import StandardInput from '../../common/inputs/StandardInput';
import { loginRequest } from '../../../support/authentication';
import { getErrorCode } from '../../../utils/errors';
import { RequestError, RequestErrorCode } from '../../../types';
import { routes } from '../../../types//routes';
import {
  isValidEmailCharacter,
  validateEmail,
} from '../../../utils/validation';
import {
  LoginMutation,
  LoginMutationVariables,
  LoginResult,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

type SignInProps = {
  email: string;
  setEmail: (email: string) => void;
  setLoginResult: (loginResult: LoginResult) => void;
};

/**
 * Handles input of email address and password, and the login button.
 */
export default function SignIn({
  email,
  setEmail,
  setLoginResult,
}: SignInProps) {
  const loginMutation = useMutation<
    LoginMutation,
    RequestError,
    LoginMutationVariables
  >({
    mutationFn: loginRequest,
    onSuccess: (data) => setLoginResult(data.login),
    onError: applyBackendValidation,
  });

  function attemptToLogin() {
    loginMutation.reset();
    if (isInputValid) {
      loginMutation.mutate({ email, password });
    } else {
      applyFrontendValidation();
    }
  }

  // input state

  const [password, setPassword] = useState('');
  // note: email state comes from parent

  // validation

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;

  // backend validation

  function applyBackendValidation(e: RequestError) {
    switch (getErrorCode(e)) {
      case RequestErrorCode.UNAUTHENTICATED:
      case RequestErrorCode.BAD_USER_INPUT:
        setErrorMessage(strings.authentication.invalidCredentials);
        break;

      case RequestErrorCode.FORBIDDEN:
        setErrorMessage(strings.authentication.accountLocked);
        break;

      default:
        setErrorMessage(strings.errors.generic);
        break;
    }
  }

  const emailHasBackendError =
    loginMutation.isError &&
    [
      RequestErrorCode.UNAUTHENTICATED,
      RequestErrorCode.BAD_USER_INPUT,
      RequestErrorCode.FORBIDDEN,
    ].includes(getErrorCode(loginMutation.error));
  const passwordHasBackendError =
    loginMutation.isError &&
    [
      RequestErrorCode.UNAUTHENTICATED,
      RequestErrorCode.BAD_USER_INPUT,
      RequestErrorCode.FORBIDDEN,
    ].includes(getErrorCode(loginMutation.error));

  // frontend validation

  const isEmailValid = validateEmail(email);
  const isPasswordValid = !!password;
  const isInputValid = isEmailValid && isPasswordValid;

  function applyFrontendValidation() {
    if (!isEmailValid && !isPasswordValid) {
      setErrorMessage(strings.authentication.incompleteEmailAndPassword);
    } else if (!isEmailValid) {
      setErrorMessage(strings.authentication.incompleteEmail);
    } else if (!isPasswordValid) {
      setErrorMessage(strings.authentication.incompletePassword);
    }
  }

  // UI

  const signInButton = {
    text: strings.authentication.signIn,
    onClick: attemptToLogin,
    isDisabled: loginMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.logIn}
      instructions={strings.authentication.logInInstructions}
      buttonProps={signInButton}
    >
      <Box paddingTop="35px">
        <StandardInput
          label={strings.common.email}
          labelAsPlaceholder
          value={email}
          isValidCharacter={isValidEmailCharacter}
          setSanitizedValue={setEmail}
          isInvalid={
            hasTriedToSubmit && (!isEmailValid || emailHasBackendError)
          }
          isDisabled={loginMutation.isLoading || loginMutation.isSuccess}
        />
      </Box>
      <Box paddingTop="30px" paddingBottom="50px">
        <StandardInput
          isPassword
          label={strings.common.password}
          value={password}
          labelAsPlaceholder
          setSanitizedValue={setPassword}
          isInvalid={
            hasTriedToSubmit && (!isPasswordValid || passwordHasBackendError)
          }
          isDisabled={loginMutation.isLoading || loginMutation.isSuccess}
          rightLabel={{
            label: strings.authentication.forgotPassword,
            to: routes.forgotPassword,
            isExternal: false,
          }}
        />
      </Box>
    </Authentication>
  );
}
