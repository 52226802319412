import React from 'react';
import { Text, VStack, Image } from '@chakra-ui/react';
import { nameAsUrl } from '../../utils';
import ImageNotFound from './ImageNotFound';

type LabelAndImageProps = {
  label: string;
  src?: string | null;
  width?: string;
};

/**
 * Pass in label and image src string.
 */
export default function LabelAndImage({
  label,
  src,
  width,
}: LabelAndImageProps) {
  return (
    <VStack spacing="10px" width={width} align="left">
      <Text
        casing="uppercase"
        textStyle="subtitle4"
        textColor="neutrals.brandGrey.500"
        noOfLines={1}
      >
        {label}
      </Text>
      {src ? (
        <Image
          layerStyle="labelImage"
          src={src}
          alt={label}
          fallback={<ImageNotFound />}
          data-testid={`image-${nameAsUrl(label)}`}
        />
      ) : (
        <ImageNotFound />
      )}
    </VStack>
  );
}
