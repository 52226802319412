import validator from 'validator';
import { isValidPhoneNumber } from 'libphonenumber-js';
import {
  COLOR_CHARS,
  COLOR_REGEX,
  PASSWORD_CHARS,
  PHONE_CHARS,
  SEMANTIC_VERSION_REGEX,
  SPACE_CHAR,
} from './regexp';
import { Environment } from '../gql/gqlRequests';

// strings

export function validateUrl(url: string): boolean {
  return validator.isURL(url);
}

export function validateEmail(email: string): boolean {
  return validator.isEmail(email);
}

export function validatePhone(phone: string): boolean {
  return isValidPhoneNumber(phone, 'US');
}

export function validateSemanticVersion(string: string): boolean {
  return SEMANTIC_VERSION_REGEX.test(string);
}

export function validateEnv(str: string | null) {
  return Object.values(Environment)
    .map((x) => x.toString())
    .includes(str ?? '');
}

export function validateColor(str: string) {
  return COLOR_REGEX.test(str);
}

export function isJson(item: any) {
  let value = typeof item !== 'string' ? JSON.stringify(item) : item;
  try {
    value = JSON.parse(value);
  } catch (e) {
    return false;
  }

  return typeof value === 'object' && value !== null;
}

// characters

// cognito will throw an error if there's a space in an email
export function isValidEmailCharacter(char: string) {
  return !SPACE_CHAR.test(char);
}

export function isValidPasswordCharacter(char: string) {
  return PASSWORD_CHARS.test(char);
}

export function isValidPhoneNumberCharacter(char: string) {
  return PHONE_CHARS.test(char);
}

export function isValidColorCharacter(char: string) {
  return COLOR_CHARS.test(char);
}
