import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Input,
  InputProps,
  StyleProps,
  Text,
  TextProps,
} from '@chakra-ui/react';
import React from 'react';
import Tooltip from '../Tooltip';
import FileInput, { FileInputProps } from './FileInput';

type RowProps = {
  label: string;
  tooltipText?: string;
  actionElement?: React.ReactNode;
  containerStyle?: StyleProps;
  labelProps?: TextProps;
  children?: React.ReactNode;
};

type RowInputProps = RowProps & {
  inputProps?: InputProps;
  'data-testid'?: string;
};

type RowFileInputProps = RowProps & {
  fileInputProps: FileInputProps;
};

export default function RowInput(props: RowInputProps) {
  const { inputProps, 'data-testid': testId } = props;
  return (
    <Row {...props}>
      <Input
        data-testid={testId}
        placeholder={inputProps?.placeholder || props.label}
        {...inputProps}
      />
    </Row>
  );
}

export function RowFileInput(props: RowFileInputProps) {
  const { fileInputProps } = props;
  return (
    <Row {...props}>
      <FileInput {...fileInputProps} />
    </Row>
  );
}

export function Row({
  label,
  tooltipText,
  actionElement,
  containerStyle,
  labelProps,
  children,
}: RowProps) {
  return (
    <FormControl {...containerStyle}>
      <FormLabel>
        <HStack
          textStyle="subtitle3"
          color="neutrals.navigationOutline"
          width="274px"
          spacing="15px"
          {...labelProps}
        >
          <Text>{label}</Text>
          {tooltipText && <Tooltip tipText={tooltipText} />}
        </HStack>
      </FormLabel>
      {children}
      {actionElement}
    </FormControl>
  );
}

export function RowDetail({
  label,
  tooltipText,
  actionElement,
  containerStyle,
  labelProps,
  children,
}: RowProps) {
  return (
    <Box {...containerStyle}>
      <HStack
        textStyle="subtitle3"
        color="neutrals.navigationOutline"
        width="274px"
        spacing="15px"
        {...labelProps}
      >
        <Text role="metaphrase" aria-label={label}>
          {label}
        </Text>
        {tooltipText && <Tooltip tipText={tooltipText} />}
      </HStack>
      {children}
      {actionElement}
    </Box>
  );
}
