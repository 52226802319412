import { useEffect, useState } from 'react';
import { debounce } from '../utils';

const resizeDebounceInterval = 500;

export type WindowSize = {
  width: number;
  height: number;
};

export function useWindowSize() {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function handleResize() {
    debounce(
      () =>
        setWindowSize({ width: window.innerWidth, height: window.innerHeight }),
      resizeDebounceInterval,
    )();
  }

  return windowSize;
}
