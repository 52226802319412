import React from 'react';
import Button from './Button';

type SecondaryButtonProps = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

/**
 * The other, outline button, with less padding.
 */
export default function SecondaryButton({
  label,
  onClick,
  isDisabled = false,
}: SecondaryButtonProps) {
  return (
    <Button
      type="button"
      variant="secondary"
      label={label}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}
