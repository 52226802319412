import React, { JSXElementConstructor, ReactElement } from 'react';
import { AddIcon } from '@chakra-ui/icons';
import Button from './Button';

type CreateButtonProps = {
  label: string;
  leftIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  onClick: () => void;
  isDisabled?: boolean;
  withoutIcon?: boolean;
};

/**
 * A different outline button, the narrowest.
 * Usually with a `+` symbol (omit via `withoutIcon`).
 */
export default function CreateButton({
  label,
  leftIcon = <AddIcon data-testid="plus-icon" />,
  onClick,
  isDisabled = false,
  withoutIcon = false,
}: CreateButtonProps) {
  return (
    <Button
      type="button"
      variant="secondary"
      label={label}
      onClick={onClick}
      isDisabled={isDisabled}
      leftIcon={withoutIcon ? undefined : leftIcon}
      iconSpacing="15px"
    />
  );
}
