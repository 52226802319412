import React from 'react';
import ImageInput from '../../common/inputs/ImageInput';
import { ImageType } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

type ScreenContextInputsProps = {
  value: string;
  onChange: (value: string) => void;
  isInvalid: boolean;
  isDisabled?: boolean;
};

export function BackgroundImageInput({
  onChange,
  value,
  isInvalid,
  isDisabled = false,
}: ScreenContextInputsProps) {
  return (
    <ImageInput
      label={strings.screenContexts.backgroundImage}
      imageType={ImageType.Background}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
    />
  );
}

export function PrimaryBackgroundImageInput({
  onChange,
  value,
  isInvalid,
}: ScreenContextInputsProps) {
  return (
    <ImageInput
      label={strings.screenContexts.primaryBackgroundImage}
      imageType={ImageType.Background}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid}
    />
  );
}

export function SecondaryBackgroundImageInput({
  onChange,
  value,
  isInvalid,
}: ScreenContextInputsProps) {
  return (
    <ImageInput
      label={strings.screenContexts.secondaryBackgroundImage}
      imageType={ImageType.Background}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid}
    />
  );
}

export function IconInput({
  onChange,
  value,
  isInvalid,
  isDisabled,
}: ScreenContextInputsProps) {
  return (
    <ImageInput
      label={strings.screenContexts.icon}
      imageType={ImageType.Standard}
      value={value}
      onChange={onChange}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
    />
  );
}
