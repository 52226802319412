import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { NavEntry } from '../types';

export default function useActiveNavLink(navEntry: NavEntry) {
  const location = useLocation();
  const pathArray = useMemo(() => location.pathname.split('/'), [location]);
  const isNameInPath = pathArray.includes(navEntry.toString().toLowerCase());

  // account works differently: should additionally be highlighted on app pages
  // app works differently: name is "app" but url uses "apps" (and most urls have "apps" but shouldn't match)
  const isAppDetailPage = pathArray.length <= 3 && pathArray[1] === 'apps';
  const isEditAppPage =
    pathArray.length === 4 &&
    pathArray[1] === 'apps' &&
    pathArray[3] === 'edit';
  const isExceptionActive =
    (navEntry === NavEntry.APP || navEntry === NavEntry.ACCOUNTS) &&
    (isAppDetailPage || isEditAppPage);

  return isNameInPath || isExceptionActive;
}
