import React from 'react';
import { Center, Image, Text, VStack } from '@chakra-ui/react';
import notFoundIcon from '../../assets/not-found.svg';
import { strings } from '../../utils/strings';
import { spacing } from '../../themes/constants';

export default function ImageNotFound() {
  return (
    <Center layerStyle="imageNotFound">
      <VStack spacing={spacing[15]}>
        <Image src={notFoundIcon} alt={strings.errors.imageNotFoundIcon} />
        <Text textStyle="bodyCopySmall" color="neutrals.brandGrey.300">
          {strings.errors.unableToLoadImage}
        </Text>
      </VStack>
    </Center>
  );
}
