import React, { useContext, useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import StandardTable from '../../common/tables/StandardTable';
import { ScreenFeed, ScreenQuery } from '../../../gql/gqlRequests';
import { displayEnum } from '../../../utils';
import { strings } from '../../../utils/strings';
import TextButton from '../../common/buttons/TextButton';
import { useNavigate, useParams } from 'react-router-dom';
import { routes } from '../../../types/routes';
import { AppContext } from '../../../contexts';

// createColumnHelper causes a ts error if any nested types appear in it
type ScreenFeedRow = Pick<ScreenFeed, 'id'> & {
  layout: string;
  action: string;
  itemLayout: string;
  feed: string;
  feedTitle: string;
  order: number;
};

type ScreenFeedDetailProps = {
  screenFeeds: ScreenQuery['screen']['screenFeeds'];
};

export default function ScreenFeedTable({
  screenFeeds,
}: ScreenFeedDetailProps) {
  const navigate = useNavigate();
  const { appId } = useContext(AppContext);
  const params = useParams();

  // table columns
  const columnHelper = createColumnHelper<ScreenFeedRow>();
  const columns = [
    columnHelper.accessor('order', {}),
    columnHelper.accessor('id', {}),
    columnHelper.accessor('feed', {
      header: strings.screenFeed.screenFeed,
      sortingFn: 'alphanumeric',
      cell: (info) => (
        <TextButton
          label={info.row.original.feedTitle}
          onClick={() => {
            const feedId = info.row.original.feed;
            navigate(
              routes.appScreenFeedDetail({
                appId: appId,
                screenId: params.screenId as string,
                feedId: feedId,
              }),
            );
          }}
        />
      ),
    }),
    columnHelper.accessor('layout', {
      header: strings.screens.layoutType,
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor('action', {
      header: strings.common.action,
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor('itemLayout', {
      header: strings.screens.itemLayout,
      sortingFn: 'alphanumeric',
    }),
  ];

  // table data
  const screenFeedData = useMemo(
    () =>
      screenFeeds.map(({ id, feed, layout, action, itemLayout, order }) => ({
        id,
        feed: feed.id,
        feedTitle: feed.name,
        layout: displayEnum(layout),
        action: displayEnum(action),
        itemLayout: displayEnum(itemLayout),
        order,
      })),
    [screenFeeds],
  );

  // UI

  return (
    <StandardTable
      title={strings.screens.allScreenFeeds}
      canSearch={false}
      data={screenFeedData}
      columns={columns}
      boldColumn="feed"
      defaultSortColumn="order"
      withOuterBorder
      withoutMenuCol
    />
  );
}
