import {
  Box,
  Button,
  HStack,
  IconButton,
  Image,
  Input,
  Text,
} from '@chakra-ui/react';
import React, { createRef, Fragment, useState } from 'react';
import { strings } from '../../../utils/strings';
import trashIcon from '../../../assets/trash.svg';
import downloadIcon from '../../../assets/download.svg';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { UploadFileResponse, UploadFileVariables } from '../../../types/file';
import { fileRequest, uploadFileRequest } from '../../../support/file';
import { testIds } from '../../../utils/testIds';
import {
  File as FileMetadata,
  FileQuery,
  FileQueryVariables,
} from '../../../gql/gqlRequests';
import {
  downloadResource,
  getFileSizeText,
  translateUploadFileResponseToFileMetadata,
} from '../../../utils/file';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';

export const maxFileSize = 1000000;

export type FileInputProps = {
  fileInputRef?: React.RefObject<HTMLInputElement>;
  fileKey: string;
  setFileKey: React.Dispatch<string>;
  isDisabled?: boolean;
  isInvalid?: boolean;
  width?: string;
};

export default function FileInput(props: FileInputProps) {
  const [fileMetadata, setFileMetadata] = useState<FileMetadata>();
  const {
    fileInputRef = createRef<HTMLInputElement>(),
    fileKey,
    setFileKey,
    isDisabled,
    isInvalid = false,
    width = '400px',
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [isUploading, setIsUploading] = useState(false);

  const fileRequestFn = useAuthRequest<FileQueryVariables, FileQuery>(
    fileRequest,
  );
  const fileRequestQuery = useQuery<FileQuery, RequestError>({
    queryKey: ['file', fileKey],
    queryFn: () => fileRequestFn({ key: fileKey }),
    enabled: !!fileKey,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: onFetchFileSuccess,
    onError: onFetchFileError,
  });
  const { isFetching: isFetchingFile } = fileRequestQuery;

  const uploadFileRequestFn = useAuthRequest<
    UploadFileVariables,
    UploadFileResponse
  >(uploadFileRequest);

  function onFetchFileSuccess(data: FileQuery) {
    const { file } = data;
    setFileMetadata({
      key: file.key,
      url: file.url,
      filename: file.filename,
      size: file.size,
    });
    setErrorMessage('');
  }

  const isLoading = isFetchingFile || isUploading;

  function onFetchFileError(_err: RequestError) {
    setErrorMessage(strings.errors.generic);
  }

  const setFileHelper = (metadata?: FileMetadata) => {
    setFileMetadata(metadata);
    setFileKey(metadata?.key ?? '');
  };

  const downloadFile = () => {
    const file = fileInputRef.current?.files?.[0];

    if (fileMetadata) {
      downloadResource({ url: fileMetadata.url, name: fileMetadata.filename });
    } else if (file) {
      downloadResource(file);
    }
  };

  const removeUploadedFile = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.value = '';
    setFileHelper(undefined);
  };

  const handleOnFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return setFileHelper(undefined);
    } else if (file.size > maxFileSize) {
      removeUploadedFile();
      return setErrorMessage(
        strings.errors.fileSizeExceeded({ size: getFileSizeText(maxFileSize) }),
      );
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      setIsUploading(true);
      const response = await uploadFileRequestFn({ formData });
      setFileHelper(translateUploadFileResponseToFileMetadata(response));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage(strings.errors.generic);
      removeUploadedFile();
    }

    setIsUploading(false);
  };

  const triggerFileRefClickAndDisable = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.disabled = false;
    fileInputRef.current.click();
    fileInputRef.current.disabled = true;
  };

  const renderFileInfo = () => {
    if (errorMessage) {
      return (
        <Text textStyle="bodyCopySmall" color="system.error.700">
          {errorMessage}
        </Text>
      );
    }

    if (!fileMetadata) {
      return (
        <Text
          textStyle="bodyCopySmall"
          color={isInvalid ? 'system.error.700' : 'neutrals.brandGrey.500'}
        >
          {strings.files.noFileSelected}
        </Text>
      );
    } else {
      return (
        <Box
          layerStyle="fileInfo"
          backgroundColor={
            isDisabled ? 'neutrals.brandGrey.50' : 'neutrals.staticWhite'
          }
          borderColor={
            isDisabled ? 'neutrals.brandGrey.50' : 'neutrals.brandGrey.300'
          }
        >
          <HStack spacing="4px">
            <IconButton
              variant="iconButton"
              aria-label={strings.files.ariaDownloadFile}
              size="sm"
            >
              <Image src={downloadIcon} onClick={downloadFile} />
            </IconButton>
            <Text
              textStyle="bodyCopySmall"
              color="neutrals.brandGrey.500"
              noOfLines={1}
              wordBreak="break-word"
            >
              {`${fileMetadata.filename} (${getFileSizeText(fileMetadata)})`}
            </Text>
          </HStack>
          {!isDisabled && (
            <IconButton
              variant="iconButton"
              aria-label={strings.files.ariaDeleteFile}
              size="sm"
              onClick={removeUploadedFile}
            >
              <Image src={trashIcon} />
            </IconButton>
          )}
        </Box>
      );
    }
  };

  return (
    <Fragment>
      <HStack spacing="20px" width={width}>
        {!isDisabled && (
          <Button
            variant="fileUploadButton"
            onClick={triggerFileRefClickAndDisable}
            isDisabled={isLoading}
          >
            {strings.common.choose}
          </Button>
        )}
        {renderFileInfo()}
      </HStack>
      <Input
        type="file"
        hidden
        disabled
        ref={fileInputRef}
        onChange={handleOnFileUpload}
        data-testid={testIds.file_upload_input}
      />
    </Fragment>
  );
}
