import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { screensRequest } from '../../../support/screens';
import { displayScreenType } from '../../../utils';
import Page from '../../common/Page';
import StandardTable from '../../common/tables/StandardTable';
import Link from '../../common/Link';
import { AppContext, EnvironmentContext } from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import PlatformIconsList from '../../common/PlatformIconsList';
import DeleteScreenDialog from './DeleteScreenDialog';
import {
  Environment,
  Screen,
  ScreensQuery,
  ScreensQueryVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

type ScreenRow = Pick<Screen, 'createdAt' | 'id' | 'name' | 'platforms'> & {
  type: string;
};

const columnHelper = createColumnHelper<ScreenRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.screens.screen,
    cell: (info) => <Link label={info.getValue()} to={info.row.original.id} />,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('type', {
    header: strings.common.type,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('platforms', {
    header: strings.platforms.platforms,
    cell: (info) => <PlatformIconsList platforms={info.getValue()} />,
  }),
];

export default function Screens() {
  const navigate = useNavigate();
  const { appId } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);

  // screens associated with app (for selected environment)

  const screensQueryFn = useAuthRequest<ScreensQueryVariables, ScreensQuery>(
    screensRequest,
  );
  const screensQuery = useQuery<ScreensQuery, RequestError>({
    queryKey: ['screens', appId, environment],
    queryFn: () => screensQueryFn({ appId, environment }),
  });

  const screens = screensQuery.data?.screens;

  // table data

  // note: platforms stored as object so can't search/filter for them
  const screensData =
    screens?.map(({ type, ...rest }) => ({
      type: displayScreenType(type),
      ...rest,
    })) ?? [];

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();

  // UI

  if (screensQuery.isError) throw parsedRequestError(screensQuery.error);

  return (
    <Page withEnvironmentBar title={strings.screens.screens}>
      <StandardTable
        title={strings.screens.allScreens}
        isLoading={screensQuery.isLoading}
        data={screensData}
        columns={columns}
        onEditRow={
          environment !== Environment.Production
            ? (screenId: string) =>
                navigate(routes.editAppScreen({ appId, screenId }))
            : undefined
        }
        onDeleteRow={
          environment !== Environment.Production ? setIdToDelete : undefined
        }
        onCreate={
          environment !== Environment.Production
            ? () => navigate(routes.createAppScreen({ appId }))
            : undefined
        }
        createButtonTitle={strings.screens.createScreen}
        boldColumn="name"
      />
      <DeleteScreenDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        screens={screens}
        onSuccess={() => screensQuery.refetch()}
        onClose={() => setIdToDelete(undefined)}
      />
    </Page>
  );
}
