import React, { ReactNode } from 'react';
import { Box, HStack, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { spacing } from '../../themes/constants';

type DetailsProps = {
  children: ReactNode;
};

/** Children should consist only of `DetailsSection`s. */
export default function Details({ children }: DetailsProps) {
  return (
    <VStack spacing={spacing[30]} align="left">
      {children}
    </VStack>
  );
}

type DetailsSectionProps = {
  singleRow?: boolean;
  children: ReactNode;
};

/**
 * Children should consist only of a `SectionTitle`, and a `SectionBody`.
 * Make sure to specify if there's a single row of data in the body -
 * it changes the padding.
 */
export function DetailsSection({
  singleRow = false,
  children,
}: DetailsSectionProps) {
  return (
    <VStack
      layerStyle="detailsSection"
      align="left"
      spacing={spacing[singleRow ? 30 : 40]}
    >
      {children}
    </VStack>
  );
}

type SectionTitleProps = {
  title: string;
  children?: ReactNode;
};

/** For an edit icon on the right, provide a `Spacer` and the icon as children. */
export function SectionTitle({ title, children }: SectionTitleProps) {
  return (
    <HStack spacing="0">
      <Text as="h2" textStyle="h2" color="neutrals.navigationOutline">
        {title}
      </Text>
      {children}
    </HStack>
  );
}

type SectionBodyProps = {
  children?: ReactNode;
};

/** Children should consist only of one or more `SectionGrid`s. */
export function SectionBody({ children }: SectionBodyProps) {
  return (
    <VStack spacing={spacing[40]} align="left">
      {children}
    </VStack>
  );
}

type SectionGridProps = {
  colCount?: number;
  children: ReactNode;
};

/** Use `SectionEmptyCell` to 'skip' a spot in the grid. */
export function SectionGrid({ colCount = 2, children }: SectionGridProps) {
  return (
    <SimpleGrid
      templateColumns={`repeat(${colCount}, 1fr)`}
      spacingX={spacing[30]}
      spacingY={spacing[40]}
    >
      {children}
    </SimpleGrid>
  );
}

export function SectionEmptyCell() {
  return <Box />;
}
