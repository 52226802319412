import { LayerStyle } from '.';

const tableTitle: LayerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '30px 40px',
};

const tableLoadingOverlay: LayerStyle = {
  zIndex: 'overlay',
  opacity: '60%',
  position: 'absolute',
  backgroundColor: 'neutrals.brandGrey.50',
  width: '100%',
  height: '100%',
};

const tableLoadingIndicator: LayerStyle = {
  zIndex: 'overlay',
  opacity: '100%',
  position: 'absolute',
  width: '100%',
  height: '100%',
};

export const tableLayerStyles = {
  tableTitle,
  tableLoadingOverlay,
  tableLoadingIndicator,
};
