import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { TokenContext } from '../../contexts';
import { routes } from '../../types/routes';

type RequiresAuthenticationProps = {
  children: JSX.Element;
};

/**
 * Kicks out users who don't have a refresh token immediately. Users with a
 * fake token will be kicked out as soon as the queries in ContextsProvider
 * fail - see `useAuthRequest` hook which they all use.
 */
export default function RequiresAuthentication({
  children,
}: RequiresAuthenticationProps) {
  // authentication logic

  const { refreshToken } = useContext(TokenContext);

  if (!refreshToken) {
    return <Navigate to={routes.login} />;
  }

  return children;
}
