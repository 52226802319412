import React, { Fragment, useContext } from 'react';
import { HStack, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import {
  Environment,
  FlowConfig,
  SubscriptionProduct,
} from '../../../gql/gqlRequests';
import { displayEnum, displayFeature, temporaryLocales } from '../../../utils';
import { AppContext, EnvironmentContext } from '../../../contexts';
import Link from '../../common/Link';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import LabelAndValue from '../../common/LabelAndValue';
import editIcon from '../../../assets/edit.svg';
import { strings } from '../../../utils/strings';
import { Route } from '../../../types/routes';
import { testIds } from '../../../utils/testIds';

type PlatformConfigProps = {
  configData: Pick<
    FlowConfig,
    | 'id'
    | 'name'
    | 'minSDKVersion'
    | 'flowVersion'
    | 'features'
    | 'certificationStatus'
  > & {
    subscriptionProducts: Pick<
      SubscriptionProduct,
      'id' | 'name' | 'isInUse'
    >[];
  };
};

export default function PlatformConfig({ configData }: PlatformConfigProps) {
  const { environment } = useContext(EnvironmentContext);
  const { defaultLocale } = useContext(AppContext);

  const showEditLink = environment !== Environment.Production;

  const inUseSubscriptionProducts = configData.subscriptionProducts.filter(
    ({ isInUse }) => !!isInUse,
  );

  return (
    <Fragment>
      <HStack spacing={0} paddingTop="40px" paddingBottom="20px" align="end">
        <Text textStyle="h2">{strings.platforms.platformConfiguration}</Text>
        <Spacer />
        {showEditLink && (
          <Link label={strings.platforms.editConfiguration} to={Route.Edit}>
            <Image src={editIcon} />
          </Link>
        )}
      </HStack>

      <Details>
        <DetailsSection singleRow>
          <SectionTitle title={strings.common.version} />
          <SectionBody>
            <SectionGrid>
              <LabelAndValue
                label={strings.platforms.minPlatformSdkVersion}
                value={configData.minSDKVersion}
                testId={testIds.min_platform_sdk_version}
              />
              <LabelAndValue
                label={strings.platforms.currentAppVersion}
                value={configData.flowVersion}
                testId={testIds.app_version}
              />

              <LabelAndValue
                label={strings.platforms.certificationStatus}
                value={displayEnum(configData.certificationStatus ?? '')}
              />
            </SectionGrid>
          </SectionBody>
        </DetailsSection>

        <DetailsSection singleRow>
          <SectionTitle title={strings.platforms.featuresAndSubscriptions} />
          <SectionBody>
            <SectionGrid>
              <LabelAndValue
                label={strings.platforms.features}
                value={
                  configData.features.length === 0
                    ? strings.common.emptyPlaceholder
                    : undefined
                }
              >
                <VStack align="left" spacing="15px">
                  {configData.features.map((feature) => (
                    <Text key={feature} data-testid={testIds.feature}>
                      {displayFeature(feature)}
                    </Text>
                  ))}
                </VStack>
              </LabelAndValue>
              <LabelAndValue
                label={strings.common.subscriptions}
                value={
                  inUseSubscriptionProducts.length === 0
                    ? strings.common.emptyPlaceholder
                    : undefined
                }
              >
                <VStack
                  align="left"
                  spacing="15px"
                  data-testid={testIds.subscriptions}
                >
                  {inUseSubscriptionProducts.map(({ id, name }) => (
                    <Text key={id}>{name}</Text>
                  ))}
                </VStack>
              </LabelAndValue>
            </SectionGrid>
          </SectionBody>
        </DetailsSection>

        <DetailsSection singleRow>
          <SectionTitle title={strings.common.localization} />
          <SectionBody>
            <LabelAndValue label={strings.common.locales}>
              <VStack align="left" spacing="15px" data-testid={testIds.locales}>
                {temporaryLocales.map((locale) => (
                  // todo v1.1: locales
                  <Text key={locale}>
                    {locale === defaultLocale.name
                      ? strings.common.localeDefault({ locale })
                      : locale}
                  </Text>
                ))}
              </VStack>
            </LabelAndValue>
          </SectionBody>
        </DetailsSection>
      </Details>
    </Fragment>
  );
}
