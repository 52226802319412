import React, { MouseEventHandler } from 'react';
import { Button as ChakraButton, ButtonProps, Text } from '@chakra-ui/react';

type ButtonPropsType = ButtonProps & {
  label?: string;
  onClick: () => void;
  withoutColorScheme?: boolean;
};

/**
 * This `Button` should only be used for exceptions. Usually one of
 * `PrimaryButton`, `SecondaryButton`, `CreateButton`, or `TextButton`
 * will work. The `label` is ignored if `children` are provided.
 */
export default function Button(props: ButtonPropsType) {
  const { label, withoutColorScheme = false, ...rest } = props;

  // this is required for when the button is type 'submit'
  const handleClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    props.onClick();
  };

  return (
    <ChakraButton
      // default scheme is secondaryBlue for all other components
      colorScheme={withoutColorScheme ? '' : 'primary.primaryBlue'}
      type="button"
      variant="solid"
      {...rest}
      onClick={handleClick}
    >
      {props.children || <Text textStyle={props.textStyle}>{label}</Text>}
    </ChakraButton>
  );
}
