import { createGqlAuthRequest } from '.';
import {
  AllPlatformsDocument,
  AllPlatformsQuery,
  AllPlatformsQueryVariables,
  AppConfigsDocument,
  AppConfigsQuery,
  AppConfigsQueryVariables,
  ConfigDocument,
  ConfigIdDocument,
  ConfigIdQuery,
  ConfigIdQueryVariables,
  ConfigQuery,
  ConfigQueryVariables,
  CreateAppPlatformDocument,
  CreateAppPlatformMutation,
  CreateAppPlatformMutationVariables,
  DeleteAppPlatformDocument,
  DeleteAppPlatformMutation,
  DeleteAppPlatformMutationVariables,
  UpdateConfigDocument,
  UpdateConfigMutation,
  UpdateConfigMutationVariables,
} from '../gql/gqlRequests';

export const allPlatformsRequest = createGqlAuthRequest<
  AllPlatformsQueryVariables,
  AllPlatformsQuery
>(AllPlatformsDocument);

export const appConfigsRequest = createGqlAuthRequest<
  AppConfigsQueryVariables,
  AppConfigsQuery
>(AppConfigsDocument);

export const configRequest = createGqlAuthRequest<
  ConfigQueryVariables,
  ConfigQuery
>(ConfigDocument);

export const configIdRequest = createGqlAuthRequest<
  ConfigIdQueryVariables,
  ConfigIdQuery
>(ConfigIdDocument);

export const createAppPlatformRequest = createGqlAuthRequest<
  CreateAppPlatformMutationVariables,
  CreateAppPlatformMutation
>(CreateAppPlatformDocument);

export const updateConfigRequest = createGqlAuthRequest<
  UpdateConfigMutationVariables,
  UpdateConfigMutation
>(UpdateConfigDocument);

export const deleteAppPlatformRequest = createGqlAuthRequest<
  DeleteAppPlatformMutationVariables,
  DeleteAppPlatformMutation
>(DeleteAppPlatformDocument);
