import React, { useContext, useEffect, useState } from 'react';
import { LoginChallenge, LoginResult } from '../../../gql/gqlRequests';
import SignIn from './SignIn';
import VerificationCode from './VerificationCode';
import { TokenContext } from '../../../contexts';
import { useNavigate } from 'react-router-dom';
import AuthenticationLoading from '../../common/authentication/AuthenticationLoading';
import { routes } from '../../../types/routes';
import NewPasswordRequired from './NewPasswordRequired';

/**
 * Entry point for logging in to the app. Will decide whether to show sign in
 * screen, MFA screen, etc.
 */
export default function Login() {
  const navigate = useNavigate();
  const { refreshToken, accessToken, setRefreshToken, setAccessToken } =
    useContext(TokenContext);

  // TODO when MFA can be easily enabled: if LoginResult includes the email (like it includes phone number) then can move email state down to `SignIn`
  const [email, setEmail] = useState('');
  const [loginResult, setLoginResult] = useState<LoginResult>();
  const hasSuccessfullyLoggedIn = loginResult?.challengeName === null;

  useEffect(
    function tryToSetTokensOnSuccessfulLogin() {
      if (hasSuccessfullyLoggedIn) {
        tryToSetTokens(loginResult);
      }

      // TODO: overlap (repeat) with Register code
      function tryToSetTokens(loginResult: LoginResult) {
        const { authenticationResult } = loginResult;
        if (authenticationResult?.accessToken) {
          setAccessToken(authenticationResult.accessToken);
        } else {
          throw new Error('Successful login did not return an access token.');
        }
        if (authenticationResult?.refreshToken) {
          setRefreshToken(authenticationResult.refreshToken);
        } else {
          throw new Error('Successful login did not return a refresh token.');
        }
      }
    },
    [loginResult, hasSuccessfullyLoggedIn, setAccessToken, setRefreshToken],
  );

  /* Note: This will immediately redirect the user to the landing page if
   * they visit `/login` while already logged in. Otherwise, it will do so
   * as soon as they have successfully logged in.
   */
  useEffect(
    function navigateToLandingWhenAuthenticated() {
      if (!!refreshToken && !!accessToken) {
        navigate(routes.root);
      }
    },
    [navigate, refreshToken, accessToken],
  );

  switch (loginResult?.challengeName) {
    case null:
      // successful login, is setting tokens
      return <AuthenticationLoading />;

    case undefined:
      // no login has been attempted yet
      return (
        <SignIn
          email={email}
          setEmail={setEmail}
          setLoginResult={setLoginResult}
        />
      );

    case LoginChallenge.SmsMfa:
      return (
        <VerificationCode
          email={email}
          session={loginResult.session ?? ''}
          phoneNumberEnd={
            loginResult.challengeParameters?.CODE_DELIVERY_DESTINATION
          }
          setLoginResult={setLoginResult}
        />
      );

    case LoginChallenge.NewPasswordRequired:
      return (
        <NewPasswordRequired session={loginResult.session} email={email} />
      );

    default:
      throw new Error(
        `Unhandled LoginChallenge: ${loginResult?.challengeName}`,
      );
  }
}
