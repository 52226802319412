import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Environment } from '../gql/gqlRequests';
import { validateEnv } from '../utils/validation';

export const environmentKey = 'environment';
const sessionStorage = window.sessionStorage;

export const envSearchParamKey = 'env';
export const defaultEnvironment = Environment.Production;

/**
 * Only call this hook once, in ContextsProvider.tsx - otherwise,
 * get these values from the environment context.
 */
export default function useEnvironment() {
  const [searchParams] = useSearchParams();
  const searchParamEnv = searchParams.get(envSearchParamKey);
  const isSearchParamValid = validateEnv(searchParamEnv);
  const savedEnv = sessionStorage.getItem(environmentKey);
  const isSavedValid = validateEnv(savedEnv);
  const initialEnv = isSearchParamValid
    ? (searchParamEnv as Environment)
    : isSavedValid
    ? (savedEnv as Environment)
    : defaultEnvironment;

  const [env, setEnv] = useState(initialEnv);
  // ignore dependency array complaints - this should only happen ONCE
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => sessionStorage.setItem(environmentKey, initialEnv), []);

  const setEnvironment = useCallback(async (newEnv: Environment) => {
    sessionStorage.setItem(environmentKey, newEnv);
    setEnv(newEnv);
  }, []);

  return {
    environment: env,
    setEnvironment,
  };
}
