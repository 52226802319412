import { createGqlAuthRequest } from '.';
import {
  CreateFeedDocument,
  CreateFeedMutation,
  CreateFeedMutationVariables,
  DeleteFeedDocument,
  DeleteFeedMutation,
  DeleteFeedMutationVariables,
  FeedDocument,
  FeedQuery,
  FeedQueryVariables,
  FeedsDocument,
  FeedsQuery,
  FeedsQueryVariables,
  UpdateFeedDocument,
  UpdateFeedMutation,
  UpdateFeedMutationVariables,
  UsedFeedIntegrationByAppDocument,
  UsedFeedIntegrationByAppQuery,
  UsedFeedIntegrationByAppQueryVariables,
} from '../gql/gqlRequests';

export const feedsRequest = createGqlAuthRequest<
  FeedsQueryVariables,
  FeedsQuery
>(FeedsDocument);

export const feedRequest = createGqlAuthRequest<FeedQueryVariables, FeedQuery>(
  FeedDocument,
);

export const usedIntegrationRequest = createGqlAuthRequest<
  UsedFeedIntegrationByAppQueryVariables,
  UsedFeedIntegrationByAppQuery
>(UsedFeedIntegrationByAppDocument);

export const createFeedRequest = createGqlAuthRequest<
  CreateFeedMutationVariables,
  CreateFeedMutation
>(CreateFeedDocument);

export const updateFeedRequest = createGqlAuthRequest<
  UpdateFeedMutationVariables,
  UpdateFeedMutation
>(UpdateFeedDocument);

export const deleteFeedRequest = createGqlAuthRequest<
  DeleteFeedMutationVariables,
  DeleteFeedMutation
>(DeleteFeedDocument);
