import React, { useEffect, useReducer, useState } from 'react';
import { Box, FormLabel, Text } from '@chakra-ui/react';
import {
  Feed,
  ScreenAction,
  ScreenItemLayout,
  ScreenLayout,
  Screen_ScreenFeedInput,
} from '../../../gql/gqlRequests';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import Form, { FormError, FormGrid } from '../../common/Form';
import Select from '../../common/inputs/Select';
import RadioGroup, { Radio } from '../../common/inputs/RadioGroup';
import NumberInput from '../../common/inputs/NumberInput';
import Toggle from '../../common/inputs/Toggle';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import TextButton from '../../common/buttons/TextButton';
import { strings } from '../../../utils/strings';
import { useParams } from 'react-router-dom';
import { routes } from '../../../types/routes';

// reducer //

type InputState = {
  feedId: string | null;
  feedTitle: string | null;
  layout: ScreenLayout;
  itemLayout: ScreenItemLayout;
  action: ScreenAction;
  refreshOnScreenResume: boolean;
  refreshIntervalSeconds: number;
  rowItemPageSize: number;
  rowPageSize: number;
};

// TODO: refactor to match new input state reducer strategy
type InputAction = {
  type: 'reset' | 'set-one' | 'set-all';
  key?: keyof InputState;
  stringValue?: string;
  numberValue?: number;
  booleanValue?: boolean;
  layoutValue?: ScreenLayout;
  itemLayoutValue?: ScreenItemLayout;
  actionValue?: ScreenAction;
  newInputState?: InputState;
};

const baseInitialInputState: InputState = {
  feedId: null,
  feedTitle: null,
  layout: ScreenLayout.Row,
  itemLayout: ScreenItemLayout.Landscape,
  action: ScreenAction.Detail,
  refreshOnScreenResume: true,
  refreshIntervalSeconds: 21600,
  rowItemPageSize: 25,
  rowPageSize: 5,
};

function createInitialInputState(
  screenFeed?: Screen_ScreenFeedInput & { feedTitle: string },
) {
  if (!screenFeed) return baseInitialInputState;
  const {
    feedId,
    feedTitle,
    layout,
    itemLayout,
    action,
    refreshOnScreenResume,
    refreshIntervalSeconds,
    rowItemPageSize,
    rowPageSize,
  } = screenFeed;
  return {
    feedId,
    feedTitle,
    layout,
    itemLayout,
    action,
    refreshOnScreenResume:
      refreshOnScreenResume ?? baseInitialInputState.refreshOnScreenResume,
    refreshIntervalSeconds:
      refreshIntervalSeconds ?? baseInitialInputState.refreshIntervalSeconds,
    rowItemPageSize: rowItemPageSize ?? baseInitialInputState.rowItemPageSize,
    rowPageSize: rowPageSize ?? baseInitialInputState.rowPageSize,
  };
}

function inputDataReducer(state: InputState, action: InputAction): InputState {
  switch (action.type) {
    case 'reset':
      return baseInitialInputState;

    case 'set-one':
      if (!action.key) return state;
      const newState = { ...state };
      if (action.key === 'feedId' || action.key === 'feedTitle') {
        newState[action.key] = action.stringValue ?? null;
      } else if (action.key === 'layout') {
        newState[action.key] =
          action.layoutValue ?? baseInitialInputState.layout;
      } else if (action.key === 'itemLayout') {
        newState[action.key] =
          action.itemLayoutValue ?? baseInitialInputState.itemLayout;
      } else if (action.key === 'action') {
        newState[action.key] =
          action.actionValue ?? baseInitialInputState.action;
      } else if (action.key === 'refreshOnScreenResume') {
        newState[action.key] =
          action.booleanValue ?? baseInitialInputState.refreshOnScreenResume;
      } else if (
        action.key === 'refreshIntervalSeconds' ||
        action.key === 'rowItemPageSize' ||
        action.key === 'rowPageSize'
      ) {
        newState[action.key] =
          action.numberValue ?? baseInitialInputState[action.key];
      }
      return newState;
    case 'set-all':
      return action.newInputState ?? state;
  }
}

type ScreenFeedFormDialogProps = {
  isOpen: boolean;
  formTitle: string;
  saveButtonLabel: string;
  onClose: () => void;
  onSave: (
    screenFeedInput: Screen_ScreenFeedInput & { feedTitle: string },
  ) => void;
  initialInputData?: Screen_ScreenFeedInput & { feedTitle: string };
  feedOptions: Pick<Feed, 'name' | 'id'>[];
  isFeedInputDisabled?: boolean;
};

/**
 * Make sure `initialInputData` won't change while the dialog is open,
 * otherwise the users' edits will be overwritten.
 */
export default function ScreenFeedFormDialog({
  isOpen,
  formTitle,
  saveButtonLabel,
  onClose,
  onSave,
  initialInputData,
  feedOptions,
  isFeedInputDisabled = false,
}: ScreenFeedFormDialogProps) {
  if (isOpen && feedOptions.length === 0) {
    throw new Error('Zero feed options supplied.');
  }

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    initialInputData,
    createInitialInputState,
  );
  function setStringValue(
    key: keyof Pick<InputState, 'feedId' | 'feedTitle'>,
    stringValue: string,
  ) {
    dispatchInputData({ type: 'set-one', key, stringValue });
  }
  function setNumberValue(
    key: keyof Pick<
      InputState,
      'refreshIntervalSeconds' | 'rowItemPageSize' | 'rowPageSize'
    >,
    numberValue: number,
  ) {
    dispatchInputData({ type: 'set-one', key, numberValue });
  }
  function setBooleanValue(
    key: keyof Pick<InputState, 'refreshOnScreenResume'>,
    booleanValue: boolean,
  ) {
    dispatchInputData({ type: 'set-one', key, booleanValue });
  }
  function setLayoutValue(
    key: keyof Pick<InputState, 'layout'>,
    layoutValue: ScreenLayout,
  ) {
    dispatchInputData({ type: 'set-one', key, layoutValue });
  }
  function setItemLayoutValue(
    key: keyof Pick<InputState, 'itemLayout'>,
    itemLayoutValue: ScreenItemLayout,
  ) {
    dispatchInputData({ type: 'set-one', key, itemLayoutValue });
  }
  function setActionValue(
    key: keyof Pick<InputState, 'action'>,
    actionValue: ScreenAction,
  ) {
    dispatchInputData({ type: 'set-one', key, actionValue });
  }

  const {
    feedId,
    layout,
    itemLayout,
    action,
    refreshOnScreenResume,
    refreshIntervalSeconds,
    rowItemPageSize,
    rowPageSize,
  } = inputData;

  // validation and errors

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  const params = useParams();
  const appId = params.appId ?? '';

  function showErrorOnFirstPage() {
    setErrorMessage(strings.errors.missingInput);
  }

  /**
   * Individual input errors are dynamically highlighted, but only once the
   * user has tried to submit; if they correct an input the highlight will
   * disappear.
   * EXCEPT: 1000 char limit, which is always highlighted when surpassed.
   */
  const isFeedValid = !!feedId;
  const isLayoutValid = !!layout;
  const isItemLayoutValid = !!itemLayout;
  const isActionValid = !!action;
  const isInputValid =
    isFeedValid && isLayoutValid && isItemLayoutValid && isActionValid;

  useEffect(
    function populateExistingData() {
      if (initialInputData) {
        dispatchInputData({
          type: 'set-all',
          newInputState: createInitialInputState(initialInputData),
        });
      }
    },
    [initialInputData],
  );

  function tryToSave() {
    if (isInputValid) {
      onSave(inputData as Screen_ScreenFeedInput & { feedTitle: string });
      closeAndReset();
    } else {
      showErrorOnFirstPage();
    }
  }

  function closeAndReset() {
    onClose();
    dispatchInputData({ type: 'reset' });
    setErrorMessage('');
  }

  return (
    <Dialog
      // TODO: ideally, scrolling shouldn't appear
      title={formTitle}
      subtitleColor="neutrals.navigationOutline"
      subtitleSpacing="26px"
      isOpen={isOpen}
      onClose={closeAndReset}
      height="815px"
    >
      <DialogBody>
        <Form hasError={!!errorMessage} marginTop="0px">
          <FormError message={errorMessage} />
          {isFeedInputDisabled ? (
            <Box>
              <FormLabel as="legend">
                <Text textStyle="subtitle3">{strings.feeds.feed}</Text>
              </FormLabel>
              <TextButton
                label={
                  feedOptions.find((feed) => feed.id === feedId)?.name ?? ''
                }
                onClick={() =>
                  window.open(
                    routes.appFeedDetail({ appId, feedId: feedId ?? '' }),
                  )
                }
              ></TextButton>
            </Box>
          ) : (
            <FormGrid>
              <Select
                label={strings.feeds.feed}
                required
                onChange={(e) => {
                  setStringValue('feedId', e.target.value);
                  setStringValue(
                    'feedTitle',
                    e.target.options[e.target.selectedIndex].innerHTML,
                  );
                }}
                value={feedId ?? ''}
                isInvalid={isValidationActive && !isFeedValid}
                isPermanentlyDisabled={isFeedInputDisabled}
                data-testid="screen-feed-select"
              >
                {feedOptions.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </Select>
            </FormGrid>
          )}
          <FormGrid>
            <RadioGroup
              label={strings.common.layout}
              direction="row"
              value={layout}
              required
              setValue={(value) =>
                setLayoutValue('layout', value as ScreenLayout)
              }
            >
              <Radio value={ScreenLayout.Row} label="Row" />
              <Radio value={ScreenLayout.Grid} label="Grid" />
            </RadioGroup>
            <RadioGroup
              label={strings.screens.itemLayout}
              direction="row"
              required
              value={itemLayout}
              setValue={(value) =>
                setItemLayoutValue('itemLayout', value as ScreenItemLayout)
              }
            >
              <Radio
                value={ScreenItemLayout.Landscape}
                label={strings.common.landscape}
              />
              <Radio
                value={ScreenItemLayout.Portrait}
                label={strings.common.portrait}
              />
              <Radio
                value={ScreenItemLayout.Square}
                label={strings.common.square}
              />
            </RadioGroup>
          </FormGrid>
          <RadioGroup
            label={strings.common.action}
            direction="row"
            value={action}
            required
            setValue={(value) =>
              setActionValue('action', value as ScreenAction)
            }
          >
            <Radio value={ScreenAction.Detail} label={strings.screens.detail} />
            <Radio
              value={ScreenAction.Playback}
              label={strings.screens.playback}
            />
          </RadioGroup>
          <FormGrid>
            <NumberInput
              label={
                layout === ScreenLayout.Row
                  ? strings.screens.rowItemPageSize
                  : strings.screens.gridItemPageSize
              }
              value={rowItemPageSize}
              onChange={(value) => setNumberValue('rowItemPageSize', value)}
            />
            {layout === ScreenLayout.Row && (
              <NumberInput
                label={strings.screens.rowPageSize}
                value={rowPageSize}
                onChange={(value) => setNumberValue('rowPageSize', value)}
              />
            )}
          </FormGrid>
          <FormGrid>
            <NumberInput
              label={strings.screens.refreshInterval}
              units={strings.screenContexts.seconds}
              value={refreshIntervalSeconds}
              onChange={(value) =>
                setNumberValue('refreshIntervalSeconds', value)
              }
            />
            <Toggle
              label={strings.screens.refreshOnScreenResume}
              value={refreshOnScreenResume}
              setValue={(value) =>
                setBooleanValue('refreshOnScreenResume', value)
              }
            />
          </FormGrid>
        </Form>
      </DialogBody>
      <DialogButtons>
        <SecondaryButton
          label={strings.common.cancel}
          onClick={() => closeAndReset()}
        />
        <PrimaryButton label={saveButtonLabel} onClick={tryToSave} />
      </DialogButtons>
    </Dialog>
  );
}
