import { LayerStyle } from '.';
import { spacing } from '../constants';

const successBar: LayerStyle = {
  backgroundColor: 'system.success.500',
  spacing: spacing[30],
  padding: '10px 15px',
};

export const successLayerStyles = {
  successBar,
};
