// see https://www.figma.com/file/pQsFmVeDaBYJ2t9ofXSXIR/Streams-Design-System?node-id=17%3A521&t=oGaVKbFkPtDfyW7r-4
export const textStyles = {
  h1: {
    fontSize: '30px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.5%',
  },
  h2: {
    fontSize: '26px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.5%',
  },
  h3: {
    fontSize: '24px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.25%',
  },
  h4: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.5%',
  },
  h5: {
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },

  subtitle1: {
    fontSize: '20px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.25%',
  },
  subtitle2: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0.25%',
  },
  subtitle3: {
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  subtitle4: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '24px',
    letterSpacing: '0%',
  },

  bodyCopyLarge: {
    fontSize: '18px',
    fontWeight: '400',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  bodyCopy: {
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  bodyCopySmall: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },
  bodyCopyVerySmall: {
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: 'auto',
    letterSpacing: '0%',
  },

  button: {
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '22px',
    letterSpacing: '0%',
  },
  flow: {
    fontSize: '58px',
    textAlign: 'center',
    fontWeight: 'bold',
    fontFamily: 'DIN condensed',
    lineHeight: '1',
    height: '40px',
    color: 'neutrals.black',
  },
  localizationStrings: {
    width: '400px',
    paddingBottom: '14px',
    paddingTop: '14px',
    paddingLeft: '20px',
    paddingRight: '20px',
    backgroundColor: 'neutrals.brandGrey.100',
    color: 'neutrals.brandGrey.500',
    borderRadius: '8px',
    height: '50px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipses',
  },
};
