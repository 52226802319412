// see https://www.figma.com/file/pQsFmVeDaBYJ2t9ofXSXIR/Streams-Design-System?node-id=17%3A241&t=oGaVKbFkPtDfyW7r-4
export const colors = {
  primary: {
    primaryBlue: {
      // 50: ?
      100: '#99B1DB',
      200: '#809ED2',
      300: '#668AC8',
      400: '#3363B6',
      500: '#003CA4',
      600: '#003694',
      700: '#003083',
      800: '#002A73',
      900: '#002462',
      // 100 @ 10% opacity
      select: '#99B1DB1A',
    },
  },
  secondary: {
    secondaryBlue: {
      // 50: ?
      100: '#B8C1F4',
      200: '#A6B2F2',
      300: '#94A3EF',
      400: '#7184EA',
      500: '#4D65E4',
      600: '#465BCD',
      700: '#3E51B6',
      800: '#3647A0',
      900: '#2E3D89',
    },
  },
  accent: {
    accentRed: {
      100: '#FBD3DE',
      300: '#F37B9B',
      500: '#EB2359',
      700: '#BC1C47',
      900: '#8D1535',
    },
  },
  neutrals: {
    staticWhite: '#FFFFFF',
    white: '#FDFDFD',
    brandGrey: {
      50: '#F6F6F6',
      100: '#EBEBEB',
      300: '#CCCCCC',
      400: '#9FA1A5',
      500: '#5F6369',
      // 100 @ 20% opacity
      hover: '#EBEBEB33',
      // 500 @ 75%
      unselected: '#5F6369BF',
    },
    backgroundGray: '#1C2128',
    navigationOutline: '#2D2F35',
    navigationBlack: '#13151A',
    black: '#030303',
    black50percent: '#03030380',
    backgroundOverlay: '#131519F2',
    backgroundDarkGrey: '#1A1C20',
  },
  system: {
    success: {
      100: '#C3D9AF',
      300: '#A4C588',
      500: '#689F38',
      700: '#3E5F22',
      900: '#2A4016',
    },
    warning: {
      100: '#FDE6AB',
      300: '#FDD981',
      500: '#FBC02D',
      700: '#F9A825',
      900: '#97731B',
    },
    error: {
      100: '#FFADAD',
      300: '#FF8585',
      500: '#FF3333',
      700: '#CC2929',
      900: '#661414',
    },
  },
};
