import { LayerStyle } from '.';

const localesTabBaseGrid: LayerStyle = {
  gridTemplateAreas: '"nav main"',
  gridTemplateColumns: '182px 1fr',
  borderColor: 'neutrals.brandGrey.300',
};

const localesTabDetailGrid: LayerStyle = {
  ...localesTabBaseGrid,
  borderTopWidth: '1px',
  borderColor: 'neutrals.brandGrey.300',
};

const localesTabFormGrid: LayerStyle = {
  ...localesTabBaseGrid,
  borderWidth: '1px',
  borderRadius: '12px',
};

const localesSection: LayerStyle = {
  borderRightWidth: '1px',
  borderColor: 'neutrals.brandGrey.300',
  gridArea: 'nav',
};

const localesTabEntries: LayerStyle = {
  paddingTop: '57px',
  paddingLeft: '40px',
};

const localesTabEntry: LayerStyle = {
  color: 'neutrals.brandGrey.500',
};

const selectedLocalesTabEntry: LayerStyle = {
  color: 'neutrals.navigationOutline',
  width: 'calc(100% + 1px)',
  borderRightWidth: '2px',
  borderColor: 'neutrals.navigationOutline',
};

const localesListSection: LayerStyle = {
  minHeight: '500px',
  padding: '50px 85px',
  area: 'main',
  overflow: 'auto',
};

export const localesTabListLayerStyle = {
  localesTabDetailGrid,
  localesTabFormGrid,
  localesSection,
  localesTabEntries,
  localesListSection,
  localesTabEntry,
  selectedLocalesTabEntry,
};
