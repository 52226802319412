import { useMutation } from '@tanstack/react-query';
import React, { useMemo } from 'react';
import useAuthRequest from '../../../hooks/useAuthRequest';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
  UsersQuery,
} from '../../../gql/gqlRequests';
import { deleteUserRequest } from '../../../support/users';

type DeleteUserDialogProps = {
  isOpen: boolean;
  id?: string;
  usersData?: UsersQuery;
  onSuccess: (data: DeleteUserMutation) => void;
  onClose: () => void;
};

export default function DeleteUserDialog({
  isOpen,
  id,
  usersData,
  onSuccess,
  onClose,
}: DeleteUserDialogProps) {
  const email = useMemo(
    () => usersData?.users.find(({ username }) => username === id)?.email ?? '',
    [id, usersData],
  );

  const deleteMutationFn = useAuthRequest<
    DeleteUserMutationVariables,
    DeleteUserMutation
  >(deleteUserRequest);
  const deleteUserMutation = useMutation<
    DeleteUserMutation,
    Error,
    DeleteUserMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data) => {
      onSuccess(data);
      closeAndReset();
    },
  });

  function deleteUser() {
    deleteUserMutation.mutate({ email });
  }

  function closeAndReset() {
    onClose();
    deleteUserMutation.reset();
  }

  if (!!id && !email) {
    throw new Error('User email not found for deletion');
  }

  return (
    <StandardDeletionDialog
      itemType="User"
      isOpen={isOpen}
      isLoading={deleteUserMutation.isLoading}
      onDelete={deleteUser}
      onClose={closeAndReset}
    />
  );
}
