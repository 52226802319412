import React, { ReactNode, useState } from 'react';
import {
  Box,
  Text,
  Checkbox as ChakraCheckbox,
  VStack,
  Center,
} from '@chakra-ui/react';
import FlowDialog from '../dialogs/FlowDialog';
import Button from '../buttons/Button';
import Link from '../Link';
import { strings } from '../../../utils/strings';
import { useMutation } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import {
  SubmitProductConsentMutation,
  SubmitProductConsentMutationVariables,
} from '../../../gql/gqlRequests';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { submitProductConsentRequest } from '../../../support/consent';
import useLogout from '../../../hooks/useLogout';
import { externalLinks } from '../../../utils/externalLinks';
import { standardButtonHeight } from '../../../themes/constants';

export default function ProductConsentModal() {
  const logout = useLogout();
  const [errorMessage, setErrorMessage] = useState('');
  // TODO: v1.1 refactor to use useReducer instead of useState
  const [checkedConsents, setCheckedConsents] = React.useState([false, false]);
  const isAgreedToAll = checkedConsents.every(Boolean);

  const [isAgreedToPrivacyPolicy, isAgreedToTermsOfUse] = checkedConsents;

  const submitConsentMutationFn = useAuthRequest<
    SubmitProductConsentMutationVariables,
    SubmitProductConsentMutation
  >(submitProductConsentRequest);
  const submitConsentMutation = useMutation<
    SubmitProductConsentMutation,
    RequestError,
    SubmitProductConsentMutationVariables
  >({
    mutationFn: submitConsentMutationFn,
    onError: () => setErrorMessage(strings.errors.generic),
    onSuccess: continueToOriginalPage,
  });

  function tryToSubmitConsent() {
    if (isAgreedToAll) {
      submitConsentMutation.mutate({});
    } else {
      setErrorMessage(strings.consent.consentError);
    }
  }

  function agreeToAll(isChecked: boolean) {
    setCheckedConsents([isChecked, isChecked]);
  }

  function agreeToPrivacyPolicy(isChecked: boolean) {
    setCheckedConsents([isChecked, isAgreedToTermsOfUse]);
  }

  function agreeToTermsOfUse(isChecked: boolean) {
    setCheckedConsents([isAgreedToPrivacyPolicy, isChecked]);
  }

  function continueToOriginalPage() {
    window.location.reload();
  }

  return (
    <FlowDialog
      errorMessage={errorMessage}
      title={strings.consent.welcomeToFlow}
      instructions={strings.consent.acknowledgement}
    >
      <form>
        <Box paddingTop="30px">
          <Center>
            <VStack spacing="35px" align="left">
              <ConsentCheckbox isChecked={isAgreedToAll} onChange={agreeToAll}>
                <Text textStyle="subtitle3">{strings.consent.agreeToAll}</Text>
              </ConsentCheckbox>
              <ConsentCheckbox
                isChecked={isAgreedToPrivacyPolicy}
                onChange={agreeToPrivacyPolicy}
              >
                <Link
                  isExternal
                  label={strings.consent.privacyPolicy}
                  to={externalLinks.consent.privacyPolicy}
                  textStyle="bodyCopy"
                />
              </ConsentCheckbox>
              <ConsentCheckbox
                isChecked={isAgreedToTermsOfUse}
                onChange={agreeToTermsOfUse}
              >
                <Link
                  isExternal
                  label={strings.consent.termsOfUse}
                  to={externalLinks.consent.termsOfUse}
                  textStyle="bodyCopy"
                />
              </ConsentCheckbox>
            </VStack>
          </Center>
        </Box>
        <Box
          position="absolute"
          bottom="60px"
          display="flex"
          justifyContent="space-between"
        >
          <Button
            type="button"
            variant="outline"
            label={strings.consent.decline}
            onClick={logout}
            width="147px"
            height={standardButtonHeight}
            marginRight="32px"
          />
          <Button
            type="button"
            variant="solid"
            label={strings.consent.ok}
            onClick={tryToSubmitConsent}
            width="147px"
            height={standardButtonHeight}
          />
        </Box>
      </form>
    </FlowDialog>
  );
}

type ConsentCheckboxProps = {
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  children?: ReactNode;
};

function ConsentCheckbox({
  isChecked,
  onChange,
  children,
}: ConsentCheckboxProps) {
  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    onChange(e.target.checked);
  }

  return (
    <ChakraCheckbox
      isChecked={isChecked}
      spacing="20px"
      height="22px"
      width="fit-content"
      onChange={handleChange}
    >
      {children}
    </ChakraCheckbox>
  );
}
