import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Box } from '@chakra-ui/react';
import Authentication from '../../common/authentication/Authentication';
import StandardInput from '../../common/inputs/StandardInput';
import { resendRegistrationRequest } from '../../../support/authentication';
import {
  isValidEmailCharacter,
  validateEmail,
} from '../../../utils/validation';
import { RequestError } from '../../../types';
import {
  ResendRegistrationMutation,
  ResendRegistrationMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

type RegistrationLinkRequestProps = {
  onLinkSent: (data: ResendRegistrationMutation) => void;
};

/**
 * A sub-component of `RegistrationExpired` which handles input for an email
 * to send a new link.
 */
export default function RegistrationLinkRequest({
  onLinkSent,
}: RegistrationLinkRequestProps) {
  const resendRegistrationMutation = useMutation<
    ResendRegistrationMutation,
    RequestError,
    ResendRegistrationMutationVariables
  >({
    mutationFn: resendRegistrationRequest,
    onSuccess: onLinkSent,
    // error should only happen if backend is broken/has unexpected issue
    onError: () => setErrorMessage(strings.errors.generic),
  });

  function attemptToRequestLink() {
    resendRegistrationMutation.reset();
    if (isEmailValid) {
      resendRegistrationMutation.mutate({ email });
    } else {
      setErrorMessage(strings.authentication.incompleteEmail);
    }
  }

  // input

  const [email, setEmail] = useState('');

  // validation

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;

  const isEmailValid = validateEmail(email);

  // UI

  const requestNewLinkButton = {
    text: strings.authentication.requestNewLink,
    onClick: attemptToRequestLink,
    isDisabled: resendRegistrationMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.requestNewLink}
      instructions={
        strings.authentication.requestNewRegistrationLinkInstructions
      }
      buttonProps={requestNewLinkButton}
    >
      <Box paddingTop="35px">
        <StandardInput
          label={strings.common.email}
          labelAsPlaceholder
          isValidCharacter={isValidEmailCharacter}
          setSanitizedValue={setEmail}
          isInvalid={hasTriedToSubmit && !isEmailValid}
          isDisabled={resendRegistrationMutation.isLoading}
        />
      </Box>
    </Authentication>
  );
}
