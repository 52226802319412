import { LayerStyle } from '.';
import { standardInputHeight } from '../constants';

const fileInfo: LayerStyle = {
  padding: '14px 20px',
  borderRadius: '10px',
  gap: '4px',
  height: standardInputHeight,
  display: 'flex',
  flexGrow: '1',
  alignItems: 'center',
  justifyContent: 'space-between',
};

const imageLibraryTile: LayerStyle = {
  maxHeight: '190px',
  objectFit: 'cover',
  transition: 'all 150ms ease-in-out',
};

const imageLibraryTileOverlay: LayerStyle = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  border: '2px solid',
  borderRadius: '10px',
};

const imagePreview: LayerStyle = {
  width: '880px',
  height: '549px',
  maxWidth: '100%',
  maxHeight: '100%',
  objectFit: 'contain',
  backgroundColor: 'neutrals.brandGrey.400',
  border: '2px solid',
  borderRadius: '10px',
  borderColor: 'neutrals.brandGrey.300',
};

const colorPreviewElement: LayerStyle = {
  borderRadius: '5px',
  margin: '6px',
  borderWidth: '1px',
  borderColor: 'neutrals.brandGrey.100',
};

export const inputLayerStyles = {
  fileInfo,
  imageLibraryTile,
  imageLibraryTileOverlay,
  imagePreview,
  colorPreviewElement,
};
