import {
  StyleFunctionProps,
  defineStyle,
  defineStyleConfig,
  theme,
} from '@chakra-ui/react';
import { smallButtonHeight, spacing, standardButtonHeight } from './constants';

const baseStyle = defineStyle({
  borderRadius: '10px',
});

const baseVariant = defineStyle({
  height: standardButtonHeight,
  minWidth: 'unset',
});

const primary = defineStyle({
  ...baseVariant,
});

const secondary = defineStyle({
  ...baseVariant,
  color: 'primary.primaryBlue.500',
  borderWidth: '2px',
});

const text = defineStyle({
  textColor: 'neutrals.navigationOutline',
  textUnderlineOffset: spacing[5],
  textDecoration: 'underline',
});

const preview = defineStyle({
  ...secondary,
  paddingX: '20px',
  backgroundColor: 'neutrals.staticWhite',
});

const primaryFullWidth = defineStyle({
  ...primary,
  width: '100%',
});

const outline = defineStyle({
  borderWidth: '2px',
});

const iconButton = defineStyle({
  _hover: {
    backgroundColor: 'primary.primaryBlue.select',
  },
  _active: {
    backgroundColor: 'primary.primaryBlue.select',
  },
});

const fileUploadButton = defineStyle({
  backgroundColor: 'neutrals.brandGrey.100',
  color: 'neutrals.brandGrey.500',
  padding: '14px 20px',
  borderRadius: '5px',
  fontSize: '16px',
  height: standardButtonHeight,
  minWidth: '96px',
  _hover: {
    backgroundColor: 'primary.primaryBlue.500',
    color: 'neutrals.white',
  },
  _disabled: {
    _hover: {
      backgroundColor: 'neutrals.brandGrey.100',
      color: 'neutrals.brandGrey.500',
    },
  },
});

export const buttonTheme = defineStyleConfig({
  baseStyle,
  defaultProps: {
    colorScheme: 'primary.primaryBlue',
  },
  variants: {
    outline,
    iconButton,
    fileUploadButton,
    primary: (props) => {
      const hasIcon = checkForIcon(props);

      return {
        ...theme.components.Button.variants?.solid(props),
        ...primary,
        paddingX: hasIcon ? '20px' : '59px',
      };
    },
    secondary: (props) => {
      const hasIcon = checkForIcon(props);

      return {
        ...theme.components.Button.variants?.outline(props),
        ...secondary,
        paddingX: hasIcon ? '20px' : '51px',
      };
    },
    text: (props) => {
      return {
        ...theme.components.Button.variants?.link(props),
        ...text,
      };
    },
    preview: (props) => {
      return {
        ...theme.components.Button.variants?.outline(props),
        ...preview,
      };
    },
    primaryFullWidth: (props) => {
      return {
        ...theme.components.Button.variants?.solid(props),
        ...primaryFullWidth,
      };
    },
    smallSecondary: (props) => {
      return {
        ...theme.components.Button.variants?.outline(props),
        ...secondary,
        paddingX: '10px',
        height: smallButtonHeight,
      };
    },
  },
});

function checkForIcon(props: StyleFunctionProps) {
  return Boolean(props.leftIcon || props.rightIcon);
}
