import { useCallback, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { allPlatformsRequest } from '../support/platforms';
import useAuthRequest from './useAuthRequest';
import { TokenContext } from '../contexts';
import { parsedRequestError } from '../utils/errors';
import { RequestError } from '../types';
import {
  AllPlatformsQuery,
  AllPlatformsQueryVariables,
  Platform,
} from '../gql/gqlRequests';

export function getPlatformByNameAndPlatforms(
  name = '',
  platforms: Platform[] = [],
) {
  const matchingPlatform = platforms.find((platform) => platform.name === name);
  if (!matchingPlatform) console.error('No matching platform for', name);
  return matchingPlatform;
}

/**
 * Only call this hook once, in ContextsProvider.tsx - otherwise, get
 * these values from the platform context.
 */
export default function usePlatforms() {
  const { refreshToken } = useContext(TokenContext);

  // all platforms

  const platformsQueryFn = useAuthRequest<
    AllPlatformsQueryVariables,
    AllPlatformsQuery
  >(allPlatformsRequest);
  const platformsQuery = useQuery<AllPlatformsQuery, RequestError>({
    queryKey: ['platforms', refreshToken],
    queryFn: () => platformsQueryFn({}),
    // don't refetch; it changes extremely rarely
    staleTime: Infinity,
  });

  const allPlatforms = platformsQuery.data?.platforms;

  const getPlatformByName = useCallback(
    (name?: string) => getPlatformByNameAndPlatforms(name, allPlatforms),
    [allPlatforms],
  );

  if (platformsQuery.isError) throw parsedRequestError(platformsQuery.error);

  const { isLoading } = platformsQuery;

  if (isLoading) {
    return { isLoading };
  }

  return {
    isLoading,
    allPlatforms: platformsQuery.data.platforms,
    getPlatformByName,
  };
}
