import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
} from '@tanstack/react-query';
import { createContext } from 'react';
import {
  AppQuery,
  Environment,
  LoggedInUserQuery,
  Platform,
  Role,
} from '../gql/gqlRequests';
import { Locale, RequestError } from '../types';
import { LocaleCode } from '../types/locale';

// tokens

type TokenContextType = {
  refreshToken: string;
  accessToken: string;
  setRefreshToken: (token: string) => void;
  setAccessToken: (token: string) => void;
  clearTokens: () => void;
  isAccessTokenValidAndUnexpired: () => boolean;
};

export const TokenContext = createContext<TokenContextType>({
  refreshToken: '',
  accessToken: '',
  setRefreshToken: () => {
    // no action
  },
  setAccessToken: () => {
    // no action
  },
  clearTokens: () => {
    // no action
  },
  isAccessTokenValidAndUnexpired: () => true,
});

// logged in user
export type LoggedInUserType = {
  username: string;
  role: Role;
  firstName: string;
  lastName: string;
  appId: string;
  appIds: string[];
  appName: string[];
  accountName: string;
  email: string;
  isTwoFactorEnabled: boolean;
  phoneNumber: string | null;
};
export type UserContextType = {
  isLoading: false;
  loggedInUser: LoggedInUserType;
  refetch?: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
  ) => Promise<QueryObserverResult<LoggedInUserQuery, RequestError>>;
};

export const initialUserContextState = {
  username: '',
  role: Role.AppUser,
  firstName: '',
  lastName: '',
  appId: '',
  appIds: [],
  appName: [],
  accountName: '',
  email: '',
  isTwoFactorEnabled: false,
  phoneNumber: null,
};

export const UserContext = createContext<UserContextType>({
  isLoading: false,
  loggedInUser: initialUserContextState,
});

// selected app

type AppContextType = {
  isLoading: boolean;
  isSuccess: boolean;
  appId: string;
  setApp: (appId: string) => void;
  appData?: AppQuery['app'];
  locales: Locale[];
  defaultLocale: Locale;
  refetch: () => void;
};

export const AppContext = createContext<AppContextType>({
  isLoading: false,
  isSuccess: false,
  appId: '',
  setApp: () => {
    // no action
  },
  locales: [],
  defaultLocale: { name: 'English', code: LocaleCode.EN },
  refetch: () => {
    // no action
  },
});

// platforms

type PlatformsContextType = {
  isLoading: boolean;
  allPlatforms: Platform[];
  getPlatformByName: (name?: string) => Platform | undefined;
};

export const PlatformsContext = createContext<PlatformsContextType>({
  isLoading: false,
  allPlatforms: [],
  getPlatformByName: () => undefined,
});

// environment

type EnvironmentContextType = {
  environment: Environment;
  setEnvironment: (env: Environment) => void;
};

export const EnvironmentContext = createContext<EnvironmentContextType>({
  environment: Environment.Production,
  setEnvironment: () => {
    // no action
  },
});
