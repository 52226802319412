import React, { ReactNode } from 'react';
import useApp from '../../hooks/useApp';
import useEnvironment from '../../hooks/useEnvironment';
import usePlatforms from '../../hooks/usePlatforms';
import {
  AppContext,
  EnvironmentContext,
  PlatformsContext,
  UserContext,
} from '../../contexts';
import useUser from '../../hooks/useUser';
import Progress from './Progress';
import { Center } from '@chakra-ui/react';

type ContextsProviderProps = {
  children: ReactNode;
};

/**
 * Provides all contexts (except the token context) to the rest of the app, once they load.
 */
export default function ContextsProvider({ children }: ContextsProviderProps) {
  // hooks for contexts
  const userValues = useUser();

  const environmentValues = useEnvironment();
  const platformsValues = usePlatforms();
  // note: useApp called outside of user context, so pass values explicitly instead
  const appValues = useApp(
    userValues.isLoading ? '' : userValues.loggedInUser.appId,
    userValues.isLoading ? undefined : userValues.loggedInUser.role,
    userValues.isLoading ? false : userValues.loggedInUser.appIds.length > 1,
    environmentValues.environment,
  );

  // environment doesn't load at all, and app may be loaded later (sys admins)
  if (userValues.isLoading || platformsValues.isLoading) {
    return (
      <Center width="100vw" height="100vh">
        <Progress />
      </Center>
    );
  }

  return (
    <UserContext.Provider value={userValues}>
      <EnvironmentContext.Provider value={environmentValues}>
        <PlatformsContext.Provider value={platformsValues}>
          <AppContext.Provider value={appValues}>
            {children}
          </AppContext.Provider>
        </PlatformsContext.Provider>
      </EnvironmentContext.Provider>
    </UserContext.Provider>
  );
}
