import React, { Fragment, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { RequestError } from '../../../types';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import UnableToDeleteDialog from '../../common/dialogs/UnableToDeleteDialog';
import {
  DeleteFeedMutation,
  DeleteFeedMutationVariables,
  Feed,
} from '../../../gql/gqlRequests';
import { deleteFeedRequest } from '../../../support/feeds';

type DeleteFeedDialogProps = {
  isOpen: boolean;
  id?: string;
  feeds?: Pick<Feed, 'id' | 'platforms'>[];
  onSuccess: (data: DeleteFeedMutation) => void;
  onClose: () => void;
};

export default function DeleteFeedDialog({
  isOpen,
  id = '',
  feeds,
  onSuccess,
  onClose,
}: DeleteFeedDialogProps) {
  const feed = useMemo(
    () => feeds?.find((feed) => feed.id === id),
    [feeds, id],
  );
  const associatedPlatforms = feed?.platforms ?? [];
  const isUsedOnPlatforms = associatedPlatforms
    ? associatedPlatforms.length > 0
    : undefined;
  const canDeleteFeed = !!feed && !isUsedOnPlatforms;

  const deleteMutationFn = useAuthRequest<
    DeleteFeedMutationVariables,
    DeleteFeedMutation
  >(deleteFeedRequest);
  const deleteMutation = useMutation<
    DeleteFeedMutation,
    RequestError,
    DeleteFeedMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data) => {
      onSuccess(data);
      closeAndReset();
    },
  });

  function deleteFeed() {
    deleteMutation.mutate({ id });
  }

  function closeAndReset() {
    onClose();
    deleteMutation.reset();
  }

  if (!!id && !feed) {
    throw new Error('Cannot find feed to delete');
  }

  return (
    <Fragment>
      <StandardDeletionDialog
        itemType="Feed"
        isOpen={isOpen && canDeleteFeed}
        isLoading={deleteMutation.isLoading}
        onDelete={deleteFeed}
        onClose={closeAndReset}
      />
      <UnableToDeleteDialog
        itemType="Feed"
        isOpen={isOpen && !canDeleteFeed}
        onClose={closeAndReset}
        platforms={associatedPlatforms}
      />
    </Fragment>
  );
}
