import React, { ChangeEventHandler, useContext, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { createAppPlatformRequest } from '../../../support/platforms';
import SecondaryButton from '../../common/buttons/SecondaryButton';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dialog, {
  DialogBody,
  DialogButtons,
  spaceBetweenBodyAndFooter,
} from '../../common/dialogs/Dialog';
import Select from '../../common/inputs/Select';
import { AppContext } from '../../../contexts';
import {
  CreateAppPlatformMutation,
  CreateAppPlatformMutationVariables,
  Platform,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import NotificationBar from '../../common/NotificationBar';

const spaceBelowSelectInput = '120px';

type AddAppPlatformDialogType = {
  isOpen: boolean;
  onClose: () => void;
  platformOptions: Platform[];
  onSuccess: (data: CreateAppPlatformMutation) => void;
};

export default function AddAppPlatformDialog({
  isOpen,
  onClose: externalOnClose,
  platformOptions,
  onSuccess,
}: AddAppPlatformDialogType) {
  const { appId, refetch: appRefetch } = useContext(AppContext);
  const [errorMessage, setErrorMessage] = useState('');

  const [platformId, setPlatformId] = useState<string>();
  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setPlatformId(e.target.value);
  };
  const paddingBottom = errorMessage
    ? '13px' // This comes from the design 63px spacing. The math is 63px = spaceBetweenBodyAndFooter + 13px (this value)
    : `calc(${spaceBelowSelectInput} - ${spaceBetweenBodyAndFooter})`;

  function closeAndReset() {
    externalOnClose();
    setPlatformId(undefined);
    setErrorMessage('');
    addMutation.reset();
  }

  function addPlatformAndRefetchApp() {
    setErrorMessage('');
    addMutation.mutate({ appId, platformId: platformId ?? '' });
    appRefetch();
  }

  const addMutationFn = useAuthRequest<
    CreateAppPlatformMutationVariables,
    CreateAppPlatformMutation
  >(createAppPlatformRequest);
  const addMutation = useMutation<
    CreateAppPlatformMutation,
    Error,
    CreateAppPlatformMutationVariables
  >({
    mutationFn: addMutationFn,
    onSuccess,
    onError: () => {
      setErrorMessage(strings.errors.generic);
    },
  });

  return (
    <Dialog
      width="648px"
      title={strings.platforms.addPlatform}
      isOpen={isOpen}
      onClose={closeAndReset}
      hasError={!!errorMessage}
    >
      <DialogBody>
        {errorMessage && (
          <NotificationBar message={errorMessage} marginBottom="30px" />
        )}
        <Box paddingBottom={paddingBottom}>
          <Select
            label={strings.common.type}
            onChange={onChange}
            isDisabled={addMutation.isLoading}
          >
            {platformOptions.map(({ name, id }) => (
              <option key={id} value={id}>
                {name}
              </option>
            ))}
          </Select>
        </Box>
      </DialogBody>

      <DialogButtons>
        <SecondaryButton
          label={strings.common.cancel}
          isDisabled={addMutation.isLoading}
          onClick={closeAndReset}
        />
        <PrimaryButton
          label={strings.platforms.addPlatform}
          isDisabled={addMutation.isLoading || !platformId}
          onClick={addPlatformAndRefetchApp}
        />
      </DialogButtons>
    </Dialog>
  );
}
