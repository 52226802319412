import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import StandardTable from '../common/tables/StandardTable';
import useAuthRequest from '../../hooks/useAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../types';
// import { AppContext } from '../../contexts';
import TextButton from '../common/buttons/TextButton';
import Dialog, { DialogBody } from '../common/dialogs/Dialog';
import { parsedRequestError } from '../../utils/errors';
import {
  AllAppsQuery,
  AllAppsQueryVariables,
  App,
} from '../../gql/gqlRequests';
import { allAppsRequest } from '../../support/apps';
import { strings } from '../../utils/strings';
import { routes } from '../../types/routes';

// Note: we want a fixed height for this dialog, so that it doesn't jump
// around when using the search functionality.

// this is a bit awkward but avoids having 2 scroll bars at once
// FIXME: table height should somehow be directly based on dialog body height available
const dialogHeight = '83vh';
const maxTableHeight = `calc(${dialogHeight} - 334px)`;

// createColumnHelper causes a ts error if any nested types appear in it
type AppRow = Pick<App, 'id' | 'name' | 'createdAt'> & { accountName: string };

type ChangeAppDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function ChangeAppDialog({
  isOpen,
  onClose,
}: ChangeAppDialogProps) {
  // const { setApp } = useContext(AppContext);
  const navigate = useNavigate();

  // Apps query

  const allAppsQueryFn = useAuthRequest<AllAppsQueryVariables, AllAppsQuery>(
    allAppsRequest,
  );
  const allAppsQuery = useQuery<AllAppsQuery, RequestError>({
    queryKey: ['allApps'],
    queryFn: () => allAppsQueryFn({}),
    enabled: isOpen,
  });

  // table data

  const appData = useMemo(
    () =>
      allAppsQuery.data?.apps.map(({ id, name, account, createdAt }) => ({
        id,
        name,
        createdAt,
        accountName: account.name,
      })) ?? [],
    [allAppsQuery.data],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<AppRow>();
    return [
      columnHelper.accessor('createdAt', {}),
      columnHelper.accessor('id', {}),
      columnHelper.accessor('name', {
        header: strings.apps.app,
        cell: (info) => (
          <TextButton
            label={info.getValue()}
            onClick={() => {
              onClose();
              // setApp(info.row.original.id);
              // fixme: maintain current url to the extent possible?
              navigate(routes.appDetail({ appId: info.row.original.id }));
            }}
            textStyle="subtitle3"
          />
        ),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('accountName', {
        header: strings.accounts.account,
        sortingFn: 'alphanumeric',
      }),
    ];
  }, [onClose, navigate]);

  // UI

  // query only runs while open, but error may come after closing
  if (isOpen && allAppsQuery.isError) {
    throw parsedRequestError(allAppsQuery.error);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      width="1316px"
      backgroundColor="neutrals.brandGrey.50"
      height={dialogHeight}
    >
      <DialogBody marginTop="80px" marginBottom="80px">
        <StandardTable
          title={strings.apps.selectAnApp}
          isLoading={allAppsQuery.isLoading}
          data={appData}
          columns={columns}
          canSearch
          boldColumn="name"
          maxHeight={maxTableHeight}
        />
      </DialogBody>
    </Dialog>
  );
}
