import { createGqlAuthRequest } from '.';
import {
  LocalizedTranslationsDocument,
  LocalizedTranslationsQuery,
  LocalizedTranslationsQueryVariables,
  SetLocalizedTranslationsDocument,
  SetLocalizedTranslationsMutation,
  SetLocalizedTranslationsMutationVariables,
  UpdateLocalizedTranslationsDocument,
  UpdateLocalizedTranslationsMutation,
  UpdateLocalizedTranslationsMutationVariables,
} from '../gql/gqlRequests';

export const localizedTranslationsRequest = createGqlAuthRequest<
  LocalizedTranslationsQueryVariables,
  LocalizedTranslationsQuery
>(LocalizedTranslationsDocument);

export const setLocalizedTranslations = createGqlAuthRequest<
  SetLocalizedTranslationsMutationVariables,
  SetLocalizedTranslationsMutation
>(SetLocalizedTranslationsDocument);

export const updateLocalizedTranslations = createGqlAuthRequest<
  UpdateLocalizedTranslationsMutationVariables,
  UpdateLocalizedTranslationsMutation
>(UpdateLocalizedTranslationsDocument);
