import { File as FileMetadata, Image } from '../gql/gqlRequests';
import { UploadFileResponse } from '../types/file';
import { strings } from './strings';

/**
 * Given a number, file or file metadata - function returns a string that represents the file size.
 * Unit of measurement chosen is the largest unit that the file size can reach 1 unit of
 *
 * @example
 * size: 999 -> 999B
 * size: 1000 -> 1KB
 *
 * @param data a number representing the file size in bytes, a File, or FileMetadata
 * @returns a strings representing the file size
 */
export function getFileSizeText(data: number | File | FileMetadata | Image) {
  let size: number;
  if (typeof data === 'number') size = data;
  else size = data.size;

  const { byte, kilobyte, megabyte } = strings.files;
  let unit: string = byte,
    scale = 1;

  for (const currUnit of [byte, kilobyte, megabyte]) {
    unit = currUnit;
    if (size < scale * 1000) break;
    scale *= 1000;
  }

  return `${(size / scale).toPrecision(3)}${unit}`;
}

export function translateUploadFileResponseToFileMetadata(
  response: UploadFileResponse,
): FileMetadata {
  const { file, url } = response;
  return { filename: file.filename, key: file.key, size: file.size, url };
}

export function downloadResource(
  resource: { url: string; name: string } | File,
) {
  let url: string;
  const name = resource.name;
  if (resource instanceof File) {
    const blob = new Blob([resource] as BlobPart[]);
    url = URL.createObjectURL(blob);
  } else {
    url = resource.url;
  }

  const link = document.createElement('a');
  link.download = name;
  link.href = url;
  link.click();
  link.remove();
}
