import React, { Fragment, useContext, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ConfigIntegration,
  ConfigQuery,
  Environment,
  Integration,
  Role,
} from '../../../gql/gqlRequests';
import { useNavigate } from 'react-router-dom';
import { EnvironmentContext, UserContext } from '../../../contexts';
import StandardTable from '../../common/tables/StandardTable';
import DeleteIntegrationDialog from './DeleteIntegrationDialog';
import { displayEnum } from '../../../utils';
import { strings } from '../../../utils/strings';
import { Route } from '../../../types/routes';

type IntegrationRow = Pick<Integration, 'id' | 'key'> & { type: string } & Pick<
    ConfigIntegration,
    'createdAt'
  >;

const columnHelper = createColumnHelper<IntegrationRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('key', {
    header: strings.platforms.integration,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('type', {
    sortingFn: 'alphanumeric',
  }),
];

type IntegrationsProps = {
  configIntegrations: ConfigQuery['app']['config']['integrations'];
  refetchConfig: () => void;
};

export default function Integrations({
  configIntegrations,
  refetchConfig,
}: IntegrationsProps) {
  const { environment } = useContext(EnvironmentContext);
  const { loggedInUser } = useContext(UserContext);
  const navigate = useNavigate();

  // table data

  const integrationsData = useMemo(
    () =>
      configIntegrations.map(
        ({ integration: { type, ...rest }, createdAt }) => ({
          type: displayEnum(type),
          ...rest,
          createdAt,
        }),
      ),
    [configIntegrations],
  );

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();

  // UI

  const canModify =
    loggedInUser.role === Role.SystemAdmin &&
    environment !== Environment.Production;

  return (
    <Fragment>
      <StandardTable
        title={strings.platforms.allIntegrations}
        data={integrationsData}
        columns={columns}
        onEditRow={
          canModify
            ? (id: string) => navigate(`${id}/${Route.Edit}`)
            : undefined
        }
        onDeleteRow={canModify ? setIdToDelete : undefined}
        onCreate={canModify ? () => navigate(Route.Create) : undefined}
        createButtonTitle={strings.platforms.addIntegration}
        boldColumn="key"
        marginTop="40px"
      />

      <DeleteIntegrationDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        onSuccess={refetchConfig}
        onClose={() => setIdToDelete(undefined)}
      />
    </Fragment>
  );
}
