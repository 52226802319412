import React from 'react';
import { useMutation } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { RequestError } from '../../../types';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import { deleteSubscriptionProductRequest } from '../../../support/subscriptions';
import {
  DeleteSubscriptionProductMutation,
  DeleteSubscriptionProductMutationVariables,
} from '../../../gql/gqlRequests';

type DeleteSubscriptionDialogProps = {
  isOpen: boolean;
  id?: string;
  onSuccess: (data: DeleteSubscriptionProductMutation) => void;
  onClose: () => void;
};

export default function DeleteSubscriptionDialog({
  isOpen,
  id = '',
  onSuccess,
  onClose,
}: DeleteSubscriptionDialogProps) {
  const deleteMutationFn = useAuthRequest<
    DeleteSubscriptionProductMutationVariables,
    DeleteSubscriptionProductMutation
  >(deleteSubscriptionProductRequest);
  const deleteMutation = useMutation<
    DeleteSubscriptionProductMutation,
    RequestError,
    DeleteSubscriptionProductMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data) => {
      onSuccess(data);
      closeAndReset();
    },
  });

  function deleteSubscription() {
    deleteMutation.mutate({ id });
  }

  function closeAndReset() {
    onClose();
    deleteMutation.reset();
  }

  return (
    <StandardDeletionDialog
      itemType="Subscription"
      isOpen={isOpen}
      isLoading={deleteMutation.isLoading}
      onDelete={deleteSubscription}
      onClose={closeAndReset}
    />
  );
}
