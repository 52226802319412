import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import {
  configIntegrationsRequest,
  deleteIntegrationRequest,
} from '../../../support/integrations';
import { RequestError } from '../../../types';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import useConfigId from '../../../hooks/useConfigId';
import {
  ConfigIntegrationsQuery,
  ConfigIntegrationsQueryVariables,
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
} from '../../../gql/gqlRequests';

type DeleteIntegrationDialogProps = {
  isOpen: boolean;
  id?: string;
  onSuccess: (data: DeleteIntegrationMutation) => void;
  onClose: () => void;
};

export default function DeleteIntegrationDialog({
  isOpen,
  id = '',
  onSuccess,
  onClose,
}: DeleteIntegrationDialogProps) {
  const { configId, isLoading: isConfigIdLoading } = useConfigId();

  // current list of integrations
  const configIntegrationsQueryFn = useAuthRequest<
    ConfigIntegrationsQueryVariables,
    ConfigIntegrationsQuery
  >(configIntegrationsRequest);
  const configIntegrationsQuery = useQuery<
    ConfigIntegrationsQuery,
    RequestError
  >({
    queryKey: ['configIntegrations', configId],
    queryFn: () => configIntegrationsQueryFn({ id: configId }),
    enabled: !isConfigIdLoading,
  });

  // submitIntegrationRequest handles create, update and delete actions.
  const deleteMutationFn = useAuthRequest<
    DeleteIntegrationMutationVariables,
    DeleteIntegrationMutation
  >(deleteIntegrationRequest);

  const deleteMutation = useMutation<
    DeleteIntegrationMutation,
    RequestError,
    DeleteIntegrationMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data: DeleteIntegrationMutation) => {
      onSuccess(data);
      // if we don't refetch/reset the query, the next deletion will still
      // have the first deleted integration cached and will 'revive' it
      configIntegrationsQuery.refetch();
      onClose();
    },
  });

  function deleteIntegration() {
    deleteMutation.mutate({
      id: configId,
      integrationId: id,
    });
  }

  const isLoading =
    isConfigIdLoading ||
    deleteMutation.isLoading ||
    configIntegrationsQuery.isLoading;

  return (
    <StandardDeletionDialog
      itemType="Integration"
      isOpen={isOpen}
      isLoading={isLoading}
      onDelete={deleteIntegration}
      onClose={onClose}
    />
  );
}
