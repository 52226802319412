import { createGqlAuthRequest } from '.';
import {
  AppThemeBackgroundColorQuery,
  AppThemeBackgroundColorQueryVariables,
  AppThemeDocument,
  AppThemeQuery,
  AppThemeQueryVariables,
  UpdateThemeDocument,
  UpdateThemeMutation,
  UpdateThemeMutationVariables,
} from '../gql/gqlRequests';

export const appThemeRequest = createGqlAuthRequest<
  AppThemeQueryVariables,
  AppThemeQuery
>(AppThemeDocument);

export const appThemeBackgroundColorRequest = createGqlAuthRequest<
  AppThemeBackgroundColorQueryVariables,
  AppThemeBackgroundColorQuery
>(AppThemeDocument);

export const updateThemeRequest = createGqlAuthRequest<
  UpdateThemeMutationVariables,
  UpdateThemeMutation
>(UpdateThemeDocument);
