import { createGqlAuthRequest } from '.';
import {
  CreateScreenFeedDocument,
  CreateScreenFeedMutation,
  CreateScreenFeedMutationVariables,
  DeleteScreenFeedDocument,
  DeleteScreenFeedMutation,
  DeleteScreenFeedMutationVariables,
  ScreenFeedDetailDocument,
  ScreenFeedDetailQuery,
  ScreenFeedDetailQueryVariables,
  ScreenFeedDocument,
  ScreenFeedQuery,
  ScreenFeedQueryVariables,
  ScreenFeedsDocument,
  ScreenFeedsQuery,
  ScreenFeedsQueryVariables,
  UpdateScreenFeedDocument,
  UpdateScreenFeedMutation,
  UpdateScreenFeedMutationVariables,
} from '../gql/gqlRequests';

export const screenFeedRequest = createGqlAuthRequest<
  ScreenFeedQueryVariables,
  ScreenFeedQuery
>(ScreenFeedDocument);

export const screenFeedsRequest = createGqlAuthRequest<
  ScreenFeedsQueryVariables,
  ScreenFeedsQuery
>(ScreenFeedsDocument);

export const screenFeedDetailRequest = createGqlAuthRequest<
  ScreenFeedDetailQueryVariables,
  ScreenFeedDetailQuery
>(ScreenFeedDetailDocument);

export const createScreenFeedRequest = createGqlAuthRequest<
  CreateScreenFeedMutationVariables,
  CreateScreenFeedMutation
>(CreateScreenFeedDocument);

export const updateScreenFeedRequest = createGqlAuthRequest<
  UpdateScreenFeedMutationVariables,
  UpdateScreenFeedMutation
>(UpdateScreenFeedDocument);

export const deleteScreenFeedRequest = createGqlAuthRequest<
  DeleteScreenFeedMutationVariables,
  DeleteScreenFeedMutation
>(DeleteScreenFeedDocument);
