/** Returns the original array if reordering is impossible/redundant. */
export default function makeReorderedArray<T>(
  array: T[],
  getId: (item: T) => string,
  draggedId: string,
  targetId: string,
): T[] {
  if (draggedId === targetId) return array;

  const reorderedArray = [...array];
  const draggedIndex = reorderedArray.findIndex((t) => getId(t) === draggedId);
  if (draggedIndex === -1) return array;

  const [draggedItem] = reorderedArray.splice(draggedIndex, 1);
  // note: important to get target index after removing dragged element
  const targetIndex = reorderedArray.findIndex((t) => getId(t) === targetId);
  if (targetIndex === -1) return array;

  const isDraggingDown = draggedIndex <= targetIndex;
  // insert after target (if dragging down) or before target (if dragging up)
  const insertionIndex = targetIndex + (isDraggingDown ? 1 : 0);
  reorderedArray.splice(insertionIndex, 0, draggedItem);
  return reorderedArray;
}
