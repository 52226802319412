import React, { Fragment } from 'react';
import { HStack, Image, Text, VStack } from '@chakra-ui/react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Dialog, { DialogBody, DialogButtons } from './Dialog';
import userPresentIcon from '../../../assets/user-present.svg';
import userAbsentIcon from '../../../assets/user-absent.svg';
import { strings } from '../../../utils/strings';

const userRowsPaddingLeft = '50px';
const userRowsPaddingY = '25px';
const spaceBetweenUserRows = '10px';
const spaceBetweenIconAndName = '10px';
const spaceBetweenNameAndStatus = '50px';

type TwoDeletionsDialogProps = {
  type?: 'Delete' | 'Remove';
  itemType: 'Account' | 'Platform' | 'App';
  deletionRequestingUser:
    | { firstName: string; lastName: string }
    | null
    | undefined;
  hasAlreadyRequested: boolean;
  isLoading?: boolean;
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
};

export default function TwoDeletionsDialog({
  type = 'Delete',
  itemType,
  deletionRequestingUser,
  hasAlreadyRequested,
  isLoading = false,
  isOpen,
  onDelete,
  onCancel,
}: TwoDeletionsDialogProps) {
  const verb =
    type === 'Delete' ? strings.dialogs.deleting : strings.dialogs.removing;
  const aOrAn =
    itemType === strings.accounts.account || itemType === strings.apps.app
      ? 'an'
      : 'a';

  return (
    <Dialog
      title={
        hasAlreadyRequested
          ? strings.dialogs.awaitingRemovalRequest
          : `${type} ${itemType}`
      }
      isOpen={isOpen}
      onClose={onCancel}
    >
      <DialogBody>
        <Text>
          {hasAlreadyRequested
            ? strings.dialogs.alreadyRequestedRemovalMessage
            : `${verb} ${aOrAn} ${itemType.toLowerCase()} ${
                strings.dialogs.requiresTwoSystemAdminApprovals
              }`}
        </Text>

        {!hasAlreadyRequested && (
          <Fragment>
            <HStack
              textStyle="bodyCopy"
              spacing={spaceBetweenNameAndStatus}
              paddingY={userRowsPaddingY}
              paddingLeft={userRowsPaddingLeft}
            >
              <VStack align="left" spacing={spaceBetweenUserRows}>
                <HStack spacing={spaceBetweenIconAndName}>
                  <Image src={userPresentIcon} />
                  <Text>{strings.dialogs.systemAdmin1You}</Text>
                </HStack>
                <HStack spacing={spaceBetweenIconAndName}>
                  <Image
                    src={
                      deletionRequestingUser ? userPresentIcon : userAbsentIcon
                    }
                  />
                  <Text>
                    {strings.dialogs.systemAdmin2}
                    {deletionRequestingUser &&
                      ` (${deletionRequestingUser.firstName} ${deletionRequestingUser.lastName})`}
                  </Text>
                </HStack>
              </VStack>
              <VStack align="left" spacing={spaceBetweenUserRows}>
                <Text color="neutrals.brandGrey.500">
                  {strings.dialogs.requestingRemoval}
                </Text>
                <Text color="neutrals.brandGrey.500">
                  {deletionRequestingUser
                    ? strings.dialogs.removalRequested
                    : strings.dialogs.awaitingRemovalRequest}
                </Text>
              </VStack>
            </HStack>

            <Text>
              {deletionRequestingUser
                ? `${
                    type === 'Delete'
                      ? `${strings.dialogs.thisItemWillPermanentlyBeDeleted({
                          itemType,
                        })} `
                      : ''
                  }${strings.dialogs.areYouSureAction({
                    action: type.toLowerCase(),
                  })}`
                : strings.dialogs.actionRequiresAnotherSystemAdmins}
            </Text>
          </Fragment>
        )}
      </DialogBody>

      <DialogButtons>
        {!hasAlreadyRequested && (
          <SecondaryButton
            label={strings.common.cancel}
            isDisabled={isLoading}
            onClick={onCancel}
          />
        )}
        <PrimaryButton
          label={
            hasAlreadyRequested
              ? strings.common.close
              : deletionRequestingUser
              ? type
              : strings.common.proceed
          }
          isDisabled={isLoading}
          onClick={hasAlreadyRequested ? onCancel : onDelete}
        />
      </DialogButtons>
    </Dialog>
  );
}
