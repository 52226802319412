import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import FlowDialog from '../dialogs/FlowDialog';
import Button from '../buttons/Button';

type AuthenticationProps = {
  errorMessage?: string;
  title?: string;
  instructions?: string;
  buttonProps?: {
    text: string;
    onClick: () => void;
    isDisabled?: boolean;
  };
  spacingBetweenFlowAndTitle?: string;
  children?: ReactNode;
};

/**
 * A wrapper for all authentication screens such as login, registration,
 * password reset, etc. Provide content as children.
 */
export default function Authentication({
  errorMessage,
  title,
  instructions,
  buttonProps,
  spacingBetweenFlowAndTitle = '100px',
  children,
}: AuthenticationProps) {
  return (
    <FlowDialog
      errorMessage={errorMessage}
      title={title}
      instructions={instructions}
      spacingBetweenFlowAndTitle={spacingBetweenFlowAndTitle}
    >
      {buttonProps ? (
        <Box as="form" layerStyle="authenticationForm">
          <Box>{children}</Box>
          <Box>
            <Button
              type="submit"
              variant="primaryFullWidth"
              label={buttonProps.text}
              onClick={buttonProps.onClick}
              isDisabled={buttonProps.isDisabled}
              textStyle="subtitle3"
            />
          </Box>
        </Box>
      ) : (
        children
      )}
    </FlowDialog>
  );
}
