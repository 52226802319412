import { Text } from '@chakra-ui/react';
import React from 'react';
import { strings } from '../../../utils/strings';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';

type UnableToCreatePlatformDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function UnableToCreatePlatformDialog({
  isOpen,
  onClose,
}: UnableToCreatePlatformDialogProps) {
  return (
    <Dialog
      title={strings.platforms.unableToAddPlatform}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogBody>
        <Text>{strings.platforms.noMoreAdditionalPlatforms}</Text>
      </DialogBody>
      <DialogButtons>
        <PrimaryButton label={strings.common.goBack} onClick={onClose} />
      </DialogButtons>
    </Dialog>
  );
}
