import { createGqlAuthRequest } from '.';
import {
  CreateSubscriptionProductDocument,
  CreateSubscriptionProductMutation,
  CreateSubscriptionProductMutationVariables,
  DeleteSubscriptionProductDocument,
  DeleteSubscriptionProductMutation,
  DeleteSubscriptionProductMutationVariables,
  SubscriptionProductDocument,
  SubscriptionProductQuery,
  SubscriptionProductQueryVariables,
  SubscriptionProductsDocument,
  SubscriptionProductsQuery,
  SubscriptionProductsQueryVariables,
  UpdateSubscriptionProductDocument,
  UpdateSubscriptionProductMutation,
  UpdateSubscriptionProductMutationVariables,
} from '../gql/gqlRequests';

export const subscriptionProductsRequest = createGqlAuthRequest<
  SubscriptionProductsQueryVariables,
  SubscriptionProductsQuery
>(SubscriptionProductsDocument);

export const subscriptionProductRequest = createGqlAuthRequest<
  SubscriptionProductQueryVariables,
  SubscriptionProductQuery
>(SubscriptionProductDocument);

export const createSubscriptionProductRequest = createGqlAuthRequest<
  CreateSubscriptionProductMutationVariables,
  CreateSubscriptionProductMutation
>(CreateSubscriptionProductDocument);

export const updateSubscriptionProductRequest = createGqlAuthRequest<
  UpdateSubscriptionProductMutationVariables,
  UpdateSubscriptionProductMutation
>(UpdateSubscriptionProductDocument);

export const deleteSubscriptionProductRequest = createGqlAuthRequest<
  DeleteSubscriptionProductMutationVariables,
  DeleteSubscriptionProductMutation
>(DeleteSubscriptionProductDocument);
