import React, { ChangeEvent, ChangeEventHandler, useState } from 'react';
import {
  FormControl,
  FormLabel,
  HStack,
  Input as ChakraInput,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Spacer,
  Stack,
  Text,
  Textarea,
  theme,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Link from '../Link';
import Tooltip from '../Tooltip';
import {
  inputElementSize,
  standardInputHeight,
} from '../../../themes/constants';
import { inputTheme } from '../../../themes/input';

type InputPropsType = {
  label?: string;
  labelAsPlaceholder?: boolean;
  placeholder?: string;
  description?: string;
  tooltipText?: string;
  isPassword?: boolean;
  isTextArea?: boolean;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  withSearchIcon?: boolean;
  withColorBox?: boolean;
  colorBoxValue?: string;
  errorMessage?: string;
  rightLabel?: {
    label: string;
    to: string;
    isExternal: boolean;
  };
  bottomRightMessage?: string;
  phonePrefix?: string;
  value?: string;
  isValidCharacter?: (char: string) => boolean;
  setSanitizedValue?: (value: string) => void;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  direction?: 'row' | 'column';
  boxWidth?: string;
  boxHeight?: string;
  netWidth?: string;
};

// note: will need new variant(s) for screens/tags input (both horizontal, but with different widths)

/**
 * Typically, one of the other common input components should be used --
 * this is only for exceptional cases.
 *
 * If you want to sanitize the input, provide `isValidCharacter` and
 * receive the sanitized value in `setSanitizedValue`. `onChange` always
 * returns the (unsanitized) change event regardless.
 *
 * If you want to control the input value yourself (for example, set it
 * once data loads from the backend), then also pass `value`. Otherwise,
 * you can omit it.
 */
export default function Input({
  label,
  labelAsPlaceholder = false,
  placeholder,
  description,
  tooltipText,
  isPassword = false,
  isTextArea = false,
  isInvalid = false,
  isDisabled = false,
  isPermanentlyDisabled = false,
  withSearchIcon = false,
  withColorBox = false,
  colorBoxValue,
  errorMessage,
  rightLabel,
  bottomRightMessage,
  phonePrefix,
  value: externalValue,
  isValidCharacter = () => true,
  setSanitizedValue,
  onChange,
  direction = 'column',
  boxWidth,
  boxHeight = standardInputHeight,
  netWidth,
}: InputPropsType) {
  // the internal, visible value (not including phone prefix), if none provided
  const [value, setValue] = useState('');
  // override visible value with externally provided value if given
  const realValue = phonePrefix
    ? externalValue?.substring(phonePrefix.length)
    : externalValue ?? value;

  function sanitize(value: string) {
    return value.split('').filter(isValidCharacter).join('');
  }
  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) {
    // send raw event regardless of sanitization and phone prefix
    if (onChange) onChange(e);
    const sanitizedValue = sanitize(e.target.value);
    // send sanitized value back, with prefix re-attached if needed
    if (setSanitizedValue)
      setSanitizedValue((phonePrefix ?? '') + sanitizedValue);
    // set the interval, visible value - no prefix
    setValue(sanitizedValue);
  }

  const isRow = direction === 'row';
  const isCol = direction === 'column';

  if (withColorBox && !setSanitizedValue) {
    throw new Error('Must supply setSanitizedValue to use color box');
  }

  const commonInputProps = {
    value: realValue,
    placeholder: labelAsPlaceholder ? label : placeholder,
    onChange: handleChange,
    isInvalid,
    isDisabled: isDisabled || isPermanentlyDisabled,
    width: boxWidth,
  };

  return (
    <FormControl width={netWidth}>
      <Stack spacing="5px" direction={direction}>
        {(label || rightLabel) && (
          <HStack spacing="10px" width={isCol ? boxWidth : undefined}>
            <FormLabel margin="0">
              <Text textStyle="subtitle3" color="neutrals.navigationOutline">
                {label}
              </Text>
            </FormLabel>
            {tooltipText && <Tooltip tipText={tooltipText} />}
            <Spacer />
            {rightLabel && (
              <Link
                label={rightLabel.label}
                to={rightLabel.to}
                isExternal={rightLabel.isExternal}
                textStyle="bodyCopySmall"
              />
            )}
          </HStack>
        )}
        {description && (
          <Text textStyle="bodyCopySmall" color="neutrals.brandGrey.500">
            {description}
          </Text>
        )}

        {isRow && <Spacer />}

        <InputGroup height={boxHeight} width={boxWidth}>
          {withSearchIcon && (
            <InputLeftElement>
              <SearchIcon
                color="neutrals.brandGrey.500"
                data-testid="search-icon"
              />
            </InputLeftElement>
          )}
          {phonePrefix && <InputLeftAddon>{phonePrefix.trim()}</InputLeftAddon>}
          {isTextArea ? (
            <Textarea
              // unable to override styles in extended theme, so doing it here
              {...inputTheme.variants?.outline({
                colorMode: 'light', // using arbitrary values here, since we do not support color modes/schemes
                colorScheme: '',
                theme: { theme },
                isDisabled,
                isInvalid,
              }).field}
              height={boxHeight}
              {...commonInputProps}
              resize="none"
            />
          ) : (
            <ChakraInput
              type={isPassword ? 'password' : 'text'}
              {...commonInputProps}
              height="100%"
              paddingLeft={withSearchIcon ? inputElementSize : undefined}
              paddingRight={withColorBox ? inputElementSize : undefined}
              maxLength={withColorBox ? 7 : undefined}
            />
          )}

          {withColorBox && setSanitizedValue && (
            <InputRightElement
              layerStyle="colorPreviewElement"
              width="44px"
              height="44px"
              backgroundColor={colorBoxValue}
            >
              <ChakraInput
                type="color"
                style={{
                  width: '100%',
                  height: '100%',
                  // this <input> can't easily be styled directly, so hide it
                  opacity: 0,
                }}
                _hover={{
                  cursor: commonInputProps.isDisabled
                    ? 'not-allowed'
                    : 'pointer',
                }}
                value={colorBoxValue}
                onInput={(e) => setSanitizedValue(e.currentTarget.value)}
                isDisabled={commonInputProps.isDisabled}
                data-testid="color-box"
              />
            </InputRightElement>
          )}
        </InputGroup>

        {(errorMessage || bottomRightMessage) && (
          <HStack width={boxWidth}>
            <Text textStyle="bodyCopySmall" color="system.error.700">
              {errorMessage}
            </Text>
            <Spacer />
            <Text textStyle="bodyCopySmall" color="neutrals.brandGrey.500">
              {bottomRightMessage}
            </Text>
          </HStack>
        )}
      </Stack>
    </FormControl>
  );
}
