import React, { MouseEventHandler } from 'react';
import {
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
} from '@chakra-ui/react';
import MenuItem from './../MenuItem';
import settingsIcon from '../../../assets/dots-black.svg';
import editIcon from '../../../assets/edit.svg';
import deleteIcon from '../../../assets/delete.svg';
import { strings } from '../../../utils/strings';

type TableRowMenuProps = {
  isMenuOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
  onEdit?: MouseEventHandler;
  onDelete?: MouseEventHandler;
  onCancelDelete?: MouseEventHandler;
  hasCancel?: boolean;
  deleteText?: string;
  isDisabled?: boolean;
};

export default function TableRowMenu({
  isMenuOpen,
  onClose,
  onOpen,
  onEdit,
  onDelete,
  onCancelDelete,
  deleteText = 'Delete',
  isDisabled = false,
  hasCancel = false,
}: TableRowMenuProps) {
  return (
    <Menu
      onClose={onClose}
      onOpen={onOpen}
      autoSelect={false}
      placement="bottom-end"
    >
      <MenuButton
        as={IconButton}
        variant="iconButton"
        disabled={isDisabled}
        size="sm"
        icon={<Image src={settingsIcon} alt={strings.common.options} />}
        aria-label={strings.common.options}
      />
      {/* MenuList is `visible: hidden` when closed, which messes up the drag preview on some browsers */}
      {isMenuOpen && (
        <MenuList zIndex="popover">
          {onEdit && (
            <MenuItem
              icon={<Image src={editIcon} />}
              onClick={isDisabled ? undefined : onEdit}
            >
              {strings.common.edit}
            </MenuItem>
          )}
          {hasCancel
            ? onCancelDelete && (
                <MenuItem
                  icon={<Image src={deleteIcon} />}
                  onClick={isDisabled ? undefined : onCancelDelete}
                >
                  {strings.common.cancelDelete}
                </MenuItem>
              )
            : onDelete && (
                <MenuItem
                  icon={<Image src={deleteIcon} />}
                  onClick={isDisabled ? undefined : onDelete}
                >
                  {deleteText}
                </MenuItem>
              )}
        </MenuList>
      )}
    </Menu>
  );
}
