import React from 'react';
import { Center, VStack, Image, Text } from '@chakra-ui/react';
import platformIcon from '../../../assets/tv-large.svg';
import Button from '../../common/buttons/Button';
import { AddIcon } from '@chakra-ui/icons';
import { strings } from '../../../utils/strings';

type GetStartedPlatformProps = {
  height: string;
  onCreate?: () => void;
};

export default function GetStartedPlatform({
  height,
  onCreate,
}: GetStartedPlatformProps) {
  return (
    <Center
      height={height}
      backgroundColor="neutrals.white"
      borderRadius="10px"
    >
      <VStack spacing={0}>
        <Image
          src={platformIcon}
          alt={strings.platforms.platform}
          paddingBottom="15px"
          maxWidth="60px"
        />
        <Text
          textStyle="subtitle3"
          color="neutrals.brandGrey.500"
          paddingBottom="30px"
        >
          {strings.platforms.getStartedPlatform}
        </Text>
        {onCreate && (
          <Button
            // minor exception, not any of the usual buttons
            variant="solid"
            label={strings.platforms.addPlatform}
            leftIcon={<AddIcon data-testid="plus-icon" />}
            iconSpacing="15px"
            onClick={onCreate}
            height="50px"
            paddingX="20px"
          />
        )}
      </VStack>
    </Center>
  );
}
