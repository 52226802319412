import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import DragAndDropTable from '../../common/tables/DragAndDropTable';
import { useNavigate } from 'react-router-dom';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { updateNavBarOrderRequest } from '../../../support/navBarItems';
import { useMutation } from '@tanstack/react-query';
import { EnvironmentContext } from '../../../contexts';
import { RequestError } from '../../../types';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import Link from '../../common/Link';
import makeReorderedArray from '../../../utils/reordering';
import DeleteNavBarItemDialog from './DeleteNavBarItemDialog';
import { ImageDisplay } from '../../../types/images';
import {
  ConfigQuery,
  Environment,
  UpdateNavBarOrderMutation,
  UpdateNavBarOrderMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { Route } from '../../../types/routes';

const { common } = strings;

export const maxNavBarItems = 8;

type NavBarItemRow = {
  order: number;
  id: string;
  label: string;
  icon: ImageDisplay;
  requireAuthenticated: boolean;
  requireSubscribed: boolean;
  screen:
    | {
        id: string;
        name: string;
      }
    | undefined
    | null;
};

const rowHeight = '44px';
const textHeight = '20px';
const enabledScreenCellPadding = `calc((${rowHeight} - ${textHeight}) / 2)`;
const iconSide = '30px';

const columnHelper = createColumnHelper<NavBarItemRow>();
const columns = [
  columnHelper.accessor('order', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('label', {
    header: strings.common.label,
  }),
  columnHelper.accessor('icon', {
    header: strings.common.icon,
    cell: (info) => (
      <Box
        bgColor={'neutrals.brandGrey.400'}
        borderRadius="5px"
        padding="5px"
        width="fit-content"
      >
        <Image
          src={info.getValue().url}
          alt={info.getValue().filename}
          objectFit="contain"
          width={iconSide}
          height={iconSide}
        />
      </Box>
    ),
  }),
  columnHelper.accessor('screen', {
    header: strings.screens.screen,
    cell: (info) => {
      const data = info.getValue();
      return !!data ? (
        // annoying height/padding calc to make all rows have same height
        <Box height={rowHeight} paddingTop={enabledScreenCellPadding}>
          {data.name}
        </Box>
      ) : (
        <VStack spacing="0" align="left" height={rowHeight}>
          <Text textStyle="bodyCopy">{strings.common.disabled}</Text>
          <Link
            textStyle="subtitle4"
            label={strings.platforms.goToScreens}
            to={`../../../${Route.Screens}`}
            relative="path"
          />
        </VStack>
      );
    },
  }),
  columnHelper.accessor('requireAuthenticated', {
    header: strings.common.authenticated,
    cell: (info) => (info.getValue() ? common.required : common.notRequired),
  }),
  columnHelper.accessor('requireSubscribed', {
    header: strings.common.subscribed,
    cell: (info) => (info.getValue() ? common.required : common.notRequired),
  }),
];

type NavBarProps = {
  configId: string;
  isFetching: boolean;
  navBarItems: ConfigQuery['app']['config']['navBarItems'];
  refetchConfig: () => void;
};

export default function NavBar({
  configId,
  isFetching,
  navBarItems,
  refetchConfig,
}: NavBarProps) {
  const { environment } = useContext(EnvironmentContext);
  const navigate = useNavigate();

  // reorder rows mutation
  const updateNavBarOrderMutationFn = useAuthRequest<
    UpdateNavBarOrderMutationVariables,
    UpdateNavBarOrderMutation
  >(updateNavBarOrderRequest);
  const updateNavBarOrderMutation = useMutation<
    UpdateNavBarOrderMutation,
    RequestError,
    UpdateNavBarOrderMutationVariables
  >({
    mutationFn: updateNavBarOrderMutationFn,
    onSuccess: refetchConfig,
  });

  // table data

  const data = useMemo(
    () =>
      navBarItems.map((navBarItem, ind) => {
        const {
          id,
          screenType,
          label,
          requireAuthenticated,
          requireSubscribed,
          screen,
          icon,
        } = navBarItem;
        return {
          // backend provides these in order, so use ind for `order`
          order: ind,
          id,
          screenType,
          label,
          icon,
          requireAuthenticated,
          requireSubscribed,
          screen: screen,
        };
      }),
    [navBarItems],
  );
  const navBarItemCount = data.length;

  // re-ordering

  const idOrder = useMemo(() => data.map(({ id }) => id), [data]);

  function reorderNavBarItems(draggedId: string, targetId: string) {
    const newIdOrder = makeReorderedArray(
      idOrder,
      (id) => id,
      draggedId,
      targetId,
    );

    if (idOrder === newIdOrder) return;

    setIsReordering(true);
    const input = { navBarOrder: newIdOrder };
    updateNavBarOrderMutation.mutate({ configId, input });
  }

  const [isReordering, setIsReordering] = useState(false);
  useEffect(
    function detectReorderingIsDone() {
      // if both _mutation_ to re-order and _query_ to fetch new order are done
      if (!isFetching && !updateNavBarOrderMutation.isLoading) {
        setIsReordering(false);
      }
    },
    [isFetching, updateNavBarOrderMutation.isLoading],
  );

  // creation

  const [isUnableToCreateDialogOpen, setIsUnableToCreateDialogOpen] =
    useState(false);

  function tryToCreateNavBarItem() {
    if (navBarItemCount >= maxNavBarItems) {
      setIsUnableToCreateDialogOpen(true);
    } else {
      navigate(Route.Create);
    }
  }

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();

  // UI

  const canModify = environment !== Environment.Production;

  return (
    <Fragment>
      <DragAndDropTable
        title={strings.platforms.allNavBarItems}
        subtitle={strings.platforms.maxNavBarItemsMessage({
          maxNavBarItems,
        })}
        isLoading={isReordering}
        data={data}
        columns={columns}
        isReorderDisabled={!canModify}
        reorderData={reorderNavBarItems}
        onEditRow={
          canModify
            ? (id: string) => navigate(`${id}/${Route.Edit}`)
            : undefined
        }
        onDeleteRow={canModify ? setIdToDelete : undefined}
        onCreate={canModify ? tryToCreateNavBarItem : undefined}
        createButtonTitle={strings.platforms.createNavBarItem}
        boldColumn="label"
        marginTop="40px"
      />

      <Dialog
        title={strings.platforms.unableToCreateNavBarItem}
        isOpen={isUnableToCreateDialogOpen}
        onClose={() => setIsUnableToCreateDialogOpen(false)}
      >
        <DialogBody>
          <Text>
            {strings.platforms.unableToCreateMaxNavBarItemsMessage({
              maxNavBarItems,
            })}
          </Text>
        </DialogBody>
        <DialogButtons>
          <PrimaryButton
            label={strings.common.goBack}
            onClick={() => setIsUnableToCreateDialogOpen(false)}
          />
        </DialogButtons>
      </Dialog>

      <DeleteNavBarItemDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        navBarItems={data}
        onSuccess={refetchConfig}
        onClose={() => setIdToDelete(undefined)}
      />
    </Fragment>
  );
}
