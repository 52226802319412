export function joinRegExps(regExps: RegExp[]) {
  return new RegExp(regExps.map((x) => x.source).join('|'));
}
// characters

export const UPPER_CASE_CHARS = /[A-Z]/;
export const LOWER_CASE_CHARS = /[a-z]/;
export const A_F_CHARS = /[A-Fa-f]/;
export const DIGIT_CHARS = /\d/;
// eslint-disable-next-line no-useless-escape
export const SPECIAL_CHARS = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
export const PAREN_CHARS = /[)(]/;
export const SPACE_CHAR = /\s/;
export const DASH_CHAR = /-/;
export const POUND_CHAR = /#/;
export const ALPHANUMERIC = /[A-Za-z\d]/;

export const PASSWORD_CHARS = joinRegExps([
  UPPER_CASE_CHARS,
  LOWER_CASE_CHARS,
  SPECIAL_CHARS,
  DIGIT_CHARS,
  SPACE_CHAR,
]);

export const PHONE_CHARS = joinRegExps([
  DIGIT_CHARS,
  DASH_CHAR,
  SPACE_CHAR,
  PAREN_CHARS,
]);

export const COLOR_CHARS = joinRegExps([POUND_CHAR, DIGIT_CHARS, A_F_CHARS]);

// strings

// see https://semver.org/#is-there-a-suggested-regular-expression-regex-to-check-a-semver-string
export const SEMANTIC_VERSION_REGEX =
  /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/;
export const COLOR_REGEX = /^#([A-Fa-f0-9]{6})$/;
