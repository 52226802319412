import { Box, Center, Image as ChakraImage } from '@chakra-ui/react';
import React, { Fragment } from 'react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import { DialogBody, DialogButtons } from '../dialogs/Dialog';
import { Image } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

type ImagePreviewContentProps = {
  image: Image;
  onSelect: () => void;
  onClose: () => void;
};

export default function ImagePreviewContent({
  image,
  onSelect,
  onClose,
}: ImagePreviewContentProps) {
  return (
    <Fragment>
      <DialogBody>
        <Box layerStyle="imagePreview">
          <Center height="100%">
            <ChakraImage src={image.url} alt={image.filename} />
          </Center>
        </Box>
      </DialogBody>
      <DialogButtons>
        <SecondaryButton label={strings.common.cancel} onClick={onClose} />
        <PrimaryButton label={strings.common.select} onClick={onSelect} />
      </DialogButtons>
    </Fragment>
  );
}
