import { useState } from 'react';
import {
  DIGIT_CHARS,
  LOWER_CASE_CHARS,
  SPECIAL_CHARS,
  UPPER_CASE_CHARS,
} from '../utils/regexp';
import { strings } from '../utils/strings';

const { length, upperCaseChar, lowerCaseChar, specialChar, digit } =
  strings.authentication.newPasswordRequirements;

const requirements = [length, upperCaseChar, lowerCaseChar, specialChar, digit];

export default function useNewPasswordValidation() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const doPasswordsMatch = newPassword === confirmPassword;
  const requirementsAreMet = [
    newPassword.length >= 8,
    UPPER_CASE_CHARS.test(newPassword),
    LOWER_CASE_CHARS.test(newPassword),
    SPECIAL_CHARS.test(newPassword),
    DIGIT_CHARS.test(newPassword),
  ];

  const isValid = requirementsAreMet.every((x) => !!x) && doPasswordsMatch;
  const isNewInvalid = requirementsAreMet.some((x) => !x);
  const isConfirmInvalid =
    requirementsAreMet.some((x) => !x) || !doPasswordsMatch;
  // requirements are higher priority than matching
  const errorMessage = requirementsAreMet.some((x) => !x)
    ? strings.authentication.passwordDoesNotMeetRequirements
    : !doPasswordsMatch
    ? strings.authentication.passwordsDoNotMatch
    : '';

  return {
    newPassword,
    setNewPassword,
    setConfirmPassword,
    doPasswordsMatch,
    requirements,
    requirementsAreMet,
    isValid,
    isNewInvalid,
    isConfirmInvalid,
    errorMessage,
  };
}
