import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    width: '76px',
    height: '41px',
  },
  thumb: {
    marginTop: '3px',
    marginLeft: '3px',
    width: '35px',
    height: '35px',
  },
});

export const switchTheme = defineMultiStyleConfig({
  baseStyle,
});
