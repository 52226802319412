import { Center } from '@chakra-ui/react';
import React, { Fragment, ReactNode, useContext } from 'react';
import { AppContext } from '../../contexts';
import Progress from './Progress';

type WaitForAppProps = {
  children: ReactNode;
};

/**
 * Waits for the app id to be set then renders children.
 * This means we can assume the app id is truthy within.
 */
export default function WaitForApp({ children }: WaitForAppProps) {
  const { isLoading } = useContext(AppContext);

  if (isLoading) {
    return (
      <Center width="100%" height="100%">
        <Progress />
      </Center>
    );
  }

  return <Fragment>{children}</Fragment>;
}
