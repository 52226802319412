import React, { useReducer, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { displayEnum, scrollToTop } from '../../../utils';
import { ActionType, FormType, RequestError } from '../../../types';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import Select from '../../common/inputs/Select';
import Form, { FormButtons, FormDivider, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import useConfigId from '../../../hooks/useConfigId';
import { Box, Text } from '@chakra-ui/react';
import {
  addIntegrationRequest,
  configIntegrationsRequest,
  updateIntegrationRequest,
} from '../../../support/integrations';
import useIntegrationsQuery from '../../../hooks/useIntegrationsQuery';
import Toggle from '../../common/inputs/Toggle';
import {
  ConfigIntegrationsQuery,
  ConfigIntegrationsQueryVariables,
  IntegrationType,
  AddIntegrationMutation,
  AddIntegrationMutationVariables,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import StandardInput from '../../common/inputs/StandardInput';
import { isJson, validateUrl } from '../../../utils/validation';
import { platformUrlAsName } from '../../../utils/platforms';

// TODO: remove this type when backend has LogLevel type
enum TemporaryLogLevel {
  CRITICAL = 'critical',
  WARNING = 'warning',
  ERROR = 'error',
  DEBUG = 'debug',
}

// reducer //

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; state: InputState }
  | {
      type: ActionType.SET_INTEGRATION_TYPE;
      key: 'integrationType';
      value: IntegrationType;
    }
  | {
      type: ActionType.SET_INTEGRATION_KEY;
      key: 'integrationKey';
      value: {
        integrationKey: string | null;
        integrationId: string | null;
      };
    }
  | {
      type: ActionType.SET_SETTINGS;
      key: 'settings';
      value: Record<string, any>;
    };

type InputState = {
  integrationType: IntegrationType | null;
  integrationKey: string | null;
  integrationId: string | null;
  settings: Record<string, any>;
};

function inputDataReducer(state: InputState, action: Action) {
  const updatedState = { ...state };
  switch (action.type) {
    case ActionType.RESET:
      return initialInputState;

    case ActionType.SET_ALL:
      return action.state;

    case ActionType.SET_INTEGRATION_TYPE:
      updatedState.integrationId = initialInputState.integrationId;
      updatedState.settings = initialInputState.settings;
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_INTEGRATION_KEY:
      updatedState.settings = initialInputState.settings;
      updatedState[action.key] = action.value.integrationKey;
      updatedState['integrationId'] = action.value.integrationId;
      return updatedState;

    case ActionType.SET_SETTINGS:
      updatedState[action.key] = action.value;
      return updatedState;

    default:
      throw new Error('Unknown integration input state action');
  }
}

const initialInputState: InputState = {
  integrationType: null,
  integrationKey: null,
  integrationId: null,
  settings: { enabled: true },
};

// integration item form //

type IntegrationFormProps = {
  formType: FormType;
};

export default function IntegrationForm({ formType }: IntegrationFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const integrationId = params.integrationId ?? '';
  const pageRef = useRef<HTMLDivElement>(null);
  const {
    configId,
    isLoading: isConfigIdLoading,
    platformString,
  } = useConfigId();

  // ui states

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // current list of integrations
  const configIntegrationsFn = useAuthRequest<
    ConfigIntegrationsQueryVariables,
    ConfigIntegrationsQuery
  >(configIntegrationsRequest);
  const inUseConfigIntegrationsQuery = useQuery<
    ConfigIntegrationsQuery,
    RequestError
  >({
    queryKey: ['inUseConfigIntegrations', configId],
    queryFn: () => configIntegrationsFn({ id: configId }),
    onSuccess: populateInputData,
    enabled: !isConfigIdLoading,
  });
  const inUseConfigIntegrationsData =
    inUseConfigIntegrationsQuery.data?.flowConfig.integrations ?? [];

  // fetch

  function populateInputData(data: ConfigIntegrationsQuery) {
    if (formType === 'create') return;
    const inUseConfigIntegration = data.flowConfig.integrations.find(
      ({ integration: { id } }) => id === integrationId,
    );
    if (inUseConfigIntegration === undefined) {
      throw new Error('cant find config integration to edit');
    }
    const {
      settingsJson,
      integration: { id, type, key },
    } = inUseConfigIntegration;

    if (!!settingsJson['context']) {
      settingsJson['context'] = JSON.stringify(settingsJson['context']);
    }

    dispatchInputState({
      type: ActionType.SET_ALL,
      state: {
        integrationType: type,
        integrationKey: key,
        integrationId: id,
        settings: settingsJson,
      },
    });
  }

  // both update and create (backend setup)

  const addIntegrationMutationFn = useAuthRequest<
    AddIntegrationMutationVariables,
    AddIntegrationMutation
  >(addIntegrationRequest);
  const addIntegrationMutation = useMutation<
    AddIntegrationMutation,
    RequestError,
    AddIntegrationMutationVariables
  >({
    mutationFn: addIntegrationMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: () => {
      // if we don't refetch, next time the edit form is opened, the cached query
      // data might not contain the integration being edited
      // (this won't be a problem after EFS-462 is done and we switch to atomic operations)
      inUseConfigIntegrationsQuery.refetch();
      navigateBack();
    },
  });

  const updateIntegrationMutationFn = useAuthRequest<
    UpdateIntegrationMutationVariables,
    UpdateIntegrationMutation
  >(updateIntegrationRequest);
  const updateIntegrationMutation = useMutation<
    UpdateIntegrationMutation,
    RequestError,
    UpdateIntegrationMutationVariables
  >({
    mutationFn: updateIntegrationMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: () => {
      // if we don't refetch, next time the edit form is opened, the cached query
      // data might not contain the integration being edited
      // (this won't be a problem after EFS-462 is done and we switch to atomic operations)
      inUseConfigIntegrationsQuery.refetch();
      navigateBack();
    },
  });

  // input

  const [inputState, dispatchInputState] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  const {
    integrationType,
    integrationKey,
    settings,
    integrationId: intgrId,
  } = inputState;

  // integrations

  const integrationsQuery = useIntegrationsQuery(
    integrationType ? [integrationType] : [],
  );
  const integrationsData = integrationsQuery.data?.integrations;

  const unusedIntegrations = integrationsData
    ? integrationsData
        .filter(
          ({ id }) =>
            !inUseConfigIntegrationsData
              .map(({ integration: { id: usedId } }) => usedId)
              .includes(id),
        )
        .map(({ id, key }) => ({ id, key }))
    : [];
  const activeIntegration = integrationsData
    ?.map(({ id, key }) => ({ id, key }))
    .find(({ id }) => id === integrationId);

  // integrations options must always be an array even with one element
  const editIntegrationsOptions = activeIntegration ? [activeIntegration] : [];

  const integrationsOptions =
    formType === 'create' ? unusedIntegrations : editIntegrationsOptions;
  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    return formType === 'create'
      ? hasErrorForKey(addIntegrationMutation.error, key)
      : hasErrorForKey(updateIntegrationMutation.error, key);
  }

  // frontend validation

  const configurableValuesAdobeAnalytics =
    integrationType === IntegrationType.Analytics &&
    integrationKey === 'adobe_analytics';

  const configurableValuesPlayback =
    integrationType === IntegrationType.Playback &&
    integrationKey === 'nexstar';

  const isIntegrationTypeValid = !!integrationType;
  const isIntegrationKeyValid = !!integrationKey;
  const showConfigurableValues =
    (integrationType === IntegrationType.Logs &&
      (integrationKey === 'new_relic' || integrationKey === 'crashlytics')) ||
    integrationType === IntegrationType.Image ||
    configurableValuesPlayback ||
    configurableValuesAdobeAnalytics;

  const configurableValuesLogs =
    integrationType === IntegrationType.Logs &&
    (integrationKey === 'new_relic' || integrationKey === 'crashlytics');

  const configurableValuesImage = integrationType === IntegrationType.Image;

  const isIntegrationConfigurableValuesValidImage = configurableValuesImage
    ? !!settings.baseURL && validateUrl(settings.baseURL)
    : true;
  const isIntegrationConfigurableValuesValidLog = configurableValuesLogs
    ? !!settings?.level
    : true;
  const isIntegrationConfigurableValuesValidPlayback =
    configurableValuesPlayback
      ? !!settings?.vodAdUrl
        ? validateUrl(settings.vodAdUrl)
        : true
      : true;

  const isIntegrationConfigurableValuesValidAdobeAnalytics =
    configurableValuesAdobeAnalytics
      ? !!settings?.context
        ? isJson(settings.context)
        : true
      : true;

  const isInputValid =
    isIntegrationTypeValid &&
    isIntegrationKeyValid &&
    isIntegrationConfigurableValuesValidLog &&
    isIntegrationConfigurableValuesValidImage &&
    isIntegrationConfigurableValuesValidPlayback &&
    isIntegrationConfigurableValuesValidAdobeAnalytics;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    const { integrationId } = inputState;
    // this will be removed in 1.5
    const settingsJson = showConfigurableValues
      ? configurableValuesPlayback
        ? !!settings?.vodAdUrl
          ? { vodAdUrl: settings?.vodAdUrl }
          : {}
        : settings
      : {};
    if (integrationType !== IntegrationType.Logs) {
      delete settingsJson['enabled'];
    }
    if (!!settingsJson['context']) {
      settingsJson['context'] = JSON.parse(settingsJson['context']);
    }

    Object.keys(settingsJson).forEach((key) => {
      if (!settings[key]) {
        delete settingsJson[key];
      }
    });

    if (!integrationId) {
      return;
    }

    if (formType === 'create') {
      addIntegrationMutation.mutate({
        id: configId,
        input: {
          integrationId,
          settingsJson,
        },
      });
    } else {
      updateIntegrationMutation.mutate({
        id: configId,
        input: {
          integrationId,
          settingsJson,
        },
      });
    }
  }

  // UI

  const title =
    formType === 'create'
      ? strings.platforms.addIntegration
      : strings.platforms.editIntegration;
  const submitText =
    formType === 'create'
      ? strings.platforms.addIntegration
      : strings.common.saveChanges;

  const isCreateLoading =
    isConfigIdLoading ||
    (inUseConfigIntegrationsQuery.isLoading && !!configId) ||
    (integrationsQuery.isLoading && !!integrationType) ||
    addIntegrationMutation.isLoading;
  const isEditLoading =
    isConfigIdLoading ||
    !integrationType ||
    !integrationsData ||
    updateIntegrationMutation.isLoading;
  const isLoading = formType === 'create' ? isCreateLoading : isEditLoading;

  if (inUseConfigIntegrationsQuery.isError)
    throw parsedRequestError(inUseConfigIntegrationsQuery.error);

  const configurableValuesFactory = () => {
    switch (true) {
      case configurableValuesLogs:
        return (
          <>
            <Select
              label={strings.platforms.logLevel}
              isInvalid={
                isValidationActive && !isIntegrationConfigurableValuesValidLog
              }
              isDisabled={isLoading}
              value={settings?.level}
              onChange={(e) =>
                dispatchInputState({
                  type: ActionType.SET_SETTINGS,
                  key: 'settings',
                  value: { ...settings, level: e.target.value },
                })
              }
            >
              {/* TODO: use actual LogLevel type once available */}
              {Object.values(TemporaryLogLevel).map((level) => (
                <option key={level} value={level}>
                  {displayEnum(level)}
                </option>
              ))}
            </Select>
            <Toggle
              label={strings.common.enabled}
              isDisabled={isLoading}
              value={settings?.enabled}
              setValue={(value) =>
                dispatchInputState({
                  type: ActionType.SET_SETTINGS,
                  key: 'settings',
                  value: { ...settings, enabled: value },
                })
              }
            />
          </>
        );
      case configurableValuesImage:
        return (
          <>
            <StandardInput
              label={strings.platforms.baseURL}
              value={settings?.baseURL}
              isInvalid={
                isValidationActive && !isIntegrationConfigurableValuesValidImage
              }
              isDisabled={isLoading}
              onChange={(e) =>
                dispatchInputState({
                  type: ActionType.SET_SETTINGS,
                  key: 'settings',
                  value: { ...settings, baseURL: e.target.value },
                })
              }
            />
          </>
        );
      case configurableValuesPlayback:
        return (
          <StandardInput
            label={strings.platforms.vodAdUrl}
            isDisabled={isLoading}
            value={settings?.vodAdUrl ?? undefined}
            isInvalid={
              isValidationActive &&
              !isIntegrationConfigurableValuesValidPlayback
            }
            onChange={(e) =>
              dispatchInputState({
                key: 'settings',
                type: ActionType.SET_SETTINGS,
                value: { ...settings, vodAdUrl: e.target.value },
              })
            }
          />
        );
      case configurableValuesAdobeAnalytics:
        return (
          <StandardInput
            label={strings.platforms.context}
            isDisabled={isLoading}
            value={settings?.context ?? undefined}
            isInvalid={
              isValidationActive &&
              !isIntegrationConfigurableValuesValidAdobeAnalytics
            }
            onChange={(e) =>
              dispatchInputState({
                key: 'settings',
                type: ActionType.SET_SETTINGS,
                value: { ...settings, context: e.target.value },
              })
            }
            tooltipText={strings.platforms.networkTooltip}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Page
      isForm
      withEnvSearchParam
      disallowProduction
      withBack
      title={title}
      subtitle={platformUrlAsName(platformString)}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />
        <Select
          label={strings.platforms.integrationType}
          isInvalid={
            isValidationActive &&
            (!isIntegrationTypeValid || hasBackendError('integrationType'))
          }
          isDisabled={isLoading}
          // cannot edit integration type
          isPermanentlyDisabled={formType === 'edit'}
          value={integrationType ?? undefined}
          onChange={(e) =>
            dispatchInputState({
              type: ActionType.SET_INTEGRATION_TYPE,
              key: 'integrationType',
              value: e.target.value as IntegrationType,
            })
          }
        >
          {Object.values(IntegrationType).map((integrationType) => (
            <option key={integrationType} value={integrationType}>
              {displayEnum(integrationType as IntegrationType)}
            </option>
          ))}
        </Select>
        {integrationsOptions.length !== 0 && (
          <Select
            label={strings.platforms.integration}
            isInvalid={
              isValidationActive &&
              (!isIntegrationKeyValid || hasBackendError('integrationKey'))
            }
            isDisabled={isLoading}
            // cannot edit integration key
            isPermanentlyDisabled={formType === 'edit'}
            value={
              integrationKey || intgrId
                ? JSON.stringify([integrationKey, intgrId])
                : undefined
            }
            onChange={(e) => {
              const integration = JSON.parse(e.target.value);
              dispatchInputState({
                type: ActionType.SET_INTEGRATION_KEY,
                key: 'integrationKey',
                value: {
                  integrationKey: integration[0],
                  integrationId: integration[1],
                },
              });
            }}
          >
            {integrationsOptions.map(({ id, key }) => (
              <option key={id} value={JSON.stringify([key, id])}>
                {key}
              </option>
            ))}
          </Select>
        )}
        {/* TODO: when loading edit this section appears earlier than Integration select */}
        {integrationKey && showConfigurableValues && (
          // TODO: change this to be a factory when more Configurable Values are added
          <>
            <FormDivider />
            <Box>
              <Text textStyle="subtitle1">
                {strings.platforms.configurableValues}
              </Text>
              <Form marginTop="10px">{configurableValuesFactory()}</Form>
            </Box>
          </>
        )}
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
