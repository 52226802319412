import React from 'react';
import AuthenticationInfo from '../../common/authentication/AuthenticationInfo';
import hourglass from '../../../assets/hourglass.svg';
import { strings } from '../../../utils/strings';

type RegistrationLinkExpiredProps = {
  onNewLinkRequest: () => void;
};

/**
 * A sub-component of `RegistrationExpired`, explaining the situation.
 */
export default function RegistrationLinkExpired({
  onNewLinkRequest,
}: RegistrationLinkExpiredProps) {
  const requestNewLinkButton = {
    text: strings.authentication.requestNewLink,
    onClick: onNewLinkRequest,
  };
  return (
    <AuthenticationInfo
      icon={hourglass}
      iconAlt={strings.common.expired}
      title={strings.authentication.registrationLinkExpired}
      subtitle={strings.authentication.registrationLinkInvalid}
      buttonProps={requestNewLinkButton}
    />
  );
}
