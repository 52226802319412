import React, { JSXElementConstructor, ReactElement } from 'react';
import Button from './Button';
import { ThemeTypings } from '@chakra-ui/react';
import { spacing } from '../../../themes/constants';

type TextButtonPropsType = {
  label: string;
  leftIcon?: ReactElement<any, string | JSXElementConstructor<any>>;
  onClick: () => void;
  isDisabled?: boolean;
  textStyle?: ThemeTypings['textStyles'];
};

/**
 * A button which looks like a text link.
 * Add an icon via `leftIcon`.
 */
export default function TextButton({
  label,
  leftIcon,
  onClick,
  isDisabled,
  textStyle = 'subtitle2',
}: TextButtonPropsType) {
  return (
    <Button
      type="button"
      variant="text"
      label={label}
      leftIcon={leftIcon}
      onClick={onClick}
      isDisabled={isDisabled}
      iconSpacing={spacing[5]}
      textStyle={textStyle}
      height="auto"
    />
  );
}
