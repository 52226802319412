import { radioAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const bigger = definePartsStyle({
  control: {
    width: '24px',
    height: '24px',
  },
});

export const radioTheme = defineMultiStyleConfig({
  variants: {
    bigger,
  },
});
