import React, { useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import Authentication from '../../common/authentication/Authentication';
import Input from '../../common/inputs/Input';
import { verificationRequest } from '../../../support/authentication';
import { DIGIT_CHARS } from '../../../utils/regexp';
import { RequestError, RequestErrorCode } from '../../../types';
import {
  LoginResult,
  VerificationMutation,
  VerificationMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { displayMaskedPhoneNumber } from '../../../utils';
import { getErrorCode } from '../../../utils/errors';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../types/routes';

type TwoStepVerificationType = {
  email: string;
  session: string;
  phoneNumberEnd: string;
  setLoginResult: (loginResult: LoginResult) => void;
};

/**
 * Handles input of MFA code.
 */
export default function VerificationCode({
  email,
  session,
  phoneNumberEnd,
  setLoginResult,
}: TwoStepVerificationType) {
  const navigate = useNavigate();

  if (!email || !session || !phoneNumberEnd) {
    throw new Error('Must provide email, session, and phone number for MFA.');
  }

  const verificationMutation = useMutation<
    VerificationMutation,
    RequestError,
    VerificationMutationVariables
  >({
    mutationFn: verificationRequest,
    onSuccess: (data) => setLoginResult(data.respondToAuthChallenge),
    onError: applyBackendValidation,
  });

  function attemptToVerify() {
    verificationMutation.reset();
    if (isCodeValid) {
      verificationMutation.mutate({ email, session, code });
    } else {
      setErrorMessage(strings.authentication.incompleteVerificationCode);
    }
  }

  // input

  const [code, setCode] = useState('');

  // validation

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;

  const isCodeValid = !!code;
  const hasBadUserInputFromBackend =
    !!verificationMutation.error &&
    getErrorCode(verificationMutation.error) ===
      RequestErrorCode.BAD_USER_INPUT;

  function applyBackendValidation(e: RequestError) {
    switch (getErrorCode(e)) {
      case RequestErrorCode.UNAUTHENTICATED:
        navigate(routes.root);
        break;

      case RequestErrorCode.BAD_USER_INPUT:
        setErrorMessage(strings.authentication.incorrectVerificationCode);
        break;

      default:
        setErrorMessage(strings.errors.generic);
        break;
    }
  }

  // UI

  const nextButton = {
    text: strings.authentication.next,
    onClick: attemptToVerify,
    isDisabled: verificationMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.twoStepVerification}
      buttonProps={nextButton}
    >
      <Text textStyle="subtitle2" marginTop="25px">
        {strings.authentication.enterVerificationCode}
      </Text>
      <Text
        textStyle="bodyCopyLarge"
        textColor="neutrals.brandGrey.500"
        marginTop="6px"
        maxWidth="400px"
        data-testid="verification-description"
      >
        {strings.authentication.verificationCodeSentTo({
          phone: displayMaskedPhoneNumber(phoneNumberEnd),
        })}
      </Text>
      <Box marginTop="50px">
        <Input
          // unique - no label
          placeholder={strings.authentication.verificationCode}
          isValidCharacter={(char) => DIGIT_CHARS.test(char)}
          setSanitizedValue={setCode}
          isInvalid={
            hasTriedToSubmit && (!isCodeValid || hasBadUserInputFromBackend)
          }
          isDisabled={verificationMutation.isLoading}
          boxWidth="340px"
        />
      </Box>
    </Authentication>
  );
}
