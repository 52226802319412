import React from 'react';
import Button from './Button';

type SmallCreateButtonProps = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

/**
 * A small version of CreateButton (used for StandardImage Dropzone button).
 */
export default function SmallCreateButton({
  label,
  onClick,
  isDisabled = false,
}: SmallCreateButtonProps) {
  return (
    <Button
      type="button"
      variant="smallSecondary"
      textStyle="bodyCopySmall"
      label={label}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}
