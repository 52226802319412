import React from 'react';
import { Text } from '@chakra-ui/react';
import PrimaryButton from '../buttons/PrimaryButton';
import SecondaryButton from '../buttons/SecondaryButton';
import Dialog, { DialogBody, DialogButtons } from './Dialog';
import { strings } from '../../../utils/strings';

type StandardDeletionDialogProps = {
  itemType: string;
  isOpen: boolean;
  isLoading: boolean;
  onDelete: () => void;
  onClose: () => void;
  message?: string;
};

export default function StandardDeletionDialog({
  itemType,
  isOpen,
  isLoading,
  onDelete,
  onClose,
  message,
}: StandardDeletionDialogProps) {
  function closeUnlessLoading() {
    if (!isLoading) {
      onClose();
    }
  }

  return (
    <Dialog
      title={strings.dialogs.deleteItemType({ itemType })}
      isOpen={isOpen}
      onClose={closeUnlessLoading}
    >
      <DialogBody>
        <Text>{message ?? strings.dialogs.confirmDeletionMessage}</Text>
      </DialogBody>
      <DialogButtons>
        <SecondaryButton
          label={strings.common.cancel}
          isDisabled={isLoading}
          onClick={closeUnlessLoading}
        />
        <PrimaryButton
          label={strings.common.delete}
          isDisabled={isLoading}
          onClick={onDelete}
        />
      </DialogButtons>
    </Dialog>
  );
}
