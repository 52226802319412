import { useContext, useMemo, useReducer } from 'react';
import {
  SubscriptionProductsQuery,
  SubscriptionProductsQueryVariables,
} from '../gql/gqlRequests';
import { AppContext, EnvironmentContext } from '../contexts';
import useAuthRequest from './useAuthRequest';
import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { RequestError } from '../types';
import { parsedRequestError } from '../utils/errors';
import { subscriptionProductsRequest } from '../support/subscriptions';

type QueryType = UseQueryOptions<SubscriptionProductsQuery, RequestError>;

type SimplifiedSubscriptionProducts =
  SubscriptionProductsQuery['app']['config']['subscriptionProducts'];

export type SimplifiedSubscriptionProductsWithPlatform =
  (SimplifiedSubscriptionProducts[0] & {
    platformName: string;
  })[];

type Action = {
  index: number;
  subscriptions: SimplifiedSubscriptionProducts;
  platformName: string;
};

function reducer(
  state: SimplifiedSubscriptionProductsWithPlatform[],
  action: Action,
) {
  const { index, subscriptions, platformName } = action;
  const newState = [...state];
  newState.splice(
    index,
    1,
    subscriptions.map((subscription) => ({
      ...subscription,
      platformName,
    })),
  );
  return newState;
}

export default function useEnabledSubscriptions(platformIds: string[] = []) {
  const { appId, appData } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);
  // appData should be defined within ContextProviders component so ?? case shouldn't happen
  const appPlatforms = appData?.platforms;

  // store state, rather than accessing query.data directly, because variable-length queries array makes caching via useMemo infeasible
  const [subscriptionsPerPlatform, dispatchSubscriptions] = useReducer(
    reducer,
    Array(appPlatforms?.length ?? 0).fill([]),
  );

  const subscriptionsQueryFn = useAuthRequest<
    SubscriptionProductsQueryVariables,
    SubscriptionProductsQuery
  >(subscriptionProductsRequest);
  const subscriptionsQueries = useQueries({
    queries:
      appPlatforms?.map<QueryType>(({ id }, index) => {
        return {
          queryKey: ['subscriptionProducts', appId, id, environment],
          queryFn: () =>
            subscriptionsQueryFn({
              appId,
              platformId: id,
              environment,
            }),
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          onSuccess: (data) =>
            dispatchSubscriptions({
              index,
              subscriptions: data.app.config.subscriptionProducts,
              platformName: data.app.config.name,
            }),
        };
      }) ?? [],
  });

  const isLoading = subscriptionsQueries.some(
    (query) => query.fetchStatus !== 'idle',
  );

  const indicesOfSelectedPlatforms = useMemo(
    () =>
      platformIds.map(
        (selectedId) =>
          appPlatforms?.findIndex(({ id }) => id === selectedId) ?? -1,
      ),
    [appPlatforms, platformIds],
  );

  const enabledSubscriptionsForSelectedPlatforms = useMemo(
    () =>
      subscriptionsPerPlatform
        .filter((_, index) => indicesOfSelectedPlatforms.includes(index))
        .flat(1)
        .filter(({ isInUse }) => isInUse),
    [indicesOfSelectedPlatforms, subscriptionsPerPlatform],
  );

  subscriptionsQueries.forEach((query) => {
    if (query.isError) throw parsedRequestError(query.error);
  });

  if (isLoading) {
    return { isLoading: true };
  }

  return {
    isLoading: false,
    enabledSubscriptions: enabledSubscriptionsForSelectedPlatforms,
  };
}
