import React, { ReactNode, useState } from 'react';
import {
  Text,
  CheckboxGroup as ChakraCheckboxGroup,
  VStack,
  HStack,
  Checkbox as ChakraCheckbox,
  FormLabel,
  FormControl,
  Box,
} from '@chakra-ui/react';
import Tooltip from '../Tooltip';
import { spacing } from '../../../themes/constants';

type CheckboxGroupProps<T> = {
  label: string;
  tooltipText?: string;
  isDisabled?: boolean;
  values?: T[];
  setValues?: (nextValues: T[]) => void;
  defaultValues?: T[];
  children: ReactNode;
  withContainer?: boolean;
};

/**
 * Pass in options as children using our `Checkbox`:
 * ```
 * <CheckboxGroup<CustomType> values={["value2"]}>
 *   <Checkbox label="Option 1" value="value1" />
 *   <Checkbox label="Option 2" value="value2" />
 * </CheckboxGroup />
 * ```
 * Use `isInvalid` on individual `Checkbox`es.
 */
export default function CheckboxGroup<T extends string>({
  label,
  tooltipText,
  isDisabled = false,
  values: externalValues,
  setValues: externalSetValues,
  defaultValues = [],
  children: options,
  withContainer = false,
}: CheckboxGroupProps<T>) {
  const [values, setValues] = useState(defaultValues);

  function handleChange(nextValues: T[]) {
    if (externalSetValues) externalSetValues(nextValues);
    setValues(nextValues);
  }

  // note: the styling here is essentially identical to that in RadioGroup
  return (
    <FormControl as="fieldset">
      {/* `legend` must be first child of `fieldset` - so can't be in stack */}
      <FormLabel as="legend" marginBottom="10px">
        <HStack spacing={spacing[10]}>
          <Text textStyle="subtitle3">{label}</Text>
          {tooltipText && <Tooltip tipText={tooltipText} />}
        </HStack>
      </FormLabel>
      <ChakraCheckboxGroup
        isDisabled={isDisabled}
        value={externalValues ?? values}
        onChange={handleChange}
        size="lg"
      >
        {withContainer ? (
          options
        ) : (
          <VStack spacing={spacing[15]} align="left">
            {options}
          </VStack>
        )}
      </ChakraCheckboxGroup>
    </FormControl>
  );
}

type CheckboxProps = {
  label?: string;
  value: string;
  disabled?: boolean;
  isInvalid?: boolean;
  children?: ReactNode;
};

/** Pass text as `label`; any icons etc as children. */
export function Checkbox({
  label,
  value,
  isInvalid = false,
  children,
}: CheckboxProps) {
  return (
    <ChakraCheckbox
      value={value}
      isInvalid={isInvalid}
      spacing="10px"
      height="22px"
      width="fit-content"
      // to prevent "Duplicate form field id in the same form" issue
      id={value}
    >
      <Box gap="15px" display="flex" flexDirection="row" alignItems="center">
        <Text textStyle="bodyCopy" color={'neutrals.navigationOutline'}>
          {label}
        </Text>
        {children}
      </Box>
    </ChakraCheckbox>
  );
}
