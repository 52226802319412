import React, {
  Fragment,
  useContext,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { displayEnum, displayFeature, scrollToTop } from '../../../utils';
import { platformUrlAsName } from '../../../utils/platforms';
import { validateSemanticVersion } from '../../../utils/validation';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import Select from '../../common/inputs/Select';
import {
  AppContext,
  EnvironmentContext,
  PlatformsContext,
  UserContext,
} from '../../../contexts';
import Form, {
  FormButtons,
  FormDivider,
  FormError,
  FormGrid,
} from '../../common/Form';
import { temporaryLocales } from '../../../utils';
import { configRequest, updateConfigRequest } from '../../../support/platforms';
import CheckboxGroup, { Checkbox } from '../../common/inputs/CheckboxGroup';
import { ActionType, RequestError } from '../../../types';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import {
  CertificationStatus,
  ConfigQuery,
  ConfigQueryVariables,
  Feature,
  Role,
  SubscriptionProduct,
  UpdateConfigMutation,
  UpdateConfigMutationVariables,
  UpdateFlowConfigInput,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

// reducer //

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; state: InputState }
  | {
      type: ActionType.SET_FEATURES;
      key: 'features';
      value: Feature[];
    }
  | {
      type: ActionType.SET_STRING;
      key: 'name' | 'minSDKVersion' | 'flowVersion' | 'defaultLocale';
      value: string;
    }
  | {
      type: ActionType.SET_CERTIFICATION;
      key: 'certificationStatus';
      value?: CertificationStatus;
    }
  | {
      type: ActionType.SET_STRINGS;
      key: 'locales' | 'subscriptionProductIds';
      value: string[];
    };

type InputState = {
  name: string;
  minSDKVersion?: string;
  flowVersion?: string;
  features: Feature[];
  certificationStatus?: CertificationStatus;
  // TODO v1.1: locales
  locales: string[];
  defaultLocale: string;
  subscriptionProductIds: string[];
};

const initialInputState: InputState = {
  name: '',
  features: [],
  locales: [],
  defaultLocale: '',
  subscriptionProductIds: [],
  certificationStatus: undefined,
};

function inputDataReducer(state: InputState, action: Action) {
  const updatedState = { ...state };
  switch (action.type) {
    case ActionType.RESET:
      return initialInputState;

    case ActionType.SET_ALL:
      return action.state;

    case ActionType.SET_CERTIFICATION:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_FEATURES:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;

    // TODO v1.1: break out `locales`, clear default locale if unchecked
    case ActionType.SET_STRINGS:
      updatedState[action.key] = action.value;
      return updatedState;

    default:
      throw new Error('Unknown config input state action');
  }
}

// platform form (edit only)

export default function PlatformConfigForm() {
  const pageRef = useRef<HTMLDivElement>(null);
  const { loggedInUser } = useContext(UserContext);
  const { appId, defaultLocale: appDefaultLocale } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);
  const { getPlatformByName } = useContext(PlatformsContext);

  const navigate = useNavigate();
  const params = useParams();
  const platformString = params.platformString;
  const platformName = platformUrlAsName(platformString);
  const platformId = getPlatformByName(platformName)?.id ?? '';

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // fetch

  function populateInputData(data: ConfigQuery) {
    const {
      name,
      minSDKVersion,
      flowVersion,
      features,
      subscriptionProducts,
      certificationStatus,
    } = data.app.config;

    /* Our user roles need separate input states because they need different fields. To be specific
     * App Admin must not have `minSDKVersion` and `flowVersion` in their input state because they
     * can't update those fields. If they try this will result in 403 error
     */
    const inputState =
      loggedInUser.role === Role.SystemAdmin
        ? {
            name,
            minSDKVersion,
            flowVersion,
            features,
            certificationStatus,
            // todo v1.1: locales
            locales: temporaryLocales,
            defaultLocale: appDefaultLocale.name,
            subscriptionProductIds: inUseIds(subscriptionProducts),
          }
        : {
            name,
            features,
            certificationStatus,
            // todo v1.1: locales
            locales: temporaryLocales,
            defaultLocale: appDefaultLocale.name,
            subscriptionProductIds: inUseIds(subscriptionProducts),
          };
    dispatchInputState({
      type: ActionType.SET_ALL,
      state: inputState,
    });
  }

  function inUseIds(
    subscriptionProducts: Pick<SubscriptionProduct, 'id' | 'isInUse'>[],
  ) {
    return subscriptionProducts
      .filter(({ isInUse }) => isInUse)
      .map(({ id }) => id);
  }

  const configQueryFn = useAuthRequest<ConfigQueryVariables, ConfigQuery>(
    configRequest,
  );
  const configQuery = useQuery<ConfigQuery, RequestError>({
    queryKey: ['form', 'config', appId, platformId, environment],
    queryFn: () => configQueryFn({ appId, platformId, environment }),
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });
  const configData = configQuery.data?.app.config;
  const configSubscriptions = configData?.subscriptionProducts ?? [];

  function shouldShowFeatureCheckbox(feature: Feature) {
    switch (feature) {
      case Feature.Svod:
        return configSubscriptions.length > 0;
      default:
        return true;
    }
  }

  // update

  const updateMutationFn = useAuthRequest<
    UpdateConfigMutationVariables,
    UpdateConfigMutation
  >(updateConfigRequest);
  const updateMutation = useMutation<
    UpdateConfigMutation,
    RequestError,
    UpdateConfigMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // input

  const [inputState, dispatchInputState] = useReducer(
    inputDataReducer,
    initialInputState,
  );

  const {
    name,
    minSDKVersion,
    flowVersion,
    features,
    locales,
    defaultLocale,
    subscriptionProductIds,
    certificationStatus,
  } = inputState;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    return hasErrorForKey(updateMutation.error, key);
  }

  // frontend validation

  const isNameValid = !!name;
  const isSdkVersionValid =
    (!!minSDKVersion && validateSemanticVersion(minSDKVersion)) ||
    loggedInUser.role === Role.AppAdmin;
  const isFlowVersionValid =
    (!!flowVersion && validateSemanticVersion(flowVersion)) ||
    loggedInUser.role === Role.AppAdmin;
  const isLocalesValid = locales.length > 0;
  const isDefaultLocaleValid =
    !!defaultLocale && locales.includes(defaultLocale);
  const isSubscriptionsValid =
    !features.includes(Feature.Svod) || subscriptionProductIds.length > 0;
  const isInputValid =
    isNameValid &&
    isSdkVersionValid &&
    isFlowVersionValid &&
    isLocalesValid &&
    isDefaultLocaleValid &&
    isSubscriptionsValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      // TODO v1.1: dialog warning if a locale was unchecked
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    // TODO v1.1: locales
    const {
      locales: _locales,
      defaultLocale: _defaultLocale,
      ...rest
    } = inputState;
    updateMutation.mutate({
      id: configData?.id ?? '',
      input: rest as UpdateFlowConfigInput,
    });
  }

  // UI

  const isLoading = configQuery.isLoading || updateMutation.isLoading;

  if (configQuery.isError) throw parsedRequestError(configQuery.error);
  return (
    <Page
      isForm
      withEnvSearchParam
      disallowProduction
      withBack
      title={strings.platforms.editConfiguration}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.platforms.name}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading}
          value={name}
          setSanitizedValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_STRING,
              key: 'name',
              value,
            })
          }
        />

        <FormDivider />

        {loggedInUser.role === Role.SystemAdmin && (
          <Fragment>
            <StandardInput
              label={strings.platforms.minPlatformSdkVersion}
              placeholder={strings.common.versionPlaceholder}
              tooltipText={strings.platforms.minPlatformSdkVersionTip}
              isInvalid={
                isValidationActive &&
                (!isSdkVersionValid || hasBackendError('minSDKVersion'))
              }
              isDisabled={isLoading}
              value={minSDKVersion}
              setSanitizedValue={(value) =>
                dispatchInputState({
                  type: ActionType.SET_STRING,
                  key: 'minSDKVersion',
                  value,
                })
              }
            />
            <StandardInput
              label={strings.platforms.appVersion}
              placeholder={strings.common.versionPlaceholder}
              tooltipText={strings.platforms.currentAppVersion}
              isInvalid={
                isValidationActive &&
                (!isFlowVersionValid || hasBackendError('flowVersion'))
              }
              isDisabled={isLoading}
              value={flowVersion}
              setSanitizedValue={(value) =>
                dispatchInputState({
                  type: ActionType.SET_STRING,
                  key: 'flowVersion',
                  value,
                })
              }
            />
          </Fragment>
        )}

        <Select
          label={strings.platforms.certificationStatus}
          value={certificationStatus}
          onChange={(e) => {
            dispatchInputState({
              type: ActionType.SET_CERTIFICATION,
              key: 'certificationStatus',
              value: e.target.value as CertificationStatus,
            });
          }}
        >
          {Object.values(CertificationStatus).map((key) => (
            <option key={key} value={key}>
              {displayEnum(key)}
            </option>
          ))}
        </Select>

        <FormDivider />

        <CheckboxGroup<Feature>
          label={strings.platforms.features}
          tooltipText={strings.platforms.featuresTip}
          isDisabled={isLoading}
          values={features}
          setValues={(values) =>
            dispatchInputState({
              type: ActionType.SET_FEATURES,
              key: 'features',
              value: values,
            })
          }
        >
          {Object.values(Feature).map(
            (feature) =>
              shouldShowFeatureCheckbox(feature as Feature) && (
                <Checkbox
                  key={feature}
                  label={displayFeature(feature)}
                  value={feature}
                  isInvalid={
                    isValidationActive &&
                    (hasBackendError('features') ||
                      // if SVOD is selected, a subscription must be selected
                      (feature === Feature.Svod && !isSubscriptionsValid))
                  }
                />
              ),
          )}
        </CheckboxGroup>

        <FormDivider />

        <FormGrid>
          <CheckboxGroup
            // TODO v1.1: locales - isDisabled here & map in children
            label={strings.common.locales}
            tooltipText={strings.platforms.localesTip}
            // isDisabled={isLoading}
            isDisabled
            values={locales}
            setValues={(values) =>
              dispatchInputState({
                type: ActionType.SET_STRINGS,
                key: 'locales',
                value: values,
              })
            }
          >
            {temporaryLocales.map((locale) => (
              <Checkbox
                key={locale}
                label={locale}
                value={locale}
                isInvalid={
                  isValidationActive &&
                  (!isLocalesValid || hasBackendError('locales'))
                }
              />
            ))}
          </CheckboxGroup>
          <Select
            label={strings.platforms.defaultLocale}
            isInvalid={
              isValidationActive &&
              (!isDefaultLocaleValid || hasBackendError('defaultLocale'))
            }
            // TODO v1.1: locales
            // isDisabled={isLoading}
            isDisabled
            value={defaultLocale}
            onChange={(e) =>
              dispatchInputState({
                type: ActionType.SET_STRING,
                key: 'defaultLocale',
                value: e.target.value,
              })
            }
          >
            {locales.map((locale) => (
              <option key={locale} value={locale}>
                {locale}
              </option>
            ))}
          </Select>
        </FormGrid>

        {configSubscriptions.length > 0 && (
          <Fragment>
            <FormDivider />

            <CheckboxGroup
              label={strings.common.subscriptions}
              tooltipText={strings.platforms.subscriptionsTip}
              isDisabled={isLoading}
              values={subscriptionProductIds}
              setValues={(values) =>
                dispatchInputState({
                  type: ActionType.SET_STRINGS,
                  key: 'subscriptionProductIds',
                  value: values,
                })
              }
            >
              {configSubscriptions.map(({ id, name }) => (
                <Checkbox
                  key={id}
                  label={name}
                  value={id}
                  isInvalid={
                    isValidationActive &&
                    (!isSubscriptionsValid ||
                      hasBackendError('subscriptionProductIds'))
                  }
                />
              ))}
            </CheckboxGroup>
          </Fragment>
        )}

        <FormButtons
          positiveLabel={strings.common.saveChanges}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
