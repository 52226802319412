import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { EnvironmentContext, PlatformsContext } from '../contexts';
import { configIdRequest } from '../support/platforms';
import { RequestError } from '../types';
import { platformUrlAsName } from '../utils/platforms';
import { parsedRequestError } from '../utils/errors';
import useAuthRequest from './useAuthRequest';
import { ConfigIdQuery, ConfigIdQueryVariables } from '../gql/gqlRequests';

// todo: consider passing the return values via context in the router? if this ends up getting used a lot
/**
 * When navigating within a platform for an app, this will retrieve the
 * corresponding configId.
 */
export default function useConfigId() {
  const params = useParams();
  const appId = params.appId ?? '';
  const platformString = params.platformString ?? '';
  const { getPlatformByName } = useContext(PlatformsContext);
  const platformId =
    getPlatformByName(platformUrlAsName(platformString))?.id ?? '';
  const { environment } = useContext(EnvironmentContext);

  const configIdQueryFn = useAuthRequest<ConfigIdQueryVariables, ConfigIdQuery>(
    configIdRequest,
  );
  const configIdQuery = useQuery<ConfigIdQuery, RequestError>({
    queryKey: ['configId', appId, platformId, environment],
    queryFn: () => configIdQueryFn({ appId, platformId, environment }),
  });

  if (configIdQuery.isError) throw parsedRequestError(configIdQuery.error);

  return {
    isLoading: configIdQuery.isLoading,
    configId: configIdQuery.data?.app.config.id ?? '',
    platformId,
    platformString,
  };
}
