import { modalAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const closeButtonSize = 24;
const closeButtonPadding = 24;
const closeButtonPosition = 35 - closeButtonPadding / 2;

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys);

const dialog = definePartsStyle({
  closeButton: {
    padding: `${closeButtonPadding}px`,
    fontSize: `${closeButtonSize}px`,
    top: `${closeButtonPosition}px`,
    right: `${closeButtonPosition}px`,
    color: 'neutrals.navigationOutline',
  },
});

const flowDialog = definePartsStyle({
  dialogContainer: {
    backgroundColor: 'neutrals.backgroundDarkGrey',
  },
  dialog: {
    height: '730px',
    width: '420px',
    borderRadius: '10px',
    backgroundColor: 'neutrals.staticWhite',
    paddingX: '40px',
    paddingY: '60px',
    position: 'relative',
  },
});

export const modalTheme = defineMultiStyleConfig({
  variants: { dialog, flowDialog },
});
