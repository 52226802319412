import { createGqlAuthRequest } from '.';
import {
  ImageLibraryDocument,
  ImageLibraryQuery,
  ImageLibraryQueryVariables,
} from '../gql/gqlRequests';
import { UploadImageVariables, UploadImageResponse } from '../types/images';
import { imageTypeToURL } from '../utils';
import { RequestErrorCode } from '../types';
import { RequestError } from '../utils/errors';

export const imageLibraryRequest = createGqlAuthRequest<
  ImageLibraryQueryVariables,
  ImageLibraryQuery
>(ImageLibraryDocument);

// doesn't use graphql because graphql isn't good with image upload
export const uploadImageRequest = async (
  accessToken: string,
  variables: UploadImageVariables,
): Promise<UploadImageResponse> => {
  const response = await fetch(
    `/image-upload/${variables.acconutId}/${imageTypeToURL(
      variables.imageType,
    )}`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: variables.formData,
    },
  );

  const { image, url, message }: UploadImageResponse & { message?: string } =
    await response.json();
  if (response.ok) {
    if (image !== undefined && url !== undefined) {
      return { image, url };
    } else {
      throw new RequestError('No image found', RequestErrorCode.NOT_FOUND);
    }
  } else {
    throw new RequestError(`${message}`, response.status);
  }
};
