import React, { useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import { Image, Spacer } from '@chakra-ui/react';
import Link from '../../common/Link';
import editIcon from '../../../assets/edit.svg';
import LabelAndValue from '../../common/LabelAndValue';
import { displayAppRefresh, displayScreenType } from '../../../utils';
import { EnvironmentContext } from '../../../contexts';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import { screenRequest } from '../../../support/screens';
import ScreenContextDetailFactory from './ScreenContextDetailFactory';
import ScreenFeedTable from './ScreenFeedTable';
import {
  Environment,
  ScreenQuery,
  ScreenQueryVariables,
} from '../../../gql/gqlRequests';
import {
  createKeyValuePairs,
  hasLocalizations,
  hasScreenFeeds,
} from '../../../utils/screens';
import { strings } from '../../../utils/strings';
import { LocaleCode } from '../../../types/locale';
import useEnabledSubscriptions from '../../../hooks/useEnabledSubscriptions';
import { testIds } from '../../../utils/testIds';
import { Route } from '../../../types/routes';
import ScreenLocalizationDetail from './ScreenLocalizationDetail';

const { common, screens } = strings;

export default function ScreenDetail() {
  const params = useParams();
  const screenId = params.screenId ?? '';
  const { environment } = useContext(EnvironmentContext);

  // screen (for selected environment)

  const screenQueryFn = useAuthRequest<ScreenQueryVariables, ScreenQuery>(
    screenRequest,
  );
  const screenQuery = useQuery<ScreenQuery, RequestError>({
    queryKey: ['screen', screenId, environment],
    queryFn: () =>
      // v1.1: won't specify locale, will get all at once
      screenQueryFn({ id: screenId, environment, locale: LocaleCode.EN }),
  });

  const screenData = screenQuery.data?.screen;

  // localization

  const platformIds = useMemo(
    () => (screenData ? screenData.platforms.map(({ id }) => id) : []),
    [screenData],
  );

  const { isLoading: areSubscriptionsLoading, enabledSubscriptions } =
    useEnabledSubscriptions(platformIds);

  const keyValuePairs = useMemo(
    () =>
      screenData
        ? createKeyValuePairs(
            screenData.type,
            enabledSubscriptions,
            screenData.localization,
            '',
          )
        : {},
    [enabledSubscriptions, screenData],
  );

  // UI

  if (screenQuery.isError) throw parsedRequestError(screenQuery.error);

  const isLoading = screenQuery.isLoading || areSubscriptionsLoading;

  return (
    <Page
      isLoading={isLoading}
      withEnvironmentBar
      withBreadcrumbs
      title={screens.screenDetail}
    >
      {screenData && (
        <Details>
          <DetailsSection>
            <SectionTitle title={screenData.name}>
              <Spacer />
              {environment !== Environment.Production && (
                <Link label={screens.editScreen} to={Route.Edit}>
                  <Image src={editIcon} />
                </Link>
              )}
            </SectionTitle>

            <SectionBody>
              <SectionGrid colCount={1}>
                <LabelAndValue
                  label={common.type}
                  value={displayScreenType(screenData.type)}
                  testId={testIds.screen_type}
                />
              </SectionGrid>

              <SectionGrid>
                <LabelAndValue
                  label={screens.platforms}
                  value={
                    screenData.platforms.map(({ name }) => name).join(', ') ||
                    common.emptyPlaceholder
                  }
                />
                <LabelAndValue
                  label={screens.appRefresh}
                  value={displayAppRefresh(screenData.allowAppRefresh)}
                />
              </SectionGrid>

              {hasScreenFeeds(screenData.type) && (
                <ScreenFeedTable screenFeeds={screenData.screenFeeds} />
              )}

              <ScreenContextDetailFactory screenData={screenData} />

              {hasLocalizations(screenData.type) && (
                <ScreenLocalizationDetail
                  screenType={screenData.type}
                  enabledSubscriptions={enabledSubscriptions ?? []}
                  keyValuePairs={keyValuePairs}
                />
              )}
            </SectionBody>
          </DetailsSection>
        </Details>
      )}
    </Page>
  );
}
