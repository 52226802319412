import { Image } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { resetPasswordRequest } from '../../../support/authentication';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import envelopeIcon from '../../../assets/envelope.svg';
import { strings } from '../../../utils/strings';
import { RequestError } from '../../../types';
import {
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables,
} from '../../../gql/gqlRequests';

type ChangePasswordDialogProps = {
  isOpen: boolean;
  onClose(): void;
  email: string;
};

const MODAL_SUCCESS_HEIGHT = '326px';

export function ChangePasswordDialog({
  isOpen,
  onClose,
  email,
}: ChangePasswordDialogProps) {
  const resetPasswordMutation = useMutation<
    ResetPasswordRequestMutation,
    RequestError,
    ResetPasswordRequestMutationVariables
  >(resetPasswordRequest);
  const resetLinkSentSuccess = !!resetPasswordMutation.data?.resetPassword;

  const handleOnChangePassword = useCallback(() => {
    resetPasswordMutation.mutate({ email });
  }, [resetPasswordMutation, email]);

  const handleOnClose = () => {
    if (!resetPasswordMutation.isLoading) {
      resetPasswordMutation.reset();
      onClose();
    }
  };

  const getDialogTitle = () => {
    if (resetPasswordMutation.isError) {
      return strings.errors.error;
    } else if (resetLinkSentSuccess) {
      return strings.authentication.checkYourInbox;
    } else {
      return strings.profile.changePassword;
    }
  };

  const renderSendInstructions = () => {
    return (
      <>
        <DialogBody>{strings.profile.changePasswordMessage}</DialogBody>
        <DialogButtons justifyContent="flex-start">
          <PrimaryButton
            label={strings.authentication.sendInstructions}
            onClick={handleOnChangePassword}
            isDisabled={resetPasswordMutation.isLoading}
          />
        </DialogButtons>
      </>
    );
  };

  const renderInstructionsSent = () => {
    return (
      <DialogBody>
        <Image src={envelopeIcon} width="80px" height="80px" />
      </DialogBody>
    );
  };

  const renderErrorMessage = () => {
    return <DialogBody>{strings.errors.generic}</DialogBody>;
  };

  const renderDialogBody = () => {
    if (resetPasswordMutation.isError) {
      return renderErrorMessage();
    } else if (resetLinkSentSuccess) {
      return renderInstructionsSent();
    } else {
      return renderSendInstructions();
    }
  };

  return (
    <Dialog
      title={getDialogTitle()}
      subtitle={
        resetLinkSentSuccess
          ? strings.authentication.passwordResetLinkSent
          : undefined
      }
      onClose={handleOnClose}
      isOpen={isOpen}
      height={resetLinkSentSuccess ? MODAL_SUCCESS_HEIGHT : undefined}
      modalContentProps={
        resetLinkSentSuccess
          ? {
              textAlign: 'center',
              alignItems: 'center',
            }
          : undefined
      }
    >
      {renderDialogBody()}
    </Dialog>
  );
}
