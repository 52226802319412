import React, { useState } from 'react';
import {
  Text,
  VStack,
  HStack,
  NumberInput as ChakraNumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import { standardInputHeight } from '../../../themes/constants';
import Tooltip from '../Tooltip';

type NumberInputProps = {
  label: string;
  units?: string;
  labelTextStyle?: string;
  unitsTextStyle?: string;
  isDisabled?: boolean;
  value?: number;
  onChange?: (number: number) => void;
  defaultValue?: number;
  minValue?: number;
  maxValue?: number;
  precision?: number;
  marginTop?: string;
  marginBottom?: string;
  isInvalid?: boolean;
  tooltipText?: string;
};

export default function NumberInput({
  label,
  units,
  isDisabled = false,
  labelTextStyle = 'subtitle3',
  unitsTextStyle = 'bodyCopy',
  isInvalid = false,
  value: externalValue,
  onChange,
  defaultValue,
  minValue = 0,
  maxValue = Number.MAX_SAFE_INTEGER,
  precision = 0,
  marginTop,
  marginBottom,
  tooltipText,
}: NumberInputProps) {
  const [value, setValue] = useState(externalValue);

  function handleChange(_: string, numberValue: number) {
    if (onChange) onChange(numberValue);
    setValue(Number.isNaN(numberValue) ? 0 : numberValue);
  }

  React.useEffect(() => {
    setValue(externalValue);
  }, [externalValue]);

  return (
    <FormControl>
      <VStack
        spacing="5px"
        align="left"
        marginTop={marginTop}
        marginBottom={marginBottom}
      >
        <HStack>
          <FormLabel margin="0">
            <Text textStyle={labelTextStyle}>{label}</Text>
          </FormLabel>
          {tooltipText && <Tooltip tipText={tooltipText} />}
        </HStack>
        <HStack spacing="20px">
          <ChakraNumberInput
            size="md"
            maxW="85px"
            onChange={handleChange}
            value={value}
            isDisabled={isDisabled}
            defaultValue={defaultValue}
            min={minValue}
            max={maxValue}
            isInvalid={isInvalid}
            precision={precision}
            backgroundColor="neutrals.staticWhite"
          >
            <NumberInputField
              textAlign="center"
              height={standardInputHeight}
              paddingLeft="0.5rem"
              data-testid="number-inputfield"
            />
            <NumberInputStepper>
              <NumberIncrementStepper data-testid="number-increment-stepper" />
              <NumberDecrementStepper data-testid="number-decrement-stepper" />
            </NumberInputStepper>
          </ChakraNumberInput>

          <Text textStyle={unitsTextStyle}>{units}</Text>
        </HStack>
      </VStack>
    </FormControl>
  );
}
