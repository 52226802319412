import React, { useContext } from 'react';
import {
  Avatar,
  Box,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  VStack,
} from '@chakra-ui/react';
import { UserContext } from '../../contexts';
import settingsIcon from '../../assets/dots-white.svg';
import { displayEnum } from '../../utils';
import MenuItem from '../common/MenuItem';
import { useNavigate } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import { strings } from '../../utils/strings';
import { routes } from '../../types/routes';

// we aren't using avatars; always show initials
const userIcon = '';

export default function NavigationProfile() {
  const { loggedInUser } = useContext(UserContext);
  const userName = `${loggedInUser.firstName} ${loggedInUser.lastName}`;
  const navigate = useNavigate();
  const logout = useLogout();

  return (
    <Box layerStyle="navigationProfile">
      <HStack spacing="20px">
        <Avatar name={userName} src={userIcon} variant="navigation" />
        <VStack spacing="0px" align="left">
          <Text
            textStyle="bodyCopy"
            noOfLines={1}
            wordBreak="break-all"
            lineHeight="22px"
          >
            {userName}
          </Text>
          <Text
            fontSize="12px"
            fontWeight="400"
            textColor="neutrals.brandGrey.300"
            lineHeight="18px"
          >
            {displayEnum(loggedInUser.role)}
          </Text>
        </VStack>
      </HStack>
      <Menu autoSelect={false}>
        <MenuButton
          as={IconButton}
          variant="iconButton"
          aria-label={strings.users.userOptions}
          icon={<Image src={settingsIcon} alt={strings.users.userOptions} />}
          size="sm"
        />
        <MenuList>
          <MenuItem onClick={() => navigate(routes.profile)}>
            {strings.common.viewProfile}
          </MenuItem>
          <MenuItem onClick={logout}>{strings.common.logOut}</MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
}
