import React from 'react';
import { Box, HStack, Image, Spacer, Text } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { NavEntry } from '../../types';
import useActiveNavLink from '../../hooks/useActiveNavLink';

type NavigationEntryProps = {
  entry: NavEntry;
  navTo: string;
  src?: string;
};

export default function NavigationEntry({
  entry,
  navTo,
  src,
}: NavigationEntryProps) {
  /*
   * Note: `NavLink` can detect whether it's active, so the useActiveNavLink
   * hook isn't necessary here, see https://reactrouter.com/en/main/components/nav-link
   * However, it would require refactoring the HStack to be an actual NavLink
   * without using 'as' as far as I can tell, and the App entry would be a special
   * case which might be difficult to get working properly.
   */
  const isActive = useActiveNavLink(entry);

  return (
    <Box width="100%">
      <HStack
        as={NavLink}
        to={navTo}
        layerStyle={isActive ? 'navigationEntryActive' : 'navigationEntryBase'}
        spacing="5px"
        _hover={{
          backgroundColor: 'neutrals.navigationOutline',
          cursor: 'pointer',
        }}
        data-testid={isActive ? 'active-nav-entry' : ''}
      >
        <Image src={src} alt={entry.toString()} marginX="25px" width="15px" />
        <Text textStyle="subtitle3" casing="capitalize">
          {entry}
        </Text>
        <Spacer />
      </HStack>
    </Box>
  );
}
