import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Role } from '../../gql/gqlRequests';
import { UserContext } from '../../contexts';
import { routes } from '../../types/routes';

/**
 * System admins will be redirected to `/accounts`, where they can select
 * an account then an app via the UI.
 *
 * App admins will be redirected to that app's details page, since that app
 * id is provided at the same time as the role.
 */
export default function Landing() {
  const { loggedInUser } = useContext(UserContext);

  switch (loggedInUser.role) {
    case Role.SystemAdmin:
      return <Navigate to={routes.accounts} replace />;

    case Role.AppAdmin:
    case Role.AppUser:
      return (
        <Navigate
          to={routes.appDetail({ appId: loggedInUser.appId })}
          replace
        />
      );
  }
}
