import React from 'react';
import Tooltip from '../Tooltip';
import { Text } from '@chakra-ui/react';

type OverflowCellProps = {
  text: string;
  noOfLines?: number;
};

export default function OverflowCell({
  text,
  noOfLines = 1,
}: OverflowCellProps) {
  // height prop ensures all rows have consistent height
  const height = noOfLines > 1 ? '40px' : undefined;
  return (
    <Tooltip tipText={text}>
      <Text whiteSpace="normal" noOfLines={noOfLines} height={height}>
        {text}
      </Text>
    </Tooltip>
  );
}
