import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../contexts';
import { ScreenType } from '../../../gql/gqlRequests';
import LocalesTabList from '../../common/LocalesTabList';
import {
  getScreenLocalizationIndividualInputsData,
  getScreenLocalizationSubscriptionsInputsData,
  parseAndAddValuesToLocalizations,
} from '../../../utils/screens';
import { SimplifiedSubscriptionProductsWithPlatform } from '../../../hooks/useEnabledSubscriptions';
import ScreenLocalizationPair from './ScreenLocalizationPair';

type ScreenLocalizationDetailProps = {
  screenType: ScreenType;
  enabledSubscriptions: SimplifiedSubscriptionProductsWithPlatform;
  keyValuePairs: Record<string, string>;
  isLoading?: boolean;
  isValidationActive?: boolean;
};

export default function ScreenLocalizationDetail({
  screenType,
  enabledSubscriptions,
  keyValuePairs,
}: ScreenLocalizationDetailProps) {
  const { locales, defaultLocale } = useContext(AppContext);

  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const parsedIndividualInputsData = useMemo(
    () =>
      parseAndAddValuesToLocalizations(
        getScreenLocalizationIndividualInputsData(screenType),
        keyValuePairs,
      ),
    [screenType, keyValuePairs],
  );

  const parsedSubscriptionsInputsData = useMemo(
    () =>
      enabledSubscriptions?.flatMap((subscription) =>
        parseAndAddValuesToLocalizations(
          getScreenLocalizationSubscriptionsInputsData(screenType),
          keyValuePairs,
          subscription.id,
          `${subscription.name} (${subscription.platformName})`,
        ),
      ) ?? [],
    [screenType, keyValuePairs, enabledSubscriptions],
  );

  return (
    <LocalesTabList
      listType="detail"
      entries={locales}
      selectedLocale={selectedLocale}
      setSelectedLocale={setSelectedLocale}
    >
      {[...parsedIndividualInputsData, ...parsedSubscriptionsInputsData].map(
        (screenLocalization) => (
          <ScreenLocalizationPair
            key={`${selectedLocale.code}-${screenLocalization.keys[0]}`}
            {...screenLocalization}
          />
        ),
      )}
    </LocalesTabList>
  );
}
