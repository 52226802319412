import React, { ReactNode, useMemo } from 'react';
import { Box, Modal, ModalContent, Text } from '@chakra-ui/react';
import NotificationBar from '../NotificationBar';
import { strings } from '../../../utils/strings';
import { doNothing } from '../../../utils';

type FlowDialogProps = {
  errorMessage?: string;
  title?: string;
  instructions?: string;
  spacingBetweenFlowAndTitle?: string;
  children?: ReactNode;
};

export default function FlowDialog({
  errorMessage,
  title,
  instructions,
  spacingBetweenFlowAndTitle = '100px',
  children,
}: FlowDialogProps) {
  const minErrorHeight = 42;
  const upperSpacing = 20;
  const lowerSpacing = useMemo(
    () => parseInt(spacingBetweenFlowAndTitle) - minErrorHeight - upperSpacing,
    [spacingBetweenFlowAndTitle],
  );

  return (
    <Modal
      variant="flowDialog"
      isOpen
      onClose={doNothing}
      isCentered
      // Note: motionPresent="none" turns off the open/close animation.
      motionPreset="none"
    >
      <ModalContent marginTop="initial" marginBottom="initial">
        <Text as="h1" textStyle="flow">
          {strings.common.flow}
        </Text>
        <Box height={`${upperSpacing}px`} />
        {/* Using minErrorHeight rather than maxErrorHeight here, since the Register page has limited space,
        and does not contain errors with multiple lines (ie. different maxErrorHeights). 
        As a result, ErrorBar will overflow into the lowerSpacing and appear as if though lowerSpacing was reduced. */}
        <Box width="100%" height={`${minErrorHeight}px`}>
          {/* space should exist here even if no error */}
          {errorMessage && <NotificationBar message={errorMessage} />}
        </Box>
        <Box height={`${lowerSpacing}px`} />
        {title && (
          <Text
            as="h2"
            textStyle="h3"
            lineHeight="32px"
            color="neutrals.backgroundOverlay"
          >
            {title}
          </Text>
        )}
        {instructions && (
          <Text
            textStyle="bodyCopy"
            lineHeight="22px"
            textColor="neutrals.brandGrey.500"
            marginTop="10px"
          >
            {instructions}
          </Text>
        )}
        {children}
      </ModalContent>
    </Modal>
  );
}
