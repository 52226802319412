import React, { Fragment, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Flex, HStack, Image, Spacer } from '@chakra-ui/react';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { AppContext, UserContext } from '../../../contexts';
import Page from '../../common/Page';
import { useNavigate, useParams } from 'react-router-dom';
import {
  displayTFA,
  displayEnum,
  displayRegistrationStatus,
} from '../../../utils';
import Details, {
  DetailsSection,
  SectionBody,
  SectionEmptyCell,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import LabelAndValue from '../../common/LabelAndValue';
import editIcon from '../../../assets/edit.svg';
import Link from '../../common/Link';
import { RequestError } from '../../../types';
import { parsedRequestError } from '../../../utils/errors';
import { Role, UserQuery, UserQueryVariables } from '../../../gql/gqlRequests';
import { userRequest } from '../../../support/users';

import { testIds } from '../../../utils/testIds';
import { strings } from '../../../utils/strings';
import { Route, routes } from '../../../types/routes';
import TextButton from '../../common/buttons/TextButton';

export default function UserDetail() {
  const params = useParams();
  const username = params.username ?? '';
  const { loggedInUser } = useContext(UserContext);
  const { setApp } = useContext(AppContext);
  const navigate = useNavigate();

  // user query

  const userQueryFn = useAuthRequest<UserQueryVariables, UserQuery>(
    userRequest,
  );
  const userQuery = useQuery<UserQuery, RequestError>({
    queryKey: ['user', username],
    queryFn: () => userQueryFn({ username }),
  });

  const userData = userQuery.data?.user;

  if (userQuery.isError) throw parsedRequestError(userQuery.error);

  return (
    <Page
      isLoading={userQuery.isLoading}
      title={strings.users.detail}
      withBreadcrumbs
    >
      {userData && (
        <Details>
          <DetailsSection>
            <SectionTitle title={`${userData.firstName} ${userData.lastName}`}>
              {userData.role === Role.AppAdmin && (
                <Fragment>
                  <Spacer />
                  <Flex paddingBottom="20px">
                    <Link label={strings.users.edit} to={Route.Edit}>
                      <Image src={editIcon} />
                    </Link>
                  </Flex>
                </Fragment>
              )}
            </SectionTitle>

            <SectionBody>
              <SectionGrid>
                <LabelAndValue
                  label={strings.profile.role}
                  value={displayEnum(userData.role)}
                  testId={testIds.role}
                />
                <SectionEmptyCell />

                <LabelAndValue
                  label={strings.common.email}
                  value={userData.email ?? '-'}
                  testId={testIds.email}
                />
                <SectionEmptyCell />

                {userData.role === Role.AppAdmin &&
                  loggedInUser.role === Role.SystemAdmin && (
                    <Fragment>
                      <LabelAndValue
                        label={strings.accounts.account}
                        value={userData.apps[0]?.account.name ?? '-'}
                        testId={testIds.account}
                      />
                      <LabelAndValue
                        label={strings.apps.apps}
                        childrenContainerProps={{
                          display: 'block',
                        }}
                        testId={testIds.app}
                        value={userData.apps.length ? undefined : '-'}
                      >
                        <HStack>
                          {userData.apps.map((app) => (
                            <TextButton
                              key={app.id}
                              label={app.name}
                              onClick={() => {
                                setApp(app.id);
                                navigate(routes.appDetail({ appId: app.id }));
                              }}
                            />
                          ))}
                        </HStack>
                      </LabelAndValue>
                    </Fragment>
                  )}

                <LabelAndValue
                  label={strings.users.registrationStatus}
                  value={displayRegistrationStatus(
                    userData.hasCompletedRegistration,
                  )}
                  testId={testIds.registration_status}
                />
                {userData.role === Role.AppAdmin && (
                  <LabelAndValue
                    label={strings.users.twoFactorAuthentication}
                    value={displayTFA(userData.isTwoFactorEnabled)}
                    testId={testIds.two_factor_authentication}
                  />
                )}
              </SectionGrid>
            </SectionBody>
          </DetailsSection>
        </Details>
      )}
    </Page>
  );
}
