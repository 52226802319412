import React from 'react';
import { CircularProgress } from '@chakra-ui/react';
import { testIds } from '../../utils/testIds';

type ProgressProps = {
  size?: string;
  color?: string;
};

export default function Progress({
  size = '70px',
  color = 'primary.primaryBlue.500',
}: ProgressProps) {
  return (
    <CircularProgress
      isIndeterminate
      size={size}
      color={color}
      data-testid={testIds.progress_spinner}
    />
  );
}
