import React, { ChangeEventHandler } from 'react';
import { isValidPasswordCharacter } from '../../../utils/validation';
import Input from './Input';

type StandardInputProps = {
  label: string;
  labelAsPlaceholder?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isValidCharacter?: (char: string) => boolean;
  setSanitizedValue?: (value: string) => void;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  isInvalid?: boolean;
  tooltipText?: string;
  isPassword?: boolean;
  errorMessage?: string;
  rightLabel?: {
    label: string;
    to: string;
    isExternal: boolean;
  };
};

/**
 * The most common input. If you need to set the value (after data loads)
 * then provide `value`, otherwise don't.
 */
export default function StandardInput({
  isPassword,
  isValidCharacter,
  ...rest
}: StandardInputProps) {
  return (
    <Input
      isPassword={isPassword}
      isValidCharacter={
        isValidCharacter ?? (isPassword ? isValidPasswordCharacter : undefined)
      }
      {...rest}
      boxWidth="340px"
    />
  );
}
