import React, { useContext, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import useAuthRequest from '../../../hooks/useAuthRequest';
import StandardTable from '../../common/tables/StandardTable';
import Page from '../../common/Page';
import { displayTFA, displayEnum } from '../../../utils';
import Link from '../../common/Link';
import { UserContext } from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import DeleteUserDialog from './DeleteUserDialog';
import {
  Role,
  User,
  UsersQuery,
  UsersQueryVariables,
} from '../../../gql/gqlRequests';
import { usersRequest } from '../../../support/users';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

// createColumnHelper causes a ts error if any nested types appear in it
type UserRow = Pick<User, 'email' | 'createdAt'> & {
  id: string;
  name: string;
  role: string;
  account?: string;
  app?: string;
  hasCompletedRegistration?: string;
  tfa?: string;
};

// table columns
const columnHelper = createColumnHelper<UserRow>();
const commonColumns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.users.user,
    cell: (info) => <Link label={info.getValue()} to={info.row.original.id} />,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('role', {
    header: strings.profile.role,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('email', {
    header: strings.common.email,
    sortingFn: 'alphanumeric',
  }),
];

export default function Users() {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(UserContext);

  // users query

  const usersQueryFn = useAuthRequest<UsersQueryVariables, UsersQuery>(
    usersRequest,
  );
  const usersQuery = useQuery<UsersQuery, RequestError>({
    queryKey: ['users'],
    queryFn: () => usersQueryFn({}),
  });

  // table data

  const columns = useMemo(() => {
    return loggedInUser.role === Role.SystemAdmin
      ? [
          ...commonColumns,
          columnHelper.accessor('account', {
            header: strings.accounts.account,
            sortingFn: 'alphanumeric',
          }),
          columnHelper.accessor('app', {
            header: strings.apps.apps,
            sortingFn: 'alphanumeric',
          }),
        ]
      : [
          ...commonColumns,
          columnHelper.accessor('hasCompletedRegistration', {
            header: strings.users.isRegistered,
          }),
          columnHelper.accessor('tfa', {
            header: strings.users.tfa,
          }),
        ];
  }, [loggedInUser.role]);

  const usersData = useMemo(
    () =>
      usersQuery.data?.users.map(
        ({
          username,
          firstName,
          lastName,
          email,
          role,
          hasCompletedRegistration,
          isTwoFactorEnabled,
          apps,
          createdAt,
        }) => ({
          id: username,
          email,
          name: `${firstName} ${lastName}`,
          role: displayEnum(role),
          app: apps.length
            ? `${apps
                .slice(0, 2)
                .map((app) => app.name)
                .join(', ')}${apps.length > 2 ? ',...' : ''}`
            : strings.common.emptyPlaceholder,
          account: apps.length
            ? apps[0].account.name
            : strings.common.emptyPlaceholder,
          hasCompletedRegistration: hasCompletedRegistration
            ? strings.common.yes
            : strings.common.no,
          tfa: displayTFA(isTwoFactorEnabled),
          createdAt,
          hideEdit: role === Role.SystemAdmin,
          hideDelete: role === Role.SystemAdmin,
        }),
      ) ?? [],
    [usersQuery.data],
  );

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();

  // UI

  if (usersQuery.isError) throw parsedRequestError(usersQuery.error);

  return (
    <Page title={strings.users.users}>
      <StandardTable
        title={strings.users.allUsers}
        isLoading={usersQuery.isLoading}
        data={usersData}
        columns={columns}
        canSearch
        onEditRow={(username: string) =>
          navigate(routes.editUser({ username }))
        }
        onDeleteRow={
          loggedInUser.role === Role.SystemAdmin ? setIdToDelete : undefined
        }
        onCreate={() => navigate(routes.createUser)}
        createButtonTitle={strings.users.createUser}
        boldColumn="name"
      />
      <DeleteUserDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        usersData={usersQuery.data}
        onSuccess={() => usersQuery.refetch()}
        onClose={() => setIdToDelete(undefined)}
      />
    </Page>
  );
}
