import React, { Fragment, ReactNode, useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../contexts';
import { Role } from '../../gql/gqlRequests';
import { routes } from '../../types/routes';

type SystemAdminOnlyProps = {
  children: ReactNode;
};

export default function SystemAdminOnly({ children }: SystemAdminOnlyProps) {
  const { loggedInUser } = useContext(UserContext);

  if (loggedInUser.role !== Role.SystemAdmin) {
    return <Navigate to={routes.root} replace />;
  }

  return <Fragment>{children}</Fragment>;
}
