import React, { Fragment, ReactNode } from 'react';
import {
  Box,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import NotificationBar from './NotificationBar';
import Divider from './Divider';
import SecondaryButton from './buttons/SecondaryButton';
import PrimaryButton from './buttons/PrimaryButton';
import Link from './Link';
import { strings } from '../../utils/strings';

const spaceAtFormTop = '30px';
const spaceAboveFormError = '25px';
const spaceBelowFormError = '30px';
const spaceBetweenFormElements = '40px';
const spaceBetweenGridTitleAndContent = '20px';
const minHorizontalSpaceBetweenGridElements = '40px';
const spaceBetweenFormButtons = '25px';
const spaceBeforeThemePictures = '70px';
const minSpaceBeforeFormButton = '70px';

type FormProps = {
  hasError?: boolean;
  marginTop?: string;
  children: ReactNode;
  ['data-testid']?: string;
};

/** This should take care of the spacing/margin of all things inside. */
export default function Form({
  hasError,
  marginTop,
  children,
  ['data-testid']: dataTestId,
}: FormProps) {
  return (
    <Box
      gap={spaceBetweenFormElements}
      display="flex"
      flexDirection="column"
      marginTop={marginTop ?? (hasError ? spaceAboveFormError : spaceAtFormTop)}
      data-testid={dataTestId}
      height={`calc(100% - ${
        marginTop ?? (hasError ? spaceAboveFormError : spaceAtFormTop)
      })`}
    >
      {children}
    </Box>
  );
}

type FormErrorProps = {
  message: string;
  success?: boolean;
};

export function FormError({ message, success = false }: FormErrorProps) {
  return message ? (
    <NotificationBar
      message={message}
      // override the stack's spacing and use special spacing
      marginBottom={`calc(${spaceBelowFormError} - ${spaceBetweenFormElements})`}
      layerStyle={success ? 'successBar' : 'errorBar'}
    />
  ) : (
    <></>
  );
}

type FormGridProps = {
  colCount?: number;
  title?: string;
  children: ReactNode;
};

export function FormGrid({ colCount = 2, title, children }: FormGridProps) {
  return (
    <VStack spacing={spaceBetweenGridTitleAndContent} align="left">
      {title && (
        <Text as="h2" textStyle="subtitle2">
          {title}
        </Text>
      )}
      <SimpleGrid
        templateColumns={`repeat(${colCount}, 1fr)`}
        spacingX={minHorizontalSpaceBetweenGridElements}
        spacingY={spaceBetweenFormElements}
      >
        {children}
      </SimpleGrid>
    </VStack>
  );
}

export function FormEmptyCell() {
  return <Box />;
}

type FormSectionWithImageProps = {
  imageSrc: string;
  imageWidth?: string;
  link?: string;
  children: ReactNode;
};

export function FormSectionWithImage({
  imageSrc,
  imageWidth = '500px',
  link,
  children,
}: FormSectionWithImageProps) {
  return (
    <HStack spacing={spaceBeforeThemePictures} align="top">
      <VStack spacing={spaceBetweenFormElements}>{children}</VStack>
      <VStack align="end">
        <Image
          src={imageSrc}
          alt={strings.theme.purposeAndUsage}
          width={imageWidth}
        />
        {link && (
          <Link
            isExternal
            label={strings.theme.learnMore}
            to={link}
            textStyle="subtitle3"
          />
        )}
      </VStack>
    </HStack>
  );
}

export function FormDivider() {
  return <Divider marginTop="0px" marginBottom="0px" />;
}

type FormButtonsProps = {
  positiveLabel: string;
  positiveOnClick: () => void;
  positiveIsDisabled: boolean;
  negativeLabel?: string;
  negativeOnClick: () => void;
  negativeIsDisabled: boolean;
};

export function FormButtons({
  positiveLabel,
  positiveOnClick,
  positiveIsDisabled = false,
  negativeLabel = strings.common.cancel,
  negativeOnClick,
  negativeIsDisabled = false,
}: FormButtonsProps) {
  return (
    <HStack
      spacing={spaceBetweenFormButtons}
      flexGrow={1}
      alignItems="flex-end"
      marginTop={`calc(${minSpaceBeforeFormButton} - ${spaceBetweenFormElements})`}
    >
      <Spacer />
      <SecondaryButton
        label={negativeLabel}
        onClick={negativeOnClick}
        isDisabled={negativeIsDisabled}
      />
      <PrimaryButton
        label={positiveLabel}
        onClick={positiveOnClick}
        isDisabled={positiveIsDisabled}
      />
    </HStack>
  );
}
