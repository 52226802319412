import { inputAnatomy } from '@chakra-ui/anatomy';
import {
  StyleFunctionProps,
  createMultiStyleConfigHelpers,
} from '@chakra-ui/react';
import { standardInputHeight } from './constants';
import { textStyles } from './textStyles';

export const standardInputFieldStyle = {
  ...textStyles.bodyCopy,
  borderRadius: '10px',
  border: '2px solid',
  borderColor: 'neutrals.brandGrey.300',
  height: standardInputHeight,
  width: '340px',
  textColor: 'neutrals.black',
  lineHeight: '22px',
  _invalid: {
    borderColor: 'system.error.700',
    boxShadow: 'none',
  },
  _placeholder: {
    color: 'neutrals.brandGrey.500',
  },
};

export function getStandardPropBasedInputStyles(props: StyleFunctionProps) {
  return {
    backgroundColor: props.isDisabled
      ? 'neutrals.brandGrey.300'
      : 'neutrals.staticWhite',
    _focusVisible: {
      borderColor: props.isInvalid
        ? 'system.error.700'
        : 'secondary.secondaryBlue.500',
      boxShadow: 'none',
    },
  };
}

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const outline = definePartsStyle({
  field: {
    ...standardInputFieldStyle,
    padding: '14px 20px',
  },
  addon: {
    height: '100%',
    backgroundColor: 'neutrals.brandGrey.300',
    borderColor: 'neutrals.brandGrey.300',
  },
});

export const inputTheme = defineMultiStyleConfig({
  variants: {
    outline: (props) => {
      return {
        field: {
          ...outline.field,
          ...getStandardPropBasedInputStyles(props),
        },
        addon: {
          ...outline.addon,
        },
      };
    },
  },
});
