import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  list: {
    minWidth: 0,
  },
  item: {
    paddingX: '20px',
    paddingY: '10px',
    textStyle: 'bodyCopy',
    textColor: 'neutrals.navigationOutline',
    _hover: { backgroundColor: 'neutrals.brandGrey.100' },
  },
});

export const menuTheme = defineMultiStyleConfig({
  baseStyle,
});
