import {
  extendTheme,
  StyleConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { buttonTheme } from './button';
import { inputTheme } from './input';
import { modalTheme } from './modal';
import { switchTheme } from './switch';
import { layerStyles } from './layerStyles';
import { colors } from './colors';
import { textStyles } from './textStyles';
import { tableTheme } from './table';
import { selectTheme } from './select';
import { menuTheme } from './menu';
import { tooltipTheme } from './tooltip';
import { checkboxTheme } from './checkbox';
import { radioTheme } from './radio';
import { numberInputTheme } from './numberInput';

const components: Record<string, StyleConfig> = {
  Avatar: {
    variants: {
      profile: {
        container: {
          width: '60px',
          height: '60px',
          backgroundColor: 'neutrals.brandGrey.500',
          color: 'neutrals.staticWhite',
        },
      },
      navigation: {
        container: {
          width: '36px',
          height: '36px',
          userSelect: 'none',
          backgroundColor: 'neutrals.brandGrey.500',
          textColor: 'neutrals.staticWhite',
        },
      },
    },
  },
};

const fonts = {
  body: 'Open Sans',
};

const extendedChakraTheme = extendTheme(
  {
    styles: {
      global: {
        'html, body, #root': {
          height: '100%',
          backgroundColor: 'neutrals.brandGrey.50',
        },
      },
    },
    colors,
    components: {
      ...components,
      Button: buttonTheme,
      Input: inputTheme,
      Menu: menuTheme,
      Modal: modalTheme,
      Select: selectTheme,
      Switch: switchTheme,
      Table: tableTheme,
      Tooltip: tooltipTheme,
      Checkbox: checkboxTheme,
      Radio: radioTheme,
      NumberInput: numberInputTheme,
    },
    fonts,
    layerStyles,
    textStyles,
  },
  withDefaultColorScheme({ colorScheme: 'secondary.secondaryBlue' }),
);

export default extendedChakraTheme;
