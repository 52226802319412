import React, { useContext, useReducer, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { descriptionCharLimit, displayEnum, scrollToTop } from '../../../utils';
import { validateUrl } from '../../../utils/validation';
import { FormType, RequestError } from '../../../types';
import useAuthRequest from '../../../hooks/useAuthRequest';
import useIntegrationsQuery from '../../../hooks/useIntegrationsQuery';
import Page from '../../common/Page';
import TextArea from '../../common/inputs/TextArea';
import StandardInput from '../../common/inputs/StandardInput';
import Select from '../../common/inputs/Select';
import RadioGroup, { Radio } from '../../common/inputs/RadioGroup';
import { AppContext, EnvironmentContext } from '../../../contexts';
import Form, { FormButtons, FormDivider, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import {
  CreateFeedMutation,
  CreateFeedMutationVariables,
  FeedQueryVariables,
  FeedType,
  IntegrationType,
  UpdateFeedMutation,
  UpdateFeedMutationVariables,
  CreateFeedInput,
  FeedQuery,
  UsedFeedIntegrationByAppQueryVariables,
  UsedFeedIntegrationByAppQuery,
} from '../../../gql/gqlRequests';
import {
  createFeedRequest,
  feedRequest,
  updateFeedRequest,
  usedIntegrationRequest,
} from '../../../support/feeds';
import { strings } from '../../../utils/strings';
import { testIds } from '../../../utils/testIds';

const integrationTypes = [IntegrationType.Content];

// reducer //

type InputState = {
  name: string;
  description: string;
  type: FeedType | null;
  url: string;
  contentIntegrationKey: string | null;
  contentDetailIntegrationKey: string | null;
  nested: boolean;
};

type InputAction = {
  type: 'reset' | 'set-one' | 'set-all';
  key?: keyof InputState;
  stringValue?: string;
  booleanValue?: boolean;
  feedValue?: FeedType;
  newInputState?: InputState;
};

const initialInputState: InputState = {
  name: '',
  description: '',
  type: null,
  url: '',
  contentIntegrationKey: null,
  contentDetailIntegrationKey: null,
  nested: true,
};

function inputDataReducer(state: InputState, action: InputAction): InputState {
  switch (action.type) {
    case 'reset':
      return initialInputState;

    case 'set-one':
      if (!action.key) return state;
      const newState = { ...state };
      if (action.key === 'type') {
        newState[action.key] = action.feedValue ?? null;
      } else if (action.key === 'nested') {
        newState[action.key] = action.booleanValue ?? false;
      } else {
        newState[action.key] = action.stringValue ?? '';
      }
      return newState;

    case 'set-all':
      return action.newInputState ?? state;
  }
}

// feed form //

type FeedFormProps = {
  formType: FormType;
};

export default function FeedForm({ formType }: FeedFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const feedId = params.feedId ?? '';
  const pageRef = useRef<HTMLDivElement>(null);
  const { appId } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // integrations

  const contentIntegrationsQuery = useIntegrationsQuery(integrationTypes);

  // create

  const createMutationFn = useAuthRequest<
    CreateFeedMutationVariables,
    CreateFeedMutation
  >(createFeedRequest);
  const createMutation = useMutation<
    CreateFeedMutation,
    RequestError,
    CreateFeedMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  function onFetchSuccess(data: FeedQuery) {
    const {
      name,
      url,
      description,
      type,
      contentIntegration,
      contentDetailIntegration,
      nested,
    } = data.feed;
    dispatchInputData({
      type: 'set-all',
      newInputState: {
        name,
        url: url ?? '',
        description: description ?? '',
        type,
        contentIntegrationKey: contentIntegration.key,
        contentDetailIntegrationKey: contentDetailIntegration.key,
        nested,
      },
    });
  }

  const isFeedQueryEnabled = formType === 'edit' && !!feedId;
  const feedQueryFn = useAuthRequest<FeedQueryVariables, FeedQuery>(
    feedRequest,
  );
  const feedQuery = useQuery<FeedQuery, RequestError>({
    queryKey: ['form', 'feed', feedId, environment],
    queryFn: () => feedQueryFn({ feedId, environment }),
    enabled: isFeedQueryEnabled,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: onFetchSuccess,
  });

  const usedIntegrationQueryFn = useAuthRequest<
    UsedFeedIntegrationByAppQueryVariables,
    UsedFeedIntegrationByAppQuery
  >(usedIntegrationRequest);

  const usedIntegration = useQuery<UsedFeedIntegrationByAppQuery, RequestError>(
    {
      queryKey: ['form', 'feed', 'usedIntegration', appId, feedId, environment],
      queryFn: () =>
        usedIntegrationQueryFn({
          appId: appId,
          feedId: feedId,
          environment: environment,
        }),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  // update

  const updateMutationFn = useAuthRequest<
    UpdateFeedMutationVariables,
    UpdateFeedMutation
  >(updateFeedRequest);
  const updateMutation = useMutation<
    UpdateFeedMutation,
    RequestError,
    UpdateFeedMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // input

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  function setStringValue(
    key: keyof Omit<InputState, 'nested' | 'type'>,
    stringValue: string,
  ) {
    dispatchInputData({ type: 'set-one', key, stringValue });
  }
  function setBooleanValue(
    key: keyof Pick<InputState, 'nested'>,
    booleanValue: boolean,
  ) {
    dispatchInputData({ type: 'set-one', key, booleanValue });
  }
  function setFeedTypeValue(
    key: keyof Pick<InputState, 'type'>,
    feedValue: FeedType,
  ) {
    dispatchInputData({ type: 'set-one', key, feedValue });
  }
  const {
    name,
    description,
    type,
    url,
    contentIntegrationKey,
    contentDetailIntegrationKey,
  } = inputData;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isNameValid = !!name;
  const isDescriptionWithinLimits =
    (description?.length ?? 0) <= descriptionCharLimit;
  const isUrlValid = validateUrl(url);
  const isTypeValid = !!type;
  const isContentIntegrationValid = !!contentIntegrationKey;
  const isContentDetailIntegrationValid = !!contentDetailIntegrationKey;
  const isInputValid =
    isNameValid &&
    isDescriptionWithinLimits &&
    isUrlValid &&
    isTypeValid &&
    isContentIntegrationValid &&
    isContentDetailIntegrationValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    // backend requires description to be null instead of empty string
    // TODO: when refactoring the reducer, maybe store null directly in reducer?
    const parsedInputData = { ...inputData } as CreateFeedInput;
    parsedInputData.description = inputData.description || null;

    if (formType === 'create') {
      // some inputData fields may be null, but validity check above would catch them
      createMutation.mutate({
        appId,
        input: parsedInputData,
      });
    } else {
      updateMutation.mutate({
        id: feedId,
        environment,
        input: parsedInputData,
      });
    }
  }

  // UI

  const contentIntegrationOptions =
    (usedIntegration.data?.usedIntegrationByApp.usedContentIntegration ?? [])
      .length != 0
      ? usedIntegration.data?.usedIntegrationByApp.usedContentIntegration
      : contentIntegrationsQuery.data?.integrations.map(({ key }) => key);

  const contentDetailIntegrationOptions =
    (
      usedIntegration.data?.usedIntegrationByApp.usedContentDetailIntegration ??
      []
    ).length != 0
      ? usedIntegration.data?.usedIntegrationByApp.usedContentDetailIntegration
      : contentIntegrationsQuery.data?.integrations.map(({ key }) => key);

  const title =
    formType === 'create' ? strings.feeds.createFeed : strings.feeds.editFeed;
  const submitText =
    formType === 'create'
      ? strings.feeds.createFeed
      : strings.common.saveChanges;

  const isLoading =
    createMutation.isLoading ||
    // unfortunately, disabled tanstack queries are in a loading state
    (feedQuery.isLoading && isFeedQueryEnabled) ||
    contentIntegrationsQuery.isLoading ||
    updateMutation.isLoading;

  if (feedQuery.isError) throw parsedRequestError(feedQuery.error);

  return (
    <Page
      isForm
      withEnvSearchParam={formType === 'edit'}
      disallowProduction={formType === 'edit'}
      withBack
      title={title}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage} data-testid={testIds.feed_form}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.feeds.name}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading}
          value={name}
          setSanitizedValue={(value) => setStringValue('name', value)}
        />
        <TextArea
          label={strings.common.description}
          labelAsPlaceholder
          isInvalid={
            !isDescriptionWithinLimits || hasBackendError('description')
          }
          isDisabled={isLoading}
          value={description}
          setSanitizedValue={(value) => setStringValue('description', value)}
          bottomRightMessage={strings.common.nCharactersMax({
            n: descriptionCharLimit,
          })}
        />
        <FormDivider />
        <Select
          label={strings.common.type}
          isInvalid={
            isValidationActive && (!isTypeValid || hasBackendError('type'))
          }
          isDisabled={isLoading}
          value={type?.toString()}
          onChange={(e) => setFeedTypeValue('type', e.target.value as FeedType)}
        >
          {Object.values(FeedType).map((feedType) => (
            <option key={feedType} value={feedType}>
              {displayEnum(feedType as FeedType)}
            </option>
          ))}
        </Select>
        <FormDivider />
        <StandardInput
          label={strings.common.url}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isUrlValid || hasBackendError('url'))
          }
          isDisabled={isLoading}
          value={url}
          setSanitizedValue={(value) => setStringValue('url', value)}
        />
        <FormDivider />
        <Select
          label={strings.feeds.contentIntegration}
          isInvalid={
            isValidationActive &&
            (!isContentIntegrationValid ||
              hasBackendError('contentIntegrationKey'))
          }
          isDisabled={isLoading}
          value={contentIntegrationKey ?? ''}
          onChange={(e) =>
            setStringValue('contentIntegrationKey', e.target.value)
          }
          data-testid={testIds.content_integration_select}
        >
          {(contentIntegrationOptions ?? []).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </Select>
        <Select
          label={strings.feeds.detailIntegration}
          isInvalid={
            isValidationActive &&
            (!isContentDetailIntegrationValid ||
              hasBackendError('contentDetailIntegrationKey'))
          }
          isDisabled={isLoading}
          value={contentDetailIntegrationKey ?? ''}
          onChange={(e) =>
            setStringValue('contentDetailIntegrationKey', e.target.value)
          }
          data-testid={testIds.detail_integration_select}
        >
          {(contentDetailIntegrationOptions ?? []).map((key) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </Select>
        <RadioGroup
          label={strings.feeds.multiOrSingleRow}
          value={inputData.nested.toString()}
          setValue={(val) => setBooleanValue('nested', val === 'true')}
          isDisabled={isLoading}
        >
          <Radio value="true" label={strings.feeds.multiRow} />
          <Radio value="false" label={strings.feeds.singleRow} />
        </RadioGroup>
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading || feedQuery.isError}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
