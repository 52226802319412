import { createGqlAuthRequest } from '.';
import {
  AddIntegrationDocument,
  AddIntegrationMutation,
  AddIntegrationMutationVariables,
  ConfigIntegrationsDocument,
  ConfigIntegrationsQuery,
  ConfigIntegrationsQueryVariables,
  DeleteIntegrationDocument,
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
  IntegrationsDocument,
  IntegrationsQuery,
  IntegrationsQueryVariables,
  SubmitIntegrationsDocument,
  SubmitIntegrationsMutation,
  SubmitIntegrationsMutationVariables,
  UpdateIntegrationDocument,
  UpdateIntegrationMutation,
  UpdateIntegrationMutationVariables,
} from '../gql/gqlRequests';

export const integrationsRequest = createGqlAuthRequest<
  IntegrationsQueryVariables,
  IntegrationsQuery
>(IntegrationsDocument);

export const configIntegrationsRequest = createGqlAuthRequest<
  ConfigIntegrationsQueryVariables,
  ConfigIntegrationsQuery
>(ConfigIntegrationsDocument);

// one mutation to create/update/delete integrations
export const submitIntegrationsRequest = createGqlAuthRequest<
  SubmitIntegrationsMutationVariables,
  SubmitIntegrationsMutation
>(SubmitIntegrationsDocument);

export const addIntegrationRequest = createGqlAuthRequest<
  AddIntegrationMutationVariables,
  AddIntegrationMutation
>(AddIntegrationDocument);

export const updateIntegrationRequest = createGqlAuthRequest<
  UpdateIntegrationMutationVariables,
  UpdateIntegrationMutation
>(UpdateIntegrationDocument);

export const deleteIntegrationRequest = createGqlAuthRequest<
  DeleteIntegrationMutationVariables,
  DeleteIntegrationMutation
>(DeleteIntegrationDocument);
