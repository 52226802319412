import React, { Fragment, useContext, useState } from 'react';
import { Spacer, Text, Box } from '@chakra-ui/react';
import { AppContext } from '../../contexts';
import Button from '../common/buttons/Button';
import Link from '../common/Link';
import ChangeAppDialog from './ChangeAppDialog';
import { strings } from '../../utils/strings';
import { routes } from '../../types/routes';

export default function NavigationApp() {
  const { appId, appData } = useContext(AppContext);
  const [isChangeAppDialogOpen, setIsChangeAppDialogOpen] = useState(false);

  return (
    <Fragment>
      <Box layerStyle="navigationApp">
        <Box display="flex" justifyContent="space-between">
          <Text textStyle="bodyCopySmall">{strings.common.viewingAs}</Text>
          <Button
            // unique button
            variant="link"
            label={strings.common.changeApp}
            onClick={() => setIsChangeAppDialogOpen(true)}
            textUnderlineOffset="5px"
            textStyle="bodyCopySmall"
            textColor="neutrals.brandGrey.300"
            withoutColorScheme
          />
        </Box>
        <Spacer />
        <Box>
          <Link to={routes.appDetail({ appId })}>
            <Text
              textStyle="subtitle3"
              textColor="neutrals.staticWhite"
              noOfLines={1}
              wordBreak="break-all"
            >
              {appData?.name}
            </Text>
          </Link>
        </Box>
      </Box>

      <ChangeAppDialog
        isOpen={isChangeAppDialogOpen}
        onClose={() => setIsChangeAppDialogOpen(false)}
      />
    </Fragment>
  );
}
