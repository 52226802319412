import { createGqlAuthRequest } from '.';
import {
  SubmitProductConsentDocument,
  SubmitProductConsentMutation,
  SubmitProductConsentMutationVariables,
} from '../gql/gqlRequests';

export const submitProductConsentRequest = createGqlAuthRequest<
  SubmitProductConsentMutationVariables,
  SubmitProductConsentMutation
>(SubmitProductConsentDocument);
