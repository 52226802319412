import React from 'react';
import AuthenticationInfo from '../../common/authentication/AuthenticationInfo';
import hourglass from '../../../assets/hourglass.svg';
import { useNavigate } from 'react-router-dom';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

export default function ResetPasswordLinkExpired() {
  const navigate = useNavigate();

  const requestNewLinkButton = {
    text: strings.authentication.requestNewLink,
    onClick: () => navigate(`${routes.forgotPassword}?expired=true`),
  };

  return (
    <AuthenticationInfo
      icon={hourglass}
      iconAlt={strings.common.expired}
      title={strings.authentication.passwordResetLinkExpired}
      subtitle={strings.authentication.linkNoLongerValid}
      buttonProps={requestNewLinkButton}
    />
  );
}
