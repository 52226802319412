import React, { useReducer, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  validateEmail,
  validatePhone,
  validateUrl,
} from '../../../utils/validation';
import { FormType, RequestError } from '../../../types';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Form, {
  FormButtons,
  FormDivider,
  FormEmptyCell,
  FormError,
  FormGrid,
} from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import PhoneInput from '../../common/inputs/PhoneInput';
import {
  AccountQuery,
  AccountQueryVariables,
  CreateAccountMutation,
  CreateAccountMutationVariables,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from '../../../gql/gqlRequests';
import {
  accountRequest,
  createAccountRequest,
  updateAccountRequest,
} from '../../../support/accounts';
import { strings } from '../../../utils/strings';
import { scrollToTop } from '../../../utils';

// reducer //

type InputState = {
  name: string;
  url: string;
  primaryContactName: string;
  primaryContactEmail: string;
  primaryContactPhone: string;
  ensembleAccountManagerName: string;
};

type InputAction = {
  type: 'reset' | 'set-one' | 'set-all';
  key?: keyof InputState;
  value?: string;
  newInputState?: InputState;
};

const initialInputState = {
  name: '',
  url: '',
  primaryContactName: '',
  primaryContactEmail: '',
  primaryContactPhone: '',
  ensembleAccountManagerName: '',
};

function inputDataReducer(state: InputState, action: InputAction): InputState {
  switch (action.type) {
    case 'reset':
      return initialInputState;

    case 'set-one':
      if (!action.key || action.value === undefined) return state;
      const newState = { ...state };
      newState[action.key] = action.value;
      return newState;

    case 'set-all':
      return action.newInputState ?? state;
  }
}

// account form //

type AccountFormProps = {
  type: FormType;
};

export default function AccountForm({ type }: AccountFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.accountId ?? '';
  const pageRef = useRef<HTMLDivElement>(null);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // create

  const createMutationFn = useAuthRequest<
    CreateAccountMutationVariables,
    CreateAccountMutation
  >(createAccountRequest);
  const createAccountMutation = useMutation<
    CreateAccountMutation,
    RequestError,
    CreateAccountMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  function populateInputData(data: AccountQuery) {
    dispatchInputData({
      type: 'set-all',
      newInputState: data.account,
    });
  }

  const isEditForm = type === 'edit' && !!accountId;
  const accountQueryFn = useAuthRequest<AccountQueryVariables, AccountQuery>(
    accountRequest,
  );
  const accountQuery = useQuery<AccountQuery, RequestError>({
    queryKey: ['account', accountId],
    queryFn: () => accountQueryFn({ id: accountId }),
    enabled: isEditForm,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });

  // update

  const updateMutationFn = useAuthRequest<
    UpdateAccountMutationVariables,
    UpdateAccountMutation
  >(updateAccountRequest);
  const updateAccountMutation = useMutation<
    UpdateAccountMutation,
    RequestError,
    UpdateAccountMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // input

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  function setValue(key: keyof InputState, value: string) {
    dispatchInputData({ type: 'set-one', key, value });
  }
  const {
    name,
    url,
    primaryContactName,
    primaryContactEmail,
    primaryContactPhone,
    ensembleAccountManagerName,
  } = inputData;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation =
      type === 'create' ? createAccountMutation : updateAccountMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isNameValid = !!name;
  const isUrlValid = validateUrl(url);
  const isContactNameValid = !!primaryContactName;
  const isContactEmailValid = validateEmail(primaryContactEmail);
  const isContactPhoneValid = validatePhone(primaryContactPhone);
  const isManagerValid = !!ensembleAccountManagerName;
  const isInputValid =
    isNameValid &&
    isUrlValid &&
    isContactNameValid &&
    isContactEmailValid &&
    isContactPhoneValid &&
    isManagerValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    if (type === 'create') {
      createAccountMutation.mutate({ ...inputData });
    } else {
      updateAccountMutation.mutate({ id: accountId ?? '', ...inputData });
    }
  }

  // UI

  const title =
    type === 'create'
      ? strings.accounts.createAccount
      : strings.accounts.editAccount;
  const submitText =
    type === 'create'
      ? strings.accounts.createAccount
      : strings.common.saveChanges;

  const isLoading =
    createAccountMutation.isLoading ||
    // unfortunately, disabled tanstack queries are in a loading state
    (accountQuery.isLoading && isEditForm) ||
    updateAccountMutation.isLoading;

  if (accountQuery.isError) throw parsedRequestError(accountQuery.error);

  return (
    <Page isForm withBack title={title} pageRef={pageRef}>
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.accounts.accountName}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading}
          isPermanentlyDisabled={type === 'edit'}
          value={name}
          setSanitizedValue={(value) => setValue('name', value)}
        />
        <FormDivider />
        <StandardInput
          label={strings.common.url}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isUrlValid || hasBackendError('url'))
          }
          isDisabled={isLoading}
          value={url}
          setSanitizedValue={(value) => setValue('url', value)}
        />
        <FormDivider />
        <FormGrid title={strings.accounts.primaryAccountContact}>
          <StandardInput
            label={strings.common.name}
            labelAsPlaceholder
            isInvalid={
              isValidationActive &&
              (!isContactNameValid || hasBackendError('primaryContactName'))
            }
            isDisabled={isLoading}
            value={primaryContactName}
            setSanitizedValue={(value) => setValue('primaryContactName', value)}
          />
          <FormEmptyCell />
          <PhoneInput
            value={primaryContactPhone}
            setSanitizedValue={(value) =>
              setValue('primaryContactPhone', value)
            }
            isInvalid={
              isValidationActive &&
              (!isContactPhoneValid || hasBackendError('primaryContactPhone'))
            }
            isDisabled={isLoading}
          />
          <StandardInput
            label={strings.common.email}
            labelAsPlaceholder
            isInvalid={
              isValidationActive &&
              (!isContactEmailValid || hasBackendError('primaryContactEmail'))
            }
            isDisabled={isLoading}
            value={primaryContactEmail}
            setSanitizedValue={(value) =>
              setValue('primaryContactEmail', value)
            }
          />
        </FormGrid>
        <FormDivider />
        <StandardInput
          label={strings.accounts.accountManagerName}
          labelAsPlaceholder
          isInvalid={
            isValidationActive &&
            (!isManagerValid || hasBackendError('ensembleAccountManagerName'))
          }
          isDisabled={isLoading}
          value={ensembleAccountManagerName}
          setSanitizedValue={(value) =>
            setValue('ensembleAccountManagerName', value)
          }
        />
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
