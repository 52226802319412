import React, { Fragment } from 'react';
import { RowDetail } from '../../common/inputs/RowInput';
import { Text } from '@chakra-ui/react';
import { ScreenLocalizationFileInput } from './ScreenLocalizationInput';
import Tooltip from '../../common/Tooltip';

type ScreenLocalizationPairProps = {
  isFile?: boolean;
  label: string;
  tooltip?: string;
  keys: string[];
  values: string[];
  groupHeading?: string;
};

export default function ScreenLocalizationPair(
  props: ScreenLocalizationPairProps,
) {
  if (props.isFile) {
    return <ScreenLocalizationFileInput viewType="detail" {...props} />;
  }
  return <ScreenLocalizationStringPair {...props} />;
}

function ScreenLocalizationStringPair({
  label,
  tooltip,
  keys,
  values,
  groupHeading,
}: ScreenLocalizationPairProps) {
  return (
    <Fragment>
      {groupHeading && (
        <Text textStyle="h5" paddingTop="15px" paddingBottom="20px">
          {groupHeading}
        </Text>
      )}
      {keys.map((key, index) => (
        <RowDetail
          key={key}
          label={index === 0 ? label : ''}
          tooltipText={tooltip}
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
            gap: '40px',
            paddingBottom: index === keys.length - 1 ? '30px' : '20px',
          }}
        >
          <Tooltip tipText={values[index]}>
            <Text
              textStyle="localizationStrings"
              textOverflow="ellipsis"
              role="phrase"
              aria-label={values[index]}
            >
              {values[index]}
            </Text>
          </Tooltip>
        </RowDetail>
      ))}
    </Fragment>
  );
}
