import { createGqlAuthRequest } from '.';
import {
  CreateUserDocument,
  CreateUserMutation,
  CreateUserMutationVariables,
  DeleteUserDocument,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  LoggedInUserDocument,
  LoggedInUserQuery,
  LoggedInUserQueryVariables,
  UpdateMfaPreferenceDocument,
  UpdateMfaPreferenceMutation,
  UpdateMfaPreferenceMutationVariables,
  UpdateUserDocument,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserDocument,
  UserQuery,
  UserQueryVariables,
  UsersDocument,
  UsersQuery,
  UsersQueryVariables,
} from '../gql/gqlRequests';

export const usersRequest = createGqlAuthRequest<
  UsersQueryVariables,
  UsersQuery
>(UsersDocument);

export const userRequest = createGqlAuthRequest<UserQueryVariables, UserQuery>(
  UserDocument,
);

export const loggedInUserRequest = createGqlAuthRequest<
  LoggedInUserQueryVariables,
  LoggedInUserQuery
>(LoggedInUserDocument);

export const createUserRequest = createGqlAuthRequest<
  CreateUserMutationVariables,
  CreateUserMutation
>(CreateUserDocument);

export const updateUserRequest = createGqlAuthRequest<
  UpdateUserMutationVariables,
  UpdateUserMutation
>(UpdateUserDocument);

export const deleteUserRequest = createGqlAuthRequest<
  DeleteUserMutationVariables,
  DeleteUserMutation
>(DeleteUserDocument);

export const updateMFAPreferenceRequest = createGqlAuthRequest<
  UpdateMfaPreferenceMutationVariables,
  UpdateMfaPreferenceMutation
>(UpdateMfaPreferenceDocument);
