import React, { useContext, useMemo, useState } from 'react';
import { AppContext } from '../../../contexts';
import { ScreenType } from '../../../gql/gqlRequests';
import LocalesTabList from '../../common/LocalesTabList';
import {
  getScreenLocalizationIndividualInputsData,
  getScreenLocalizationSubscriptionsInputsData,
  parseAndAddValuesToLocalizations,
} from '../../../utils/screens';
import ScreenLocalizationInput from './ScreenLocalizationInput';
import { SimplifiedSubscriptionProductsWithPlatform } from '../../../hooks/useEnabledSubscriptions';

type ScreenLocalizationFormProps = {
  screenType: ScreenType;
  enabledSubscriptions: SimplifiedSubscriptionProductsWithPlatform;
  keyValuePairs: Record<string, string>;
  updateKeyValuePair?: (key: string, value: string) => void;
  isLoading?: boolean;
  isValidationActive?: boolean;
};

export default function ScreenLocalizationForm({
  screenType,
  enabledSubscriptions,
  keyValuePairs,
  updateKeyValuePair,
  isLoading,
  isValidationActive,
}: ScreenLocalizationFormProps) {
  if (!updateKeyValuePair) {
    throw Error('Must supply updateKeyValuePair for form.');
  }

  const { locales, defaultLocale } = useContext(AppContext);

  const [selectedLocale, setSelectedLocale] = useState(defaultLocale);

  const parsedIndividualInputsData = useMemo(
    () =>
      parseAndAddValuesToLocalizations(
        getScreenLocalizationIndividualInputsData(screenType),
        keyValuePairs,
      ),
    [screenType, keyValuePairs],
  );

  const parsedSubscriptionsInputsData = useMemo(
    () =>
      enabledSubscriptions?.flatMap((subscription) =>
        parseAndAddValuesToLocalizations(
          getScreenLocalizationSubscriptionsInputsData(screenType),
          keyValuePairs,
          subscription.id,
          `${subscription.name} (${subscription.platformName})`,
        ),
      ) ?? [],
    [screenType, keyValuePairs, enabledSubscriptions],
  );

  return (
    <LocalesTabList
      listType="form"
      entries={locales}
      selectedLocale={selectedLocale}
      setSelectedLocale={setSelectedLocale}
    >
      {[...parsedIndividualInputsData, ...parsedSubscriptionsInputsData].map(
        (screenLocalization) => (
          <ScreenLocalizationInput
            key={`${selectedLocale.code}-${screenLocalization.keys[0]}`}
            viewType="form"
            {...screenLocalization}
            updateKeyValuePair={updateKeyValuePair}
            isDisabled={isLoading}
            isValidationActive={isValidationActive}
          />
        ),
      )}
    </LocalesTabList>
  );
}
