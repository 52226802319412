import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import { tableCellPadding, tableHeaderPadding } from './constants';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys);

const flowTable = definePartsStyle({
  table: {
    borderCollapse: 'separate',
    borderSpacing: 0,
  },
  thead: {
    position: 'sticky',
    // z-index is 1 point lower than Navigation z-index (which is 10 or 'docked')
    zIndex: '9',
    top: 0,
  },
  th: {
    padding: tableHeaderPadding,
    borderTop: '1px solid',
    borderTopColor: 'neutrals.brandGrey.300',
    borderBottom: '1px solid',
    borderBottomColor: 'neutrals.brandGrey.300',
    color: 'neutrals.brandGrey.500',
    backgroundColor: 'neutrals.white',
  },
  tr: {
    _hover: { backgroundColor: 'neutrals.brandGrey.hover' },
    _active: { backgroundColor: 'primary.primaryBlue.select' },
  },
  td: {
    padding: tableCellPadding,
    borderBottom: '1px solid',
    borderColor: 'neutrals.brandGrey.100',
  },
});

export const tableTheme = defineMultiStyleConfig({
  variants: {
    flowTable,
  },
});
