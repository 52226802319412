import { createGqlAuthRequest } from '.';
import {
  CreateNavBarItemDocument,
  CreateNavBarItemMutation,
  CreateNavBarItemMutationVariables,
  DeleteNavBarItemDocument,
  DeleteNavBarItemMutation,
  DeleteNavBarItemMutationVariables,
  NavBarItemDocument,
  NavBarItemQuery,
  NavBarItemQueryVariables,
  NavBarScreenTypesDocument,
  NavBarScreenTypesQuery,
  NavBarScreenTypesQueryVariables,
  UpdateNavBarItemDocument,
  UpdateNavBarItemMutation,
  UpdateNavBarItemMutationVariables,
  UpdateNavBarOrderDocument,
  UpdateNavBarOrderMutation,
  UpdateNavBarOrderMutationVariables,
} from '../gql/gqlRequests';

export const navBarItemRequest = createGqlAuthRequest<
  NavBarItemQueryVariables,
  NavBarItemQuery
>(NavBarItemDocument);

export const navBarScreenTypesRequest = createGqlAuthRequest<
  NavBarScreenTypesQueryVariables,
  NavBarScreenTypesQuery
>(NavBarScreenTypesDocument);

export const createNavBarItemRequest = createGqlAuthRequest<
  CreateNavBarItemMutationVariables,
  CreateNavBarItemMutation
>(CreateNavBarItemDocument);

export const updateNavBarItemRequest = createGqlAuthRequest<
  UpdateNavBarItemMutationVariables,
  UpdateNavBarItemMutation
>(UpdateNavBarItemDocument);

export const updateNavBarOrderRequest = createGqlAuthRequest<
  UpdateNavBarOrderMutationVariables,
  UpdateNavBarOrderMutation
>(UpdateNavBarOrderDocument);

export const deleteNavBarItemRequest = createGqlAuthRequest<
  DeleteNavBarItemMutationVariables,
  DeleteNavBarItemMutation
>(DeleteNavBarItemDocument);
