import React, {
  Fragment,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import {
  Box,
  Grid,
  HStack,
  Image,
  SimpleGrid,
  Spacer,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Page from '../../common/Page';
import LabelAndValue from '../../common/LabelAndValue';
import { platformIcon } from '../../../utils/platforms';
import editIcon from '../../../assets/edit.svg';
import deleteIcon from '../../../assets/delete.svg';
import addIcon from '../../../assets/add.svg';
import TwoDeletionsDialog from '../../common/dialogs/TwoDeletionsDialog';
import { useMutation, useQuery } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Link from '../../common/Link';
import AddAppPlatformDialog from '../platforms/AddAppPlatformDialog';
import GetStartedPlatform from '../platforms/GetStartedPlatform';
import UnableToCreatePlatformDialog from '../platforms/UnableToCreatePlatformDialog';
import {
  AppContext,
  EnvironmentContext,
  PlatformsContext,
  UserContext,
} from '../../../contexts';
import TextButton from '../../common/buttons/TextButton';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import {
  AppDetailQuery,
  AppDetailQueryVariables,
  DeleteAppMutation,
  DeleteAppMutationVariables,
  DeletionResult,
  Environment,
  Platform,
  RemoveDeleteAppRequestMutation,
  RemoveDeleteAppRequestMutationVariables,
  Role,
} from '../../../gql/gqlRequests';
import {
  appDetailRequest,
  deleteAppRequest,
  removeDeleteAppRequest,
} from '../../../support/apps';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

const platformIconDiameter = '100px';

export default function AppDetail() {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(UserContext);
  const { setApp } = useContext(AppContext);
  const { appId, refetch: refetchApp } = useContext(AppContext);
  const { allPlatforms } = useContext(PlatformsContext);
  const { environment } = useContext(EnvironmentContext);

  const appDetailQueryFn = useAuthRequest<
    AppDetailQueryVariables,
    AppDetailQuery
  >(appDetailRequest);

  const appDetailQuery = useQuery<AppDetailQuery, RequestError>({
    queryKey: ['appDetail', appId, environment],
    queryFn: () => appDetailQueryFn({ id: appId, environment: environment }),
  });

  const appDetailData = appDetailQuery.data?.app;

  // deletion process and approval from system admins

  const [isDeleteAppDialogOpen, setIsDeleteAppDialogOpen] = useState(false);
  const hasUserAlreadyRequestedDeletion =
    appDetailData?.deletionRequestingUser?.username === loggedInUser.username;

  const deleteAppMutationFn = useAuthRequest<
    DeleteAppMutationVariables,
    DeleteAppMutation
  >(deleteAppRequest);

  const deleteAppMutation = useMutation<
    DeleteAppMutation,
    Error,
    DeleteAppMutationVariables
  >({
    mutationFn: () => deleteAppMutationFn({ id: appId }),
    onSuccess: refetchAndCloseOrNavigateAway,
  });

  const removeDeleteAppRequestFn = useAuthRequest<
    RemoveDeleteAppRequestMutationVariables,
    RemoveDeleteAppRequestMutation
  >(removeDeleteAppRequest);

  const removeDeleteAppRequestMutation = useMutation<
    RemoveDeleteAppRequestMutation,
    Error,
    RemoveDeleteAppRequestMutationVariables
  >({
    mutationFn: removeDeleteAppRequestFn,
    onSuccess: closeAddPlatformDialogAndRefetchApp,
  });

  const cancelDeleteRequest = React.useCallback(() => {
    removeDeleteAppRequestMutation.mutate({ id: appId });
  }, [appId, removeDeleteAppRequestMutation]);

  function callDeleteMutation() {
    deleteAppMutation.mutate({ id: appId });
  }

  function refetchAndCloseOrNavigateAway(data: DeleteAppMutation) {
    if (data.deleteApp === DeletionResult.NeedSecondDeletion) {
      refetchApp();
      appDetailQuery.refetch();
      setIsDeleteAppDialogOpen(false);
    } else if (data.deleteApp === DeletionResult.Success) {
      navigate(routes.accounts);
      setApp('');
    }
  }

  // adding platforms

  const usedPlatformIds = useMemo(
    () => appDetailData?.platforms.map(({ id }) => id),
    [appDetailData],
  );
  const unusedPlatforms = useMemo(
    () => allPlatforms.filter(({ id }) => !usedPlatformIds?.includes(id)),
    [allPlatforms, usedPlatformIds],
  );

  const [isAddPlatformDialogOpen, setIsAddPlatformDialogOpen] = useState(false);
  const [isUnableToAddDialogOpen, setIsUnablePlatformDialogOpen] =
    useState(false);

  const tryToCreatePlatform = useCallback(() => {
    if (unusedPlatforms.length > 0) {
      setIsAddPlatformDialogOpen(true);
    } else {
      setIsUnablePlatformDialogOpen(true);
    }
  }, [unusedPlatforms]);

  function closeAddPlatformDialogAndRefetchApp() {
    setIsAddPlatformDialogOpen(false);
    // refetch the global AppContext query, used in the nav menu, etc...
    refetchApp();
    // ...AND the local appDetail query, used just on this page
    appDetailQuery.refetch();
  }

  const currentUserDelete =
    loggedInUser.email === appDetailData?.deletionRequestingUser?.email;

  const renderEditAppControls = useCallback(() => {
    return (
      <Fragment>
        <Spacer />
        <Link label={strings.apps.editApp} to={routes.editApp({ appId })}>
          <Image src={editIcon} />
        </Link>
        <TextButton
          label={
            currentUserDelete
              ? strings.common.cancelDelete
              : strings.apps.deleteApp
          }
          leftIcon={<Image src={deleteIcon} />}
          onClick={
            currentUserDelete
              ? () => cancelDeleteRequest()
              : () => setIsDeleteAppDialogOpen(true)
          }
        />
      </Fragment>
    );
  }, [appId, currentUserDelete, cancelDeleteRequest]);

  const renderGeneralAppInfo = useCallback(() => {
    return (
      <Box layerStyle="generalInfo">
        <Grid templateColumns="200px 260px 200px" gap="150px">
          <LabelAndValue
            label={strings.accounts.account}
            value={appDetailData?.account.name}
          />
          <LabelAndValue
            label={strings.common.url}
            value={appDetailData?.website ?? strings.common.emptyPlaceholder}
          />
          <LabelAndValue
            label={strings.apps.ensembleManager}
            value={appDetailData?.account.ensembleAccountManagerName}
          />
        </Grid>
      </Box>
    );
  }, [appDetailData]);

  const renderPlatformItem = useCallback(
    (platform: Pick<Platform, 'name' | 'id'>) => {
      return (
        <Box key={platform.id} width={platformIconDiameter}>
          <Link
            to={routes.appPlatformDetail({ appId, platform })}
            textStyle="bodyCopy"
            noUnderline
          >
            <VStack>
              <Image
                src={platformIcon(platform.name, 'circled')}
                alt={platform.name}
              />
              <Text>{platform.name}</Text>
            </VStack>
          </Link>
        </Box>
      );
    },
    [appId],
  );

  const renderAppPlatformsSection = useCallback(() => {
    return appDetailData?.platforms.length ? (
      <Box layerStyle="appDetailPlatforms">
        <HStack
          alignItems="center"
          justifyContent="space-between"
          paddingBottom="25px"
        >
          <Text as="h2" textStyle="h1">
            {strings.platforms.platforms}
          </Text>
          {loggedInUser.role === Role.SystemAdmin &&
            environment !== Environment.Production && (
              <TextButton
                label={strings.platforms.addPlatform}
                leftIcon={<Image src={addIcon} />}
                onClick={tryToCreatePlatform}
              />
            )}
        </HStack>
        <SimpleGrid columns={6}>
          {appDetailData?.platforms.slice(0, 4).map(renderPlatformItem)}
          <Box width={platformIconDiameter}>
            <Link
              to={routes.appPlatforms({ appId })}
              textStyle="bodyCopy"
              noUnderline
            >
              <VStack>
                <Image src={platformIcon('See All')} />
                <Text>{strings.common.seeAll}</Text>
              </VStack>
            </Link>
          </Box>
        </SimpleGrid>
      </Box>
    ) : (
      loggedInUser.role === Role.SystemAdmin && (
        <GetStartedPlatform
          height="258px"
          onCreate={
            environment === Environment.Production
              ? undefined
              : tryToCreatePlatform
          }
        />
      )
    );
  }, [
    environment,
    appDetailData?.platforms,
    loggedInUser.role,
    tryToCreatePlatform,
    renderPlatformItem,
    appId,
  ]);

  if (appDetailQuery.isError) throw parsedRequestError(appDetailQuery.error);

  return (
    <Page
      isLoading={appDetailQuery.isLoading}
      withEnvironmentBar
      withBreadcrumbs={loggedInUser.role === Role.SystemAdmin}
      /* This page UI is unique therefore it does not have standard page title */
      title={''}
      isForm
    >
      <Box position="relative" width="100%">
        <Box display="flex" justifyContent="space-between">
          {/* The title is moved here because it has unique control buttons "Edit" and "Delete" on the right */}
          <Text as="h1" textStyle="h1" color="neutrals.navigationOutline">
            {appDetailData?.name}
          </Text>
          <HStack spacing="48px" align="end">
            {loggedInUser.role === Role.SystemAdmin &&
              environment !== Environment.Production &&
              renderEditAppControls()}
          </HStack>
        </Box>
        {renderGeneralAppInfo()}
        {renderAppPlatformsSection()}
      </Box>
      <TwoDeletionsDialog
        itemType="App"
        deletionRequestingUser={appDetailData?.deletionRequestingUser}
        hasAlreadyRequested={hasUserAlreadyRequestedDeletion}
        isLoading={deleteAppMutation.isLoading}
        isOpen={isDeleteAppDialogOpen}
        onDelete={callDeleteMutation}
        onCancel={() => setIsDeleteAppDialogOpen(false)}
      />
      <AddAppPlatformDialog
        isOpen={isAddPlatformDialogOpen}
        onClose={() => setIsAddPlatformDialogOpen(false)}
        platformOptions={unusedPlatforms}
        onSuccess={closeAddPlatformDialogAndRefetchApp}
      />
      <UnableToCreatePlatformDialog
        isOpen={isUnableToAddDialogOpen}
        onClose={() => setIsUnablePlatformDialogOpen(false)}
      />
    </Page>
  );
}
