import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import { Image, Spacer, Text } from '@chakra-ui/react';
import Link from '../../common/Link';
import editIcon from '../../../assets/edit.svg';
import LabelAndValue from '../../common/LabelAndValue';
import { displayEnum } from '../../../utils';
import { EnvironmentContext } from '../../../contexts';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import {
  Environment,
  FeedQuery,
  FeedQueryVariables,
} from '../../../gql/gqlRequests';
import { feedRequest } from '../../../support/feeds';
import { strings } from '../../../utils/strings';
import { testIds } from '../../../utils/testIds';
import { Route, routes } from '../../../types/routes';

export default function FeedDetail() {
  const params = useParams();
  const appId = params.appId ?? '';
  const feedId = params.feedId ?? '';
  const { environment } = useContext(EnvironmentContext);

  // feed (for selected environment)

  const feedQueryFn = useAuthRequest<FeedQueryVariables, FeedQuery>(
    feedRequest,
  );
  const feedQuery = useQuery<FeedQuery, RequestError>({
    queryKey: ['feed', feedId, environment],
    queryFn: () => feedQueryFn({ feedId, environment }),
  });

  const feedData = feedQuery.data?.feed;

  // UI

  if (feedQuery.isError) throw parsedRequestError(feedQuery.error);

  return (
    <Page
      isLoading={feedQuery.isLoading}
      withEnvironmentBar
      withBreadcrumbs
      title={strings.feeds.feedDetail}
    >
      {feedData && (
        <Details>
          <DetailsSection>
            <SectionTitle title={feedData.name}>
              <Spacer />
              {environment !== Environment.Production && (
                <Link label={strings.feeds.editFeed} to={Route.Edit}>
                  <Image src={editIcon} />
                </Link>
              )}
            </SectionTitle>

            <SectionBody>
              <SectionGrid colCount={1}>
                <LabelAndValue
                  label={strings.common.description}
                  value={
                    feedData.description || strings.common.emptyPlaceholder
                  }
                  noOfLines={2}
                  testId={testIds.description}
                />
              </SectionGrid>

              <SectionGrid>
                <LabelAndValue
                  label={strings.common.type}
                  value={displayEnum(feedData.type)}
                  testId={testIds.feed_type}
                />
                <LabelAndValue
                  label={strings.common.url}
                  value={feedData.url}
                  testId={testIds.url}
                  isUrl
                />
                <LabelAndValue
                  label={strings.feeds.contentIntegration}
                  value={feedData.contentIntegration.key}
                  testId={testIds.content_integration}
                />
                <LabelAndValue
                  label={strings.feeds.contentDetailIntegration}
                  value={feedData.contentDetailIntegration.key}
                  testId={testIds.content_detail}
                />
                <LabelAndValue
                  label={strings.feeds.multiSlashSingleRow}
                  value={
                    feedData.nested
                      ? strings.feeds.multiRow
                      : strings.feeds.singleRow
                  }
                  testId={testIds.row_type}
                />
              </SectionGrid>
            </SectionBody>
          </DetailsSection>

          <DetailsSection singleRow>
            <SectionTitle title={strings.feeds.platformsAndScreens} />
            <SectionBody>
              <SectionGrid>
                <LabelAndValue
                  label={strings.feeds.usedOnPlatforms}
                  value={
                    feedData.platforms.length === 0
                      ? strings.common.emptyPlaceholder
                      : undefined
                  }
                >
                  {feedData.platforms.map(({ name, id }) => (
                    <Text key={id}>{name}</Text>
                  ))}
                </LabelAndValue>
                <LabelAndValue
                  label={strings.feeds.usedOnScreens}
                  value={
                    feedData.screenFeeds.length === 0
                      ? strings.common.emptyPlaceholder
                      : undefined
                  }
                >
                  {feedData.screenFeeds.map(({ screen: { id, name } }) => (
                    <Link
                      key={id}
                      label={name}
                      to={routes.appScreenDetail({ appId, screenId: id })}
                      relative="path"
                    />
                  ))}
                </LabelAndValue>
              </SectionGrid>
            </SectionBody>
          </DetailsSection>
        </Details>
      )}
    </Page>
  );
}
