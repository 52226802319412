import { useCallback, useMemo, useState } from 'react';
import jwt_decode from 'jwt-decode';

export const refreshTokenKey = 'refresh-token';
export const accessTokenKey = 'access-token';
const fiveSecondsInMs = 5000;

type DecodedTokenType = {
  exp: number;
  username: string;
  'cognito:groups': string[];
};

/**
 * Only call this hook once, in App.tsx - otherwise, get these values
 * from the token context.
 */
export default function useTokens() {
  const savedRefreshToken = localStorage.getItem(refreshTokenKey) ?? '';
  const savedAccessToken = localStorage.getItem(accessTokenKey) ?? '';
  const [refreshTokenState, setRefreshTokenState] = useState(savedRefreshToken);
  const [accessTokenState, setAccessTokenState] = useState(savedAccessToken);

  const decodedAccessToken = useMemo<DecodedTokenType | undefined>(() => {
    try {
      return savedAccessToken ? jwt_decode(savedAccessToken) : undefined;
    } catch (e) {
      return undefined;
    }
  }, [savedAccessToken]);
  const accessTokenExpiresAtInSeconds = decodedAccessToken?.exp;

  const setRefreshToken = useCallback((token: string) => {
    localStorage.setItem(refreshTokenKey, token);
    setRefreshTokenState(token);
  }, []);

  const setAccessToken = useCallback((token: string) => {
    localStorage.setItem(accessTokenKey, token);
    setAccessTokenState(token);
  }, []);

  const clearTokens = useCallback(() => {
    localStorage.removeItem(refreshTokenKey);
    setRefreshTokenState('');
    localStorage.removeItem(accessTokenKey);
    setAccessTokenState('');
  }, []);

  /** Provides 5 second buffer, ie declares token expired 5s early. */
  const isAccessTokenValidAndUnexpired = useCallback(() => {
    if (!accessTokenExpiresAtInSeconds) return false;
    const expiresAtInMs = accessTokenExpiresAtInSeconds * 1000;
    const nowInMs = new Date().getTime();
    return expiresAtInMs - nowInMs > fiveSecondsInMs;
  }, [accessTokenExpiresAtInSeconds]);

  return {
    refreshToken: refreshTokenState,
    accessToken: accessTokenState,
    setRefreshToken,
    setAccessToken,
    clearTokens,
    isAccessTokenValidAndUnexpired,
  };
}
