import React, { ReactNode } from 'react';
import { Grid, GridItem, VStack, Text } from '@chakra-ui/react';
import { isLocaleRequired } from '../../utils';
import { Locale, ViewType } from '../../types';
import { strings } from '../../utils/strings';
import { testIds } from '../../utils/testIds';

type LocalesTabListProps = {
  listType: ViewType;
  entries: Locale[];
  selectedLocale: Locale;
  setSelectedLocale: (locale: Locale) => void;
  children: ReactNode;
};

export default function LocalesTabList({
  listType,
  entries,
  selectedLocale,
  setSelectedLocale,
  children,
}: LocalesTabListProps) {
  const isForm = listType === 'form';

  return (
    <Grid
      layerStyle={isForm ? 'localesTabFormGrid' : 'localesTabDetailGrid'}
      data-testid={
        isForm ? testIds.locales_tab_list_form : testIds.locales_tab_list_detail
      }
    >
      <GridItem layerStyle="localesSection">
        <VStack layerStyle="localesTabEntries" align="left" spacing="62px">
          {entries.map((locale) => (
            <LocalesTabEntry
              key={locale.code}
              locale={locale}
              isSelected={selectedLocale.code === locale.code}
              setSelectedLocale={setSelectedLocale}
            />
          ))}
        </VStack>
      </GridItem>
      <GridItem layerStyle="localesListSection">{children}</GridItem>
    </Grid>
  );
}

type LocalesTabEntryProps = {
  locale: Locale;
  isSelected: boolean;
  setSelectedLocale: (locale: Locale) => void;
};

function LocalesTabEntry({
  locale,
  isSelected,
  setSelectedLocale,
}: LocalesTabEntryProps) {
  return (
    <Text
      layerStyle={isSelected ? 'selectedLocalesTabEntry' : 'localesTabEntry'}
      textStyle="subtitle2"
      lineHeight="38px"
      _hover={{ cursor: 'pointer' }}
      onClick={() => setSelectedLocale(locale)}
      data-testid={isSelected ? 'selected-locale-tab' : ''}
    >
      {locale.name}
      {isLocaleRequired(locale) && (
        <Text textStyle="button" as="span" color="red.600">
          {' '}
          {strings.common.requiredSymbol}
        </Text>
      )}
    </Text>
  );
}
