import React, { useContext, useMemo } from 'react';
import { Breadcrumb, BreadcrumbItem, Text } from '@chakra-ui/react';
import { useMatches, useParams } from 'react-router-dom';
import { AppContext } from '../../contexts';
import { platformUrlAsName } from '../../utils/platforms';
import useAccountName from '../../hooks/useAccountName';
import Link from './Link';
import Progress from './Progress';

const breadcrumbHeight = '24px';
const breadcrumbSpacing = '10px';

export default function Breadcrumbs() {
  const params = useParams();
  const { appData } = useContext(AppContext);
  const { isLoading: isAccountNameLoading, accountName } = useAccountName(
    params.accountId ?? '',
  );

  /*
   * Be careful when using account name or id. On `/apps/:appId` the breadcrumb
   * refers to the account of the currently selected app, but on
   * `/accounts/:accountId` it refers to the account in the url, which may be
   * different from the account of the currently selected app (and possibly no
   * app is selected).
   */

  const names = useMemo(
    () => ({
      account: params.accountId ? accountName : appData?.account.name,
      app: appData?.name,
      platform: platformUrlAsName(params.platformString),
    }),
    [accountName, params.accountId, params.platformString, appData],
  );

  const ids = useMemo(
    () => ({
      account: appData?.account.id,
      app: params.appId,
      screen: params.screenId,
    }),
    [params.appId, appData, params.screenId],
  );

  const matches = useMatches();
  const crumbs = useMemo(
    () =>
      matches
        // flatMap because some handles have an array of crumbs, some undefined
        .flatMap(
          ({ handle }) =>
            (handle as Record<string, any>)?.crumb(names, ids) ?? [],
        ),
    [matches, ids, names],
  );
  const crumbCount = crumbs.length;

  if (isAccountNameLoading) {
    return <Progress size={breadcrumbHeight} />;
  }

  return (
    <Breadcrumb spacing={breadcrumbSpacing}>
      {crumbs.map(({ label, to }, ind) => {
        const isCurrentPage = ind === crumbCount - 1;
        return (
          <BreadcrumbItem key={label} isCurrentPage={isCurrentPage}>
            {/* not using BreadcrumbLink since it uses href (reloads page) */}
            {isCurrentPage ? (
              // the current page shouldn't be a link (not even a disabled one)
              <Text textStyle="subtitle3">{label}</Text>
            ) : (
              <Link key={label} to={to} label={label} textStyle="subtitle3" />
            )}
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
}
