import React from 'react';
import { Divider as ChakraDivider } from '@chakra-ui/react';

type DividerInput = {
  marginTop?: string;
  marginBottom?: string;
};

export default function Divider({
  marginTop = '40px',
  marginBottom = '40px',
}: DividerInput) {
  return (
    <ChakraDivider
      borderWidth="1px"
      borderColor="neutrals.brandGrey.100"
      marginTop={marginTop}
      marginBottom={marginBottom}
    />
  );
}
