import React from 'react';
import {
  HStack,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from '@chakra-ui/react';

const standardWidth = '1000px';
const dialogPadding = '60px';
const spaceBetweenTitleAndSubtitle = '10px';
const spaceBetweenHeaderAndBody = '40px';
const spaceBetweenHeaderAndBodyWithError = '25px';
export const spaceBetweenBodyAndFooter = '50px';
const spaceBetweenButtons = '30px';

type DialogPropsType = ModalProps & {
  modalProps?: ModalProps;
  modalContentProps?: ModalContentProps;
  title?: string;
  titleColor?: string;
  subtitle?: string;
  subtitleColor?: string;
  subtitleSpacing?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  hasError?: boolean;
};

/** Provide `DialogBody` and `DialogBottom` as children.
 * Typically, `subtitleColor`, `subtitleSpacing`, `width`, and `height`
 * shouldn't be provided - default values usually work.
 */
export default function Dialog(props: DialogPropsType) {
  const {
    modalProps,
    modalContentProps,
    title,
    titleColor = 'neutrals.navigationOutline',
    subtitle,
    subtitleColor = 'neutrals.brandGrey.500',
    subtitleSpacing = spaceBetweenTitleAndSubtitle,
    isOpen = false,
    onClose,
    backgroundColor,
    width = standardWidth,
    height,
    hasError = false,
    children,
  } = props;

  const headerPadding = hasError
    ? `${dialogPadding} ${dialogPadding} ${spaceBetweenHeaderAndBodyWithError} ${dialogPadding}`
    : `${dialogPadding} ${dialogPadding} ${spaceBetweenHeaderAndBody} ${dialogPadding}`;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      {...modalProps}
      variant="dialog"
    >
      <ModalOverlay />
      <ModalContent
        backgroundColor={backgroundColor}
        maxW={width}
        height={height}
        {...modalContentProps}
      >
        {title && (
          <ModalHeader padding={headerPadding}>
            <Text as="h1" textStyle="h1" lineHeight={1} color={titleColor}>
              {title}
            </Text>
            {subtitle && (
              <Text
                textStyle="subtitle2"
                color={subtitleColor}
                paddingTop={subtitleSpacing}
              >
                {subtitle}
              </Text>
            )}
          </ModalHeader>
        )}
        <ModalCloseButton />
        {children}
      </ModalContent>
    </Modal>
  );
}

type DialogChildrenProps = ModalBodyProps;

export function DialogBody(props: DialogChildrenProps) {
  return (
    <ModalBody
      {...props}
      minHeight="3rem"
      padding={`0 ${dialogPadding}`}
      alignItems="center"
    >
      {props.children}
    </ModalBody>
  );
}

export function DialogButtons(props: ModalFooterProps) {
  const { children } = props;
  return (
    <ModalFooter
      {...props}
      padding={`${spaceBetweenBodyAndFooter} ${dialogPadding} ${dialogPadding} ${dialogPadding}`}
    >
      <HStack spacing={spaceBetweenButtons}>{children}</HStack>
    </ModalFooter>
  );
}
