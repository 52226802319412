import React, { Fragment, useContext } from 'react';
import { AppContext, UserContext } from '../../contexts';
import { Role } from '../../gql/gqlRequests';
import NavigationEntry from './NavigationEntry';
import accountsIcon from '../../assets/administration.svg';
import usersIcon from '../../assets/users.svg';
import feedsIcon from '../../assets/content.svg';
import platformsIcon from '../../assets/tv.svg';
import themeIcon from '../../assets/subscription.svg';
import tagsIcon from '../../assets/tags.svg';
import screensIcon from '../../assets/screens.svg';
import { VStack } from '@chakra-ui/react';
import { NavEntry } from '../../types';
import { routes } from '../../types/routes';

const spaceBetweenNavigationEntries = '1px';

export default function NavigationEntries() {
  const { loggedInUser } = useContext(UserContext);
  const { appId } = useContext(AppContext);
  const isAnAppSelected = !!appId;

  return (
    <VStack
      layerStyle="navigationEntries"
      spacing={spaceBetweenNavigationEntries}
    >
      {loggedInUser.role === Role.SystemAdmin ? (
        <NavigationEntry
          entry={NavEntry.ACCOUNTS}
          navTo={routes.accounts}
          src={accountsIcon}
        />
      ) : (
        <NavigationEntry
          entry={NavEntry.APP}
          navTo={routes.appDetail({ appId })}
          src={accountsIcon}
        />
      )}

      {isAnAppSelected && (
        <Fragment>
          <NavigationEntry
            entry={NavEntry.PLATFORMS}
            navTo={routes.appPlatforms({ appId })}
            src={platformsIcon}
          />
          <NavigationEntry
            entry={NavEntry.FEEDS}
            navTo={routes.appFeeds({ appId })}
            src={feedsIcon}
          />
          <NavigationEntry
            entry={NavEntry.SCREENS}
            navTo={routes.appScreens({ appId })}
            src={screensIcon}
          />
          <NavigationEntry
            entry={NavEntry.TAGS}
            navTo={routes.appTags({ appId })}
            src={tagsIcon}
          />
          <NavigationEntry
            entry={NavEntry.THEME}
            navTo={routes.appTheme({ appId })}
            src={themeIcon}
          />
        </Fragment>
      )}

      <NavigationEntry
        entry={NavEntry.USERS}
        navTo={routes.users}
        src={usersIcon}
      />
    </VStack>
  );
}
