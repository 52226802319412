import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import { useMutation } from '@tanstack/react-query';
import Authentication from '../../common/authentication/Authentication';
import NewPassword from '../../common/authentication/NewPassword';
import AuthenticationLoading from '../../common/authentication/AuthenticationLoading';
import RegistrationExpired from './RegistrationExpired';
import {
  loginRequest,
  newPasswordRequest,
} from '../../../support/authentication';
import { getErrorCode } from '../../../utils/errors';
import useNewPasswordValidation from '../../../hooks/useNewPasswordValidation';
import { TokenContext } from '../../../contexts';
import { RequestError, RequestErrorCode } from '../../../types';
import {
  LoginChallenge,
  LoginMutation,
  LoginMutationVariables,
  NewPasswordMutation,
  NewPasswordMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

/**
 * Handles creating a password on registration -- or, if the link is invalid,
 * handles all steps of requesting a new link via email address.
 */
export default function Register() {
  const {
    refreshToken,
    accessToken,
    setRefreshToken,
    setAccessToken,
    clearTokens,
  } = useContext(TokenContext);

  const navigate = useNavigate();
  const { email, password: temporaryPassword } = queryString.parse(
    window.location.search,
    {
      decode: false,
    },
  );
  const hasEmailAndPassword = !!email && !!temporaryPassword;

  // initial automatic login using temporary password

  const loginMutation = useMutation<
    LoginMutation,
    RequestError,
    LoginMutationVariables
  >({
    mutationFn: loginRequest,
  });

  useEffect(
    function loginWithTempPassword() {
      if (hasEmailAndPassword && loginMutation.isIdle) {
        loginMutation.mutate({
          email: `${email}`,
          password: `${temporaryPassword}`,
        });
      }
    },
    [hasEmailAndPassword, loginMutation, email, temporaryPassword],
  );

  // subsequent new password creation

  const newPasswordMutation = useMutation<
    NewPasswordMutation,
    RequestError,
    NewPasswordMutationVariables
  >({
    mutationFn: newPasswordRequest,
    onSuccess: tryToSetTokens,
    onError: applyBackendValidation,
  });

  function attemptToRegister() {
    newPasswordMutation.reset();
    if (isValid) {
      register();
    } else {
      setErrorMessage(frontendErrorMessage);
    }
  }

  function register() {
    clearTokens();
    newPasswordMutation.mutate({
      email: `${email}`,
      newPassword,
      session: loginMutation.data?.login.session ?? '',
    });
  }

  function tryToSetTokens(data: NewPasswordMutation) {
    const { authenticationResult, challengeName } = data.respondToAuthChallenge;
    if (challengeName) {
      throw new Error(
        `Creating first password returned login challenge ${challengeName}`,
      );
    }
    if (authenticationResult?.accessToken) {
      setAccessToken(authenticationResult.accessToken);
    } else {
      throw new Error(
        'Successful login returned neither challenge nor access token. ',
      );
    }
    if (authenticationResult?.refreshToken) {
      setRefreshToken(authenticationResult.refreshToken);
    } else {
      throw new Error(
        'Successful login returned neither challenge nor refresh token. ',
      );
    }
  }

  useEffect(
    function navigateToLandingOnSuccess() {
      if (newPasswordMutation.isSuccess && !!refreshToken && !!accessToken) {
        navigate(routes.root);
      }
    },
    [navigate, newPasswordMutation.isSuccess, refreshToken, accessToken],
  );

  // input state & validation

  const {
    newPassword,
    setNewPassword,
    setConfirmPassword,
    requirements,
    requirementsAreMet,
    isValid,
    isNewInvalid,
    isConfirmInvalid,
    errorMessage: frontendErrorMessage,
  } = useNewPasswordValidation();

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;
  const hasBadUserInputFromBackend =
    newPasswordMutation.isError &&
    getErrorCode(newPasswordMutation.error) === RequestErrorCode.BAD_USER_INPUT;

  function applyBackendValidation(e: RequestError) {
    switch (getErrorCode(e)) {
      case RequestErrorCode.UNAUTHENTICATED:
        navigate(routes.root);
        break;

      // this shouldn't happen, frontend validation should catch all these
      case RequestErrorCode.BAD_USER_INPUT:
        setErrorMessage(strings.authentication.passwordDoesNotMeetRequirements);
        break;

      default:
        setErrorMessage(strings.errors.generic);
        break;
    }
  }

  // UI

  const areSearchParamsPresent =
    !!email &&
    email !== 'null' &&
    !!temporaryPassword &&
    temporaryPassword !== 'null';
  const isLoading =
    loginMutation.isLoading || (areSearchParamsPresent && loginMutation.isIdle);
  const isLoginResponseMissingData =
    loginMutation.isSuccess &&
    (!loginMutation.data.login.session ||
      loginMutation.data.login.challengeName !==
        LoginChallenge.NewPasswordRequired);
  const isLinkInvalid =
    !areSearchParamsPresent ||
    loginMutation.isError ||
    isLoginResponseMissingData;

  if (isLoading) {
    return <AuthenticationLoading />;
  }

  // even if it's invalid, not expired, still show expired component
  if (isLinkInvalid) {
    return <RegistrationExpired />;
  }

  const registerButton = {
    text: strings.authentication.register,
    onClick: attemptToRegister,
    isDisabled: newPasswordMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.register}
      instructions={strings.authentication.registerInstructions}
      buttonProps={registerButton}
      spacingBetweenFlowAndTitle="82px"
    >
      <NewPassword
        setNewPassword={setNewPassword}
        isNewInvalid={isNewInvalid || hasBadUserInputFromBackend}
        setConfirmPassword={setConfirmPassword}
        isConfirmInvalid={isConfirmInvalid || hasBadUserInputFromBackend}
        requirements={requirements}
        requirementsAreMet={requirementsAreMet}
        hasTriedToSubmit={hasTriedToSubmit}
      />
    </Authentication>
  );
}
