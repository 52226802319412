export const spacing = {
  5: '5px',
  10: '10px',
  15: '15px',
  20: '20px',
  25: '25px',
  30: '30px',
  40: '40px',
  50: '50px',
};

export const navBarWidth = '270px';
export const environmentBarHeight = '65px';
export const standardButtonHeight = '50px';
export const smallButtonHeight = '39px';

const tableRowPaddingX = '35px';
export const tableHeaderPadding = `20px ${tableRowPaddingX}`;
export const tableCellPadding = `25px ${tableRowPaddingX}`;

export const inputElementSize = '50px';
export const standardInputHeight = '50px';

export const labelImageDimensions = { width: '320px', height: '180px' };

export const maxThemeGuideImageWidth = '500px';
