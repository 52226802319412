import { nameAsUrl } from '.';
import fireTvCircledIcon from '../assets/fire-tv-circled.svg';
import androidTvCircledIcon from '../assets/android-tv-circled.svg';
import tvOsCircledIcon from '../assets/tv-os-circled.svg';
import rokuTvCircledIcon from '../assets/roku-tv-circled.svg';
import seeAllIcon from '../assets/see-all.svg';
import fireTvIcon from '../assets/fire-tv.svg';
import androidTvIcon from '../assets/android-tv.svg';
import tvOsIcon from '../assets/tv-os.svg';
import rokuTvIcon from '../assets/roku-tv.svg';
import tizenIcon from '../assets/tizen.svg';
import tizenCircledIcon from '../assets/tizen-circled.svg';
import lgIcon from '../assets/lg.svg';
import vizioIcon from '../assets/vizio.svg';
import lgCircledIcon from '../assets/lg-circled.svg';
import vizioCircledIcon from '../assets/vizio-circled.svg';

export type PlatformIconStyle = 'default' | 'circled';

export function platformIcon(
  name: string,
  style: PlatformIconStyle = 'default',
) {
  const useCircledIcon = style === 'circled';
  switch (name) {
    case 'Fire TV':
      return useCircledIcon ? fireTvCircledIcon : fireTvIcon;
    case 'Android TV':
      return useCircledIcon ? androidTvCircledIcon : androidTvIcon;
    case 'tvOS':
      return useCircledIcon ? tvOsCircledIcon : tvOsIcon;
    case 'Roku':
      return useCircledIcon ? rokuTvCircledIcon : rokuTvIcon;
    case 'Tizen':
      return useCircledIcon ? tizenCircledIcon : tizenIcon;
    // for now using the tizen icons
    case 'LG':
      return useCircledIcon ? lgCircledIcon : lgIcon;
    case 'Vizio':
      return useCircledIcon ? vizioCircledIcon : vizioIcon;
    case 'See All':
      return seeAllIcon;
    default:
      return '';
  }
}

export function platformUrlAsName(url?: string) {
  switch (url) {
    case 'fire-tv':
      return 'Fire TV';
    case 'android-tv':
      return 'Android TV';
    case 'tv-os':
      return 'tvOS';
    case 'roku':
      return 'Roku';
    case 'tizen':
      return 'Tizen';
    case 'lg':
      return 'LG';
    case 'vizio':
      return 'Vizio';
    default:
      return '';
  }
}

export function platformNameAsUrl(name: string) {
  if (name === 'tvOS') return 'tv-os';
  return nameAsUrl(name);
}
