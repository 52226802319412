import React, { ChangeEvent, useState } from 'react';
import { Text, Switch, FormControl, FormLabel, Box } from '@chakra-ui/react';

type ToggleProps = {
  label: string;
  description?: string;
  isDisabled?: boolean;
  value?: boolean;
  setValue?: (newValue: boolean) => void;
  defaultValue?: boolean;
  marginTop?: string;
  marginBottom?: string;
};

export default function Toggle({
  label,
  description,
  isDisabled = false,
  value: externalValue,
  setValue: externalSetValue,
  defaultValue = false,
  marginTop,
  marginBottom,
}: ToggleProps) {
  const [value, setValue] = useState(externalValue ?? defaultValue);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newValue = e.target.checked;
    if (externalSetValue) externalSetValue(newValue);
    setValue(newValue);
  }

  return (
    <FormControl>
      <Box marginTop={marginTop} marginBottom={marginBottom}>
        <FormLabel marginBottom="0">
          <Text textStyle="subtitle3">{label}</Text>
        </FormLabel>
        {description && (
          <Text
            textStyle="bodyCopySmall"
            color="neutrals.brandGrey.500"
            marginTop="5px"
          >
            {description}
          </Text>
        )}
        <Switch
          marginTop="10px"
          isDisabled={isDisabled}
          isChecked={externalValue ?? value}
          onChange={handleChange}
          sx={{
            '--switch-track-diff': '35px',
          }}
        />
      </Box>
    </FormControl>
  );
}
