import { createGqlAuthRequest } from '.';
import {
  FileDocument,
  FileQuery,
  FileQueryVariables,
} from '../gql/gqlRequests';
import { UploadFileResponse, UploadFileVariables } from '../types/file';

export async function uploadFileRequest(
  accessToken: string,
  variables: UploadFileVariables,
): Promise<UploadFileResponse> {
  const response = await fetch('/file-upload/upload', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    body: variables.formData,
  });

  return await response.json();
}

export const fileRequest = createGqlAuthRequest<FileQueryVariables, FileQuery>(
  FileDocument,
);
