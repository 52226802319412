import React, { ChangeEventHandler, ReactNode, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  Text,
} from '@chakra-ui/react';

const spaceBetweenLabelAndSelect = '5px';
const spaceBetweenSelectAndMessage = '10px';

type SelectPropsType = {
  label: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLSelectElement>;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  message?: ReactNode;
  children: ReactNode;
  ['data-testid']?: string;
  required?: boolean;
};

/**
 * Pass in options as children:
 * ```
 * <Select>
 *   <option value="value1">Option 1</option>
 *   <option value="value2">Option 2</option>
 * </Select>
 * ```
 * Note: We don't have control over how the browser styles the options.
 */
export default function Select({
  label,
  value: externalValue,
  onChange,
  isInvalid = false,
  isDisabled = false,
  isPermanentlyDisabled = false,
  required = false,
  message,
  children: options,
  ['data-testid']: dataTestId,
}: SelectPropsType) {
  // need value to check whether the 'placeholder' value is selected
  const [internalValue, setInternalValue] = useState('');
  const value = externalValue ?? internalValue;

  return (
    // note: global Chakra themes are overridden in the utils folder
    <FormControl data-testid={dataTestId} isRequired={required}>
      <FormLabel marginBottom={spaceBetweenLabelAndSelect} display="flex">
        <Text textStyle="subtitle3">{label}</Text>
      </FormLabel>
      <ChakraSelect
        value={value}
        onChange={(e) => {
          if (onChange) onChange(e);
          setInternalValue(e.target.value);
        }}
        isInvalid={isInvalid}
        isDisabled={isPermanentlyDisabled || isDisabled}
        width="340px"
        textColor={value === '' ? 'neutrals.brandGrey.500' : undefined}
      >
        {/*
         * note: using this explicit option instead of the placeholder prop of
         * ChakraSelect so that it doesn't show up in the dropdown list;
         * this is also why the text color is explicitly set above
         */}
        <option hidden disabled value="">
          {label}
        </option>
        {options}
      </ChakraSelect>
      {message && <Box marginTop={spaceBetweenSelectAndMessage}>{message}</Box>}
    </FormControl>
  );
}
