import React, { ReactNode } from 'react';
import { Image, Tooltip as ChakraTooltip } from '@chakra-ui/react';
import informationIcon from '../../assets/information.svg';
import { strings } from '../../utils/strings';

type TooltipProps = {
  tipText: string;
  isOpen?: boolean;
  isDisabled?: boolean;
  children?: ReactNode;
};

/** If no children are provided, an info icon will be used. */
export default function Tooltip({
  tipText,
  isOpen,
  isDisabled,
  children,
}: TooltipProps) {
  return (
    <ChakraTooltip
      isOpen={isOpen}
      label={tipText}
      isDisabled={isDisabled}
      placement="right-start"
      aria-label="tooltip"
      // prevents brief flash if mouse travels across quickly
      openDelay={100}
    >
      {children || (
        <Image
          src={informationIcon}
          alt={strings.common.information}
          width="21px"
        />
      )}
    </ChakraTooltip>
  );
}
