import React from 'react';
import { Text, VStack } from '@chakra-ui/react';
import Authentication from './Authentication';
import Progress from '../Progress';
import { strings } from '../../../utils/strings';
import { spacing } from '../../../themes/constants';

export default function AuthenticationLoading() {
  return (
    <Authentication spacingBetweenFlowAndTitle="191px">
      <VStack spacing={spacing[25]}>
        <Progress size="60px" />
        <Text textStyle="h3">{strings.common.loading}</Text>
      </VStack>
    </Authentication>
  );
}
