import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { TokenContext, UserContextType } from '../contexts';
import { RequestError } from '../types';
import { parsedRequestError } from '../utils/errors';
import useAuthRequest from './useAuthRequest';
import {
  LoggedInUserQuery,
  LoggedInUserQueryVariables,
} from '../gql/gqlRequests';
import { loggedInUserRequest } from '../support/users';

export default function useUser(): UserContextType | { isLoading: true } {
  const { refreshToken } = useContext(TokenContext);

  const loggedInUserQueryFn = useAuthRequest<
    LoggedInUserQueryVariables,
    LoggedInUserQuery
  >(loggedInUserRequest);
  const loggedInUserQuery = useQuery<LoggedInUserQuery, RequestError>({
    queryKey: ['loggedInUser', refreshToken],
    queryFn: () => loggedInUserQueryFn({}),
    // shouldn't change while logged in
    staleTime: Infinity,
  });
  const { isError, isLoading, refetch, data } = loggedInUserQuery;

  if (isError) throw parsedRequestError(loggedInUserQuery.error);

  let loggedInUser;
  if (data) {
    const { loggedInUser: loggedInUserRemote } = data;
    const { __typename, apps, phoneNumber, ...rest } = loggedInUserRemote;
    loggedInUser = {
      ...rest,
      phoneNumber: phoneNumber ?? null,
      appId: apps[0]?.id ?? '',
      appIds: apps.map((app) => app.id),
      appName: apps.map((app) => app.name),
      accountName: apps[0]?.account.name ?? '',
    };
  }

  if (!isLoading && loggedInUser) {
    return {
      isLoading,
      loggedInUser,
      refetch,
    };
  } else {
    return {
      isLoading: true,
    };
  }
}
