import { LayerStyle } from '.';
import { spacing } from '../constants';

const errorBar: LayerStyle = {
  backgroundColor: 'system.error.700',
  spacing: spacing[30],
  padding: '10px 15px',
};

export const errorLayerStyles = {
  errorBar,
};
