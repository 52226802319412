import React, {
  ReactNode,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { displayScreenType, scrollToTop } from '../../../utils';
import fastDeepEqual from 'fast-deep-equal';
import { ActionType, FormType, RequestError } from '../../../types';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import Select from '../../common/inputs/Select';
import Form, { FormButtons, FormDivider, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import {
  createNavBarItemRequest,
  navBarItemRequest,
  navBarScreenTypesRequest,
  updateNavBarItemRequest,
} from '../../../support/navBarItems';
import ImageInput from '../../common/inputs/ImageInput';
import Toggle from '../../common/inputs/Toggle';
import useConfigId from '../../../hooks/useConfigId';
import { screensRequest } from '../../../support/screens';
import { AppContext, EnvironmentContext } from '../../../contexts';
import { HStack, Text, VStack } from '@chakra-ui/react';
import Link from '../../common/Link';
import {
  CreateNavBarItemInput,
  CreateNavBarItemMutation,
  CreateNavBarItemMutationVariables,
  ImageType,
  NavBarItemQueryVariables,
  NavBarScreenTypesQuery,
  NavBarScreenTypesQueryVariables,
  ScreenType,
  ScreensQuery,
  ScreensQueryVariables,
  UpdateNavBarItemInput,
  UpdateNavBarItemMutation,
  UpdateNavBarItemMutationVariables,
  NavBarItemQuery,
} from '../../../gql/gqlRequests';
import { routes } from '../../../types/routes';
import { strings } from '../../../utils/strings';
import FormPrompt from '../../common/FormPrompt';
import { isJson } from '../../../utils/validation';

const allScreenTypes = Object.values(ScreenType) as ScreenType[];

// reducer //

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; state: InputState }
  | {
      type: ActionType.SET_STRING;
      key: 'label' | 'iconKey' | 'context';
      value: string;
    }
  | {
      type: ActionType.SET_BOOLEAN;
      key: 'requireAuthenticated' | 'requireSubscribed';
      value: boolean;
    }
  | {
      type: ActionType.SET_SCREEN_TYPE;
      key: 'screenType';
      value: ScreenType | null;
    }
  | {
      type: ActionType.UPDATE_INITIAL_STATE;
    };

type InputStateComplete = {
  requireAuthenticated: boolean;
  requireSubscribed: boolean;
  screenType: ScreenType | null;
  label: string;
  iconKey: string;
  initialState: InputState;
  context: string | null;
};

type InputState = Omit<InputStateComplete, 'initialState'>;

function inputDataReducer(
  state: InputStateComplete,
  action: Action,
): InputStateComplete {
  const updatedState = { ...state };
  switch (action.type) {
    case ActionType.RESET:
      return {
        ...initialInputState,
        initialState: initialInputState,
      };

    case ActionType.SET_ALL:
      return { initialState: action.state, ...action.state };

    case ActionType.SET_BOOLEAN:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_SCREEN_TYPE:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;

    case ActionType.UPDATE_INITIAL_STATE:
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { initialState, ...rest } = updatedState;
      return { ...rest, initialState: rest };

    default:
      throw new Error('Unknown nav bar item input state action');
  }
}

const initialInputState: InputState = {
  requireAuthenticated: false,
  requireSubscribed: false,
  screenType: null,
  context: null,
  label: '',
  iconKey: '',
};

// nav bar item form //

type NavBarItemFormProps = {
  formType: FormType;
};

export default function NavBarItemForm({ formType }: NavBarItemFormProps) {
  const navigate = useNavigate();
  const pageRef = useRef<HTMLDivElement>(null);
  const { appId } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);
  const params = useParams();
  const navBarId = params.navBarId ?? '';
  const { configId, isLoading: isConfigIdLoading, platformId } = useConfigId();
  const [shouldCheckForm, setShouldCheckForm] = useState(true);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  function navigateToScreen(screenId: string) {
    const backPath =
      formType === 'create' ? '../../../../screens' : '../../../../../screens';
    navigate(`${backPath}/${screenId}`, { relative: 'path' });
  }

  // screen types - for screen types dropdown & sub-message

  // to get the screen name for a given type
  const screensQueryFn = useAuthRequest<ScreensQueryVariables, ScreensQuery>(
    screensRequest,
  );
  const screensQuery = useQuery<ScreensQuery, RequestError>({
    queryKey: ['form', 'screens', appId, environment],
    queryFn: () => screensQueryFn({ appId, environment }),
  });

  // to get all in-use screen types
  const navBarItemsScreenTypesQueryFn = useAuthRequest<
    NavBarScreenTypesQueryVariables,
    NavBarScreenTypesQuery
  >(navBarScreenTypesRequest);
  const navBarItemsScreenTypesQuery = useQuery<
    NavBarScreenTypesQuery,
    RequestError
  >({
    queryKey: ['nav-bar-items-screen-types', configId, 'config'],
    queryFn: () => navBarItemsScreenTypesQueryFn({ configId }),
    enabled: formType === 'create' && !isConfigIdLoading,
  });

  const screenTypesWithNavBarItem = useMemo(
    () =>
      navBarItemsScreenTypesQuery.data?.navBarItems.map(
        ({ screenType }) => screenType,
      ),
    [navBarItemsScreenTypesQuery.data?.navBarItems],
  );
  const screenTypesWithoutNavBarItem = useMemo(
    () =>
      screenTypesWithNavBarItem
        ? allScreenTypes.filter(
            (screenType) => !screenTypesWithNavBarItem?.includes(screenType),
          )
        : [],
    [screenTypesWithNavBarItem],
  );

  // create

  const createMutationFn = useAuthRequest<
    CreateNavBarItemMutationVariables,
    CreateNavBarItemMutation
  >(createNavBarItemRequest);
  const createMutation = useMutation<
    CreateNavBarItemMutation,
    RequestError,
    CreateNavBarItemMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  function populateInputData(data: NavBarItemQuery) {
    const { id: _, icon, context, ...rest } = data.navBarItem;
    dispatchInputState({
      type: ActionType.SET_ALL,
      state: {
        ...rest,
        context: !context ? null : JSON.stringify(context),
        iconKey: icon.key,
      },
    });
  }

  const navBarItemQueryFn = useAuthRequest<
    NavBarItemQueryVariables,
    NavBarItemQuery
  >(navBarItemRequest);
  const navBarItemQuery = useQuery<NavBarItemQuery, RequestError>({
    queryKey: ['navBarItem', navBarId],
    queryFn: () => navBarItemQueryFn({ id: navBarId }),
    enabled: formType === 'edit',
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });

  // update

  const updateMutationFn = useAuthRequest<
    UpdateNavBarItemMutationVariables,
    UpdateNavBarItemMutation
  >(updateNavBarItemRequest);
  const updateMutation = useMutation<
    UpdateNavBarItemMutation,
    RequestError,
    UpdateNavBarItemMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // input

  const [inputState, dispatchInputState] = useReducer(inputDataReducer, {
    ...initialInputState,
    initialState: initialInputState,
  });
  const {
    label,
    screenType,
    requireAuthenticated,
    requireSubscribed,
    iconKey,
    context,
  } = inputState;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setShouldCheckForm(true);
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isLabelValid = !!label;
  const isScreenValid = !!screenType;
  const isIconValid = !!iconKey;
  const isContextValid = !context ? true : isJson(context);
  const isInputValid =
    isLabelValid && isScreenValid && isIconValid && isContextValid;

  function isFormDirty() {
    const { initialState, ...actualState } = inputState;

    return !fastDeepEqual(actualState, initialState) ? shouldCheckForm : false;
  }

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    setShouldCheckForm(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { initialState, context, ...correctInputState } = inputState;
    if (!!context) {
      (correctInputState as CreateNavBarItemInput | UpdateNavBarItemInput)[
        'context'
      ] = JSON.parse(context);
    }
    dispatchInputState({ type: ActionType.UPDATE_INITIAL_STATE });
    if (formType === 'create') {
      createMutation.mutate({
        // submit button disabled until configId has loaded, so won't be ''
        configId,
        // input.screenType won't be null due to isInputValid check above
        input: correctInputState as CreateNavBarItemInput,
      });
    } else {
      const { screenType: _, ...updateInput } = correctInputState;
      updateMutation.mutate({
        id: navBarId,
        input: updateInput as UpdateNavBarItemInput,
      });
    }
  }

  // UI

  const title =
    formType === 'create'
      ? strings.platforms.createNavBarItem
      : strings.platforms.editNavBarItem;
  const submitText =
    formType === 'create'
      ? strings.platforms.createNavBarItem
      : strings.common.saveChanges;
  const screen = screensQuery.data?.screens.find(
    (screen) =>
      screen.type === screenType &&
      screen.platforms.some((plt) => plt.id === platformId),
  );
  const screenMessage: ReactNode =
    screensQuery.isLoading || !screenType
      ? null
      : screen?.name
      ? HasScreenMessage(screen.name, () => navigateToScreen(screen.id))
      : NoScreenMessage(appId);
  /*
   * We want to omit already-used screen types - but in edit mode, the screen
   * type is not editable and is an already-used screen type, so we need to
   * show only that single option (once it has loaded).
   */
  const screenTypeOptions =
    formType === 'create'
      ? screenTypesWithoutNavBarItem
      : !!screenType
      ? [screenType]
      : [];

  const isLoading =
    // initial data for both form types
    screensQuery.isLoading ||
    // initial data for create form type
    (formType === 'create' &&
      (isConfigIdLoading || navBarItemsScreenTypesQuery.isLoading)) ||
    // initial data for edit form type
    (formType === 'edit' && navBarItemQuery.isLoading) ||
    // mutations
    createMutation.isLoading ||
    updateMutation.isLoading;

  if (navBarItemQuery.isError) throw parsedRequestError(navBarItemQuery.error);
  if (screensQuery.isError) throw parsedRequestError(screensQuery.error);
  if (navBarItemsScreenTypesQuery.isError)
    throw parsedRequestError(navBarItemsScreenTypesQuery.error);

  return (
    <Page
      isForm
      withEnvSearchParam
      disallowProduction
      withBack
      title={title}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage}>
        <FormPrompt isFormDirty={isFormDirty()} />
        <FormError message={errorMessage} />
        <Select
          label={strings.screens.screen}
          isInvalid={
            isValidationActive &&
            (!isScreenValid || hasBackendError('screenType'))
          }
          isDisabled={isLoading}
          // cannot edit screen type
          isPermanentlyDisabled={formType === 'edit'}
          value={screenType ?? undefined}
          message={screenMessage}
          onChange={(e) =>
            dispatchInputState({
              type: ActionType.SET_SCREEN_TYPE,
              key: 'screenType',
              value: e.target.value as ScreenType,
            })
          }
        >
          {screenTypeOptions.map((screenType) => (
            <option key={screenType} value={screenType}>
              {displayScreenType(screenType as ScreenType)}
            </option>
          ))}
        </Select>
        <StandardInput
          label={strings.common.label}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isLabelValid || hasBackendError('label'))
          }
          isDisabled={isLoading}
          value={label}
          setSanitizedValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_STRING,
              key: 'label',
              value,
            })
          }
        />
        <FormDivider />
        <ImageInput
          label={strings.common.icon}
          imageType={ImageType.Standard}
          value={iconKey}
          onChange={(value) =>
            dispatchInputState({
              type: ActionType.SET_STRING,
              key: 'iconKey',
              value,
            })
          }
          isInvalid={
            isValidationActive && (!isIconValid || hasBackendError('iconKey'))
          }
          isDisabled={isLoading}
        />
        <FormDivider />
        <Toggle
          label={strings.common.authentication}
          description={strings.platforms.navBarAuthenticationDescription}
          isDisabled={isLoading}
          value={requireAuthenticated}
          setValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_BOOLEAN,
              key: 'requireAuthenticated',
              value,
            })
          }
        />
        <Toggle
          label={strings.common.subscription}
          description={strings.platforms.navBarSubscriptionDescription}
          isDisabled={isLoading}
          value={requireSubscribed}
          setValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_BOOLEAN,
              key: 'requireSubscribed',
              value,
            })
          }
        />
        <FormDivider />
        <StandardInput
          label="Context"
          tooltipText='{"mediaId": "ed12345"}'
          labelAsPlaceholder
          isDisabled={isLoading}
          value={context ?? undefined}
          isInvalid={
            isValidationActive &&
            (!isContextValid || hasBackendError('context'))
          }
          setSanitizedValue={(value) =>
            dispatchInputState({
              value: value,
              type: ActionType.SET_STRING,
              key: 'context',
            })
          }
        />
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}

function NoScreenMessage(appId: string) {
  return (
    <VStack spacing="0" align="left" textStyle="bodyCopy">
      <Text>{strings.platforms.noScreenOnPlatformForType}</Text>
      <HStack spacing="0">
        <Text pr="5px">{strings.platforms.toCreateEditScreenGoTo}</Text>
        <Link
          label={strings.screens.screens}
          to={routes.appScreens({ appId })}
          textStyle="subtitle3"
        />
        <Text>.</Text>
      </HStack>
    </VStack>
  );
}

function HasScreenMessage(screenName: string, redirectFunc: () => void) {
  return (
    <HStack spacing="5px" display="flex" flexDirection="column" align="start">
      <Text textStyle="subtitle3">{`${strings.platforms.screenName}:`}</Text>
      <Text
        textStyle="bodyCopy"
        as="u"
        onClick={redirectFunc}
        style={{ cursor: 'pointer' }}
      >
        {screenName}
      </Text>
    </HStack>
  );
}
