import React, { Fragment, useContext, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  ConfigQuery,
  Environment,
  SubscriptionProduct,
} from '../../../gql/gqlRequests';
import { useNavigate } from 'react-router-dom';
import { EnvironmentContext } from '../../../contexts';
import StandardTable from '../../common/tables/StandardTable';
import DeleteSubscriptionDialog from './DeleteSubscriptionDialog';
import { strings } from '../../../utils/strings';
import { Route } from '../../../types/routes';
import OverflowCell from '../../common/tables/OverflowCell';

type SubscriptionRow = Pick<
  SubscriptionProduct,
  'id' | 'name' | 'createdAt' | 'description' | 'sku'
> & { status: string };

const columnHelper = createColumnHelper<SubscriptionRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.platforms.subscription,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('description', {
    header: strings.common.description,
    cell: (info) => (
      <OverflowCell
        text={info.getValue() || strings.common.emptyPlaceholder}
        noOfLines={2}
      />
    ),
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('sku', {
    header: strings.common.sku,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('status', {
    header: strings.common.status,
  }),
];

type SubscriptionsProps = {
  subscriptions: ConfigQuery['app']['config']['subscriptionProducts'];
  refetchConfig: () => void;
};

export default function Subscriptions({
  subscriptions,
  refetchConfig,
}: SubscriptionsProps) {
  const { environment } = useContext(EnvironmentContext);
  const navigate = useNavigate();

  // table data

  const subscriptionsData = useMemo(
    () =>
      subscriptions.map(({ isInUse, ...rest }) => ({
        ...rest,
        status: isInUse ? strings.common.enabled : strings.common.disabled,
        hideDelete: isInUse,
      })),
    [subscriptions],
  );

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();

  // UI

  const canModify = environment !== Environment.Production;

  return (
    <Fragment>
      <StandardTable
        title={strings.platforms.allSubscriptions}
        data={subscriptionsData}
        columns={columns}
        onEditRow={
          canModify
            ? (id: string) => navigate(`${id}/${Route.Edit}`)
            : undefined
        }
        onDeleteRow={canModify ? setIdToDelete : undefined}
        onCreate={canModify ? () => navigate(Route.Create) : undefined}
        createButtonTitle={strings.platforms.createSubscription}
        boldColumn="name"
        marginTop="40px"
      />

      <DeleteSubscriptionDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        onSuccess={refetchConfig}
        onClose={() => setIdToDelete(undefined)}
      />
    </Fragment>
  );
}
