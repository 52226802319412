import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import queryString from 'query-string';
import { useNavigate } from 'react-router-dom';

import Authentication from '../../common/authentication/Authentication';
import NewPassword from '../../common/authentication/NewPassword';
import ResetPasswordLinkExpired from './ResetPasswordLinkExpired';
import useNewPasswordValidation from '../../../hooks/useNewPasswordValidation';
import { RequestError, RequestErrorCode } from '../../../types';
import AuthenticationLoading from '../../common/authentication/AuthenticationLoading';
import { getErrorCode } from '../../../utils/errors';
import {
  ConfirmNewPasswordMutation,
  ConfirmNewPasswordMutationVariables,
  ValidateSessionMutation,
  ValidateSessionMutationVariables,
} from '../../../gql/gqlRequests';
import {
  confirmNewPasswordRequest,
  validateSessionRequest,
} from '../../../support/authentication';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

/**
 * A form to create a new password. Unless the session is expired,
 * in which case show message saying it is expired.
 */
export default function CreateNewPassword() {
  const navigate = useNavigate();

  // use query-string instead of react-router-dom for ability to not decode
  // NOTE: not decoding means null will come back as a string... which is truthy...
  const { session, code } = queryString.parse(window.location.search, {
    decode: false,
  });

  // initial automatic session validation

  const validationMutation = useMutation<
    ValidateSessionMutation,
    RequestError,
    ValidateSessionMutationVariables
  >({
    mutationFn: validateSessionRequest,
  });

  useEffect(
    function validateSession() {
      if (session && validationMutation.isIdle) {
        validationMutation.mutate({ session: `${session}` });
      }
    },
    [session, validationMutation],
  );

  // subsequent creation of new password

  const confirmNewPasswordMutation = useMutation<
    ConfirmNewPasswordMutation,
    RequestError,
    ConfirmNewPasswordMutationVariables
  >({
    mutationFn: confirmNewPasswordRequest,
    onSuccess: () => navigate(routes.root),
    onError: applyBackendValidation,
  });

  function attemptToResetPassword() {
    confirmNewPasswordMutation.reset();
    if (isValid) {
      resetPassword();
    } else {
      setErrorMessage(frontendErrorMessage);
    }
  }

  function resetPassword() {
    confirmNewPasswordMutation.mutate({
      session: `${session}`,
      password: newPassword,
      code: `${code}`,
    });
  }

  // input state & validation

  const {
    newPassword,
    setNewPassword,
    setConfirmPassword,
    requirements,
    requirementsAreMet,
    isValid,
    isNewInvalid,
    isConfirmInvalid,
    errorMessage: frontendErrorMessage,
  } = useNewPasswordValidation();

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;
  const hasBadUserInputFromBackend =
    confirmNewPasswordMutation.isError &&
    getErrorCode(confirmNewPasswordMutation.error) ===
      RequestErrorCode.BAD_USER_INPUT;

  function applyBackendValidation(e: RequestError) {
    switch (getErrorCode(e)) {
      case RequestErrorCode.UNAUTHENTICATED:
        navigate(routes.root);
        break;

      // this shouldn't happen, frontend validation should catch all these
      case RequestErrorCode.BAD_USER_INPUT:
        setErrorMessage(strings.authentication.passwordDoesNotMeetRequirements);
        break;

      default:
        setErrorMessage(strings.errors.generic);
        break;
    }
  }

  // UI

  // since they are not decoded, they may come back as the string 'null'
  const hasSessionAndCode =
    !!session && session !== 'null' && !!code && code !== 'null';
  const isLoading =
    validationMutation.isLoading ||
    (hasSessionAndCode && validationMutation.isIdle);
  const isLinkInvalid = !hasSessionAndCode || validationMutation.isError;

  if (isLoading) {
    return <AuthenticationLoading />;
  }

  // Display expired page regardless of 'URI malformed' error or 'Session is expired' error
  if (isLinkInvalid) {
    return <ResetPasswordLinkExpired />;
  }

  const resetPasswordButton = {
    text: strings.authentication.resetPassword,
    onClick: attemptToResetPassword,
    isDisabled: confirmNewPasswordMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.createNewPassword}
      buttonProps={resetPasswordButton}
    >
      <NewPassword
        setNewPassword={setNewPassword}
        isNewInvalid={isNewInvalid || hasBadUserInputFromBackend}
        setConfirmPassword={setConfirmPassword}
        isConfirmInvalid={isConfirmInvalid || hasBadUserInputFromBackend}
        requirements={requirements}
        requirementsAreMet={requirementsAreMet}
        hasTriedToSubmit={hasTriedToSubmit}
      />
    </Authentication>
  );
}
