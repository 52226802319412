import { LayerStyle } from '.';
import {
  environmentBarHeight,
  labelImageDimensions,
  navBarWidth,
} from '../constants';

const pageContainer: LayerStyle = {
  position: 'absolute',
  top: '0px',
  left: navBarWidth,
  height: '100%',
  width: `calc(100% - ${navBarWidth})`,
  minWidth: '1164px',
  paddingX: '62px',
  backgroundColor: 'neutrals.brandGrey.50',
  overflow: 'auto',
};

const pageContainerWithEnvironmentBar: LayerStyle = {
  ...pageContainer,
  top: environmentBarHeight,
  height: `calc(100% - ${environmentBarHeight})`,
};

const detailsSection: LayerStyle = {
  padding: '40px 60px',
  borderRadius: '10px',
  backgroundColor: 'neutrals.staticWhite',
};

const imageNotFound: LayerStyle = {
  ...labelImageDimensions,
  backgroundColor: 'neutrals.brandGrey.50',
};

const labelImage: LayerStyle = {
  ...labelImageDimensions,
  objectFit: 'contain',
  objectPosition: 'left',
};

export const pageLayerStyles = {
  pageContainer,
  pageContainerWithEnvironmentBar,
  detailsSection,
  imageNotFound,
  labelImage,
};
