import React, { MouseEventHandler, useState } from 'react';
import { flexRender, Row } from '@tanstack/react-table';
import { useDrag, useDrop } from 'react-dnd';
import { chakra, Image, Td, Tr } from '@chakra-ui/react';
import { DragAndDropRowData } from './DragAndDropTable';
import TableRowMenu from './TableRowMenu';
import hamburgerIcon from '../../../assets/hamburger.svg';
import { strings } from '../../../utils/strings';

// based on: https://tanstack.com/table/v8/docs/examples/react/row-dnd

type DragAndDropRowProps<Data extends DragAndDropRowData> = {
  row: Row<Data>;
  onEdit?: MouseEventHandler;
  onDelete?: MouseEventHandler;
  reorderRow: (draggedRowId: string, targetRowId: string) => void;
  boldColumn?: keyof Data;
  isDisabled?: boolean;
  isReorderDisabled?: boolean;
};

export default function DragAndDropRow<Data extends DragAndDropRowData>({
  row,
  onEdit,
  onDelete,
  reorderRow,
  boldColumn,
  isDisabled = false,
  isReorderDisabled = false,
}: DragAndDropRowProps<Data>) {
  const dropRef = useDrop({
    accept: 'row',
    drop: (draggedRow: Row<Data>) =>
      reorderRow(draggedRow.original.id, row.original.id),
  })[1];

  const [{ isDragging }, dragRef] = useDrag({
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => row,
    type: 'row',
  });

  // disable dragging when menu is open (some browsers will mess up the drag preview)
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // disable dragging when hovering on text (to allow copying)
  const [isOverText, setIsOverText] = useState(false);
  const isDraggable = !isReorderDisabled && !isMenuOpen && !isOverText;

  return (
    <Tr
      ref={(el) => {
        if (isDraggable) dragRef(el);
        dropRef(el);
      }}
      style={{
        opacity: isDragging ? 0.25 : 1,
      }}
      _hover={{
        backgroundColor: 'neutrals.brandGrey.hover',
        cursor: isDraggable ? 'pointer' : 'default',
      }}
    >
      {/* if the width is not specified, the table will automatically scale it;
          we need it to be fixed - it needs to be the icon width (24) plus
          the padding on each side (30 + 30) */}
      <Td width="84px" borderBottom="none">
        {!isReorderDisabled && (
          <Image
            minWidth="24px"
            src={hamburgerIcon}
            alt={strings.common.reorder}
          />
        )}
      </Td>

      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          textStyle={cell.column.id === boldColumn ? 'subtitle3' : 'bodyCopy'}
          borderBottom="none"
        >
          <chakra.span
            onMouseEnter={() => setIsOverText(true)}
            onMouseLeave={() => setIsOverText(false)}
            _hover={{ cursor: 'text' }}
          >
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </chakra.span>
        </Td>
      ))}

      {/* if the width is not specified, the table will automatically scale it;
          we need it to be fixed - it needs to be the icon width (25) plus
          the padding on each side (30 + 30) */}
      <Td width="85px" borderBottom="none">
        {(onEdit || onDelete) && (
          <TableRowMenu
            isMenuOpen={isMenuOpen}
            onClose={() => setIsMenuOpen(false)}
            onOpen={() => setIsMenuOpen(true)}
            onEdit={onEdit}
            onDelete={onDelete}
            isDisabled={isDisabled}
          />
        )}
      </Td>
    </Tr>
  );
}
