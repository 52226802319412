import { Text, VStack } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useState } from 'react';
import { UserContext } from '../../../contexts';
import {
  UpdateMfaPreferenceMutation,
  UpdateMfaPreferenceMutationVariables,
} from '../../../gql/gqlRequests';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { updateMFAPreferenceRequest } from '../../../support/users';
import { RequestError } from '../../../types';
import { strings } from '../../../utils/strings';
import { validatePhone } from '../../../utils/validation';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import PhoneInput from '../../common/inputs/PhoneInput';
import Toggle from '../../common/inputs/Toggle';

type TwoFactorAuthenticationDialogProps = {
  isOpen: boolean;
  onClose(): void;
  isTwoFactorEnabled: boolean;
  phoneNumber: string;
};

export function TwoFactorAuthenticationDialog({
  isOpen,
  onClose,
  isTwoFactorEnabled,
  phoneNumber,
}: TwoFactorAuthenticationDialogProps) {
  const { refetch } = useContext(UserContext);
  const [isTwoFactorEnabledClone, setIsTwoFactorEnabledClone] =
    useState(isTwoFactorEnabled);
  const [phoneNumberClone, setPhoneNumberClone] = useState(phoneNumber);
  const [isInvalidPhoneNumber, setIsInvalidPhoneNumber] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const updateMfaPreferenceMutationFn = useAuthRequest<
    UpdateMfaPreferenceMutationVariables,
    UpdateMfaPreferenceMutation
  >(updateMFAPreferenceRequest);
  const updateMfaPreferenceMutation = useMutation<
    UpdateMfaPreferenceMutation,
    RequestError,
    UpdateMfaPreferenceMutationVariables
  >({
    mutationFn: updateMfaPreferenceMutationFn,
    onSuccess: onSaveSuccess,
    onError: onUpdateError,
  });

  const resetData = () => {
    setIsTwoFactorEnabledClone(isTwoFactorEnabled);
    setPhoneNumberClone(phoneNumber);
    setErrorMessage('');
    updateMfaPreferenceMutation.reset();
  };

  const handleOnClose = () => {
    resetData();
    onClose();
  };

  const handleOnSave = () => {
    if (validatePhone(phoneNumberClone)) {
      setIsInvalidPhoneNumber(false);
      updateMfaPreferenceMutation.mutate({
        useMFA: isTwoFactorEnabledClone,
        phoneNumber: phoneNumberClone,
      });
    } else {
      setIsInvalidPhoneNumber(true);
    }
  };

  function onSaveSuccess(data: UpdateMfaPreferenceMutation) {
    if (data.updateMFAPreference) {
      if (refetch) refetch();
      onClose();
    } else {
      setErrorMessage(strings.errors.generic); // TODO: update generic error message
    }
  }

  function onUpdateError(_error: RequestError) {
    setErrorMessage(strings.errors.generic); // TODO: update generic error message
  }

  const renderMfaInputs = () => {
    return (
      <VStack spacing="40px">
        <Toggle
          label={strings.profile.twoFactorAuthentication}
          description={strings.profile.twoFactorAuthenticationDescription}
          value={isTwoFactorEnabledClone}
          setValue={setIsTwoFactorEnabledClone}
        />
        {!isTwoFactorEnabled && (
          <PhoneInput
            label={strings.profile.mobilePhoneNumber}
            description={strings.profile.mobilePhoneNumberDescription}
            value={phoneNumberClone}
            setSanitizedValue={(value) => setPhoneNumberClone(value)}
            isInvalid={isInvalidPhoneNumber}
          />
        )}
      </VStack>
    );
  };

  const renderErrorMessage = () => {
    return <Text>{errorMessage}</Text>;
  };

  return (
    <Dialog
      title={
        updateMfaPreferenceMutation.isError
          ? strings.errors.error
          : strings.profile.editTwoFactorAuthentication
      }
      isOpen={isOpen}
      onClose={handleOnClose}
    >
      <DialogBody>
        {updateMfaPreferenceMutation.isError
          ? renderErrorMessage()
          : renderMfaInputs()}
      </DialogBody>
      <DialogButtons>
        {!updateMfaPreferenceMutation.isError && (
          <PrimaryButton
            label={strings.common.saveChanges}
            onClick={handleOnSave}
            isDisabled={
              updateMfaPreferenceMutation.isLoading ||
              isTwoFactorEnabled === isTwoFactorEnabledClone
            }
          />
        )}
      </DialogButtons>
    </Dialog>
  );
}
