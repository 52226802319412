import React from 'react';
import AuthenticationInfo from '../../common/authentication/AuthenticationInfo';
import envelopeIcon from '../../../assets/envelope.svg';
import { strings } from '../../../utils/strings';

/**
 * A sub-component of `RegistrationExpired`, showing that a new link was sent.
 */
export default function RegistrationLinkSent() {
  return (
    <AuthenticationInfo
      icon={envelopeIcon}
      iconAlt={strings.common.email}
      title={strings.authentication.checkYourInbox}
      subtitle={strings.authentication.registrationLinkSent}
    />
  );
}
