import React, { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import { useNavigate, useParams } from 'react-router-dom';
import useAuthRequest from '../../../hooks/useAuthRequest';
import StandardTable from '../../common/tables/StandardTable';
import Page from '../../common/Page';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import { AppContext } from '../../../contexts';
import useAccountName from '../../../hooks/useAccountName';
import TextButton from '../../common/buttons/TextButton';
import {
  AccountAppsQuery,
  AccountAppsQueryVariables,
  App,
} from '../../../gql/gqlRequests';
import { accountAppsRequest } from '../../../support/apps';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';
import OverflowCell from '../../common/tables/OverflowCell';

type AppRow = Pick<App, 'id' | 'name' | 'website' | 'createdAt'>;

const columnHelper = createColumnHelper<AppRow>();

export default function Apps() {
  const { setApp } = useContext(AppContext);
  const navigate = useNavigate();
  const params = useParams();
  const accountId = params.accountId ?? '';
  const { isLoading: isAccountNameLoading, accountName } =
    useAccountName(accountId);

  // accounts query

  const accountAppsQueryFn = useAuthRequest<
    AccountAppsQueryVariables,
    AccountAppsQuery
  >(accountAppsRequest);
  const accountAppsQuery = useQuery<AccountAppsQuery, RequestError>({
    queryKey: ['apps', accountId],
    queryFn: () => accountAppsQueryFn({ id: accountId }),
  });

  // table data

  const columns = useMemo(
    () => [
      columnHelper.accessor('createdAt', {}),
      columnHelper.accessor('id', {}),
      columnHelper.accessor('name', {
        header: strings.apps.app,
        cell: (info) => (
          <TextButton
            label={info.getValue()}
            onClick={() => {
              const appId = info.row.original.id;
              setApp(appId);
              navigate(routes.appDetail({ appId }));
            }}
            textStyle="subtitle3"
          />
        ),
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('website', {
        header: strings.common.url,
        cell: (info) => (
          <OverflowCell
            text={info.getValue() || strings.common.emptyPlaceholder}
          />
        ),
        sortingFn: 'alphanumeric',
      }),
    ],
    [setApp, navigate],
  );

  const appsData = accountAppsQuery.data?.apps ?? [];

  // UI

  if (accountAppsQuery.isError)
    throw parsedRequestError(accountAppsQuery.error);

  return (
    <Page
      isLoading={isAccountNameLoading}
      withBreadcrumbs
      title={accountName}
      subtitle={strings.apps.selectAppDescription}
    >
      <StandardTable
        title={strings.apps.allApps}
        isLoading={accountAppsQuery.isLoading}
        data={appsData}
        columns={columns}
        canSearch={false}
        onCreate={() => navigate(routes.createAppForAccount({ accountId }))}
        createButtonTitle={strings.apps.createApp}
        boldColumn="name"
      />
    </Page>
  );
}
