import React, { Fragment, useMemo } from 'react';
import { useMutation } from '@tanstack/react-query';
import { deleteScreenRequest } from '../../../support/screens';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { RequestError } from '../../../types';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import UnableToDeleteDialog from '../../common/dialogs/UnableToDeleteDialog';
import {
  DeleteScreenMutation,
  DeleteScreenMutationVariables,
  Screen,
} from '../../../gql/gqlRequests';

type DeleteScreenProps = {
  isOpen: boolean;
  id?: string;
  screens?: Pick<Screen, 'id' | 'type' | 'platforms' | 'name'>[];
  onClose: () => void;
  onSuccess: (data: DeleteScreenMutation) => void;
};

export default function DeleteScreenDialog({
  isOpen,
  id = '',
  screens,
  onClose,
  onSuccess,
}: DeleteScreenProps) {
  const screen = useMemo(
    () => screens?.find((screen) => screen.id === id),
    [id, screens],
  );
  const associatedPlatforms = screen?.platforms ?? [];
  const isUsedOnPlatforms = associatedPlatforms
    ? associatedPlatforms.length > 0
    : undefined;
  const canDeleteScreen = !!screen && !isUsedOnPlatforms;

  const deleteMutationFn = useAuthRequest<
    DeleteScreenMutationVariables,
    DeleteScreenMutation
  >(deleteScreenRequest);
  const deleteMutation = useMutation<
    DeleteScreenMutation,
    RequestError,
    DeleteScreenMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data) => {
      onSuccess(data);
      closeAndReset();
    },
  });

  function deleteScreen() {
    deleteMutation.mutate({ id });
  }

  function closeAndReset() {
    onClose();
    deleteMutation.reset();
  }

  if (!!id && !screen) {
    throw new Error('Cannot find screen to delete');
  }

  return (
    <Fragment>
      <StandardDeletionDialog
        itemType="Screen"
        isOpen={isOpen && canDeleteScreen}
        isLoading={deleteMutation.isLoading}
        onDelete={deleteScreen}
        onClose={closeAndReset}
      />
      <UnableToDeleteDialog
        itemType="Screen"
        isOpen={isOpen && !canDeleteScreen}
        onClose={closeAndReset}
        platforms={associatedPlatforms}
      />
    </Fragment>
  );
}
