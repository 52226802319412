import React, { useContext, useMemo, useState } from 'react';
import { Box, Text } from '@chakra-ui/react';
import CreateButton from '../../common/buttons/CreateButton';
import DragAndDropTable from '../../common/tables/DragAndDropTable';
import { createColumnHelper } from '@tanstack/react-table';
import { displayEnum } from '../../../utils';
import ScreenFeedFormDialog from './ScreenFeedFormDialog';
import { Screen_ScreenFeedInputWithId } from '../../../types/screens';
import { AppContext, EnvironmentContext } from '../../../contexts';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import { parsedRequestError } from '../../../utils/errors';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import {
  FeedsQuery,
  FeedsQueryVariables,
  Screen_ScreenFeedInput,
} from '../../../gql/gqlRequests';
import { feedsRequest } from '../../../support/feeds';
import { strings } from '../../../utils/strings';
import TextButton from '../../common/buttons/TextButton';
import { routes } from '../../../types/routes';

type ScreenFeedRow = {
  order: number;
  id: string;
  feedId: string;
  feedTitle: string;
  layout: string;
};

export type ScreenFeedsInputProps = {
  isScreenFormLoading: boolean;
  // we don't use `ScreenFeed`s because we always need them ready for a form
  value: Screen_ScreenFeedInputWithId[];
  isInvalid: boolean;
  addScreenFeed: (
    screenFeed: Screen_ScreenFeedInput & { feedTitle: string },
  ) => void;
  updateScreenFeed: (screenFeed: Screen_ScreenFeedInputWithId) => void;
  removeScreenFeed: (id: string) => void;
  reorderScreenFeeds: (draggedId: string, targetId: string) => void;
};

const columnHelper = createColumnHelper<ScreenFeedRow>();

export default function ScreenFeedsInput({
  isScreenFormLoading,
  value,
  isInvalid,
  addScreenFeed,
  updateScreenFeed,
  reorderScreenFeeds,
  removeScreenFeed,
}: ScreenFeedsInputProps) {
  const { environment } = useContext(EnvironmentContext);
  const { appId } = useContext(AppContext);

  //table columns
  const columns = useMemo(
    () => [
      columnHelper.accessor('order', {}),
      columnHelper.accessor('id', {}),
      columnHelper.accessor('feedId', {
        header: strings.feeds.feed,
        sortingFn: 'alphanumeric',
        cell: (info) => (
          <TextButton
            label={info.row.original.feedTitle}
            onClick={() => {
              const feedId = info.row.original.feedId;
              window.open(
                routes.appFeedDetail({ appId: appId, feedId: feedId }),
              );
            }}
          />
        ),
      }),
      columnHelper.accessor('layout', {
        header: strings.screens.layoutType,
        cell: (info) => displayEnum(info.getValue()),
        sortingFn: 'alphanumeric',
      }),
    ],
    [appId],
  );

  const [activeScreenFeed, setActiveScreenFeed] =
    useState<Screen_ScreenFeedInputWithId>();
  const isEditingExistingScreenFeed = !!activeScreenFeed;

  // feeds query - need this info before the user can create/update screen feed

  const feedsQueryFn = useAuthRequest<FeedsQueryVariables, FeedsQuery>(
    feedsRequest,
  );
  const feedsQuery = useQuery<FeedsQuery, RequestError>({
    queryKey: ['screen-feeds-input', 'feeds', appId, environment],
    queryFn: () => feedsQueryFn({ appId, environment }),
  });

  const allFeeds = useMemo(
    () =>
      feedsQuery.data?.feedsByApp.edges.map(({ node: { id, name } }) => ({
        id,
        name,
      })),
    [feedsQuery.data],
  );
  const usedFeedIds = useMemo(() => value.map(({ feedId }) => feedId), [value]);
  const unusedFeeds = useMemo(
    () => allFeeds?.filter(({ id }) => !usedFeedIds.includes(id)),
    [allFeeds, usedFeedIds],
  );
  const areThereUnusedFeeds = unusedFeeds && unusedFeeds.length > 0;
  const feedOfActiveScreenFeed = allFeeds?.find(
    ({ id }) => id === activeScreenFeed?.feedId,
  );

  const feedOptions = feedOfActiveScreenFeed
    ? [feedOfActiveScreenFeed]
    : unusedFeeds;

  // table data

  const screenFeedData = useMemo(
    () =>
      value.map((screenFeed, ind) => {
        const { id, feedId, feedTitle, layout } = screenFeed;
        return {
          // should be displayed in the order provided, so use ind for `order`
          order: ind,
          id,
          feedId,
          feedTitle,
          layout,
        };
      }),
    [value],
  );

  const [currentlyOpenDialog, setCurrentlyOpenDialog] = useState<
    'form' | 'unable'
  >();
  const closeDialog = () => setCurrentlyOpenDialog(undefined);

  function onSave(screenFeed: Screen_ScreenFeedInput & { feedTitle: string }) {
    if (isEditingExistingScreenFeed) {
      updateScreenFeed({ id: activeScreenFeed.id, ...screenFeed });
      setActiveScreenFeed(undefined);
    } else {
      addScreenFeed(screenFeed);
    }
  }

  function onClose() {
    closeDialog();
    setActiveScreenFeed(undefined);
  }

  // UI

  const formTitle = isEditingExistingScreenFeed
    ? strings.screens.editScreenFeed
    : strings.screens.createScreenFeed;
  const saveButtonLabel = isEditingExistingScreenFeed
    ? strings.common.saveChanges
    : strings.screens.createScreenFeed;

  const isLoading = isScreenFormLoading || feedsQuery.isLoading;

  if (feedsQuery.isError) throw parsedRequestError(feedsQuery.error);

  return (
    <Box>
      <Text as="h2" textStyle="subtitle3">
        {strings.screens.screenFeeds}
      </Text>
      <Box marginTop="5px">
        <CreateButton
          label={strings.screens.createScreenFeed}
          onClick={() =>
            setCurrentlyOpenDialog(areThereUnusedFeeds ? 'form' : 'unable')
          }
          isDisabled={isLoading}
        />
      </Box>
      {isInvalid && (
        <Text
          textStyle="bodyCopySmall"
          color="system.error.700"
          marginTop="5px"
        >
          {strings.screens.mustBeOneScreenFeed}
        </Text>
      )}
      {!!screenFeedData.length && (
        <Box maxWidth="743px" marginTop="15px">
          <DragAndDropTable
            isLoading={isLoading}
            data={screenFeedData}
            columns={columns}
            reorderData={reorderScreenFeeds}
            onEditRow={(selectedId) => {
              setActiveScreenFeed(value.find(({ id }) => id === selectedId));
              setCurrentlyOpenDialog('form');
            }}
            onDeleteRow={removeScreenFeed}
            boldColumn="feedId"
          />
        </Box>
      )}

      <ScreenFeedFormDialog
        isOpen={!!feedOptions && currentlyOpenDialog === 'form'}
        formTitle={formTitle}
        saveButtonLabel={saveButtonLabel}
        onClose={onClose}
        onSave={onSave}
        initialInputData={activeScreenFeed}
        feedOptions={feedOptions ?? []}
        isFeedInputDisabled={isEditingExistingScreenFeed}
      />

      <Dialog
        title={strings.screens.unableToCreateScreenFeed}
        isOpen={currentlyOpenDialog === 'unable'}
        onClose={onClose}
      >
        <DialogBody>
          <Text>{strings.screens.allScreenFeedsUsedMessage}</Text>
        </DialogBody>
        <DialogButtons>
          <PrimaryButton label={strings.common.goBack} onClick={onClose} />
        </DialogButtons>
      </Dialog>
    </Box>
  );
}
