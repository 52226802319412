import { useQuery } from '@tanstack/react-query';
import { integrationsRequest } from '../support/integrations';
import { RequestError } from '../types';
import { parsedRequestError } from '../utils/errors';
import useAuthRequest from './useAuthRequest';
import {
  IntegrationType,
  IntegrationsQuery,
  IntegrationsQueryVariables,
} from '../gql/gqlRequests';

export default function useIntegrationsQuery(
  types: IntegrationType[],
  withRefetch = false,
) {
  const integrationsQueryFn = useAuthRequest<
    IntegrationsQueryVariables,
    IntegrationsQuery
  >(integrationsRequest);
  const integrationsQuery = useQuery<IntegrationsQuery, RequestError>({
    queryKey: ['integrations', ...types],
    queryFn: () => integrationsQueryFn({ types }),
    enabled: types.length > 0,
    refetchOnWindowFocus: withRefetch,
    refetchOnReconnect: withRefetch,
  });

  if (integrationsQuery.isError)
    throw parsedRequestError(integrationsQuery.error);

  return integrationsQuery;
}
