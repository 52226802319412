import React, { Fragment, ReactNode } from 'react';
import { Box, Center, Image, Text } from '@chakra-ui/react';
import useEnvSearchParam from '../../hooks/useEnvSearchParam';
import EnvironmentBar from './EnvironmentBar';
import Breadcrumbs from './Breadcrumbs';
import Link from './Link';
import backArrowIcon from '../../assets/back-arrow.svg';
import Progress from './Progress';

import { testIds } from '../../utils/testIds';
import { strings } from '../../utils/strings';
import { spacing } from '../../themes/constants';

export const pagePaddingX = '62px';
export const pagePaddingBottom = '60px';

type PageProps = {
  isLoading?: boolean;
  isForm?: boolean;
  withEnvironmentBar?: boolean;
  withEnvSearchParam?: boolean;
  disallowProduction?: boolean;
  withBack?: boolean;
  withBreadcrumbs?: boolean;
  title: string;
  subtitle?: string;
  pageRef?: React.RefObject<HTMLDivElement>;
  children?: ReactNode;
  environmentBarDirtyFormCheck?: () => boolean;
  withEnvironmentPrompt?: boolean;
  withPublishPrompt?: boolean;
};

/**
 * To have the environment bar *and* the environment in the url search params,
 * use `withEnvironmentBar`.
 *
 * Otherwise, to have just the search param but *not* the environment bar, use
 * `withEnvSearchParam`.
 *
 * If something is being edited in that case, you'll probably need to
 * `disallowProduction`.
 */
export default function Page({
  isLoading = false,
  isForm = false,
  withEnvironmentBar = false,
  withEnvSearchParam = false,
  disallowProduction = false,
  withBreadcrumbs = false,
  withBack = false,
  title,
  subtitle,
  pageRef,
  children,
  environmentBarDirtyFormCheck,
  withEnvironmentPrompt = false,
  withPublishPrompt = false,
}: PageProps) {
  useEnvSearchParam(
    withEnvSearchParam || withEnvironmentBar,
    disallowProduction,
  );

  return (
    <Fragment>
      {withEnvironmentBar && (
        <EnvironmentBar
          usePublishPrompt={withPublishPrompt}
          useEnvironmentPrompt={withEnvironmentPrompt}
          isFormDirty={environmentBarDirtyFormCheck?.() ?? false}
        />
      )}

      <Box
        ref={pageRef}
        layerStyle={
          withEnvironmentBar
            ? 'pageContainerWithEnvironmentBar'
            : 'pageContainer'
        }
        data-testid={testIds.page_container}
      >
        {isLoading ? (
          <Center height="100%">
            <Progress />
          </Center>
        ) : (
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            paddingTop={withEnvironmentBar ? '25px' : '60px'}
          >
            <Box>
              {withBack && (
                // Box components simulates standard navbar structure
                <Box as="nav">
                  <Box as="ol" display="flex">
                    <Box as="li" display="inline-flex">
                      {/* this `to` and `relative` combo removes 1 param from the url */}
                      <Link label={strings.common.back} to=".." relative="path">
                        <Image src={backArrowIcon} paddingRight="5px" />
                      </Link>
                    </Box>
                  </Box>
                </Box>
              )}
              {withBreadcrumbs && <Breadcrumbs />}

              {/* form does its own padding; it's dependent on errors */}
              <Box paddingBottom={isForm ? '0' : '40px'}>
                {(withBack || withBreadcrumbs) && <Box height={spacing[20]} />}

                {/* TODO: make a separate PageTitle component to use here and in AppDetails*/}
                <Text as="h1" textStyle="h1" color="neutrals.navigationOutline">
                  {title}
                </Text>

                {subtitle && (
                  <Fragment>
                    <Box height={spacing[10]} />
                    <Text
                      textStyle="h4"
                      color="neutrals.brandGrey.500"
                      data-testid={testIds.page_subtitle}
                    >
                      {subtitle}
                    </Text>
                  </Fragment>
                )}
              </Box>
            </Box>
            <Box flexGrow={1} paddingBottom="60px">
              {children}
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
}
