import React, {
  Fragment,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { validateEmail } from '../../../utils/validation';
import { FormType, RequestError } from '../../../types';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { UserContext } from '../../../contexts';
import Form, {
  FormButtons,
  FormDivider,
  FormError,
  FormGrid,
} from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import {
  AllAppsQuery,
  AllAppsQueryVariables,
  CreateUserInput,
  CreateUserMutation,
  CreateUserMutationVariables,
  Role,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserQuery,
  UserQueryVariables,
} from '../../../gql/gqlRequests';
import { allAppsRequest } from '../../../support/apps';
import {
  createUserRequest,
  updateUserRequest,
  userRequest,
} from '../../../support/users';
import { strings } from '../../../utils/strings';
import { scrollToTop } from '../../../utils';
import CheckboxGroup, { Checkbox } from '../../common/inputs/CheckboxGroup';
import { Grid, GridItem, VStack } from '@chakra-ui/react';

// reducer //

type InputState = {
  firstName: string;
  lastName: string;
  email: string;
  appIds?: string[] | null;
};

type InputAction = {
  type: 'reset' | 'set-one' | 'set-all';
  key?: keyof InputState;
  value?: string | string[];
  newInputState?: InputState;
};

const initialInputState = {
  firstName: '',
  lastName: '',
  email: '',
  appIds: [],
};

function inputDataReducer(state: InputState, action: InputAction): InputState {
  switch (action.type) {
    case 'reset':
      return initialInputState;

    case 'set-one':
      if (!action.key) return state;
      const newState = { ...state };
      if (action.key === 'appIds') {
        newState[action.key] = (action.value as string[]) ?? [];
      } else {
        newState[action.key] = (action.value as string) ?? '';
      }
      return newState;

    case 'set-all':
      return action.newInputState ?? state;
  }
}

// User form //

type UserFormProps = {
  formType: FormType;
};

export default function UserForm({ formType }: UserFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const username = params.username ?? '';
  const pageRef = useRef<HTMLDivElement>(null);
  const { loggedInUser, refetch } = useContext(UserContext);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // Apps query

  const allAppsQueryFn = useAuthRequest<AllAppsQueryVariables, AllAppsQuery>(
    allAppsRequest,
  );
  const allAppsQuery = useQuery<AllAppsQuery, RequestError>({
    queryKey: ['allApps'],
    queryFn: () => allAppsQueryFn({}),
  });

  // apps data

  const appsDataChunks = useMemo(() => {
    const apps =
      allAppsQuery.data?.apps.map(({ id, name, account }) => ({
        id,
        appName: name,
        name: account.name,
      })) ?? [];

    const perChunk = 25;
    const dataChunks = [];
    for (let i = 0; i < apps.length; i += perChunk) {
      dataChunks.push(apps.slice(i, i + perChunk));
    }
    return dataChunks;
  }, [allAppsQuery.data]);

  // create

  const createMutationFn = useAuthRequest<
    CreateUserMutationVariables,
    CreateUserMutation
  >(createUserRequest);
  const createMutation = useMutation<
    CreateUserMutation,
    RequestError,
    CreateUserMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  function dispatchFetchFactory(data: UserQuery): InputAction {
    const { firstName, lastName, email, apps } = data.user;
    switch (true) {
      case loggedInUser.role === Role.SystemAdmin:
        return {
          type: 'set-all',
          newInputState: {
            firstName,
            lastName,
            email,
            appIds: apps.length ? apps.map((app) => app.id) : [],
          },
        };
      case loggedInUser.role === Role.AppAdmin &&
        loggedInUser.appIds.length > 1:
        return {
          type: 'set-all',
          newInputState: {
            firstName,
            lastName,
            email,
            appIds: apps.length ? apps.map((app) => app.id) : [],
          },
        };
      default:
        return {
          type: 'set-all',
          newInputState: {
            firstName,
            lastName,
            email,
          },
        };
    }
  }

  function populateInputData(data: UserQuery) {
    dispatchInputData(dispatchFetchFactory(data));
  }

  const isEditForm = formType === 'edit';
  const userQueryFn = useAuthRequest<UserQueryVariables, UserQuery>(
    userRequest,
  );
  const userQuery = useQuery<UserQuery, RequestError>({
    queryKey: ['form', 'user', username],
    queryFn: () => userQueryFn({ username }),
    enabled: isEditForm,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: 1,
    onSuccess: populateInputData,
  });

  // update

  function handleOnUpdateSuccess(data: UpdateUserMutation) {
    if (data.updateUser.username === loggedInUser.username && refetch) {
      refetch();
    }
    navigateBack();
  }

  const updateMutationFn = useAuthRequest<
    UpdateUserMutationVariables,
    UpdateUserMutation
  >(updateUserRequest);
  const updateMutation = useMutation<
    UpdateUserMutation,
    RequestError,
    UpdateUserMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: handleOnUpdateSuccess,
  });

  // input

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  function setValue(key: keyof InputState, value: string | string[]) {
    dispatchInputData({ type: 'set-one', key, value });
  }
  const { firstName, lastName, email, appIds } = inputData;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = isEditForm ? updateMutation : createMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isFirstNameValid = !!firstName;
  const isLastNameValid = !!lastName;
  const isEmailValid = !!email && validateEmail(email);
  const isAppValid = loggedInUser.role === Role.SystemAdmin ? !!appIds : true;
  const isInputValid =
    isFirstNameValid && isLastNameValid && isEmailValid && isAppValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    if (isEditForm) {
      updateMutation.mutate({ ...inputData });
    } else {
      const newInputData = { ...inputData };
      if (loggedInUser.role === Role.AppAdmin) {
        // can only create user for same app
        newInputData.appIds = [loggedInUser.appId];
      }
      createMutation.mutate({ ...(newInputData as CreateUserInput) });
    }
  }

  // UI

  const title = isEditForm ? strings.users.edit : strings.users.createUser;
  const submitText = isEditForm
    ? strings.common.saveChanges
    : strings.users.createUser;

  const isLoading =
    createMutation.isLoading ||
    // unfortunately, disabled tanstack queries are in a loading state
    (userQuery.isLoading && isEditForm) ||
    allAppsQuery.isLoading ||
    updateMutation.isLoading;

  if (userQuery.isError) throw parsedRequestError(userQuery.error);

  if (allAppsQuery.isError) throw parsedRequestError(allAppsQuery.error);

  return (
    <Page
      isForm
      withBack
      title={title}
      pageRef={pageRef}
      isLoading={(userQuery.isLoading && isEditForm) || allAppsQuery.isLoading}
    >
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />

        <FormGrid>
          <StandardInput
            label={strings.users.firstName}
            labelAsPlaceholder
            isInvalid={
              isValidationActive &&
              (!isFirstNameValid || hasBackendError('firstName'))
            }
            isDisabled={isLoading}
            value={firstName}
            setSanitizedValue={(value) => setValue('firstName', value)}
          />
          <StandardInput
            label={strings.users.lastName}
            labelAsPlaceholder
            isInvalid={
              isValidationActive &&
              (!isLastNameValid || hasBackendError('lastName'))
            }
            isDisabled={isLoading}
            value={lastName}
            setSanitizedValue={(value) => setValue('lastName', value)}
          />
        </FormGrid>

        <FormDivider />

        <StandardInput
          label={strings.common.email}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isEmailValid || hasBackendError('email'))
          }
          isDisabled={isLoading}
          isPermanentlyDisabled={isEditForm}
          value={email}
          setSanitizedValue={(value) => setValue('email', value)}
        />

        {(loggedInUser.role === Role.SystemAdmin ||
          (loggedInUser.role === Role.AppAdmin &&
            loggedInUser.appIds.length > 1)) && (
          <Fragment>
            <FormDivider />

            <CheckboxGroup
              label={strings.apps.apps}
              values={appIds ?? []}
              setValues={(values) => setValue('appIds', values)}
              withContainer
            >
              <Grid templateColumns="repeat(4, 1fr)">
                {appsDataChunks.map((appsData, index) => (
                  <GridItem key={index}>
                    <VStack alignItems={'flex-start'}>
                      {appsData.map((app) => (
                        <Checkbox
                          value={app.id}
                          label={app.appName}
                          key={app.id}
                          isInvalid={
                            isValidationActive && hasBackendError('appIds')
                          }
                        />
                      ))}
                    </VStack>
                  </GridItem>
                ))}
              </Grid>
            </CheckboxGroup>
          </Fragment>
        )}

        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
