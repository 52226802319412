import React, { Fragment } from 'react';
import { Text } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { deleteNavBarItemRequest } from '../../../support/navBarItems';
import { RequestError } from '../../../types';
import PrimaryButton from '../../common/buttons/PrimaryButton';
import Dialog, { DialogBody, DialogButtons } from '../../common/dialogs/Dialog';
import StandardDeletionDialog from '../../common/dialogs/StandardDeletionDialog';
import {
  DeleteNavBarItemMutation,
  DeleteNavBarItemMutationVariables,
  NavBarItem,
  ScreenType,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

const requiredScreenTypes: ScreenType[] = [];

type DeleteNavBarItemDialogProps = {
  isOpen: boolean;
  id?: string;
  navBarItems: Pick<NavBarItem, 'id' | 'screenType'>[];
  onSuccess: (data: DeleteNavBarItemMutation) => void;
  onClose: () => void;
};

export default function DeleteNavBarItemDialog({
  isOpen,
  id = '',
  navBarItems,
  onSuccess,
  onClose,
}: DeleteNavBarItemDialogProps) {
  const navBarItem = navBarItems.find((navBarItem) => navBarItem.id === id);
  const canDeleteNavBarItem =
    !!navBarItem && !requiredScreenTypes.includes(navBarItem.screenType);

  const deleteMutationFn = useAuthRequest<
    DeleteNavBarItemMutationVariables,
    DeleteNavBarItemMutation
  >(deleteNavBarItemRequest);
  const deleteMutation = useMutation<
    DeleteNavBarItemMutation,
    RequestError,
    DeleteNavBarItemMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: (data) => {
      onSuccess(data);
      closeAndReset();
    },
  });

  function deleteNavBarItem() {
    deleteMutation.mutate({ id });
  }

  function closeAndReset() {
    onClose();
    deleteMutation.reset();
  }

  if (!!id && !navBarItem) {
    throw new Error('Cannot find nav bar item for deletion');
  }

  return (
    <Fragment>
      <StandardDeletionDialog
        itemType="Nav Bar Item"
        isOpen={isOpen && canDeleteNavBarItem}
        isLoading={deleteMutation.isLoading}
        onDelete={deleteNavBarItem}
        onClose={closeAndReset}
      />

      {/* TODO: consider moving to its own component */}
      <Dialog
        title={strings.platforms.unableToDeleteNavBarItem}
        isOpen={isOpen && !canDeleteNavBarItem}
        onClose={closeAndReset}
      >
        <DialogBody>
          <Text>{strings.platforms.cannotDeleteLastNavBarMessage}</Text>
        </DialogBody>
        <DialogButtons>
          <PrimaryButton
            label={strings.common.goBack}
            onClick={closeAndReset}
          />
        </DialogButtons>
      </Dialog>
    </Fragment>
  );
}
