import React, { ReactNode, useState } from 'react';
import {
  Text,
  Radio as ChakraRadio,
  RadioGroup as ChakraRadioGroup,
  Stack,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';

type RadioGroupProps = {
  label: string;
  direction?: 'row' | 'column';
  isDisabled?: boolean;
  value?: string;
  setValue?: (nextValue: string) => void;
  defaultValue?: string;
  children: ReactNode;
  required?: boolean;
};

/**
 * Pass in options as children using our `Radio`:
 * ```
 * <RadioGroup>
 *   <Radio value="value1" label="Option 1" />
 *   <Radio value="value2" label="Option 2" />
 * </RadioGroup />
 * ```
 */
export default function RadioGroup({
  label,
  direction = 'column',
  isDisabled = false,
  value: externalValue,
  setValue: externalSetValue,
  defaultValue,
  children: options,
  required = false,
}: RadioGroupProps) {
  const [value, setValue] = useState(defaultValue);

  function handleChange(nextValue: string) {
    if (externalSetValue) externalSetValue(nextValue);
    setValue(nextValue);
  }

  // note: the styling here is essentially identical to that in CheckboxGroup
  return (
    <FormControl as="fieldset" isRequired={required}>
      <FormLabel as="legend" marginBottom="10px" display="flex">
        <Text textStyle="subtitle3">{label}</Text>
      </FormLabel>
      <ChakraRadioGroup
        isDisabled={isDisabled}
        value={externalValue ?? value}
        onChange={handleChange}
        size="lg"
      >
        <Stack
          direction={direction}
          spacing={direction === 'row' ? '45px' : '15px'}
          align="left"
        >
          {options}
        </Stack>
      </ChakraRadioGroup>
    </FormControl>
  );
}

type RadioProps = {
  label: string;
  value: string;
};

export function Radio({ label, value }: RadioProps) {
  return (
    <ChakraRadio
      value={value}
      spacing="10px"
      height="24px"
      width="fit-content"
      variant={'bigger'}
    >
      <Text textStyle="bodyCopy">{label}</Text>
    </ChakraRadio>
  );
}
