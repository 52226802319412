import {
  RequestError as RequestErrorType,
  RequestErrorCode,
  FileType,
} from '../types';
import { getFileSizeText } from './file';
import { renderFileTypes } from './images';
import { strings } from './strings';

export const failedFileSizeValidationMessage =
  'Validation failed (expected size is less than';

export const failedFileTypeValidationMessage =
  'Validation failed (expected type is';

export function getErrorMessagesText(code: number) {
  switch (code) {
    case RequestErrorCode.FORBIDDEN:
      return {
        errorTitle: strings.errors.forbidden,
        errorSubtitle: strings.errors.forbiddenMessage,
      };
      break;
    case RequestErrorCode.NOT_FOUND:
      return {
        errorTitle: strings.errors.pageNotFound,
        errorSubtitle: strings.errors.pageNotFoundMessage,
      };
    case RequestErrorCode.INTERNAL_SERVER_ERROR:
      return {
        errorTitle: strings.errors.unexpectedError,
        errorSubtitle: strings.errors.unexpectedErrorMessage,
      };
    default:
      return {
        errorTitle: strings.errors.unexpectedError,
        errorSubtitle: strings.errors.unexpectedErrorMessage,
      };
  }
}

export function getUploadErrorMessagesText(error: unknown) {
  if (
    error instanceof RequestError &&
    error.message.includes(failedFileSizeValidationMessage) &&
    error.status === RequestErrorCode.UNPROCESSABLE_ENTITY
  ) {
    const validSize = parseInt(
      error.message
        .replace(failedFileSizeValidationMessage, '')
        .replace(')', ''),
    );
    return strings.errors.fileSizeExceeded({
      size: getFileSizeText(validSize),
    });
  } else if (
    error instanceof RequestError &&
    error.message.includes(failedFileTypeValidationMessage) &&
    error.status === RequestErrorCode.UNPROCESSABLE_ENTITY
  ) {
    const validFileTypes = error.message
      .replace(failedFileTypeValidationMessage, '')
      .replace(')', '')
      .replaceAll('image\\', '')
      .replaceAll('/', '')
      .split('|');
    return renderFileTypes(validFileTypes as FileType[]);
  } else return strings.errors.generic;
}

/**
 * Provide a mutation's error and the key of an input.
 * Note: only detects validation-time errors - not, for example, duplicate email.
 */
export function hasErrorForKey(error: RequestErrorType | null, key: string) {
  return (
    error?.response?.errors?.some((err) =>
      err.extensions?.errors?.some(({ property }) => property === key),
    ) ?? false
  );
}

export function parseRequestError(code?: string): RequestErrorCode | undefined {
  switch (code) {
    case 'BAD_REQUEST':
      return RequestErrorCode.BAD_REQUEST;
    case 'BAD_USER_INPUT':
      return RequestErrorCode.BAD_USER_INPUT;
    case 'UNAUTHENTICATED':
      return RequestErrorCode.UNAUTHENTICATED;
    case 'FORBIDDEN':
      return RequestErrorCode.FORBIDDEN;
    case 'NOT_FOUND':
      return RequestErrorCode.NOT_FOUND;
    case 'INTERNAL_SERVER_ERROR':
      return RequestErrorCode.INTERNAL_SERVER_ERROR;
  }
  return undefined;
}

export function getErrorCode(error: RequestErrorType): number {
  const errorObj = error.response.errors[0]?.extensions;
  return (
    errorObj?.response?.statusCode ??
    errorObj?.exception.$metadata?.httpStatusCode ??
    parseRequestError(errorObj?.code) ??
    500
  );
}

export function parsedRequestError(error: RequestErrorType) {
  const message = error.response.errors[0]?.message;
  return new RequestError(message, getErrorCode(error));
}

export class RequestError extends Error {
  status: RequestErrorCode;
  constructor(message: string, status: RequestErrorCode) {
    super(message);
    this.name = this.constructor.name;
    this.status = status;
  }
}
