import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createColumnHelper } from '@tanstack/react-table';
import useAuthRequest from '../../../hooks/useAuthRequest';
import {
  appConfigsRequest,
  deleteAppPlatformRequest,
} from '../../../support/platforms';
import AddAppPlatformDialog from './AddAppPlatformDialog';
import UnableToCreatePlatformDialog from './UnableToCreatePlatformDialog';
import TwoDeletionsDialog from '../../common/dialogs/TwoDeletionsDialog';
import Page from '../../common/Page';
import StandardTable from '../../common/tables/StandardTable';
import GetStartedPlatform from './GetStartedPlatform';
import Link from '../../common/Link';
import { platformIcon, platformNameAsUrl } from '../../../utils/platforms';
import {
  AppContext,
  EnvironmentContext,
  PlatformsContext,
  UserContext,
} from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import {
  AppConfigsQuery,
  AppConfigsQueryVariables,
  AppPlatform,
  DeleteAppPlatformMutation,
  DeleteAppPlatformMutationVariables,
  Environment,
  Role,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';
import { Box, Image, Text } from '@chakra-ui/react';

type AppPlatformRow = AppPlatform & {
  appVersion: string;
  lastEditedBy: string;
};

const columnHelper = createColumnHelper<AppPlatformRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.platforms.platform,
    cell: (info) => {
      const platformName = info.getValue();
      return (
        <Box display="flex" alignItems="center" gap="15px">
          <Image src={platformIcon(platformName)} width="24px" />
          <Link label={platformName} to={platformNameAsUrl(platformName)} />
        </Box>
      );
    },
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('appVersion', {
    header: strings.platforms.appVersion,
  }),
  columnHelper.accessor('lastEditedBy', {
    header: strings.common.lastEditedBy,
    cell: (info) => (
      <Text textStyle="bodyCopy" color="neutrals.navigationOutline">
        {info.getValue() || strings.common.emptyPlaceholder}
      </Text>
    ),
    sortingFn: 'alphanumeric',
  }),
];

export default function Platforms() {
  const { loggedInUser } = useContext(UserContext);
  const { appId, refetch: appRefetch } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);

  // all platforms

  const { allPlatforms } = useContext(PlatformsContext);

  // platforms associated with app

  const configsQueryFn = useAuthRequest<
    AppConfigsQueryVariables,
    AppConfigsQuery
  >(appConfigsRequest);
  const configsQuery = useQuery<AppConfigsQuery, RequestError>({
    queryKey: ['appConfigs', appId, environment],
    queryFn: () => configsQueryFn({ appId, environment }),
  });

  const configsData = configsQuery.data?.app.configs;
  const appPlatformsData = configsQuery.data?.app.platforms;

  const usedPlatformIds = useMemo(
    () => appPlatformsData?.map(({ id }) => id),
    [appPlatformsData],
  );
  const unusedPlatforms = useMemo(
    () => allPlatforms.filter(({ id }) => !usedPlatformIds?.includes(id)),
    [allPlatforms, usedPlatformIds],
  );

  // table data

  const platformData: AppPlatformRow[] = useMemo(
    () =>
      appPlatformsData?.map((platform) => {
        const configData = configsData?.find(
          (data) => data.platform.id === platform.id,
        );
        return {
          createdAt: platform.createdAt ?? new Date(),
          id: platform.id,
          name: platform.name,
          appVersion: configData?.flowVersion ?? '',
          lastEditedBy: [
            configData?.lastModifiedByUser?.firstName,
            configData?.lastModifiedByUser?.lastName,
          ]
            .join(' ')
            .trim(),
        };
      }) ?? [],
    [appPlatformsData, configsData],
  );

  // Create Dialogs

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
  const [isUnableDialogOpen, setIsUnableDialogOpen] = useState(false);
  function onCreate() {
    if (unusedPlatforms && unusedPlatforms.length > 0) {
      setIsCreateDialogOpen(true);
    } else {
      setIsUnableDialogOpen(true);
    }
  }

  // Delete Dialog and functionality

  // dialog is open when this id is defined
  const [idToDelete, setIdToDelete] = useState<string>();
  const deletionRequestingUser = useMemo(
    () =>
      idToDelete
        ? configsData?.find((config) => config.platform.id === idToDelete)
            ?.deletionRequestingUser
        : undefined,
    [idToDelete, configsData],
  );
  const hasAlreadyRequested =
    deletionRequestingUser?.username === loggedInUser.username;

  const deleteMutationFn = useAuthRequest<
    DeleteAppPlatformMutationVariables,
    DeleteAppPlatformMutation
  >(deleteAppPlatformRequest);
  const deletePlatformMutation = useMutation<
    DeleteAppPlatformMutation,
    Error,
    DeleteAppPlatformMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: refetchConfigAndCloseDialog,
  });

  function refetchConfigAndCloseDialog() {
    configsQuery.refetch();
    setIdToDelete(undefined);
  }

  function onCancelDelete() {
    if (deletePlatformMutation.isLoading) return;
    setIdToDelete(undefined);
  }

  function onDelete() {
    deletePlatformMutation.mutate({ appId, platformId: idToDelete ?? '' });
    appRefetch();
    setIdToDelete(undefined);
  }

  // UI

  const isTableLoading = configsQuery.isLoading;
  const shouldShowTable =
    loggedInUser.role === Role.AppAdmin ||
    isTableLoading ||
    platformData.length > 0;

  if (configsQuery.isError) throw parsedRequestError(configsQuery.error);

  return (
    <Page
      withEnvironmentBar
      title={strings.platforms.platforms}
      subtitle={strings.platforms.pageSubtitle}
    >
      {shouldShowTable ? (
        <StandardTable
          title={strings.platforms.allPlatforms}
          isLoading={isTableLoading}
          data={platformData}
          columns={columns}
          canSearch={false}
          onDeleteRow={
            loggedInUser.role === Role.SystemAdmin &&
            environment !== Environment.Production
              ? (id) => setIdToDelete(id)
              : undefined
          }
          deleteText={strings.common.delete}
          onCreate={
            loggedInUser.role === Role.SystemAdmin &&
            environment !== Environment.Production
              ? onCreate
              : undefined
          }
          createButtonTitle={strings.platforms.addPlatform}
          boldColumn="name"
        />
      ) : (
        <GetStartedPlatform
          height="387px"
          onCreate={
            environment === Environment.Production ? undefined : onCreate
          }
        />
      )}

      <AddAppPlatformDialog
        isOpen={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        platformOptions={unusedPlatforms}
        onSuccess={() => {
          setIsCreateDialogOpen(false);
          appRefetch();
          configsQuery.refetch();
        }}
      />

      <UnableToCreatePlatformDialog
        isOpen={isUnableDialogOpen}
        onClose={() => setIsUnableDialogOpen(false)}
      />

      <TwoDeletionsDialog
        type="Remove"
        itemType="Platform"
        deletionRequestingUser={deletionRequestingUser}
        hasAlreadyRequested={hasAlreadyRequested}
        isLoading={deletePlatformMutation.isLoading}
        isOpen={!!idToDelete}
        onDelete={onDelete}
        onCancel={onCancelDelete}
      />
    </Page>
  );
}
