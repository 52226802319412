import React, { useContext, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { Box, VStack } from '@chakra-ui/react';
import useAuthRequest from '../../../hooks/useAuthRequest';
import StandardTable from '../../common/tables/StandardTable';
import Link from '../../common/Link';
import Page from '../../common/Page';
import TwoDeletionsDialog from '../../common/dialogs/TwoDeletionsDialog';
import { UserContext } from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import {
  Account,
  AccountsQuery,
  AccountsQueryVariables,
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  RemoveDeleteAccountRequestMutation,
  RemoveDeleteAccountRequestMutationVariables,
} from '../../../gql/gqlRequests';
import {
  accountsRequest,
  deleteAccountRequest,
  removeDeleteAccountRequest,
} from '../../../support/accounts';
import { tanstackCompareAlphanumeric } from '../../../utils';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';
import OverflowCell from '../../common/tables/OverflowCell';

type AccountRow = Pick<
  Account,
  'id' | 'createdAt' | 'name' | 'url' | 'ensembleAccountManagerName'
> & {
  primaryContact: Pick<
    Account,
    'primaryContactName' | 'primaryContactEmail' | 'primaryContactPhone'
  >;
} & {
  deleteRequestUser?: string;
};

function sortAccountContactColumn(
  rowA: Row<AccountRow>,
  rowB: Row<AccountRow>,
  columnId: string,
) {
  const accountContactA = rowA.getValue(
    columnId,
  ) as AccountRow['primaryContact'];
  const accountContactB = rowB.getValue(
    columnId,
  ) as AccountRow['primaryContact'];
  let {
    primaryContactName: nameA,
    primaryContactEmail: emailA,
    primaryContactPhone: phoneA,
  } = accountContactA;
  let {
    primaryContactName: nameB,
    primaryContactEmail: emailB,
    primaryContactPhone: phoneB,
  } = accountContactB;

  nameA = nameA.toLowerCase();
  nameB = nameB.toLowerCase();
  const nameABComparisonResult = tanstackCompareAlphanumeric(nameA, nameB);

  if (nameABComparisonResult > 0) {
    return 1;
  } else if (nameABComparisonResult === 0) {
    emailA = emailA.toLowerCase();
    emailB = emailB.toLowerCase();
    const emailABComparisonResult = tanstackCompareAlphanumeric(emailA, emailB);
    if (emailABComparisonResult > 0) {
      return 1;
    } else if (emailABComparisonResult === 0) {
      phoneA = phoneA.toLowerCase();
      phoneB = phoneB.toLowerCase();
      const phoneABComparisonResult = tanstackCompareAlphanumeric(
        phoneA,
        phoneB,
      );
      return phoneABComparisonResult;
    }
  }

  return -1;
}

const columnHelper = createColumnHelper<AccountRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.accounts.account,
    cell: (info) => (
      <Link
        label={info.getValue()}
        to={routes.accountDetail({ accountId: info.row.original.id })}
      />
    ),
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('url', {
    cell: (info) => <OverflowCell text={info.getValue()} />,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('primaryContact', {
    header: strings.accounts.accountContact,
    cell: (info) => {
      const { primaryContactName, primaryContactEmail, primaryContactPhone } =
        info.getValue();
      return (
        <VStack spacing="-2px" align="left">
          <Box textStyle="bodyCopy" paddingBottom="5px">
            {primaryContactName}
          </Box>
          <Box textStyle="bodyCopySmall">{primaryContactEmail}</Box>
          <Box textStyle="bodyCopySmall">{primaryContactPhone}</Box>
        </VStack>
      );
    },
    sortingFn: sortAccountContactColumn,
    sortDescFirst: false,
  }),
  columnHelper.accessor('ensembleAccountManagerName', {
    header: strings.accounts.ensManager,
  }),
];

export default function Accounts() {
  const navigate = useNavigate();
  const { loggedInUser } = useContext(UserContext);

  // accounts query

  const accountsQueryFn = useAuthRequest<AccountsQueryVariables, AccountsQuery>(
    accountsRequest,
  );
  const accountsQuery = useQuery<AccountsQuery, RequestError>({
    queryKey: ['accounts'],
    queryFn: () => accountsQueryFn({}),
  });

  // table data

  const accountsData: AccountRow[] = useMemo(
    () =>
      accountsQuery.data?.accounts.map(
        ({
          id,
          createdAt,
          name,
          url,
          primaryContactName,
          primaryContactEmail,
          primaryContactPhone,
          ensembleAccountManagerName,
          deletionRequestingUser,
        }) => ({
          id,
          createdAt,
          name,
          url,
          // note: these aren't seen by table search functionality
          // (which is ok because this table isn't searchable)
          primaryContact: {
            primaryContactName,
            primaryContactEmail,
            primaryContactPhone,
          },
          ensembleAccountManagerName,
          deleteRequestUser: deletionRequestingUser?.email,
        }),
      ) ?? [],
    [accountsQuery.data],
  );

  // deletion

  // dialog is open when this id is defined
  const [idToDelete, setIdToDelete] = useState<string>();
  const deletionRequestingUser = useMemo(
    () =>
      idToDelete && accountsQuery.isSuccess
        ? accountsQuery.data.accounts.find(({ id }) => id === idToDelete)
            ?.deletionRequestingUser
        : undefined,
    [idToDelete, accountsQuery.isSuccess, accountsQuery.data],
  );
  const hasAlreadyRequested =
    deletionRequestingUser?.username === loggedInUser.username;

  const deleteMutationFn = useAuthRequest<
    DeleteAccountMutationVariables,
    DeleteAccountMutation
  >(deleteAccountRequest);
  const deleteAccountMutation = useMutation<
    DeleteAccountMutation,
    Error,
    DeleteAccountMutationVariables
  >({
    mutationFn: deleteMutationFn,
    onSuccess: refetchAccountsAndCloseDialog,
  });

  const removeDeleteAccountRequestFn = useAuthRequest<
    RemoveDeleteAccountRequestMutationVariables,
    RemoveDeleteAccountRequestMutation
  >(removeDeleteAccountRequest);
  const removeDeleteAccountRequestMutation = useMutation<
    RemoveDeleteAccountRequestMutation,
    Error,
    RemoveDeleteAccountRequestMutationVariables
  >({
    mutationFn: removeDeleteAccountRequestFn,
    onSuccess: refetchAccountsAndCloseDialog,
  });

  function refetchAccountsAndCloseDialog() {
    accountsQuery.refetch();
    closeDialog();
  }

  function closeDialog() {
    setIdToDelete(undefined);
  }

  function deleteAccount() {
    deleteAccountMutation.mutate({ id: idToDelete ?? '' });
  }

  function cancelDeleteRequest(id: string) {
    removeDeleteAccountRequestMutation.mutate({ id });
  }

  // UI

  if (accountsQuery.isError) throw parsedRequestError(accountsQuery.error);

  return (
    <Page
      title={strings.accounts.accounts}
      subtitle={strings.accounts.pageSubtitle}
    >
      <StandardTable
        title={strings.accounts.allAccounts}
        isLoading={accountsQuery.isLoading}
        data={accountsData}
        columns={columns}
        canSearch={false}
        cancelDeleteProperty="deleteRequestUser"
        onCancelDeleteRow={cancelDeleteRequest}
        onEditRow={(accountId: string) =>
          navigate(routes.editAccount({ accountId }))
        }
        onDeleteRow={(id) => setIdToDelete(id)}
        onCreate={() => navigate(routes.createAccount)}
        createButtonTitle={strings.accounts.createAccount}
        boldColumn="name"
      />
      <TwoDeletionsDialog
        itemType="Account"
        deletionRequestingUser={deletionRequestingUser}
        hasAlreadyRequested={hasAlreadyRequested}
        isLoading={deleteAccountMutation.isLoading}
        isOpen={!!idToDelete}
        onDelete={deleteAccount}
        onCancel={closeDialog}
      />
    </Page>
  );
}
