import { LayerStyle } from '.';

const updateLocalizedTranslations: LayerStyle = {
  padding: '0px',
  overflow: 'auto',
  height: '100%',
  gap: '40px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
};

export const tagsLayerStyle = {
  updateLocalizedTranslations,
};
