export const testIds = {
  required: 'required',

  progress_spinner: 'progress-spinner',
  file_upload_input: 'file-upload-input',

  app_form: 'app-form',

  feed_form: 'feed-form',
  content_integration_select: 'content-integration-select',
  detail_integration_select: 'detail-integration-select',

  active_nav: 'active-nav-entry',

  page_container: 'page-container',
  page_title: 'page-title',
  page_subtitle: 'page-subtitle',

  error_page: 'error-page',

  role: 'role',
  email: 'email',
  account: 'account',
  app: 'app',
  registration_status: 'registration-status',
  two_factor_authentication: 'two-factor-authentication',

  invalid_image_input: 'invalid-image-input',

  description: 'feed-description',
  feed_type: 'feed-type',
  url: 'url',
  content_integration: 'content-integration',
  content_detail: 'content-detail',
  row_type: 'row-type',

  screen_type: 'screen-type',

  image_tile: 'image-tile',

  locales_tab_list_detail: 'locales-tab-list-detail',
  locales_tab_list_form: 'locales-tab-list-form',

  min_platform_sdk_version: 'min-platform-sdk-version',
  app_version: 'app-version',
  feature: 'feature',
  subscriptions: 'subscriptions',
  locales: 'locales',
};
