import React, { useEffect, useState } from 'react';
import { debounce } from '../../../utils';
import { strings } from '../../../utils/strings';
import {
  validateColor,
  isValidColorCharacter,
} from '../../../utils/validation';
import Input from './Input';

const defaultColor = '#000000';

function processPoundSymbolsAndUpperCase(str: string) {
  // would use replaceAll('#', '') but the tests complain
  const strWithoutPounds = str.replace(/#/g, '');
  const strWithOnePoundAtStart = '#' + strWithoutPounds;
  return strWithOnePoundAtStart.toUpperCase();
}

type ColorInputProps = {
  label: string;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  value: string;
  setSanitizedValue: (color: string) => void;
};

export default function ColorInput({
  label,
  isInvalid = false,
  isDisabled = false,
  isPermanentlyDisabled = false,
  value,
  setSanitizedValue,
}: ColorInputProps) {
  const [lastValidValue, setLastValidValue] = useState(
    validateColor(value) ? value : defaultColor,
  );

  useEffect(() => {
    if (validateColor(value)) {
      setLastValidValue(value);
    }
  }, [value]);

  return (
    <Input
      label={label}
      placeholder={strings.common.emptyColor}
      isInvalid={isInvalid}
      isDisabled={isDisabled}
      isPermanentlyDisabled={isPermanentlyDisabled}
      withColorBox
      colorBoxValue={lastValidValue}
      value={value}
      setSanitizedValue={debounce((c) =>
        setSanitizedValue(processPoundSymbolsAndUpperCase(c)),
      )}
      isValidCharacter={isValidColorCharacter}
      direction="row"
      netWidth="517px"
      boxWidth="205px"
      boxHeight="56px"
    />
  );
}
