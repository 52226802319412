import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
  control: {
    border: '1px solid',
    borderRadius: '1px',
    borderColor: 'neutrals.brandGrey.300',
  },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
