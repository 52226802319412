import { RequestDocument, Variables, request } from 'graphql-request';

// GraphQL path is always a relative path.
// Routing to the appropriate server is handled at another level.
const URL = '/graphql';

export function createGqlAuthRequest<TVars extends Variables, TResp>(
  document: RequestDocument,
) {
  return (accessToken: string, variables: TVars): Promise<TResp> =>
    request<TResp, Variables>({
      url: URL,
      document,
      variables,
      requestHeaders: { Authorization: `Bearer ${accessToken}` },
    });
}

export function createGqlRequest<TVars extends Variables, TResp>(
  document: RequestDocument,
) {
  return (variables: TVars): Promise<TResp> =>
    request<TResp, Variables>({
      url: URL,
      document,
      variables,
    });
}
