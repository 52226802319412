import React, { useContext, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import Authentication from '../../common/authentication/Authentication';
import NewPassword from '../../common/authentication/NewPassword';
import useNewPasswordValidation from '../../../hooks/useNewPasswordValidation';
import { RequestError, RequestErrorCode } from '../../../types';
import { getErrorCode } from '../../../utils/errors';
import {
  Maybe,
  NewPasswordMutation,
  NewPasswordMutationVariables,
} from '../../../gql/gqlRequests';
import { newPasswordRequest } from '../../../support/authentication';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';
import { TokenContext } from '../../../contexts';

type NewPasswordRequiredProps = {
  session?: Maybe<string>;
  email: string;
};

/**
 * A form to create a new password when the user receives new password challenge
 */
export default function NewPasswordRequired({
  session,
  email,
}: NewPasswordRequiredProps) {
  const navigate = useNavigate();
  const { clearTokens } = useContext(TokenContext);

  const newPasswordMutation = useMutation<
    NewPasswordMutation,
    RequestError,
    NewPasswordMutationVariables
  >({
    mutationFn: newPasswordRequest,
    onSuccess: () => navigate(routes.root),
    onError: applyBackendValidation,
  });

  function attemptToCreateNewPassword() {
    newPasswordMutation.reset();
    if (isValid) {
      createNewPassword();
    } else {
      setErrorMessage(frontendErrorMessage);
    }
  }

  function createNewPassword() {
    clearTokens();
    newPasswordMutation.mutate({
      email,
      newPassword,
      session: session ?? '',
    });
  }

  // input state & validation

  const {
    newPassword,
    setNewPassword,
    setConfirmPassword,
    requirements,
    requirementsAreMet,
    isValid,
    isNewInvalid,
    isConfirmInvalid,
    errorMessage: frontendErrorMessage,
  } = useNewPasswordValidation();

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;
  const hasBadUserInputFromBackend =
    newPasswordMutation.isError &&
    getErrorCode(newPasswordMutation.error) === RequestErrorCode.BAD_USER_INPUT;

  function applyBackendValidation(e: RequestError) {
    switch (getErrorCode(e)) {
      case RequestErrorCode.UNAUTHENTICATED:
        navigate(routes.root);
        break;

      // this shouldn't happen, frontend validation should catch all these
      case RequestErrorCode.BAD_USER_INPUT:
        setErrorMessage(strings.authentication.passwordDoesNotMeetRequirements);
        break;

      default:
        setErrorMessage(strings.errors.generic);
        break;
    }
  }

  // UI

  // navigate back to login page if something is invalid
  if (!session || !email) {
    navigate(routes.login);
  }

  const createNewPasswordButton = {
    text: strings.authentication.createNewPassword,
    onClick: attemptToCreateNewPassword,
    isDisabled: newPasswordMutation.isLoading,
  };

  return (
    <Authentication
      errorMessage={errorMessage}
      title={strings.authentication.createNewPassword}
      buttonProps={createNewPasswordButton}
    >
      <NewPassword
        setNewPassword={setNewPassword}
        isNewInvalid={isNewInvalid || hasBadUserInputFromBackend}
        setConfirmPassword={setConfirmPassword}
        isConfirmInvalid={isConfirmInvalid || hasBadUserInputFromBackend}
        requirements={requirements}
        requirementsAreMet={requirementsAreMet}
        hasTriedToSubmit={hasTriedToSubmit}
      />
    </Authentication>
  );
}
