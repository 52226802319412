import React, { useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { resetPasswordRequest } from '../../../support/authentication';
import StandardInput from '../../common/inputs/StandardInput';
import Authentication from '../../common/authentication/Authentication';
import ResetPasswordLinkSent from './ResetPasswordLinkSent';
import { RequestError } from '../../../types';
import {
  isValidEmailCharacter,
  validateEmail,
} from '../../../utils/validation';
import { useSearchParams } from 'react-router-dom';
import {
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables,
} from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

/**
 * A form to provide an email address for password reset. Or,
 * once submitted, a success screen.
 */
export default function ForgotPassword() {
  const [searchParams] = useSearchParams();
  const expired = searchParams.get('expired') ?? '';
  const isFromExpiredLink = !!expired && expired !== 'false';

  const resetMutation = useMutation<
    ResetPasswordRequestMutation,
    RequestError,
    ResetPasswordRequestMutationVariables
  >({
    mutationFn: resetPasswordRequest,
    onError: () => setErrorMessage(strings.errors.generic),
  });

  function requestPasswordReset() {
    resetMutation.reset();
    if (isEmailValid) {
      resetMutation.mutate({ email });
    } else {
      setErrorMessage(strings.authentication.incompleteEmail);
    }
  }

  // input state

  const [email, setEmail] = useState('');

  // validation

  const [errorMessage, setErrorMessage] = useState('');
  const hasTriedToSubmit = !!errorMessage;

  const isEmailValid = validateEmail(email);

  // UI

  const title = isFromExpiredLink
    ? strings.authentication.requestNewLink
    : strings.authentication.resetPassword;

  const instructions = isFromExpiredLink
    ? strings.authentication.forgotPasswordInstructionsResetLink
    : strings.authentication.forgotPasswordInstructionsResetPassword;

  const buttonText = isFromExpiredLink
    ? strings.authentication.requestNewLink
    : strings.authentication.sendInstructions;

  const linkSentMessage = isFromExpiredLink
    ? strings.authentication.passwordResetLinkSent
    : strings.authentication.forgotPasswordLinkSent;

  const requestPasswordButton = {
    text: buttonText,
    onClick: requestPasswordReset,
    isDisabled: resetMutation.isLoading,
  };

  return resetMutation.isSuccess ? (
    <ResetPasswordLinkSent subtitle={linkSentMessage} />
  ) : (
    <Authentication
      errorMessage={errorMessage}
      title={title}
      instructions={instructions}
      buttonProps={requestPasswordButton}
    >
      <Box paddingTop="40px">
        <StandardInput
          label={strings.common.email}
          labelAsPlaceholder
          isValidCharacter={isValidEmailCharacter}
          setSanitizedValue={setEmail}
          isInvalid={hasTriedToSubmit && !isEmailValid}
          isDisabled={resetMutation.isLoading}
        />
      </Box>
    </Authentication>
  );
}
