import { Locale, LocalizationNamespace } from '../gql/gqlRequests';
import { Route } from '../types/routes';
import {
  AllLocalizedTranslationInputs,
  UpdateLocalizedTranslationInputExtended,
} from '../types/tags';

export function buildDefaultAllLocalizedTranslationInputs(
  locales: Locale[],
): AllLocalizedTranslationInputs {
  const defaultAllLocalizedTranslationInputs: any = {};
  Object.values(LocalizationNamespace).forEach((namespace) => {
    defaultAllLocalizedTranslationInputs[namespace] = {};
    locales.forEach((locale) => {
      defaultAllLocalizedTranslationInputs[namespace][locale.code] = [];
    });
  });
  return defaultAllLocalizedTranslationInputs;
}

/**
 * Returns a list of localized translation inputs with
 * deleted localized translations removed and errors cleared
 *
 * @param localeTranslation extended update localized translation inputs
 * @returns sanitized update localized translation inputs
 */
export function sanitizeExtendedUpdateLocalizedTranslationInputs(
  localeTranslation: UpdateLocalizedTranslationInputExtended[],
) {
  return localeTranslation
    .filter(({ deleted }) => !deleted)
    .map(({ key, translation }) => ({ key, translation }));
}

export function getCurrentLocalizationNamespace(pathname: string) {
  const paths = pathname.split('/');
  // /apps/:appId/tags, /apps/:appId/tags/badges, /apps/:appId/tags/tune-in
  const currTab = paths.length > 4 ? paths[4] : paths[3];
  switch (currTab) {
    case Route.Tags:
      return LocalizationNamespace.Tag;
    case Route.Badges:
      return LocalizationNamespace.Badge;
    case Route.TuneIn:
      return LocalizationNamespace.TuneIn;
    default:
      throw new Error('Could not get localization namespace');
  }
}
