import React from 'react';
import { Circle, Box, Text, VStack, Flex } from '@chakra-ui/react';
import { useRouteError } from 'react-router-dom';
import { getErrorMessagesText, RequestError } from '../../utils/errors';
import { RequestErrorCode } from '../../types';
import ProductConsentModal from './authentication/ProductConsentModal';
import { strings } from '../../utils/strings';

type ErrorPageProps = {
  code?: number;
};

// fixme: use textStyles - they don't appear to match up with figma though?

export default function ErrorPage({ code }: ErrorPageProps) {
  const error = useRouteError();
  const errorCode =
    code ?? (error instanceof RequestError ? error.status : 500);
  const errorMessage = error instanceof Error ? error.message : '';

  const { errorTitle, errorSubtitle } = getErrorMessagesText(errorCode);

  const consentError =
    errorCode === RequestErrorCode.FORBIDDEN &&
    errorMessage === strings.consent.mustConsentMessage;

  return consentError ? (
    <ProductConsentModal />
  ) : (
    <Box paddingTop="62px" data-testid="error-page">
      <Text
        fontFamily="DIN condensed"
        fontSize="60px"
        fontWeight="700"
        paddingLeft="62px"
        margin="auto"
      >
        {strings.common.flow}
      </Text>
      <Flex paddingTop="160px" w="100%" direction="row" justify="center">
        <Box paddingRight="80px">
          <Circle size="425px" backgroundColor="neutrals.brandGrey.100">
            <VStack>
              <Text
                fontSize="150px"
                fontWeight="700"
                letterSpacing="0.005em"
                color="neutrals.brandGrey.300"
                lineHeight="1em"
              >
                {errorCode}
              </Text>
              <Text
                // figma says h2 but doesn't seem to look right
                fontSize="44px"
                fontWeight="700"
                letterSpacing="0.005em"
                color="neutrals.brandGrey.300"
              >
                {strings.errors.error}
              </Text>
            </VStack>
          </Circle>
        </Box>
        <VStack spacing="15px" align="left">
          <Text
            fontSize="96px"
            fontWeight="700"
            letterSpacing="0.005em"
            color="primary.primaryBlue.600"
            paddingTop="40px"
          >
            {strings.errors.oops}
          </Text>
          {/* // figma says h3 but doesn't seem to look right */}
          <Text fontSize="36px" fontWeight="600">
            {errorTitle}
          </Text>
          <Text
            // figma says subtitle1 but doesn't seem to look right
            fontSize="20px"
            fontWeight="600"
            opacity="0.75"
            color="neutrals.brandGrey.500"
          >
            {errorSubtitle}
          </Text>
        </VStack>
      </Flex>
    </Box>
  );
}
