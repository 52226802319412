import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Locale, RequestError, RequestErrorCode } from '../types';
import {
  getErrorCode,
  parsedRequestError,
  RequestError as RError,
} from '../utils/errors'; // RequestError name is already used so RError
import useAuthRequest from './useAuthRequest';
import {
  AppQuery,
  AppQueryVariables,
  Environment,
  Role,
} from '../gql/gqlRequests';
import { appRequest } from '../support/apps';
import { LocaleCode } from '../types/locale';
import { routes } from '../types/routes';

export const defaultAppId = '';
export const appIdKey = 'app-id';

const temporaryLocales: Locale[] = [{ name: 'English', code: LocaleCode.EN }];
const temporaryDefaultLocale = temporaryLocales[0];

/**
 * Only call this hook once, in ContextsProvider.tsx - otherwise,
 * get these values from the app context.
 */
export default function useApp(
  userAppId: string,
  userRole?: Role,
  multipleAppsOnAppAdmin = false,
  environment: Environment = Environment.Production,
) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const paramAppId = params.appId;
  const savedAppId = sessionStorage.getItem(appIdKey);
  // priority for initial value: params -> storage -> no id
  const initialAppId = paramAppId ?? savedAppId ?? defaultAppId;

  const [appId, setAppId] = useState(initialAppId);
  // ignore dependency array complaints - this should only happen ONCE
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => sessionStorage.setItem(appIdKey, initialAppId), []);

  // ids both exist and are different
  const areIdsMismatched = appId && userAppId && appId !== userAppId;

  // App data fetching
  const isEnabled = !!appId;
  const appQueryFn = useAuthRequest<AppQueryVariables, AppQuery>(appRequest);
  const appQuery = useQuery<AppQuery, RequestError>({
    queryKey: ['app', appId],
    queryFn: () => appQueryFn({ id: appId, environment }),
    enabled: isEnabled,
    retry: 0,
  });

  const appData = appQuery.data?.app;
  const { isLoading, isSuccess, refetch } = appQuery;

  // note: query will update itself once setAppId is called
  const setApp = useCallback((newAppId: string) => {
    sessionStorage.setItem(appIdKey, newAppId);
    setAppId(newAppId);
  }, []);

  // if/when user context provides an app id
  useEffect(() => {
    // userAppId and userRole should change exactly once
    if (userAppId && userRole && userRole !== Role.SystemAdmin) {
      if (!appId) {
        setApp(userAppId);
      } else if (areIdsMismatched && !multipleAppsOnAppAdmin) {
        // technically don't need this, as the query would eventually fail, but this is faster
        throw new RError( // RError = RequestError
          "User doesn't have access to this app",
          RequestErrorCode.FORBIDDEN,
        );
      }
      // otherwise they match; nothing needs to be done
    }
  }, [
    setApp,
    userRole,
    userAppId,
    paramAppId,
    appId,
    areIdsMismatched,
    multipleAppsOnAppAdmin,
  ]);

  useEffect(() => {
    if (
      !!paramAppId &&
      location.pathname.includes(paramAppId) &&
      !location.pathname.includes(appId)
    ) {
      setAppId(paramAppId);
    }
  }, [location, paramAppId, appId]);

  if (appQuery.isError) {
    const errorCode = getErrorCode(appQuery.error);
    // if we get a NOT_FOUND error with the id of the last app loaded successfully
    // it means it was deleted

    if (
      errorCode === RequestErrorCode.NOT_FOUND &&
      userRole === Role.SystemAdmin
    ) {
      setApp('');
      navigate(routes.accounts);
    } else {
      throw parsedRequestError(appQuery.error);
    }
  }

  return {
    // disabled queries are always in loading state, which we don't want
    isLoading: isLoading && isEnabled,
    isSuccess,
    appId,
    setApp,
    appData,
    locales: temporaryLocales,
    defaultLocale: temporaryDefaultLocale,
    refetch,
  };
}
