import { LayerStyle } from '.';

const generalInfo: LayerStyle = {
  paddingTop: '45px',
  paddingBottom: '45px',
};

const appDetailPlatforms: LayerStyle = {
  paddingX: '40px',
  paddingY: '30px',
  borderRadius: '10px',
  backgroundColor: 'neutrals.white',
};

export const appDetailLayerStyle = {
  generalInfo,
  appDetailPlatforms,
};
