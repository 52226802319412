import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import Authentication from './Authentication';
import { spacing } from '../../../themes/constants';

type AuthenticationInfoProps = {
  icon: string;
  iconAlt: string;
  title: string;
  subtitle: string;
  buttonProps?: {
    text: string;
    onClick: () => void;
  };
};

/**
 * A wrapper which uses `Authentication` to create an information-only
 * (no inputs) screen with an icon, explanation, and button.
 */
export default function AuthenticationInfo({
  icon,
  iconAlt,
  title,
  subtitle,
  buttonProps,
}: AuthenticationInfoProps) {
  return (
    <Authentication
      buttonProps={buttonProps}
      spacingBetweenFlowAndTitle="136px"
    >
      <VStack spacing={0}>
        <Image width="80px" src={icon} alt={iconAlt} />
        <Box height={spacing[25]} />
        <Text as="h2" textStyle="h3">
          {title}
        </Text>
        <Box height={spacing[10]} />
        <Text textStyle="bodyCopy" textColor="neutrals.brandGrey.500">
          {subtitle}
        </Text>
      </VStack>
    </Authentication>
  );
}
