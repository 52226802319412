import { createGqlAuthRequest } from '.';
import {
  AccountDocument,
  AccountNameDocument,
  AccountNameQuery,
  AccountNameQueryVariables,
  AccountQuery,
  AccountQueryVariables,
  AccountsDocument,
  AccountsQuery,
  AccountsQueryVariables,
  CreateAccountDocument,
  CreateAccountMutation,
  CreateAccountMutationVariables,
  DeleteAccountDocument,
  DeleteAccountMutation,
  DeleteAccountMutationVariables,
  RemoveDeleteAccountRequestDocument,
  RemoveDeleteAccountRequestMutation,
  RemoveDeleteAccountRequestMutationVariables,
  UpdateAccountDocument,
  UpdateAccountMutation,
  UpdateAccountMutationVariables,
} from '../gql/gqlRequests';

export const accountsRequest = createGqlAuthRequest<
  AccountsQueryVariables,
  AccountsQuery
>(AccountsDocument);

export const accountRequest = createGqlAuthRequest<
  AccountQueryVariables,
  AccountQuery
>(AccountDocument);

export const accountNameRequest = createGqlAuthRequest<
  AccountNameQueryVariables,
  AccountNameQuery
>(AccountNameDocument);

export const createAccountRequest = createGqlAuthRequest<
  CreateAccountMutationVariables,
  CreateAccountMutation
>(CreateAccountDocument);

export const updateAccountRequest = createGqlAuthRequest<
  UpdateAccountMutationVariables,
  UpdateAccountMutation
>(UpdateAccountDocument);

export const deleteAccountRequest = createGqlAuthRequest<
  DeleteAccountMutationVariables,
  DeleteAccountMutation
>(DeleteAccountDocument);

export const removeDeleteAccountRequest = createGqlAuthRequest<
  RemoveDeleteAccountRequestMutationVariables,
  RemoveDeleteAccountRequestMutation
>(RemoveDeleteAccountRequestDocument);
