import React, { Fragment, useContext, useState } from 'react';
import {
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import MenuItem from './MenuItem';
import settingsIcon from '../../assets/dots-black.svg';
import publishIcon from '../../assets/publish.svg';
import revertIcon from '../../assets/return.svg';
import Dialog, { DialogBody, DialogButtons } from './dialogs/Dialog';
import PrimaryButton from './buttons/PrimaryButton';
import SecondaryButton from './buttons/SecondaryButton';
import useAuthRequest from '../../hooks/useAuthRequest';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppContext, EnvironmentContext } from '../../contexts';
import {
  Environment,
  PublishAppMutation,
  PublishAppMutationVariables,
  RevertAppMutation,
  RevertAppMutationVariables,
  Status,
  TriggerConfigWriteMutation,
  TriggerConfigWriteMutationVariables,
} from '../../gql/gqlRequests';
import {
  publishAppRequest,
  revertAppRequest,
  triggerConfigWrtiteRequest,
} from '../../support/apps';
import { strings } from '../../utils/strings';
import Prompt from './Prompt';
import { displayEnvironment } from '../../utils';

type EnvironmentPublishingMenuProps = {
  withPrompt?: boolean;
  isFormDirty?: boolean;
};

export default function EnvironmentPublishingMenu({
  isFormDirty = false,
  withPrompt = false,
}: EnvironmentPublishingMenuProps) {
  const queryClient = useQueryClient();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [nextToModal, setNextToModal] = useState<'Publish' | 'Revert' | 'none'>(
    'none',
  );
  const { appId, appData, refetch } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);

  const showRevert = environment === Environment.Staging;
  const onProduction = environment === Environment.Production;

  // mutations
  const triggerConfigWriteFn = useAuthRequest<
    TriggerConfigWriteMutationVariables,
    TriggerConfigWriteMutation
  >(triggerConfigWrtiteRequest);
  const triggerConfigWriteMutation = useMutation<
    TriggerConfigWriteMutation,
    Error,
    TriggerConfigWriteMutationVariables
  >({
    mutationFn: triggerConfigWriteFn,
    onSuccess: () => invalidateQueriesAndCloseDialog(environment),
  });

  const publishMutationFn = useAuthRequest<
    PublishAppMutationVariables,
    PublishAppMutation
  >(publishAppRequest);
  const publishMutation = useMutation<
    PublishAppMutation,
    Error,
    PublishAppMutationVariables
  >({
    mutationFn: publishMutationFn,
    onSuccess: () => invalidateQueriesAndCloseDialog(Environment.Production),
  });

  const revertMutationFn = useAuthRequest<
    RevertAppMutationVariables,
    RevertAppMutation
  >(revertAppRequest);
  const revertMutation = useMutation<
    RevertAppMutation,
    Error,
    RevertAppMutationVariables
  >({
    mutationFn: revertMutationFn,
    onSuccess: () => invalidateQueriesAndCloseDialog(Environment.Staging),
  });

  function invalidateQueriesAndCloseDialog(updatedEnv: Environment): void {
    // invalidate any query involving the affected app and/or environment
    queryClient.invalidateQueries({
      predicate: (query) =>
        [appId, updatedEnv, 'config', 'generation'].some((key) =>
          query.queryKey.includes(key),
        ),
    });
    refetch();
    setOpenDialog('none');
  }

  // confirmation dialogs

  const [openDialog, setOpenDialog] = useState<'Publish' | 'Revert' | 'none'>(
    'none',
  );
  const doPublish = openDialog === 'Publish';
  const actionText = doPublish ? strings.common.publish : strings.common.revert;
  const isOnProd = environment === Environment.Production;

  function publishOrRevert() {
    if (doPublish) {
      if (isOnProd) {
        publishMutation.mutate({ id: appId });
      } else {
        triggerConfigWriteMutation.mutate({ id: appId, env: environment });
      }
    } else {
      revertMutation.mutate({ id: appId });
    }
  }

  function closeAndReset() {
    setOpenDialog('none');
    publishMutation.reset();
    revertMutation.reset();
    triggerConfigWriteMutation.reset();
  }

  const next = () => {
    setOpenDialog(nextToModal);
  };

  // UI

  const dialogTitle = `${actionText} ${appData?.name ?? strings.apps.app}`;
  const dialogText = doPublish
    ? isOnProd
      ? strings.common.confirmPublishMessage
      : strings.common.confirmGenerateOnEnvironmentMessage({
          environment: displayEnvironment(environment),
        })
    : strings.common.confirmRevertStagingMessage;
  const isLoading =
    publishMutation.isLoading ||
    revertMutation.isLoading ||
    triggerConfigWriteMutation.isLoading;

  return (
    <Fragment>
      <Prompt onClose={onClose} open={isOpen} onContinue={next} />
      <Menu autoSelect={false}>
        <MenuButton
          as={IconButton}
          variant="iconButton"
          size="sm"
          icon={<Image src={settingsIcon} alt={strings.common.options} />}
          aria-label={strings.common.options}
        />
        <MenuList>
          <MenuItem
            icon={<Image src={publishIcon} />}
            onClick={() => {
              if (withPrompt && isFormDirty) {
                setNextToModal('Publish');
                onOpen();
                return;
              }
              setOpenDialog('Publish');
            }}
          >
            {onProduction
              ? strings.common.publishExplicit
              : strings.common.publish}
          </MenuItem>
          {showRevert && (
            <MenuItem
              icon={<Image src={revertIcon} />}
              onClick={() => {
                if (withPrompt && isFormDirty) {
                  setNextToModal('Revert');
                  onOpen();
                  return;
                }
                setOpenDialog('Revert');
              }}
            >
              {strings.common.revert}
            </MenuItem>
          )}
        </MenuList>
      </Menu>

      <Dialog
        title={dialogTitle}
        isOpen={openDialog !== 'none'}
        onClose={closeAndReset}
      >
        <DialogBody>
          <Text>{dialogText}</Text>
        </DialogBody>
        <DialogButtons>
          <SecondaryButton
            label={strings.common.cancel}
            onClick={closeAndReset}
            isDisabled={isLoading}
          />
          <PrimaryButton
            label={actionText}
            onClick={publishOrRevert}
            isDisabled={
              isLoading || appData?.status.state === Status.InProgress
            }
          />
        </DialogButtons>
      </Dialog>
    </Fragment>
  );
}
