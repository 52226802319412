import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCallback, useContext } from 'react';
import { AppContext, EnvironmentContext, TokenContext } from '../contexts';
import { logoutRequest } from '../support/authentication';
import { RequestError } from '../types';
import { defaultAppId } from './useApp';
import { defaultEnvironment } from './useEnvironment';
import useAuthRequest from './useAuthRequest';
import { LogoutMutation, LogoutMutationVariables } from '../gql/gqlRequests';

/**
 * The returned function calls the logout mutation, then, regardless of whether
 * the query succeeds or fails, clears all queries, resets/clears all app state,
 * and clears all local/session storage.
 */
export default function useLogout() {
  const queryClient = useQueryClient();
  const { clearTokens } = useContext(TokenContext);
  const { setEnvironment } = useContext(EnvironmentContext);
  const { setApp } = useContext(AppContext);

  function clearStorageAndQueryCaches() {
    // clear all queries (cached data)
    queryClient.clear();
    // clear app states (also clears/resets storage)
    setEnvironment(defaultEnvironment);
    setApp(defaultAppId);
    // once tokens are cleared, user will be kicked out
    clearTokens();
    // clear storage explicitly (in case called outside of contexts)
    sessionStorage.clear();
    localStorage.clear();
  }

  const logoutMutationFn = useAuthRequest<
    LogoutMutationVariables,
    LogoutMutation
  >(logoutRequest);
  const logoutMutation = useMutation<
    LogoutMutation,
    RequestError,
    LogoutMutationVariables
  >({
    mutationFn: logoutMutationFn,
    onSettled: clearStorageAndQueryCaches,
  });

  const logout = useCallback(() => logoutMutation.mutate({}), [logoutMutation]);

  return logout;
}
