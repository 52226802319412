import React, { useEffect, useCallback } from 'react';
import type {
  unstable_BlockerFunction as BlockerFunction,
  Location,
} from 'react-router-dom';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';
import Prompt from './Prompt';
import { useDisclosure } from '@chakra-ui/react';

type FormPromptProps = {
  isFormDirty: boolean;
  moreExactCheck?: ((
    currentLocation: Location,
    nextLocation: Location,
  ) => boolean)[];
};

export default function FormPrompt({
  isFormDirty,
  moreExactCheck = [() => true],
}: FormPromptProps) {
  // const cancelRef = useRef<any>();
  const { onClose } = useDisclosure();
  const shouldBlock = useCallback<BlockerFunction>(
    ({ currentLocation, nextLocation }) =>
      isFormDirty &&
      currentLocation.pathname !== nextLocation.pathname &&
      (moreExactCheck && moreExactCheck.length != 0
        ? moreExactCheck.some((func) => func(currentLocation, nextLocation))
        : true),
    [isFormDirty, moreExactCheck],
  );
  const blocker = useBlocker(shouldBlock);

  // Reset the blocker if the user cleans the form
  useEffect(
    function unblockFormOnCleanInput() {
      if (blocker.state === 'blocked' && !isFormDirty) {
        blocker.reset();
      }
    },
    [blocker, isFormDirty],
  );

  // Adds a block on reload or manual url change
  useEffect(
    function preventPageReload() {
      const alertUser = (e: any) => {
        e.preventDefault();
        e.returnValue = '';
      };
      if (isFormDirty) {
        window.addEventListener('beforeunload', alertUser);
        return () => {
          window.removeEventListener('beforeunload', alertUser);
        };
      }
    },
    [isFormDirty],
  );

  if (blocker.state === 'blocked') {
    return (
      <Prompt
        onClose={onClose}
        onCancel={() => {
          blocker.reset?.();
        }}
        onContinue={() => {
          blocker.proceed?.();
        }}
      />
    );
  }
  return null;
}
