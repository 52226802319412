import React from 'react';
import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import DragAndDropRow from './DragAndDropRow';
import CreateButton from '../buttons/CreateButton';
import TableLoadingOverlay from './TableLoadingOverlay';
import { spacing } from '../../../themes/constants';

// based on: https://tanstack.com/table/v8/docs/examples/react/row-dnd

export type DragAndDropRowData = {
  id: string;
  order: number;
};

// hide id, order columns
const columnVisibility = { id: false, order: false };
// always sort by backend order
const sorting: SortingState = [{ id: 'order', desc: false }];

type DragAndDropTableProps<Data extends DragAndDropRowData> = {
  title?: string;
  subtitle?: string;
  isLoading?: boolean;
  data: Data[];
  columns: ColumnDef<Data, any>[];
  isReorderDisabled?: boolean;
  reorderData: (draggedId: string, targetId: string) => void;
  onEditRow?: (id: string) => void;
  onDeleteRow?: (id: string) => void;
  onCreate?: () => void;
  createButtonTitle?: string;
  boldColumn?: keyof Data;
  maxRows?: number;
  maxHeight?: string;
  marginTop?: string;
};

// note: currently no support for numeric data
// note: maxRows will hide the create button when full - usually we *don't* want this behaviour
export default function DragAndDropTable<Data extends DragAndDropRowData>({
  title,
  subtitle,
  isLoading = false,
  data,
  columns,
  isReorderDisabled = false,
  reorderData,
  onEditRow,
  onDeleteRow,
  onCreate,
  createButtonTitle,
  boldColumn,
  maxRows,
  maxHeight = '560px',
  marginTop,
}: DragAndDropTableProps<Data>) {
  const table = useReactTable({
    columns,
    data,
    state: {
      columnVisibility,
      sorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getRowId: (row) => row.id,
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <Box position="relative">
      {isLoading && <TableLoadingOverlay />}
      <TableContainer
        background="neutrals.white"
        borderRadius="10px"
        marginTop={marginTop}
      >
        {title && (
          <Box layerStyle="tableTitle">
            <VStack align="left" spacing={spacing[5]}>
              <Text
                as="h2"
                textStyle="h2"
                lineHeight="35px"
                textColor="neutrals.navigationOutline"
              >
                {title}
              </Text>
              {subtitle && (
                <Text
                  textStyle="subtitle2"
                  lineHeight="25px"
                  textColor="neutrals.brandGrey.500"
                >
                  {subtitle}
                </Text>
              )}
            </VStack>
            {createButtonTitle &&
              onCreate &&
              (maxRows === undefined || data.length < maxRows) && (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                >
                  <CreateButton
                    label={createButtonTitle}
                    onClick={onCreate}
                    isDisabled={isLoading}
                  />
                </Box>
              )}
          </Box>
        )}
        <Box
          overflow="auto"
          maxHeight={maxHeight}
          borderRadius={title ? '0px' : '10px'}
        >
          <Table variant="flowTable">
            <Thead>
              <Tr>
                {/* hamburger icon column - no header content */}
                <Th borderTop={title ? '1px solid' : 'none'} />
                {table.getHeaderGroups()[0].headers.map((header) => (
                  <Th key={header.id} borderTop={title ? '1px solid' : 'none'}>
                    <Text textStyle="subtitle4">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </Text>
                  </Th>
                ))}
                {/* options menu column - no header content */}
                <Th borderTop={title ? '1px solid' : 'none'} />
              </Tr>
            </Thead>

            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <DragAndDropRow<Data>
                  key={row.id}
                  row={row}
                  onEdit={onEditRow && (() => onEditRow(row.original.id))}
                  onDelete={onDeleteRow && (() => onDeleteRow(row.original.id))}
                  reorderRow={reorderData}
                  boldColumn={boldColumn}
                  isDisabled={isLoading}
                  isReorderDisabled={isReorderDisabled}
                />
              ))}
            </Tbody>
          </Table>
        </Box>
      </TableContainer>
    </Box>
  );
}
