export const externalLinks = {
  // TODO: provide real link for v1.1
  consent: {
    privacyPolicy: 'https://google.com',
    termsOfUse: 'https://google.com',
  },
  theme: {
    // TODO EFS-424 (v1.1): add correct link when available
    learnMore: 'https://google.com',
  },
} as const;
