import { navBarWidth } from '../constants';
import { LayerStyle } from '.';

const navigationEntryHeight = '58px';
const activeBorderWidth = '4px';

const navigationContainer: LayerStyle = {
  zIndex: 'docked',
  position: 'fixed',
  left: '0px',
  top: '0px',
  height: '100vh',
  width: navBarWidth,
  backgroundColor: 'neutrals.navigationBlack',
  textColor: 'neutrals.staticWhite',
};

const navigationApp: LayerStyle = {
  paddingRight: '20px',
  paddingBottom: '16px',
  marginLeft: '30px',
};

const navigationEntries: LayerStyle = {
  width: '100%',
};

const navigationEntryBase: LayerStyle = {
  height: navigationEntryHeight,
  width: '100%',
  borderColor: 'secondary.secondaryBlue.300',
};

const navigationEntryActive: LayerStyle = {
  height: navigationEntryHeight,
  width: `calc(100% + calc(${activeBorderWidth} / 2))`,
  backgroundColor: 'neutrals.navigationOutline',
  borderRight: `solid ${activeBorderWidth}`,
  borderColor: 'secondary.secondaryBlue.300',
};

const navigationProfile: LayerStyle = {
  width: '100%',
  position: 'absolute',
  bottom: '30px',
  paddingX: '20px',
  gap: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignContent: 'center',
};

export const navigationLayerStyles = {
  navigationContainer,
  navigationApp,
  navigationEntries,
  navigationEntryBase,
  navigationEntryActive,
  navigationProfile,
};
