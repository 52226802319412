import React, { ChangeEventHandler } from 'react';
import { strings } from '../../../utils/strings';
import Input from './Input';

type SearchInputProps = {
  placeholder?: string;
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  isValidCharacter?: (char: string) => boolean;
  setSanitizedValue?: (value: string) => void;
  isDisabled?: boolean;
};

/**
 * Typically for searching tables. The `placeholder` defaults to
 * `Search or Filter`.
 */
export default function SearchInput({
  placeholder = strings.common.searchFilter,
  ...rest
}: SearchInputProps) {
  return (
    <Input
      placeholder={placeholder}
      {...rest}
      withSearchIcon
      boxWidth="275px"
    />
  );
}
