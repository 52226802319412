import { createGqlAuthRequest } from '.';
import {
  CreateScreenDocument,
  CreateScreenMutation,
  CreateScreenMutationVariables,
  DeleteScreenDocument,
  DeleteScreenMutation,
  DeleteScreenMutationVariables,
  ScreenDocument,
  ScreenQuery,
  ScreenQueryVariables,
  ScreensDocument,
  ScreensQuery,
  ScreensQueryVariables,
  UpdateScreenDocument,
  UpdateScreenMutation,
  UpdateScreenMutationVariables,
} from '../gql/gqlRequests';

export const screensRequest = createGqlAuthRequest<
  ScreensQueryVariables,
  ScreensQuery
>(ScreensDocument);

export const screenRequest = createGqlAuthRequest<
  ScreenQueryVariables,
  ScreenQuery
>(ScreenDocument);

export const createScreenRequest = createGqlAuthRequest<
  CreateScreenMutationVariables,
  CreateScreenMutation
>(CreateScreenDocument);

export const updateScreenRequest = createGqlAuthRequest<
  UpdateScreenMutationVariables,
  UpdateScreenMutation
>(UpdateScreenDocument);

export const deleteScreenRequest = createGqlAuthRequest<
  DeleteScreenMutationVariables,
  DeleteScreenMutation
>(DeleteScreenDocument);
