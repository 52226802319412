import React, { useContext } from 'react';
import Page from '../../common/Page';
import {
  Environment,
  ScreenFeedDetailQuery,
  ScreenFeedDetailQueryVariables,
} from '../../../gql/gqlRequests';
import { useNavigate, useParams } from 'react-router-dom';
import { strings } from '../../../utils/strings';
import { Spacer, Image } from '@chakra-ui/react';
import { RequestError } from '../../../types';
import editIcon from '../../../assets/edit.svg';
import Details, {
  DetailsSection,
  SectionBody,
  SectionGrid,
  SectionTitle,
} from '../../common/Details';
import { EnvironmentContext } from '../../../contexts';
import { Route, routes } from '../../../types/routes';
import Link from '../../common/Link';
import LabelAndValue from '../../common/LabelAndValue';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { screenFeedDetailRequest } from '../../../support/screenFeed';
import { useQuery } from '@tanstack/react-query';
import TextButton from '../../common/buttons/TextButton';
import { parsedRequestError } from '../../../utils/errors';

export function ScreenFeedDetail() {
  const navigate = useNavigate();
  const params = useParams();
  const { environment } = useContext(EnvironmentContext);

  const appId = params.appId ?? '';
  const screenId = params.screenId ?? '';
  const feedId = params.feedId ?? '';

  const screenFeedQueryFn = useAuthRequest<
    ScreenFeedDetailQueryVariables,
    ScreenFeedDetailQuery
  >(screenFeedDetailRequest);

  const screenFeedQuery = useQuery<ScreenFeedDetailQuery, RequestError>({
    queryKey: ['screenFeed', screenId, feedId, environment],
    queryFn: () => screenFeedQueryFn({ screenId, feedId, environment }),

    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const screenFeedData = screenFeedQuery.data?.screenFeedByScreenAndFeedIds;

  if (screenFeedQuery.isError) throw parsedRequestError(screenFeedQuery.error);

  return (
    <Page
      isLoading={screenFeedQuery.isFetching}
      withBreadcrumbs
      withEnvironmentBar
      title={strings.screenFeed.detail}
    >
      {screenFeedData && (
        <Details>
          <DetailsSection>
            <SectionTitle title={''}>
              <TextButton
                textStyle="h2"
                label={screenFeedData.feed.name ?? ''}
                onClick={() =>
                  navigate(
                    routes.appFeedDetail({
                      appId,
                      feedId: screenFeedData.feed.id,
                    }),
                  )
                }
              />
              <Spacer />
              {environment !== Environment.Production && (
                <Link label={strings.screenFeed.edit} to={Route.Edit}>
                  <Image src={editIcon} />
                </Link>
              )}
            </SectionTitle>
            <SectionBody>
              <SectionGrid colCount={2}>
                <LabelAndValue
                  label={strings.screenFeed.layout}
                  value={screenFeedData.layout}
                />
                <LabelAndValue
                  label={strings.screenFeed.itemLayout}
                  value={screenFeedData.itemLayout}
                />
              </SectionGrid>
              <SectionGrid colCount={1}>
                <LabelAndValue
                  label={strings.screenFeed.action}
                  value={screenFeedData.action}
                />
              </SectionGrid>
              <SectionGrid colCount={2}>
                <LabelAndValue
                  label={strings.screenFeed.rowItemPageSize}
                  value={screenFeedData.rowItemPageSize}
                />
                <LabelAndValue
                  label={strings.screenFeed.rowPageSize}
                  value={screenFeedData.rowPageSize}
                />
              </SectionGrid>
              <SectionGrid colCount={2}>
                <LabelAndValue
                  label={strings.screenFeed.refreshInterval}
                  value={strings.screenFeed.refreshIntervalWithSeconds({
                    seconds:
                      screenFeedData.refreshPolicy.refreshIntervalSeconds,
                  })}
                />
                <LabelAndValue
                  label={strings.screenFeed.refreshOnScreenResume}
                  value={
                    screenFeedData.refreshPolicy.refreshOnScreenResume
                      ? strings.screenFeed.on
                      : strings.screenFeed.off
                  }
                />
              </SectionGrid>
            </SectionBody>
          </DetailsSection>
        </Details>
      )}
    </Page>
  );
}
