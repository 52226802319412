import React, { useContext, useState } from 'react';
import Moment from 'react-moment';
import {
  Box,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { Environment, Status } from '../../gql/gqlRequests';
import { displayEnum, displayGenerationStatus } from '../../utils';
import { AppContext, EnvironmentContext } from '../../contexts';
import EnvironmentPublishingMenu from './EnvironmentPublishingMenu';
import { strings } from '../../utils/strings';
import { environmentBarHeight, navBarWidth } from '../../themes/constants';
import Prompt from './Prompt';
import useAuthRequest from '../../hooks/useAuthRequest';
import { generationStatusRequest } from '../../support/apps';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../types/routes';

type EnvironmentBarProps = {
  useEnvironmentPrompt?: boolean;
  usePublishPrompt?: boolean;
  isFormDirty?: boolean;
};

export default function EnvironmentBar({
  useEnvironmentPrompt = false,
  usePublishPrompt = false,
  isFormDirty = false,
}: EnvironmentBarProps) {
  const { environment, setEnvironment } = useContext(EnvironmentContext);
  const { appData, appId } = useContext(AppContext);
  const queryClient = useQueryClient();

  const navigate = useNavigate();

  const [confirnNextEnv, setConfirmNextEnv] = useState({
    isOpen: false,
    nextEnv: Environment.Development,
  });

  const generationStatusFn = useAuthRequest(generationStatusRequest);
  const enableQuery = appData?.status.state === Status.InProgress;
  const generationStatus = useQuery({
    queryKey: ['config', 'generation', appId, environment],
    queryFn: () =>
      generationStatusFn({
        id: appId,
        environment: environment,
      }),
    enabled: enableQuery,
    onSuccess: (response) => {
      if (response.configStatus.state !== Status.InProgress) {
        queryClient.invalidateQueries({
          predicate: (query) =>
            ['status-details', 'statuses', 'app'].some((key) =>
              query.queryKey.includes(key),
            ),
        });
      }
    },
    refetchInterval: 10 * 1000, // 10s
  });

  const status = displayGenerationStatus(
    generationStatus.data?.configStatus.state ?? appData?.status.state,
  );
  return (
    <Box
      position="fixed"
      zIndex="overlay"
      left={navBarWidth}
      width={`calc(100% - ${navBarWidth})`}
      height={environmentBarHeight}
      backgroundColor="neutrals.brandGrey.100"
    >
      <FormControl height="100%">
        <Prompt
          open={confirnNextEnv.isOpen}
          onClose={() =>
            setConfirmNextEnv((prev) => ({ ...prev, isOpen: false }))
          }
          onContinue={() => {
            setConfirmNextEnv((prev) => ({ ...prev, isOpen: false }));
            setEnvironment(confirnNextEnv.nextEnv);
          }}
        />
        {/* Note: not using common `Select` since this one is relatively unique */}
        <HStack spacing="30px" height="100%" paddingRight="60px">
          <FormLabel paddingLeft="65px" marginY="auto">
            <Text textStyle="subtitle2">{strings.env.env}</Text>
          </FormLabel>
          <Select
            variant="environment"
            value={environment}
            onChange={async (e) => {
              if (useEnvironmentPrompt && isFormDirty) {
                setConfirmNextEnv({
                  isOpen: true,
                  nextEnv: e.target.value as Environment,
                });
                return;
              }
              setEnvironment(e.target.value as Environment);
            }}
            width="178px"
          >
            {/* Manually set Environments display order */}
            <option
              key={Environment.Development}
              value={Environment.Development}
            >
              {displayEnum(Environment.Development)}
            </option>
            <option key={Environment.Staging} value={Environment.Staging}>
              {displayEnum(Environment.Staging)}
            </option>
            <option key={Environment.Production} value={Environment.Production}>
              {displayEnum(Environment.Production)}
            </option>
          </Select>
          <Spacer />
          {appData?.lastPublished && (
            <Box display={'flex'} flexDirection={'column'}>
              <Text
                textStyle="bodyCopySmall"
                data-testid="last-published"
                color={'neutrals.brandGrey.500'}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate(routes.appStatus({ appId }))}
              >
                {strings.common.lastpublished}{' '}
                <Moment fromNow>{appData?.lastPublished}</Moment>
              </Text>
              <Text
                textStyle="bodyCopyVerySmall"
                color={'neutrals.brandGrey.500'}
                alignSelf={'flex-end'}
                key={status}
              >
                {status}
              </Text>
            </Box>
          )}
          <EnvironmentPublishingMenu
            withPrompt={usePublishPrompt}
            isFormDirty={isFormDirty}
          />
        </HStack>
      </FormControl>
    </Box>
  );
}
