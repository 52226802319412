import React, { Fragment, ReactNode, useContext } from 'react';
import { EnvironmentContext } from '../../contexts';
import { Environment } from '../../gql/gqlRequests';
import { Navigate } from 'react-router-dom';

type ForbidProductionEditProps = {
  children: ReactNode;
};

export default function ForbidProductionEdit({
  children,
}: ForbidProductionEditProps) {
  const { environment } = useContext(EnvironmentContext);

  if (environment === Environment.Production) {
    return <Navigate to=".." replace relative={'path'} />;
  }

  return <Fragment>{children}</Fragment>;
}
