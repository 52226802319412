import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Environment } from '../gql/gqlRequests';
import { EnvironmentContext } from '../contexts';
import { envSearchParamKey } from './useEnvironment';

/**
 * When active (via prop `isActive`), ensures the search params contain the
 * current environment.
 *
 * When inactive, ensures the search params do not contain any environment.
 *
 * If production is disallowed (via `isProductionDisallowed`), will redirect
 * any production environments to staging.
 *
 * Note: `useEnvironment` takes the search param environment as the initial
 * state on first page load, if applicable.
 */
export default function useEnvSearchParam(
  isActive: boolean,
  isProductionDisallowed = false,
) {
  const { environment, setEnvironment } = useContext(EnvironmentContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const envSearchParam = searchParams.get(envSearchParamKey);

  const isSearchParamOutOfSync = environment !== envSearchParam;
  const mustSwitchToStaging =
    isProductionDisallowed && environment === Environment.Production;

  useEffect(
    function switchToStagingIfNecessary() {
      if (isActive && mustSwitchToStaging) {
        setEnvironment(Environment.Staging);
        // the effect below will now update the search param if necessary
      }
    },
    [isActive, mustSwitchToStaging, setEnvironment],
  );

  useEffect(
    function updateSearchParamToMatchStateIfNecessary() {
      if (isActive && isSearchParamOutOfSync && !mustSwitchToStaging) {
        setSearchParams(
          (prevSearchParams) => ({
            ...prevSearchParams,
            [envSearchParamKey]: environment,
          }),
          { replace: true },
        );
      }
    },
    [
      environment,
      isActive,
      isSearchParamOutOfSync,
      mustSwitchToStaging,
      setSearchParams,
    ],
  );

  useEffect(
    function removeSearchParamIfNecessary() {
      if (!isActive && envSearchParam !== null) {
        setSearchParams(
          (prevSearchParams) => {
            prevSearchParams.delete(envSearchParamKey);
            return prevSearchParams;
          },
          { replace: true },
        );
      }
    },
    [envSearchParam, isActive, setSearchParams],
  );
}
