import React from 'react';
import { HStack, Image, Text } from '@chakra-ui/react';
import { Platform } from '../../gql/gqlRequests';
import { platformIcon } from '../../utils/platforms';
import { strings } from '../../utils/strings';

const iconDiameter = '30px';

type PlatformsListProps = {
  platforms: Platform[];
};

export default function PlatformIconsList({ platforms }: PlatformsListProps) {
  return platforms.length ? (
    <HStack spacing="10px">
      {platforms.map(({ id, name }) => (
        <Image
          key={id}
          src={platformIcon(name, 'circled')}
          alt={name}
          width={iconDiameter}
        />
      ))}
    </HStack>
  ) : (
    // height prop ensures all rows have consistent height
    <Text height={iconDiameter}>{strings.common.emptyPlaceholder}</Text>
  );
}
