import React, { ChangeEventHandler } from 'react';
import Input from './Input';

type TextAreaProps = {
  label: string;
  labelAsPlaceholder?: boolean;
  placeholder?: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  isValidCharacter?: (char: string) => boolean;
  setSanitizedValue?: (value: string) => void;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  isInvalid?: boolean;
  tooltipText?: string;
  errorMessage?: string;
  bottomRightMessage?: string;
};

/**
 * A larger input, usually for description input.
 */
export default function TextArea(props: TextAreaProps) {
  return <Input {...props} isTextArea boxWidth="794px" boxHeight="90px" />;
}
