import React from 'react';
import AuthenticationInfo from '../../common/authentication/AuthenticationInfo';
import envelopeIcon from '../../../assets/envelope.svg';
import { strings } from '../../../utils/strings';

type ResetPasswordLinkSentProps = {
  subtitle: string;
};

export default function ResetPasswordLinkSent({
  subtitle,
}: ResetPasswordLinkSentProps) {
  return (
    <AuthenticationInfo
      icon={envelopeIcon}
      iconAlt={strings.common.email}
      title={strings.authentication.checkYourInbox}
      subtitle={subtitle}
    />
  );
}
