import React from 'react';
import Button from './Button';

type PrimaryButtonProps = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

/**
 * The main, solid button, with the most padding.
 */
export default function PrimaryButton({
  label,
  onClick,
  isDisabled = false,
}: PrimaryButtonProps) {
  return (
    <Button
      type="button"
      variant="primary"
      label={label}
      onClick={onClick}
      isDisabled={isDisabled}
    />
  );
}
