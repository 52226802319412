import React from 'react';
import { HStack, Image, Text } from '@chakra-ui/react';

import warningIcon from '../../assets/warning.svg';
import checkmarkIcon from '../../assets/check-white.svg';
import { strings } from '../../utils/strings';

type ErrorBarProps = {
  message: string;
  marginTop?: string;
  marginBottom?: string;
  layerStyle?: 'errorBar' | 'successBar';
};

export default function NotificationBar({
  message,
  marginTop,
  marginBottom,
  layerStyle = 'errorBar',
}: ErrorBarProps) {
  return (
    <HStack
      layerStyle={layerStyle}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {layerStyle === 'errorBar' ? (
        <Image src={warningIcon} alt={strings.common.warning} />
      ) : (
        <Image src={checkmarkIcon} alt={strings.common.success} />
      )}
      <Text textStyle="bodyCopy" lineHeight="22px" color="neutrals.staticWhite">
        {message}
      </Text>
    </HStack>
  );
}
