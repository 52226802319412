import React, { Fragment } from 'react';
import LabelAndImage from '../../common/LabelAndImage';
import { SectionGrid } from '../../common/Details';
import LabelAndValue from '../../common/LabelAndValue';
import {
  SeriesOrMovieScreenContext,
  SharedBackgroundImageScreenContext,
} from '../../../types/screens';
import {
  AreYouStillWatchingScreen,
  AudioPlaybackScreen,
  HomeScreen,
  RegisterScreen,
  VideoPlaybackScreen,
  LoginCodeScreen,
  WelcomeScreen,
  HomeMiniPlayerScreen,
} from '../../../gql/gqlRequests';
import { RecommendationsChannelScreen } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

const { common, screenContexts } = strings;

const testIdsPrefix = 'screenContext';

// shared detail components

export function SharedBackgroundImageScreenContextDetail({
  screenContext,
}: {
  screenContext: SharedBackgroundImageScreenContext;
}) {
  return (
    <SectionGrid colCount={1}>
      <LabelAndImage
        label={screenContexts.backgroundImage}
        src={screenContext.backgroundImage?.url}
      />
    </SectionGrid>
  );
}

export function WelcomeScreenContextDetail({
  screenContext,
}: {
  screenContext: WelcomeScreen;
}) {
  return (
    <SectionGrid colCount={1}>
      <LabelAndValue
        label={screenContexts.alwaysShowOnLaunch}
        value={screenContext.alwaysShowOnLaunch ? common.show : common.dontShow}
      />
      <LabelAndImage
        label={screenContexts.backgroundImage}
        src={screenContext.backgroundImage?.url}
      />
    </SectionGrid>
  );
}

export function LoginCodeScreenContextDetail({
  screenContext,
}: {
  screenContext: LoginCodeScreen;
}) {
  return (
    <Fragment>
      <SectionGrid colCount={2}>
        <LabelAndValue
          label={screenContexts.qrCode}
          value={
            screenContext.qrCode ?? false
              ? common.activated
              : common.notActivated
          }
        />
        <LabelAndValue
          label={screenContexts.pollingInterval}
          value={`${screenContext.pollingIntervalSeconds ?? 7} ${
            screenContexts.seconds
          }`}
        />
      </SectionGrid>
      <SectionGrid colCount={1}>
        <LabelAndImage
          label={screenContexts.backgroundImage}
          src={screenContext.backgroundImage?.url}
        />
      </SectionGrid>
    </Fragment>
  );
}

export function SeriesOrMovieDetailScreenContextDetail({
  screenContext,
}: {
  screenContext: SeriesOrMovieScreenContext;
}) {
  return (
    <Fragment>
      <SectionGrid colCount={1}>
        <LabelAndValue
          label={screenContexts.backgroundImageLayout}
          testId={`${testIdsPrefix}-backgroundImageLayout`}
          value={screenContext.backgroundImageLayout}
        />
      </SectionGrid>
      <SectionGrid>
        <LabelAndImage
          label={screenContexts.primaryBackgroundImage}
          src={screenContext.primaryBackgroundImage?.url}
        />
        <LabelAndImage
          label={screenContexts.secondaryBackgroundImage}
          src={screenContext.secondaryBackgroundImage?.url}
        />
      </SectionGrid>
    </Fragment>
  );
}

// unique detail components

export function RegisterScreenContextDetail({
  screenContext,
}: {
  screenContext: RegisterScreen;
}) {
  return (
    <Fragment>
      <SectionGrid colCount={1}>
        <LabelAndValue
          label={screenContexts.passwordMinChars}
          testId={`${testIdsPrefix}-passwordMinChars`}
          value={`${screenContext.passwordMinCharacters} ${screenContexts.chars}`}
        />
      </SectionGrid>
      <SectionGrid>
        <LabelAndValue
          label={screenContexts.passwordUpperChars}
          testId={`${testIdsPrefix}-passwordUpperChars`}
          value={
            screenContext.passwordUppercase
              ? common.required
              : common.notRequired
          }
        />
        <LabelAndValue
          label={screenContexts.passwordLowerChars}
          testId={`${testIdsPrefix}-passwordLowerChars`}
          value={
            screenContext.passwordLowercase
              ? common.required
              : common.notRequired
          }
        />
        <LabelAndValue
          label={screenContexts.passwordNumberChars}
          testId={`${testIdsPrefix}-passwordNumberChars`}
          value={
            screenContext.passwordNumber ? common.required : common.notRequired
          }
        />
        <LabelAndValue
          label={screenContexts.passwordNonAlphanumericChars}
          testId={`${testIdsPrefix}-passwordNonAlphanumericChars`}
          value={
            screenContext.passwordNonAlphanumeric
              ? common.required
              : common.notRequired
          }
        />
      </SectionGrid>
      <SectionGrid colCount={1}>
        <LabelAndImage
          label={screenContexts.backgroundImage}
          src={screenContext.backgroundImage?.url}
        />
      </SectionGrid>
    </Fragment>
  );
}

export function HomeScreenContextDetail({
  screenContext,
}: {
  screenContext: HomeScreen;
}) {
  return (
    <SectionGrid colCount={1}>
      <LabelAndImage
        label={screenContexts.backgroundImage}
        src={screenContext.backgroundImage?.url}
      />
      <LabelAndValue
        label={screenContexts.backgroundImageLayout}
        testId={`${testIdsPrefix}-backgroundImageLayout`}
        value={screenContext.backgroundImageLayout}
      />
    </SectionGrid>
  );
}

export function AudioPlaybackScreenContextDetail({
  screenContext,
}: {
  screenContext: AudioPlaybackScreen;
}) {
  return (
    <Fragment>
      <VideoPlaybackScreenDetail
        screenContext={screenContext as VideoPlaybackScreen}
        displayPrerollAd={false}
      />
      <SectionGrid>
        <LabelAndValue
          label={screenContexts.imageLayout}
          testId={`${testIdsPrefix}-imageLayout`}
          value={screenContext.imageLayout}
        />
        {/* TODO v1.1: colour value */}
      </SectionGrid>
      <SectionGrid colCount={1}>
        <LabelAndImage
          label={screenContexts.backgroundImage}
          src={screenContext.backgroundImage?.url}
        />
      </SectionGrid>
    </Fragment>
  );
}

export function VideoPlaybackScreenDetail({
  screenContext,
  displayPrerollAd = true,
}: {
  screenContext: VideoPlaybackScreen;
  displayPrerollAd?: boolean;
}) {
  return (
    <SectionGrid>
      <LabelAndValue
        label={screenContexts.progressHeartbeat}
        testId={`${testIdsPrefix}-progressHeartbeat`}
        value={`${screenContext.progressHeartbeat} ${screenContexts.seconds}`}
      />
      <LabelAndValue
        label={screenContexts.upNextCountdown}
        testId={`${testIdsPrefix}-upNextCountdown`}
        value={`${screenContext.upNextCountdown} ${screenContexts.seconds}`}
      />
      {displayPrerollAd && (
        <LabelAndValue
          label={screenContexts.prerollAdFrequency}
          testId={`${testIdsPrefix}-prerollAdFrequency`}
          value={`${screenContext.prerollAdFrequency ?? 0}`}
        />
      )}
      <LabelAndValue
        label={screenContexts.upNextCue}
        testId={`${testIdsPrefix}-upNextCue`}
        value={`${screenContext.upNextCue ?? 0} ${screenContexts.seconds}`}
      />
    </SectionGrid>
  );
}

export function RecommendationsChannelScreenContextDetail({
  screenContext,
}: {
  screenContext: RecommendationsChannelScreen;
}) {
  return (
    <SectionGrid colCount={1}>
      <LabelAndImage label={screenContexts.icon} src={screenContext.icon.url} />
    </SectionGrid>
  );
}

export function AreYouStillWatchingScreenContextDetail({
  screenContext,
}: {
  screenContext: AreYouStillWatchingScreen;
}) {
  return (
    <SectionGrid>
      <LabelAndValue
        label={screenContexts.countdown}
        testId={`${testIdsPrefix}-countdown`}
        value={`${screenContext.countdown} ${screenContexts.seconds}`}
      />
      <LabelAndValue
        label={screenContexts.inactivityInterval}
        testId={`${testIdsPrefix}-inactivityInterval`}
        value={`${screenContext.inactivityInterval} ${screenContexts.seconds}`}
      />
    </SectionGrid>
  );
}

export function HomeMiniPlayerScreenContextDetail({
  screenContext,
}: {
  screenContext: HomeMiniPlayerScreen;
}) {
  return (
    <Fragment>
      <SectionGrid colCount={1}>
        <LabelAndImage
          label={screenContexts.backgroundImage}
          src={screenContext.backgroundImage?.url}
        />
      </SectionGrid>
      <SectionGrid>
        <LabelAndValue
          label={screenContexts.autoFullScreenTransition}
          value={`${screenContext.autoFullScreenTransition} ${screenContexts.seconds}`}
        />
      </SectionGrid>
    </Fragment>
  );
}
