import React, { useReducer, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { FormType, RequestError } from '../../../types';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import Form, { FormButtons, FormDivider, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import useConfigId from '../../../hooks/useConfigId';
import RadioGroup, { Radio } from '../../common/inputs/RadioGroup';
import { descriptionCharLimit, scrollToTop } from '../../../utils';
import TextArea from '../../common/inputs/TextArea';
import {
  CreateSubscriptionProductInput,
  CreateSubscriptionProductMutation,
  CreateSubscriptionProductMutationVariables,
  SubscriptionProductQuery,
  SubscriptionProductQueryVariables,
  UpdateSubscriptionProductInput,
  UpdateSubscriptionProductMutation,
  UpdateSubscriptionProductMutationVariables,
} from '../../../gql/gqlRequests';
import {
  createSubscriptionProductRequest,
  subscriptionProductRequest,
  updateSubscriptionProductRequest,
} from '../../../support/subscriptions';
import { strings } from '../../../utils/strings';

// reducer //

type InputState = {
  name: string;
  description: string;
  sku: string;
  isInUse: boolean;
};

type InputAction = {
  type: 'reset' | 'set-one' | 'set-all';
  key?: keyof InputState;
  stringValue?: string;
  booleanValue?: boolean;
  newInputState?: InputState;
};

const initialInputState: InputState = {
  name: '',
  description: '',
  sku: '',
  isInUse: true,
};

function inputDataReducer(state: InputState, action: InputAction): InputState {
  switch (action.type) {
    case 'reset':
      return initialInputState;

    case 'set-one':
      if (!action.key) return state;
      const newState = { ...state };
      if (action.key === 'isInUse') {
        newState[action.key] = action.booleanValue ?? false;
      } else {
        newState[action.key] = action.stringValue ?? '';
      }
      return newState;

    case 'set-all':
      return action.newInputState ?? state;
  }
}

// subscription product form //

type SubscriptionFormProps = {
  formType: FormType;
};

export default function SubscriptionForm({ formType }: SubscriptionFormProps) {
  const navigate = useNavigate();
  const params = useParams();
  const subscriptionId = params.subscriptionId ?? '';
  const pageRef = useRef<HTMLDivElement>(null);
  const { configId, isLoading: isConfigIdLoading } = useConfigId();

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // create

  const createMutationFn = useAuthRequest<
    CreateSubscriptionProductMutationVariables,
    CreateSubscriptionProductMutation
  >(createSubscriptionProductRequest);
  const createMutation = useMutation<
    CreateSubscriptionProductMutation,
    RequestError,
    CreateSubscriptionProductMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  function populateInputData(data: SubscriptionProductQuery) {
    const { name, description, sku, isInUse } = data.subscriptionProduct;
    dispatchInputData({
      type: 'set-all',
      newInputState: { name, description: description ?? '', sku, isInUse },
    });
  }

  const subscriptionProductQueryFn = useAuthRequest<
    SubscriptionProductQueryVariables,
    SubscriptionProductQuery
  >(subscriptionProductRequest);
  const subscriptionProductQuery = useQuery<
    SubscriptionProductQuery,
    RequestError
  >({
    queryKey: ['subscriptionProduct', subscriptionId],
    queryFn: () => subscriptionProductQueryFn({ id: subscriptionId }),
    enabled: formType === 'edit',
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });

  // update

  const updateMutationFn = useAuthRequest<
    UpdateSubscriptionProductMutationVariables,
    UpdateSubscriptionProductMutation
  >(updateSubscriptionProductRequest);
  const updateMutation = useMutation<
    UpdateSubscriptionProductMutation,
    RequestError,
    UpdateSubscriptionProductMutationVariables
  >({
    mutationFn: updateMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // input

  const [inputData, dispatchInputData] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  function setStringValue(
    key: keyof Omit<InputState, 'isInUse'>,
    stringValue: string,
  ) {
    dispatchInputData({ type: 'set-one', key, stringValue });
  }
  function setBooleanValue(
    key: keyof Pick<InputState, 'isInUse'>,
    booleanValue: boolean,
  ) {
    dispatchInputData({ type: 'set-one', key, booleanValue });
  }
  const { name, description, sku, isInUse } = inputData;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isNameValid = !!name;
  const isDescriptionWithinLimits =
    (description?.length ?? 0) <= descriptionCharLimit;
  const isSkuValid = !!sku;
  const isInputValid = isNameValid && isDescriptionWithinLimits && isSkuValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    // backend requires description to be null instead of empty string
    // TODO EFS-643: when backend allows null for description, change '' to null
    const parsedInputData = inputData; // temporary
    // const parsedInputData = { ...inputData } as CreateSubscriptionProductInput;
    // parsedInputData.description = inputData.description || null;

    if (formType === 'create') {
      createMutation.mutate({
        // submit button disabled until configId has loaded, so won't be ''
        configId,
        input: parsedInputData as CreateSubscriptionProductInput,
      });
    } else {
      updateMutation.mutate({
        id: subscriptionId,
        input: parsedInputData as UpdateSubscriptionProductInput,
      });
    }
  }

  // UI

  const title =
    formType === 'create'
      ? strings.platforms.createSubscription
      : strings.platforms.editSubscription;
  const submitText =
    formType === 'create'
      ? strings.platforms.createSubscription
      : strings.common.saveChanges;

  const isLoading =
    // initial data for create form type
    (formType === 'create' && isConfigIdLoading) ||
    // initial data for edit form type
    (formType === 'edit' && subscriptionProductQuery.isLoading) ||
    // mutations
    createMutation.isLoading ||
    updateMutation.isLoading;

  if (subscriptionProductQuery.isError)
    throw parsedRequestError(subscriptionProductQuery.error);

  return (
    <Page
      isForm
      withEnvSearchParam
      disallowProduction
      withBack
      title={title}
      pageRef={pageRef}
    >
      <Form hasError={!!errorMessage}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.platforms.subscriptionName}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading}
          value={name}
          setSanitizedValue={(value) => setStringValue('name', value)}
        />
        <TextArea
          label={strings.common.description}
          labelAsPlaceholder
          isInvalid={
            !isDescriptionWithinLimits || hasBackendError('description')
          }
          isDisabled={isLoading}
          value={description}
          setSanitizedValue={(value) => setStringValue('description', value)}
          bottomRightMessage={strings.common.nCharactersMax({
            n: descriptionCharLimit,
          })}
        />
        <FormDivider />
        <StandardInput
          label={strings.common.sku}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isSkuValid || hasBackendError('sku'))
          }
          isDisabled={isLoading}
          value={sku}
          setSanitizedValue={(value) => setStringValue('sku', value)}
        />
        <RadioGroup
          label={strings.common.status}
          isDisabled={isLoading}
          value={isInUse ? 'enabled' : 'disabled'}
          setValue={(newValue) =>
            setBooleanValue('isInUse', newValue === 'enabled')
          }
        >
          <Radio value="enabled" label={strings.common.enabled} />
          <Radio value="disabled" label={strings.common.disabled} />
        </RadioGroup>
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
