import React, { Fragment } from 'react';
import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import StandardInput from '../inputs/StandardInput';
import passIcon from '../../../assets/check-green.svg';
import failIcon from '../../../assets/check-red.svg';
import notYetIcon from '../../../assets/check-grey.svg';
import { strings } from '../../../utils/strings';
import { spacing } from '../../../themes/constants';

export type NewPasswordType = {
  setNewPassword: (password: string) => void;
  isNewInvalid?: boolean;
  setConfirmPassword: (password: string) => void;
  isConfirmInvalid?: boolean;
  requirements?: string[];
  requirementsAreMet?: boolean[];
  hasTriedToSubmit?: boolean;
};

/**
 * Sub-component for user input to create a new password, to be used
 * within an `Authentication`.
 */
export default function NewPassword({
  setNewPassword,
  isNewInvalid = false,
  setConfirmPassword,
  isConfirmInvalid = false,
  requirements = [],
  requirementsAreMet = [],
  hasTriedToSubmit = false,
}: NewPasswordType) {
  if (requirements.length !== requirementsAreMet.length) {
    throw new Error('Inconsistent password requirement lengths.');
  }

  // un-met requirements have a grey icon, unless the user tried (and
  // failed) to submit, at which point they turn red
  const yesIcon = passIcon;
  const noIcon = hasTriedToSubmit ? failIcon : notYetIcon;

  return (
    <Fragment>
      <Box height={spacing[20]} />
      <StandardInput
        isPassword
        label={strings.authentication.newPassword}
        labelAsPlaceholder
        isInvalid={hasTriedToSubmit && isNewInvalid}
        setSanitizedValue={setNewPassword}
      />

      <Box height={spacing[10]} />
      <VStack spacing={spacing[5]} align="left" paddingLeft="25px">
        {requirements.map((requirement, ind) => (
          <HStack key={requirement} spacing={spacing[10]}>
            <Image
              src={requirementsAreMet[ind] ? yesIcon : noIcon}
              alt={requirementsAreMet[ind] ? 'pass' : 'fail'}
            />
            <Text textStyle="bodyCopySmall" lineHeight="19px">
              {requirement}
            </Text>
          </HStack>
        ))}
      </VStack>

      <Box height={spacing[20]} />
      <StandardInput
        isPassword
        label={strings.authentication.confirmPassword}
        labelAsPlaceholder
        isInvalid={hasTriedToSubmit && isConfirmInvalid}
        setSanitizedValue={setConfirmPassword}
      />
    </Fragment>
  );
}
