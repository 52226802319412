import React, { useContext, useReducer, useRef, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { validateUrl } from '../../../utils/validation';
import { ActionType, FormType, RequestError } from '../../../types';
import Page from '../../common/Page';
import StandardInput from '../../common/inputs/StandardInput';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Form, { FormButtons, FormError } from '../../common/Form';
import { hasErrorForKey, parsedRequestError } from '../../../utils/errors';
import {
  AppQuery,
  AppQueryVariables,
  CreateAppMutation,
  CreateAppMutationVariables,
  UpdateAppMutation,
  UpdateAppMutationVariables,
} from '../../../gql/gqlRequests';

import {
  appRequest,
  createAppRequest,
  updateAppRequest,
} from '../../../support/apps';
import { AppContext, EnvironmentContext } from '../../../contexts';
import { strings } from '../../../utils/strings';
import { testIds } from '../../../utils/testIds';
import { scrollToTop } from '../../../utils';

// reducer //

type Action =
  | { type: ActionType.RESET }
  | { type: ActionType.SET_ALL; state: InputState }
  | {
      type: ActionType.SET_STRING;
      key: keyof Pick<InputState, 'name' | 'website'>;
      value: string;
    };

type InputState = {
  name: string;
  website: string;
};

const initialInputState = {
  name: '',
  website: '',
};

function inputDataReducer(state: InputState, action: Action) {
  const updatedState = { ...state };
  switch (action.type) {
    case ActionType.RESET:
      return initialInputState;

    case ActionType.SET_ALL:
      return action.state;

    case ActionType.SET_STRING:
      updatedState[action.key] = action.value;
      return updatedState;

    default:
      throw new Error('Unknown app input state action');
  }
}
// account form //

type AppFormProps = {
  formType: FormType;
};

export default function AppForm({ formType }: AppFormProps) {
  const navigate = useNavigate();
  const params = useParams();

  const { environment } = useContext(EnvironmentContext);

  /*
   * This form receives different url params depending on the FormType.
   * When it is an edit form then we should receive `appId`
   * When it is a create form then we should receive `accountId`
   */
  const appId = params.appId ?? '';
  const accountId = params.accountId ?? '';

  const pageRef = useRef<HTMLDivElement>(null);
  const { refetch: refetchApp } = useContext(AppContext);

  function navigateBack() {
    navigate('..', { relative: 'path' });
  }

  // create

  const createMutationFn = useAuthRequest<
    CreateAppMutationVariables,
    CreateAppMutation
  >(createAppRequest);
  const createMutation = useMutation<
    CreateAppMutation,
    RequestError,
    CreateAppMutationVariables
  >({
    mutationFn: createMutationFn,
    onError: showErrorAndScrollToTop,
    onSuccess: navigateBack,
  });

  // fetch

  const isEditForm = formType === 'edit';
  const appQueryFn = useAuthRequest<AppQueryVariables, AppQuery>(appRequest);
  const appQuery = useQuery<AppQuery, RequestError>({
    queryKey: ['form', 'app', appId],
    queryFn: () => appQueryFn({ id: appId, environment }),
    enabled: isEditForm,
    // don't refetch; would overwrite any changes user is making to inputs
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    onSuccess: populateInputData,
  });

  function populateInputData(data: AppQuery) {
    const { name, website } = data.app;
    dispatchInputState({
      type: ActionType.SET_ALL,
      state: {
        name,
        website: website ?? '',
      },
    });
  }

  // update

  const updateMutationFn = useAuthRequest<
    UpdateAppMutationVariables,
    UpdateAppMutation
  >(updateAppRequest);
  const updateMutation = useMutation<
    UpdateAppMutation,
    RequestError,
    UpdateAppMutationVariables
  >({
    mutationFn: (variables) => updateMutationFn(variables),
    onSuccess: () => {
      refetchApp();
      navigateBack();
    },
    onError: showErrorAndScrollToTop,
  });

  // input

  const [inputState, dispatchInputState] = useReducer(
    inputDataReducer,
    initialInputState,
  );
  const { name, website } = inputState;

  // validation

  /*
   * Until the user attempts to submit the form, no errors are highlighted
   * (exception: description character limits). This to avoid, for example,
   * seeing errors while typing an email, which won't be valid until near
   * the end.
   *
   * When the user attempts to submit (for the first time), front-end errors
   * are dynamically highlighted going forward. If there are no front-end
   * errors, then the form will be submitted to the back-end.
   *
   * If the submission fails on the back-end, back-end errors are statically
   * highlighted until the form is (successfully - ie passes front-end
   * validation) re-submitted, since we can't know whether the errors have
   * been addressed until re-submission. (At which point new back-end errors
   * may come back.)
   */

  const [errorMessage, setErrorMessage] = useState('');
  const isValidationActive = !!errorMessage;

  function showErrorAndScrollToTop() {
    setErrorMessage(strings.errors.missingInput);
    scrollToTop(pageRef);
  }

  // backend validation

  function hasBackendError(key: keyof InputState): boolean {
    const mutation = formType === 'create' ? createMutation : updateMutation;
    return hasErrorForKey(mutation.error, key);
  }

  // frontend validation

  const isNameValid = !!name;
  const isWebsiteValid = !website || validateUrl(website);
  const isInputValid = isNameValid && isWebsiteValid;

  // submission

  function tryToSubmit() {
    if (isInputValid) {
      submit();
    } else {
      showErrorAndScrollToTop();
    }
  }

  function submit() {
    const { name, website } = inputState;
    if (formType === 'create') {
      createMutation.mutate({
        accountId,
        input: { name, website: website || null },
      });
    } else {
      updateMutation.mutate({
        id: appId,
        input: { name, website: website || null },
      });
    }
  }

  // UI

  const title =
    formType === 'create' ? strings.apps.createApp : strings.apps.editApp;
  const submitText =
    formType === 'create' ? strings.apps.createApp : strings.common.saveChanges;

  const isLoading =
    createMutation.isLoading ||
    (appQuery.isLoading && isEditForm) ||
    updateMutation.isLoading;

  if (appQuery.isError) throw parsedRequestError(appQuery.error);

  return (
    <Page isForm withBack title={title} pageRef={pageRef}>
      <Form hasError={!!errorMessage} data-testid={testIds.app_form}>
        <FormError message={errorMessage} />
        <StandardInput
          label={strings.apps.appName}
          labelAsPlaceholder
          isInvalid={
            isValidationActive && (!isNameValid || hasBackendError('name'))
          }
          isDisabled={isLoading || isEditForm}
          value={name}
          setSanitizedValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_STRING,
              key: 'name',
              value,
            })
          }
        />
        <StandardInput
          label={strings.common.url}
          labelAsPlaceholder
          isInvalid={
            isValidationActive &&
            (!isWebsiteValid || hasBackendError('website'))
          }
          isDisabled={isLoading}
          value={website}
          setSanitizedValue={(value) =>
            dispatchInputState({
              type: ActionType.SET_STRING,
              key: 'website',
              value,
            })
          }
        />
        <FormButtons
          positiveLabel={submitText}
          positiveOnClick={tryToSubmit}
          positiveIsDisabled={isLoading}
          negativeOnClick={navigateBack}
          negativeIsDisabled={isLoading}
        />
      </Form>
    </Page>
  );
}
