import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const baseStyle = defineStyle({
  maxWidth: '215px',
  padding: '25px',
  borderWidth: '1px',
  borderColor: 'neutrals.brandGrey.300',
  borderRadius: '10px',
  backgroundColor: 'neutrals.staticWhite',
  textColor: 'neutrals.navigationOutline',
  textStyle: 'bodyCopy',
});

export const tooltipTheme = defineStyleConfig({
  baseStyle,
});
