import { LayerStyle } from '.';

const authenticationForm: LayerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexGrow: '1',
};

export const authenticationLayerStyle = {
  authenticationForm,
};
