import React, { MouseEventHandler, useState } from 'react';
import { Td, Tr } from '@chakra-ui/react';
import { flexRender, Row } from '@tanstack/react-table';
import { StandardRowData } from './StandardTable';
import TableRowMenu from './TableRowMenu';

// based on: https://codesandbox.io/s/chakra-ui-data-table-vfivp?file=/src/DataTable.tsx:1495-1917

type StandardRowProps<Data extends StandardRowData> = {
  row: Row<Data>;
  onEdit?: MouseEventHandler;
  onDelete?: MouseEventHandler;
  onCancelDelete?: MouseEventHandler;
  hasCancelRequest?: boolean;
  deleteText: string;
  boldColumn?: keyof Data;
  isDisabled?: boolean;
  withoutMenuCell?: boolean;
};

export default function StandardRow<Data extends StandardRowData>({
  row,
  onEdit,
  onDelete,
  deleteText,
  boldColumn,
  isDisabled = false,
  withoutMenuCell = false,
  hasCancelRequest = false,
  onCancelDelete,
}: StandardRowProps<Data>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { hideEdit, hideDelete } = row.original;

  return (
    <Tr>
      {row.getVisibleCells().map((cell) => (
        <Td
          key={cell.id}
          verticalAlign="top"
          textStyle={cell.column.id === boldColumn ? 'subtitle3' : 'bodyCopy'}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Td>
      ))}
      {/* if the width is not specified, the table will automatically scale it;
          we need it to be fixed - it needs to be the icon width (25) plus
          the padding on each side (30 + 30) */}
      {!withoutMenuCell && (
        <Td width="85px">
          {((onEdit && !hideEdit) || (onDelete && !hideDelete)) && (
            <TableRowMenu
              isMenuOpen={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
              onOpen={() => setIsMenuOpen(true)}
              onEdit={hideEdit ? undefined : onEdit}
              onDelete={hideDelete ? undefined : onDelete}
              deleteText={deleteText}
              isDisabled={isDisabled}
              onCancelDelete={onCancelDelete}
              hasCancel={hasCancelRequest}
            />
          )}
        </Td>
      )}
    </Tr>
  );
}
