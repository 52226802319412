import { Image, ImageType } from '../gql/gqlRequests';

export type ImageDisplay = Pick<Image, 'filename' | 'url'>;

// variables

export type UploadImageVariables = {
  acconutId: string;
  imageType: ImageType;
  formData: FormData;
};

export const ImageMaxSize: Record<ImageType, number> = {
  [ImageType.Background]: 1250000,
  [ImageType.Standard]: 30000,
};

// responses

export type UploadImageResponse = {
  image: {
    filename: string;
    key: string;
    mimeType: string;
    size: string;
    uploadedAt: string;
  };
  url: string;
};

// errors

export enum DropzoneError {
  FILE_TOO_LARGE = 'file-too-large',
  FILE_INVALID_TYPE = 'file-invalid-type',
}
