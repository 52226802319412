import React, { ReactNode } from 'react';
import { HStack, Text, VStack } from '@chakra-ui/react';
import { CSSObject } from '@emotion/react';

type LabelAndValueProps = {
  label: string;
  labelIcon?: ReactNode;
  value?: string | number | null;
  children?: ReactNode;
  noOfLines?: number;
  width?: string;
  testId?: string;
  isUrl?: boolean;
  childrenContainerProps?: CSSObject;
};

/**
 * Pass in label and string value. If the value is more than a single string
 * then pass in an array of texts/links as children instead.
 */
export default function LabelAndValue({
  label,
  labelIcon,
  value,
  children,
  width,
  noOfLines = 1,
  testId,
  isUrl = false,
  childrenContainerProps,
}: LabelAndValueProps) {
  return (
    <VStack spacing="10px" width={width} align="left">
      <HStack spacing="4px">
        <Text
          casing="uppercase"
          textStyle="subtitle4"
          textColor="neutrals.brandGrey.500"
          noOfLines={1}
          display="inline"
        >
          {label}
        </Text>
        {labelIcon}
      </HStack>
      {value !== null && value !== undefined && (
        <Text
          textStyle="bodyCopy"
          textColor="neutrals.navigationOutline"
          noOfLines={isUrl ? 0 : noOfLines}
          data-testid={testId}
          wordBreak={isUrl ? 'break-all' : 'normal'}
        >
          {value}
        </Text>
      )}
      {children && (
        <VStack
          spacing="15px"
          width={width}
          align="left"
          css={childrenContainerProps}
          textStyle="bodyCopy"
          textColor="neutrals.navigationOutline"
        >
          {children}
        </VStack>
      )}
    </VStack>
  );
}
