import React, { useContext, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import useAuthRequest from '../../../hooks/useAuthRequest';
import Page from '../../common/Page';
import { useParams } from 'react-router-dom';
import { configRequest } from '../../../support/platforms';
import { platformUrlAsName } from '../../../utils/platforms';
import { AppContext, EnvironmentContext } from '../../../contexts';
import { PlatformsContext } from '../../../contexts';
import Tabs, { TabData } from '../../common/Tabs';
import PlatformConfig from './PlatformConfig';
import { parsedRequestError } from '../../../utils/errors';
import { RequestError } from '../../../types';
import NavBarItems from './NavBarItems';
import Subscriptions from './Subscriptions';
import Integrations from './Integrations';
import { ConfigQuery, ConfigQueryVariables } from '../../../gql/gqlRequests';
import { routes } from '../../../types/routes';
import { strings } from '../../../utils/strings';

export default function Platform() {
  const { appId } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);
  const { getPlatformByName } = useContext(PlatformsContext);

  const params = useParams();
  const platformString = params.platformString;
  const platformName = platformUrlAsName(platformString);
  const platform = getPlatformByName(platformName);
  const platformId = platform?.id ?? '';

  if (!platform) {
    throw new Error('Platform not found');
  }

  // config query

  const configQueryFn = useAuthRequest<ConfigQueryVariables, ConfigQuery>(
    configRequest,
  );
  const configQuery = useQuery<ConfigQuery, RequestError>({
    queryKey: ['config', appId, platformId, environment],
    queryFn: () => configQueryFn({ appId, platformId, environment }),
  });
  const configData = configQuery.data?.app.config;

  // tabs

  const tabsData: TabData[] = useMemo(
    () =>
      configData
        ? [
            {
              path: routes.appPlatformDetail({ appId, platform }),
              label: strings.platforms.configuration,
              content: <PlatformConfig configData={configData} />,
            },
            {
              path: routes.appPlatformNavBarItems({ appId, platform }),
              label: strings.platforms.navBar,
              content: (
                <NavBarItems
                  configId={configData.id}
                  isFetching={configQuery.isFetching}
                  navBarItems={configData.navBarItems}
                  refetchConfig={configQuery.refetch}
                />
              ),
            },
            {
              path: routes.appPlatformIntegrations({ appId, platform }),
              label: strings.platforms.integrations,
              content: (
                <Integrations
                  configIntegrations={configData.integrations}
                  refetchConfig={configQuery.refetch}
                />
              ),
            },
            {
              path: routes.appPlatformSubscriptions({ appId, platform }),
              label: strings.common.subscriptions,
              content: (
                <Subscriptions
                  subscriptions={configData.subscriptionProducts}
                  refetchConfig={configQuery.refetch}
                />
              ),
            },
          ]
        : [],
    [appId, configData, configQuery.isFetching, configQuery.refetch, platform],
  );

  if (configQuery.isError) throw parsedRequestError(configQuery.error);

  return (
    <Page
      isLoading={configQuery.isLoading}
      withEnvironmentBar
      withBreadcrumbs
      title={configData?.name ?? ''}
    >
      {configData && <Tabs data={tabsData} spacing="50px" />}
    </Page>
  );
}
