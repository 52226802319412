import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
} from '@chakra-ui/react';
import React, { useRef } from 'react';

type PromptProps = {
  open?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onContinue?: () => void;
};

export default function Prompt({
  open,
  onContinue,
  onCancel,
  onClose,
}: PromptProps) {
  const cancelRef = useRef<any>();
  return (
    <>
      <AlertDialog
        isOpen={open ?? true}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Leave Page
            </AlertDialogHeader>

            <AlertDialogBody>Your changes will not be saved</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => {
                  onCancel?.();
                  onClose();
                }}
              >
                Back
              </Button>
              <Button
                colorScheme="blue"
                onClick={() => {
                  onClose();
                  onContinue?.();
                }}
                ml={3}
              >
                Proceed
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
