import React from 'react';
import { ListItem, Text, UnorderedList } from '@chakra-ui/react';
import PrimaryButton from '../buttons/PrimaryButton';
import Dialog, { DialogBody, DialogButtons } from './Dialog';
import { Platform } from '../../../gql/gqlRequests';
import { strings } from '../../../utils/strings';

type UnableToDeleteDialogProps = {
  itemType: 'Feed' | 'Screen';
  platforms: Platform[];
  isOpen: boolean;
  onClose: () => void;
};

const spaceBetweenListItems = '10px';
const spaceAboveList = '15px';
const spaceBelowList = '25px';
const listLeftPadding = '80px';

export default function UnableToDeleteDialog({
  itemType,
  platforms,
  isOpen,
  onClose,
}: UnableToDeleteDialogProps) {
  if (isOpen && platforms.length === 0) {
    throw new Error('No platforms were provided to the dialog');
  }

  return (
    <Dialog
      title={strings.dialogs.unableToDeleteItemType({ itemType })}
      isOpen={isOpen}
      onClose={onClose}
    >
      <DialogBody>
        <Text>{strings.dialogs.itemUsedOnThesePlatforms({ itemType })}</Text>
        <UnorderedList
          marginLeft={listLeftPadding}
          marginTop={spaceAboveList}
          marginBottom={spaceBelowList}
          spacing={spaceBetweenListItems}
        >
          {platforms.map(({ id, name }) => (
            <ListItem textStyle="bodyCopy" key={id}>
              {name}
            </ListItem>
          ))}
        </UnorderedList>
        <Text>{strings.dialogs.canOnlyDeleteIfNoPlatforms({ itemType })}</Text>
      </DialogBody>
      <DialogButtons>
        <PrimaryButton label={strings.common.goBack} onClick={onClose} />
      </DialogButtons>
    </Dialog>
  );
}
