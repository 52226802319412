import React, { useContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { displayEnum } from '../../../utils';
import Page from '../../common/Page';
import StandardTable from '../../common/tables/StandardTable';
import Link from '../../common/Link';
import DeleteFeedDialog from './DeleteFeedDialog';
import { AppContext, EnvironmentContext } from '../../../contexts';
import { parsedRequestError } from '../../../utils/errors';
import PlatformIconsList from '../../common/PlatformIconsList';
import useAuthRequest from '../../../hooks/useAuthRequest';
import { useQuery } from '@tanstack/react-query';
import { RequestError } from '../../../types';
import {
  Environment,
  Feed,
  FeedsQuery,
  FeedsQueryVariables,
} from '../../../gql/gqlRequests';
import { feedsRequest } from '../../../support/feeds';
import { strings } from '../../../utils/strings';
import { routes } from '../../../types/routes';

type FeedRow = Pick<Feed, 'id' | 'name' | 'createdAt' | 'platforms'> & {
  type: string;
  screenCount: number;
};

const columnHelper = createColumnHelper<FeedRow>();
const columns = [
  columnHelper.accessor('createdAt', {}),
  columnHelper.accessor('id', {}),
  columnHelper.accessor('name', {
    header: strings.feeds.feed,
    cell: (info) => <Link label={info.getValue()} to={info.row.original.id} />,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('type', {
    header: strings.common.type,
    sortingFn: 'alphanumeric',
  }),
  columnHelper.accessor('platforms', {
    header: strings.platforms.platforms,
    cell: (info) => <PlatformIconsList platforms={info.getValue()} />,
  }),
  columnHelper.accessor('screenCount', {
    header: strings.feeds.numScreens,
  }),
];

export default function Feeds() {
  const navigate = useNavigate();
  const { appId } = useContext(AppContext);
  const { environment } = useContext(EnvironmentContext);

  // feeds associated with app (for selected environment)

  const feedsQueryFn = useAuthRequest<FeedsQueryVariables, FeedsQuery>(
    feedsRequest,
  );
  const feedsQuery = useQuery<FeedsQuery, RequestError>({
    queryKey: ['feeds', appId, environment],
    queryFn: () => feedsQueryFn({ appId, environment }),
  });

  const feeds = feedsQuery.data?.feedsByApp.edges;

  // table data

  // note: platforms stored as object so can't search/filter for them
  const feedData = useMemo(
    () =>
      feeds?.map(
        ({ node: { id, name, createdAt, type, platforms, screenFeeds } }) => ({
          id,
          name,
          createdAt,
          type: displayEnum(type),
          platforms,
          screenCount: screenFeeds.length,
        }),
      ) ?? [],
    [feeds],
  );

  // deletion

  const [idToDelete, setIdToDelete] = useState<string>();
  // to get ride of the `node`, so deletion can work directly with `Feed`s
  const simplifiedFeedsData = useMemo(
    () => feeds?.map(({ node: { id, platforms } }) => ({ id, platforms })),
    [feeds],
  );

  // UI

  if (feedsQuery.isError) throw parsedRequestError(feedsQuery.error);

  return (
    <Page withEnvironmentBar title={strings.feeds.feeds}>
      <StandardTable
        title={strings.feeds.allFeeds}
        isLoading={feedsQuery.isLoading}
        data={feedData}
        columns={columns}
        onEditRow={
          environment !== Environment.Production
            ? (feedId: string) =>
                navigate(routes.editAppFeed({ appId, feedId }))
            : undefined
        }
        onDeleteRow={
          environment !== Environment.Production ? setIdToDelete : undefined
        }
        onCreate={
          environment !== Environment.Production
            ? () => navigate(routes.createAppFeed({ appId }))
            : undefined
        }
        createButtonTitle={strings.feeds.createFeed}
        boldColumn="name"
      />

      <DeleteFeedDialog
        isOpen={!!idToDelete}
        id={idToDelete}
        feeds={simplifiedFeedsData}
        onSuccess={() => feedsQuery.refetch()}
        onClose={() => setIdToDelete(undefined)}
      />
    </Page>
  );
}
