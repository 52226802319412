import { Platform } from '../gql/gqlRequests';
import { nameAsUrl } from '../utils';
// import { platformNameAsUrl } from '../utils/platforms';

// transpiling svgs from ../utils/platforms.ts file is not supported with playwright
// temporary rewriting the function here so playwright tests can still use these functions
function platformNameAsUrl(name: string) {
  if (name === 'tvOS') return 'tv-os';
  return nameAsUrl(name);
}

type Params = {
  accountId: string;
  appId: string;
  feedId: string;
  screenId: string;
  platform: Platform;
  navBarId: string;
  subscriptionId: string;
  integrationId: string;
  username: string;
  publishId: string;
};

export enum Route {
  Root = '/',
  Login = 'login',
  Register = 'register',
  Reset = 'reset',
  ForgotPassword = 'forgot-password',
  Accounts = 'accounts',
  Apps = 'apps',
  Feeds = 'feeds',
  Screens = 'screens',
  ScreenFeed = 'screen-feed',
  Theme = 'theme',
  Tags = 'tags',
  Badges = 'badges',
  TuneIn = 'tune-in',
  Platforms = 'platforms',
  Subscriptions = 'subscriptions',
  NavBar = 'nav-bar',
  Integrations = 'integrations',
  Users = 'users',
  Profile = 'profile',
  ChangePassword = 'change-password',
  Edit = 'edit',
  Create = 'create',
  Error = '*',
  PublishStatus = 'status',
}

export const routes = {
  root: Route.Root,

  login: `/${Route.Login}`,
  register: `/${Route.Register}`,
  reset: `/${Route.Reset}`,
  forgotPassword: `/${Route.ForgotPassword}`,
  profile: `/${Route.Profile}`,

  accounts: `/${Route.Accounts}`,
  accountDetail: ({ accountId }: Pick<Params, 'accountId'>) =>
    `/${Route.Accounts}/${accountId}`,
  createAccount: `/${Route.Accounts}/${Route.Create}`,
  editAccount: ({ accountId }: Pick<Params, 'accountId'>) =>
    `/${Route.Accounts}/${accountId}/${Route.Edit}`,

  appDetail: ({ appId }: Pick<Params, 'appId'>) => `/${Route.Apps}/${appId}`,
  appStatus: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/status`,
  appStatusDetail: ({
    appId,
    publishId,
  }: Pick<Params, 'appId' | 'publishId'>) =>
    `/${Route.Apps}/${appId}/status/${publishId}`,
  createAppForAccount: ({ accountId }: Pick<Params, 'accountId'>) =>
    `/${Route.Accounts}/${accountId}/${Route.Apps}/${Route.Create}`,
  editApp: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Edit}`,

  appFeeds: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Feeds}`,
  appFeedDetail: ({ appId, feedId }: Pick<Params, 'appId' | 'feedId'>) =>
    `/${Route.Apps}/${appId}/${Route.Feeds}/${feedId}`,
  createAppFeed: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Feeds}/${Route.Create}`,
  editAppFeed: ({ appId, feedId }: Pick<Params, 'appId' | 'feedId'>) =>
    `/${Route.Apps}/${appId}/${Route.Feeds}/${feedId}/${Route.Edit}`,

  appScreens: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Screens}`,
  appScreenDetail: ({ appId, screenId }: Pick<Params, 'appId' | 'screenId'>) =>
    `/${Route.Apps}/${appId}/${Route.Screens}/${screenId}`,
  appScreenFeedDetail: ({
    appId,
    screenId,
    feedId,
  }: Pick<Params, 'appId' | 'screenId' | 'feedId'>) =>
    `/${Route.Apps}/${appId}/${Route.Screens}/${screenId}/${Route.ScreenFeed}/${feedId}`,
  createAppScreen: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Screens}/${Route.Create}`,
  editAppScreen: ({ appId, screenId }: Pick<Params, 'appId' | 'screenId'>) =>
    `/${Route.Apps}/${appId}/${Route.Screens}/${screenId}/${Route.Edit}`,

  appTheme: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Theme}`,

  appTags: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Tags}`,
  appTagsBadges: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Tags}/${Route.Badges}`,
  appTagsTuneIn: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Tags}/${Route.TuneIn}`,

  appPlatforms: ({ appId }: Pick<Params, 'appId'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}`,
  appPlatformDetail: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}`,
  editAppPlatform: ({ appId, platform }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Edit}`,

  appPlatformNavBarItems: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.NavBar}`,
  createAppPlatformNavBarItem: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.NavBar}/${Route.Create}`,
  editAppPlatformNavBarItem: ({
    appId,
    platform,
    navBarId,
  }: Pick<Params, 'appId' | 'platform' | 'navBarId'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.NavBar}/${navBarId}/${Route.Edit}`,

  appPlatformSubscriptions: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Subscriptions}`,
  createAppPlatformSubscription: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Subscriptions}/${Route.Create}`,
  editAppPlatformSubscription: ({
    appId,
    platform,
    subscriptionId,
  }: Pick<Params, 'appId' | 'platform' | 'subscriptionId'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Subscriptions}/${subscriptionId}/${Route.Edit}`,

  appPlatformIntegrations: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Integrations}`,
  createAppPlatformIntegration: ({
    appId,
    platform,
  }: Pick<Params, 'appId' | 'platform'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Integrations}/${Route.Create}`,
  editAppPlatformIntegration: ({
    appId,
    platform,
    integrationId,
  }: Pick<Params, 'appId' | 'platform' | 'integrationId'>) =>
    `/${Route.Apps}/${appId}/${Route.Platforms}/${platformNameAsUrl(
      platform.name,
    )}/${Route.Integrations}/${integrationId}/${Route.Edit}`,

  users: `/${Route.Users}`,
  userDetail: ({ username }: Pick<Params, 'username'>) =>
    `/${Route.Users}/${username}`,
  createUser: `/${Route.Users}/${Route.Create}`,
  editUser: ({ username }: Pick<Params, 'username'>) =>
    `/${Route.Users}/${username}/${Route.Edit}`,
};
