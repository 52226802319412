import { OtherProps } from '@chakra-ui/react';
import { pageLayerStyles } from './page';
import { authenticationLayerStyle } from './authentication';
import { errorLayerStyles } from './error';
import { tableLayerStyles } from './table';
import { inputLayerStyles } from './input';
import { navigationLayerStyles } from './navigation';
import { localesTabListLayerStyle } from './localesTabList';
import { tagsLayerStyle } from './tags';
import { appDetailLayerStyle } from './appDetail';
import { successLayerStyles } from './success';

export type LayerStyle = OtherProps['layerStyle'];

export const layerStyles = {
  ...appDetailLayerStyle,
  ...authenticationLayerStyle,
  ...errorLayerStyles,
  ...inputLayerStyles,
  ...localesTabListLayerStyle,
  ...navigationLayerStyles,
  ...pageLayerStyles,
  ...tableLayerStyles,
  ...tagsLayerStyle,
  ...successLayerStyles,
};
