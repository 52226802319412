import React from 'react';
import { strings } from '../../../utils/strings';
import { isValidPhoneNumberCharacter } from '../../../utils/validation';
import Input from './Input';

type PhoneInputProps = {
  label?: string;
  placeholder?: string;
  description?: string;
  phonePrefix?: string;
  value: string;
  setSanitizedValue: (value: string) => void;
  isDisabled?: boolean;
  isPermanentlyDisabled?: boolean;
  isInvalid?: boolean;
  tooltipText?: string;
  errorMessage?: string;
};

/**
 * Adds the phone icon and handles the phone prefix.
 * The `label`, `placeholder`, and `phonePrefix` have default values.
 */
export default function PhoneInput({
  label = strings.common.phoneNumber,
  placeholder = strings.common.phoneNumber,
  phonePrefix = '+1 ',
  ...rest
}: PhoneInputProps) {
  return (
    <Input
      label={label}
      placeholder={placeholder}
      phonePrefix={phonePrefix}
      {...rest}
      isValidCharacter={isValidPhoneNumberCharacter}
      boxWidth="340px"
    />
  );
}
