import React, { Fragment } from 'react';
import RowInput, { RowFileInput } from '../../common/inputs/RowInput';
import { Text } from '@chakra-ui/react';
import { ViewType } from '../../../types';
import { doNothing } from '../../../utils';

type ScreenLocalizationInputProps = {
  viewType: ViewType;
  isFile?: boolean;
  label: string;
  tooltip?: string;
  keys: string[];
  values: string[];
  defaultValues?: string[];
  groupHeading?: string;
  isDisabled?: boolean;
  isOptional?: boolean;
  isValidationActive?: boolean;
  updateKeyValuePair?: (key: string, value: string) => void;
};

const screenLocalizationInputWidth = '400px';

export default function ScreenLocalizationInput(
  props: ScreenLocalizationInputProps,
) {
  if (props.isFile) {
    return <ScreenLocalizationFileInput {...props} />;
  }

  return <ScreenLocalizationStringInput {...props} />;
}

function ScreenLocalizationStringInput({
  label,
  tooltip,
  keys,
  values,
  defaultValues = [],
  updateKeyValuePair,
  groupHeading,
  isDisabled,
  isOptional,
  isValidationActive,
}: ScreenLocalizationInputProps) {
  return (
    <Fragment>
      {groupHeading && (
        <Text textStyle="h5" paddingTop="15px" paddingBottom="20px">
          {groupHeading}
        </Text>
      )}
      {keys.map((key, index) => (
        <RowInput
          key={key}
          label={index === 0 ? label : ''}
          tooltipText={tooltip}
          data-testid={`localization-input-${key}`}
          inputProps={{
            width: screenLocalizationInputWidth,
            isDisabled,
            isInvalid:
              isValidationActive && !isOptional && !values[index].trim(),
            value: values[index],
            placeholder: defaultValues[index] ?? '',
            onChange: updateKeyValuePair
              ? (e) => updateKeyValuePair(key, e.target.value)
              : undefined,
          }}
          containerStyle={{
            display: 'flex',
            alignItems: 'center',
            gap: '40px',
            paddingBottom: index === keys.length - 1 ? '30px' : '20px',
          }}
        />
      ))}
    </Fragment>
  );
}

export function ScreenLocalizationFileInput({
  updateKeyValuePair,
  keys,
  values,
  viewType,
  label,
  isDisabled,
  isOptional,
  isValidationActive,
}: ScreenLocalizationInputProps) {
  const setFileKey =
    viewType === 'form' && updateKeyValuePair
      ? (fileKey: string) => updateKeyValuePair(keys[0], fileKey)
      : doNothing;

  return (
    <RowFileInput
      label={label}
      containerStyle={{
        display: 'flex',
        alignItems: 'center',
        gap: '40px',
        paddingBottom: '30px',
      }}
      fileInputProps={{
        fileKey: values[0],
        setFileKey,
        isDisabled: isDisabled || viewType === 'detail',
        isInvalid: isValidationActive && !isOptional && !values[0],
      }}
    />
  );
}
