import React, { Fragment } from 'react';
import { Box, Center } from '@chakra-ui/react';
import Progress from '../Progress';

export default function TableLoadingOverlay() {
  return (
    <Fragment>
      <Box
        layerStyle="tableLoadingOverlay"
        data-testid="loading-table-progress"
      />
      <Center layerStyle="tableLoadingIndicator">
        <Progress size="60px" />
      </Center>
    </Fragment>
  );
}
