import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import {
  getStandardPropBasedInputStyles,
  standardInputFieldStyle,
} from './input';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

export const environment = definePartsStyle({
  field: {
    height: '39px',
    borderRadius: '10px',
    borderWidth: '2px',
    backgroundColor: 'neutrals.brandGrey.50',
    borderColor: 'neutrals.brandGrey.50',
    _focusVisible: {
      borderColor: 'secondary.secondaryBlue.500',
      boxShadow: 'none',
    },
  },
});

export const outline = definePartsStyle({
  field: {
    ...standardInputFieldStyle,
  },
});

export const selectTheme = defineMultiStyleConfig({
  variants: {
    outline: (props) => {
      return {
        field: {
          ...standardInputFieldStyle,
          ...getStandardPropBasedInputStyles(props),
          paddingLeft: '20px',
          paddingRight: '32px',
        },
      };
    },
    environment,
  },
});
