import React, { ReactNode } from 'react';
import { Link as ReactLink, RelativeRoutingType } from 'react-router-dom';
import { HStack, Link as ChakraLink, Text } from '@chakra-ui/react';

type LinkPropsType = {
  label?: string;
  isExternal?: boolean;
  to: string;
  relative?: RelativeRoutingType;
  state?: Record<string, string>;
  textStyle?: string;
  isDisabled?: boolean;
  noUnderline?: boolean;
  children?: ReactNode;
};

/**
 * Include an icon as an `<Image />` child.
 */
export default function Link({
  label,
  isExternal = false,
  to,
  relative,
  state,
  textStyle = 'subtitle2',
  isDisabled = false,
  noUnderline = false,
  children,
}: LinkPropsType) {
  return isExternal ? (
    <ChakraLink
      href={isDisabled ? undefined : to}
      textStyle={textStyle}
      textDecorationLine={isDisabled || noUnderline ? undefined : 'underline'}
      textUnderlineOffset="5px"
      textColor="neutrals.navigationOutline"
      _hover={{
        textColor: 'primary.primaryBlue.700',
      }}
      isExternal
    >
      <HStack spacing="5px">
        {children}
        <Text>{label}</Text>
      </HStack>
    </ChakraLink>
  ) : (
    <ChakraLink
      as={ReactLink}
      to={to}
      relative={relative}
      state={state}
      textStyle={textStyle}
      textDecorationLine={isDisabled || noUnderline ? undefined : 'underline'}
      textUnderlineOffset="5px"
      textColor="neutrals.navigationOutline"
      _hover={{
        textColor: 'primary.primaryBlue.700',
      }}
      onClick={isDisabled ? (e) => e.preventDefault() : undefined}
      style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
    >
      <HStack spacing="5px">
        {children}
        <Text>{label}</Text>
      </HStack>
    </ChakraLink>
  );
}
