import { createGqlAuthRequest, createGqlRequest } from '.';
import {
  ConfirmNewPasswordDocument,
  ConfirmNewPasswordMutation,
  ConfirmNewPasswordMutationVariables,
  LoginDocument,
  LoginMutation,
  LoginMutationVariables,
  LogoutDocument,
  LogoutMutation,
  LogoutMutationVariables,
  NewPasswordDocument,
  NewPasswordMutation,
  NewPasswordMutationVariables,
  RefreshTokenLoginDocument,
  RefreshTokenLoginMutation,
  RefreshTokenLoginMutationVariables,
  ResendRegistrationDocument,
  ResendRegistrationMutation,
  ResendRegistrationMutationVariables,
  ResetPasswordRequestDocument,
  ResetPasswordRequestMutation,
  ResetPasswordRequestMutationVariables,
  ValidateSessionDocument,
  ValidateSessionMutation,
  ValidateSessionMutationVariables,
  VerificationDocument,
  VerificationMutation,
  VerificationMutationVariables,
} from '../gql/gqlRequests';

export const resendRegistrationRequest = createGqlRequest<
  ResendRegistrationMutationVariables,
  ResendRegistrationMutation
>(ResendRegistrationDocument);

export const validateSessionRequest = createGqlRequest<
  ValidateSessionMutationVariables,
  ValidateSessionMutation
>(ValidateSessionDocument);

export const resetPasswordRequest = createGqlRequest<
  ResetPasswordRequestMutationVariables,
  ResetPasswordRequestMutation
>(ResetPasswordRequestDocument);

export const newPasswordRequest = createGqlRequest<
  NewPasswordMutationVariables,
  NewPasswordMutation
>(NewPasswordDocument);

export const confirmNewPasswordRequest = createGqlRequest<
  ConfirmNewPasswordMutationVariables,
  ConfirmNewPasswordMutation
>(ConfirmNewPasswordDocument);

export const verificationRequest = createGqlRequest<
  VerificationMutationVariables,
  VerificationMutation
>(VerificationDocument);

export const loginRequest = createGqlRequest<
  LoginMutationVariables,
  LoginMutation
>(LoginDocument);

export const refreshLoginRequest = createGqlRequest<
  RefreshTokenLoginMutationVariables,
  RefreshTokenLoginMutation
>(RefreshTokenLoginDocument);

// Note: use of *auth* request
export const logoutRequest = createGqlAuthRequest<
  LogoutMutationVariables,
  LogoutMutation
>(LogoutDocument);
